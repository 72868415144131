import type { WidgetProps } from '@rjsf/core';
import NovoFileUpload from 'framework/components/NovoFileUpload';
import React from 'react';

const UploadDocumentWidget = (props: WidgetProps) => {
  return (
    <NovoFileUpload
      {...{
        ...props,
      }}
      sessionKey='reconFileUpload'
    />
  );
};

export default UploadDocumentWidget;
