type LicenseFormat = {
  [key: string]: string[];
};

const driverLicenseFormats: LicenseFormat = {
  AL: ['^\\d{7}$'], //Alabama
  AK: ['^\\d{7}$'], //Alaska
  AZ: ['(^[a-zA-Z]\\d{8}$)|(^\\d{9}$)', '^[A-Za-z][0-9]{8}$', '^[0-9]{9}$'], //Arizona
  AR: ['^9\\d{8}$', '^[0-9]{4,9}$'], //Arkansas
  CA: ['^[a-zA-Z]\\d{7}$', '^[A-Za-z][0-9]{7}$'], //California
  CO: [
    '^\\d{2}-\\d{3}-\\d{4}$',
    '^[0-9]{9}$',
    '^[A-Za-z][0-9]{3,6}$',
    '^[A-Za-z]{2}[0-9]{2,5}$',
  ], //Colorado
  CT: ['^\\d{9}$', '^[0-9]{9}$'], //Connecticut
  DE: ['^\\d{7}$', '^[0-9]{1,7}$'], //Delaware
  DC: ['^\\d{7}$', '^[0-9]{7}$', '^[0-9]{9}$'], //District of Columbia
  FL: [
    '(^[a-zA-Z] \\d{3} \\d{3} \\d{3} \\d{3}$)|(^[a-zA-Z]\\d{12}$)|(^[a-zA-Z]\\d{3}-\\d{3}-\\d{2}-\\d{3}-\\d$)|(^[a-zA-Z]-\\d{3}-\\d{3}-\\d{3}-\\d{3}$)',
    '^[A-Za-z][0-9]{12}$',
  ], //Florida
  GA: ['^\\d{9}$', '^[0-9]{7,9}$', '^[0-9]{9}$'], //Georgia
  HI: ['^[a-zA-Z]\\d{8}$', '^[A-Za-z][0-9]{8}$', '^[0-9]{9}$'], //Hawaii
  ID: [
    '^[a-zA-Z]{2}\\d{6}[a-zA-Z]$',
    '^[A-Za-z]{2}[0-9]{6}[A-Za-z]{1}$',
    '^[0-9]{9}$',
  ], //Idaho
  IL: [
    '(^[a-zA-Z]\\d{3}-\\d{4}-\\d{4}$)|(^[a-zA-Z]\\d{11}$)',
    '^[A-Za-z][0-9]{11,12}$',
  ], //Illinois
  IN: ['^\\d{4}-\\d{2}-\\d{4}$', '^[A-Za-z][0-9]{9}$', '^[0-9]{9,10}$'], //Indiana
  IA: [
    '(^\\d{3}[a-zA-Z]{2}\\d{4}$)|(^\\d{9}$)',
    '^[0-9]{3}[A-Za-z]{2}[0-9]{4}$',
  ], //Iowa
  KS: [
    '^[a-zA-Z]\\d{2}-\\d{2}-\\d{4}$',
    '^[A-Za-z][0-9][A-Za-z][0-9][A-Za-z]$',
    '^[A-Za-z][0-9]{8}$',
    '^[0-9]{9}$',
  ], //Kansas
  KY: [
    '^[a-zA-Z]\\d{2}-\\d{3}-\\d{3}$',
    '^[A-Za-z][0-9]{8}$',
    '^[A-Za-z][0-9]{9}$',
    '^[0-9]{9}$',
  ], //Kentucky
  LA: ['^\\d{9}$', '^[0-9]{1,9}$', '^[0-9]{8}$'], //Louisiana
  ME: ['^\\d{7}$', '^[0-9]{7}$', '^[0-9]{7}[A-Za-z]{1}$'], //Maine
  MD: [
    '(^[a-zA-Z]-\\d{3}-\\d{3}-\\d{3}-\\d{3}$)|(^[a-zA-Z]\\d{12}$)',
    '^[A-Za-z][0-9]{12}$',
  ], //Maryland
  MA: ['^[A-Za-z][0-9]{8}$', '^[0-9]{9}$'], //Massachusetts
  MI: [
    '(^[a-zA-Z]\\s\\d{3}\\s\\d{3}\\s\\d{3}\\s\\d{3}$)|(^[a-zA-Z]\\d{12}$)',
    '^[A-Za-z][0-9]{10}$',
    '^[A-Za-z][0-9]{12}$',
  ], //Michigan
  MN: ['^[a-zA-Z]\\d{12}$', '^[A-Za-z][0-9]{12}$'], //Minnesota
  MS: ['^\\d{3}-\\d{2}-\\d{4}$', '^[0-9]{9}$'], //Mississippi
  MO: [
    '^[a-zA-Z]\\d{9}$',
    '^[0-9]{3}[A-Za-z][0-9]{6}$',
    '^[A-Za-z][0-9]{5,9}$',
    '^[A-Za-z][0-9]{6}R$',
    '^[0-9]{8}[A-Za-z]{2}$',
    '^[0-9]{9}[A-Za-z]$',
    '^[0-9]{9}$',
  ], //Missouri
  MT: [
    '(^(([0][1-9]|[1][0-2])\\d{3}([1-9][0-9]{3})41([0][1-9]|[1][0-9]|[3][0-1]))$)|(^\\d{9}$)',
    '^[0-9]{13,14}$',
    '^[0-9]{9}$',
    '^[A-Za-z]{1}[0-9]{8}$',
  ], //Montana
  NE: ['^[a-zA-Z]\\d{8}$', '[A-Za-z][0-9]{6,8}$'], //Nebraska
  NV: ['^\\d{10}$', '^[0-9]{9,10}$', '^[0-9]{12}$', '^X[0-9]{8}$'], //Nevada
  NH: [
    '^([0][1-9]|[1][0-2])[a-zA-Z]{3}\\d{2}(0[1-9]|[1-2][0-9]|3[0-1])\\d$',
    '^[0-9]{2}[A-Za-z]{3}[0-9]{5}$',
  ], //New Hampshire
  NJ: [
    '(^[a-zA-Z]\\d{4} \\d{5} \\d{5}$)|(^[a-zA-Z]\\d{14}$)',
    '^[A-Za-z][0-9]{14}$',
  ], //New Jersey
  NM: ['^\\d{9}$', '^[0-9]{8,9}$'], //New Mexico
  NY: [
    '(^\\d{3} \\d{3} \\d{3}$)|(^\\d{9}$)',
    '^[A-Za-z][0-9]{7}$',
    '^[A-Za-z][0-9]{18}$',
    '^[0-9]{16}$',
    '^[0-9]{8,9}$',
    '^[A-Za-z]{8}$',
  ], //New York
  NC: ['^\\d{12}$', '^[0-9]{1,12}$'], //North Carolina
  ND: ['^[a-zA-Z]{3}-\\d{2}-\\d{4}$', '^[A-Za-z]{3}[0-9]{6}$', '^[0-9]{9}$'], //North Dakota
  OH: ['^[A-Za-z]{2}[0-9]{6}$', '^[0-9]{9}$'], //Ohio
  OK: ['^[A-Za-z]{1}[0-9]{9}$', '^[0-9]{9}$'], //Oklahoma
  OR: ['^\\d{7}$', '^[0-9]{1,9}$'], //Oregon
  PA: ['^\\d{2}\\s\\d{3}\\s\\d{3}$', '^[0-9]{8}$'], //Pennsylvania
  RI: ['^[1-9]{2}\\d{5}$', '^[A-Za-z][0-9]{6}$'], //Rhode Island
  SC: ['^\\d{9}$', '^[0-9]{5,11}$'], //South Carolina
  SD: ['^\\d{8}$', '^[0-9]{6,10}$', '^[0-9]{12}$'], //South Dakota
  TN: ['^\\d{7,9}$', '^[0-9]{7,9}$'], //Tennessee
  TX: ['^\\d{8}$', '^[0-9]{7,8}$'], //Texas
  UT: ['^\\d{9}$', '^[0-9]{4,10}$'], //Utah
  VT: ['^[0-9]{8}$', '^[0-9]{7}A$'], //Vermont
  VA: [
    '(^[a-zA-Z]\\d{2}-\\d{2}-\\d{4}$)|(^[a-zA-Z]\\d{8}$)',
    '^[A-Za-z][0-9]{8,11}$',
    '^[0-9]{9}$',
  ], //Virginia
  WA: [
    '^[a-zA-Z]{3}\\*\\*[a-zA-Z]{2}\\d{3}[a-zA-Z]\\d$',
    '^[A-Za-z]{1,7}[A-Za-z0-9]{5,11}$',
  ], //Washington
  WV: [
    '(^[a-zA-Z]\\d{6}$)|(^\\d{7}$)',
    '^[0-9]{7}$',
    '^[A-Za-z]{1,2}[0-9]{5,6}$',
  ], //West Virginia
  WI: [
    '(^[a-zA-Z]\\d{3}-\\d{4}-\\d{4}-\\d{2}$)|(^[a-zA-Z]\\d{13}$)',
    '^[A-Za-z][0-9]{13}$',
  ], //Wisconsin
  WY: ['^\\d{6}-\\d{3}$', '^[0-9]{9,10}$'], //Wyoming
};

const validateLicenseNumberByState = (
  stateCode: string,
  number: string
): boolean => {
  return driverLicenseFormats?.[stateCode]?.some((item) => {
    const statePattern = new RegExp(item);
    return statePattern.test(number);
  });
};

export default validateLicenseNumberByState;
