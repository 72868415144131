import LabelField from './LabelField';
import PlacesAutoCompleteField from './PlacesAutoCompleteField';
import ArrayField from './ArrayField';
import { Field } from '@rjsf/core';
import CarHeaderIncludedSwitchField from './CarHeaderIncludedSwitchField';
import DriverInfoHeader from './DriverInfoHeader';
import LinkField from './LinkField';
import CustomTextField from './CustomTextField';

const additionalFields = {
  customTextField: CustomTextField,
  linkField: LinkField,
  carHeaderIncludedSwitchField: CarHeaderIncludedSwitchField,
  placesAutoCompleteField: PlacesAutoCompleteField,
  labelField: LabelField,
  driverInfoHeader: DriverInfoHeader,
  ArrayField: ArrayField as unknown as Field,
};

export default additionalFields;
