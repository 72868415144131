const DataService = () => {};
export default DataService;

export const getString = (key: string) => {
  return getItem(key);
};

export const setString = (area: string, key: string, value: string) => {
  return setJson(key, value);
};

export const getJson = (key: string) => {
  const value = getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return undefined;
};

export const setJson = (key: string, value: any) => {
  return setItem(key, JSON.stringify(value));
};

export const removeItem = (key: string) => {
  window.sessionStorage.removeItem(key);
  return true;
};

export const getItem = (key: string) => {
  const value = window.sessionStorage.getItem(key);
  if (value !== undefined && value !== null) {
    return value;
  }
  return undefined;
};

export const setItem = (key: string, value: string) => {
  window.sessionStorage.setItem(key, value);
  return true;
};
