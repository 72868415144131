import { Button, Grid } from '@mui/material';
import ButtonArrow from 'components/icons/ButtonArrow';
import Page from 'components/Page';
import SimpleDialog from 'framework/components/Dialogs/SimpleDialog';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import {
  AccordionItem,
  MuiAccordionProps,
} from 'framework/components/MuiAccordion';
import MuiLoader from 'framework/components/MuiLoader';
import { setItem } from 'framework/dataService';
import { getData } from 'framework/dataService/dataRehydration';
import { novoRoutes } from 'framework/routes';
import { saveApplicationCurrentSessionState } from 'library/services/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DriverFormWrapperParamType } from 'types/Driver';
import {
  calculateDestination,
  isUnevenMarriedDriversCount,
} from 'utils/driver';
import {
  nonPrimaryDriverFormDefinitions,
  primaryDriverFormDefinitions,
} from 'utils/driver/AccordionFormDefinitions';
import './Driver.scss';

export const DriverContext = React.createContext({
  driverData: { reload: false },
});

const DriverFormWrapper = ({
  mode,
  driverId,
  to,
}: DriverFormWrapperParamType) => {
  const navigate = useNavigate();
  const routeKey = mode === 'edit' ? 'EditDriver' : 'AddDriver';
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [expandedStatus, setExpandedStatus] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [validateMessage, setValidateMessage] = useState<string>('');
  const [showCustomDialog, setShowCustomDialog] = useState<boolean>(false);
  let readonly = false;

  const handleDialogClose = () => {
    setShowCustomDialog(false);
  };

  let /* drivers: any[], */ driverData: any;
  if (mode === 'edit') {
    const dataForFormData = getData(routeKey, { driverId });
    if (dataForFormData?.metaData) {
      readonly = true;
    }
    if (dataForFormData?.driver?.driverId) {
      driverData = { ...dataForFormData.driver };
    }
    /*  if (dataForFormData?.drivers?.length > 0) {
      drivers = [...dataForFormData.drivers];
    } */
  }

  /*   const updateStatus = (index: number, status: string, caller: string) => {
    return updateStatusImpl(index, status, caller);
  }; */

  const updateStatusImpl = async (
    index: number,
    status: string,
    caller: string,
    reloadDataValue: boolean = false
  ) => {
    let path = novoRoutes.Drivers.path;
    //To be discussed tomorrow
    const updatedForm = [...forms];
    if (updatedForm && updatedForm.length > 0) {
      switch (caller) {
        case 'onLoad':
          updatedForm[index].status = status;
          updatedForm[index].disableNext = false;
          if (status === 'done' && index + 1 < updatedForm.length) {
            // this form is done AND
            // next form is a valid form in Array
            if (
              updatedForm[0].status === 'done' &&
              updatedForm[index + 1].status === 'disabled'
            ) {
              // making sure first form is also 'done' - AND
              // next form is disabled
              // lets enable it
              updatedForm[index + 1].status = 'open';
              updatedForm[index].disableNext = false;
              setTimeout(loaderTimeout, 800);
            }
          } else {
            setTimeout(loaderTimeout, 800);
          }
          break;
        case 'onSubmit':
          updatedForm[index].status = status;
          updatedForm[index].disableNext = false;
          // update application object with backend
          const resp = await saveApplicationCurrentSessionState();
          if (!resp.success) {
            console.error(
              'Driver: Edit: Update Application Object is FAILED: Backend may not have these changes made to Driver data.'
            );
          } else {
            console.debug(
              'Driver: Edit: Update Application Object is done: Updated Driver info in backend.'
            );
          }
          if (index + 1 < updatedForm.length) {
            // next form is a valid form in Array
            // find the next disabled form and open it
            const disabledFormIndex = updatedForm.findIndex(
              (form) => form.status === 'disabled'
            );
            if (disabledFormIndex > -1) {
              updatedForm[disabledFormIndex].status = 'open';
              // updatedForm[index].disableNext = false;
            }
          } else {
            // last form
            if (mode === 'edit') {
              path = calculateDestination(routeKey, to);
            }
          }
          setTimeout(loaderTimeout, 800);
          break;
        case 'onChange':
          // if (status === 'open' && updatedForm[index].status === 'done') {
          updatedForm[index].status = status;
          updatedForm[index].disableNext = true;
          setTimeout(loaderTimeout, 800);
          break;
        default:
          console.debug('updateStatusImpl: caller not handled yet');
      }
      setReloadData(reloadDataValue);
      setForms(updatedForm);
    }
    return path;
  };

  const [forms, setForms] = useState(
    (driverData?.primary
      ? primaryDriverFormDefinitions(updateStatusImpl, driverId, mode, readonly)
      : nonPrimaryDriverFormDefinitions(
          updateStatusImpl,
          driverId,
          mode,
          readonly
        )) as MuiAccordionProps[]
  );

  useEffect(() => {
    // true when one of the form is dirty
    const checkIfDisableNext = () => {
      const BreakException = {};
      let flag = false;
      const lastFormIndex = forms.length - 1;
      try {
        forms.forEach((form, index) => {
          if (index < lastFormIndex) {
            // checking for all forms except last one
            if (form.status !== 'done') {
              flag = true;
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw BreakException;
            }
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      return flag;
    };
    setDisableNextBtn(checkIfDisableNext());
    setTimeout(loaderTimeout, 800);
  }, [forms]);

  const checkIfLastFormDisabled = (index: number) => {
    return index + 1 === forms.length && disableNextBtn;
  };

  const loaderTimeout = () => {
    setShowLoader(false);
  };

  const overwrittenHeader =
    mode === 'edit' && (!driverData?.primary || driverData?.primary === false)
      ? {
          heading1: 'q2b.drivers.edit.non.primary.heading1',
        }
      : mode === 'edit' && driverData.primary === true
      ? {
          heading1: 'q2b.drivers.edit.primary.heading1',
        }
      : {
          heading1: 'q2b.drivers.add.heading1',
        };

  const [activeAccordionExpanded, setActiveAccordionExpanded] =
    React.useState(null);

  const handleChange = (index: any) => {
    setActiveAccordionExpanded(index);
  };
  /* const checkIfAnyFormOpen = () => {
    if (mode === 'edit') {
      let result = forms.find((form: any) => form.status === 'open');
      if (result) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }; */

  const handleNextButton = async () => {
    setItem('ReconciliationViewed', 'false');
    if (to === '/plan-options/drivers') {
      const count = isUnevenMarriedDriversCount();
      if (count) {
        setValidateMessage('q2b.modal.uneven.unmarried.drivers.message');
        setShowCustomDialog(true);
      } else {
        navigate(calculateDestination(routeKey, to, novoRoutes.Drivers.path));
      }
    } else {
      navigate(calculateDestination(routeKey, to, novoRoutes.Drivers.path));
    }
  };

  const checkExpanded = (value: any) => {
    setExpandedStatus(value);
  };

  return (
    <Grid container className='quote-wrapper'>
      <SimpleDialog
        showDialog={showCustomDialog}
        handleDialogClose={handleDialogClose}
        content={{
          title: 'q2b.modal.uneven.unmarried.drivers.title',
          messages: [validateMessage],
        }}
      />
      <Page
        routeKey={routeKey}
        overWrittenHeader={overwrittenHeader}
        disabled={readonly}
        placeHolderText={`$DriverName$`}
        actualValue={driverData?.name?.firstName}
      >
        <>
          {showLoader && <MuiLoader />}
          <Grid className={`Container`}>
            <DriverContext.Provider
              value={{ driverData: { reload: reloadData } }}
            >
              {forms.map((item, index) => (
                <AccordionItem
                  // onClick={(e) => handleAccordionClick(index)}
                  key={index}
                  data={item}
                  index={index}
                  disabled={
                    item.status === 'disabled' || checkIfLastFormDisabled(index)
                    // || checkIfAnyFormOpen()
                  }
                  isLastItem={index === forms.length - 1}
                  type={`form`}
                  isExpanded={checkExpanded}
                  onChange={handleChange}
                  expanded={activeAccordionExpanded === index}
                  showOnlyOne={true}
                  mode={mode === 'edit' ? true : false}
                />
              ))}

              {!disableNextBtn &&
                forms &&
                forms.length > 0 &&
                forms[forms.length - 1].status === 'done' &&
                !expandedStatus && (
                  <Button
                    id='reviewHistory'
                    className='btn-arrow review-history-btn'
                    type='submit'
                    color='secondary'
                    variant='contained'
                    disabled={false}
                    onClick={handleNextButton}
                    endIcon={<ButtonArrow />}
                  >
                    <MicrocopyComponent
                      labelKey={`q2b.global.button.text.next`}
                    />
                  </Button>
                )}
            </DriverContext.Provider>
          </Grid>
        </>
      </Page>
    </Grid>
  );
};

export default DriverFormWrapper;
