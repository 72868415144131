import { Grid } from '@mui/material';
import Page from 'components/Page';
import TileRow from 'components/TileRow';
import CarAvatar from 'framework/components/CarAvatar';
import { getData } from 'framework/dataService/dataRehydration';
import React, { useEffect, useState } from 'react';
import { CarFormParamType } from 'types/Car';
import { setOtherAddressFields } from 'utils/car';
import { camelize } from 'utils/util';
import CarForm from './CarForm';

export default function CarFormWrapper({
  vehicleId,
  vehicleIndex,
}: CarFormParamType) {
  const routeKey = 'AddCar';
  const [car, setCar] = useState({} as any);
  const [readonly, setReadonly] = useState(false);

  useEffect(() => {
    const dataForFormData = getData(routeKey);
    if (dataForFormData?.metaData) {
      setReadonly(true);
    }
  }, []);

  useEffect(() => {
    if (vehicleId) {
      const param = {
        vehicleId: vehicleId,
      };
      const dataForFormData = getData(routeKey, param);
      if (dataForFormData?.vehicle?.vehicleId) {
        const data = dataForFormData.vehicle;
        if (data?.location?.locationId) {
          setOtherAddressFields(data.location, data, undefined);
        }
        setCar(data);
      }
    }
  }, [vehicleId]);

  const getModelName = (model: string) => {
    if (model) {
      let tokens = model.split(' ');
      let modelName = tokens[0];
      if (modelName.length === 1 && tokens.length > 1) {
        modelName = tokens[0] + ' ' + tokens[1];
      }
      return camelize(modelName);
    }
    return undefined;
  };

  return (
    <Grid container className='quote-wrapper'>
      <Page
        routeKey={routeKey}
        disabled={readonly}
        placeHolderText={`$model$`}
        actualValue={getModelName(car.model)}
      >
        <TileRow
          heading1={car.vin}
          heading2={`${car.manufacturer} ${car.model} ${car.year}`}
          showSwitch={false}
          avatar={
            <CarAvatar
              index={vehicleIndex}
              style={{ marginBottom: '0.75rem' }}
            />
          }
        />
        <CarForm carId={car.vehicleId} data={car} />
      </Page>
    </Grid>
  );
}
