import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import React, { useEffect, useState, useRef } from 'react';
import Validate from 'framework/forms/validations/Validate';
import getFormDefinitions from 'framework/schemaServer';
import { DriverFormParamType } from 'types/Driver';
import MuiLoader from 'framework/components/MuiLoader';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { getValidateDate, getAge, futureDateValidation } from 'utils/dateUtils';
import { getLocations } from 'framework/dataService/drivers';
import SimpleDialog from 'framework/components/Dialogs/SimpleDialog';
// import { isEmailExistInOtherPolicy } from 'library/services/utils';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
export default function PrimaryBasicInfo({
  mode,
  updateStatus,
  index,
  driverId,
  readonly,
}: DriverFormParamType) {
  const hasComponentMounted = useRef(false);
  const [isDirty, setIsDirty] = useState(true as boolean);
  const [showLoader, setShowLoader] = useState(false);
  const [validateMessage, setValidateMessage] = useState<string>('');
  const [showCustomDialog, setShowCustomDialog] = useState<boolean>(false);

  const [initialFormData, setInitialFormData] = useState({} as any);
  const routeKeyPrimaryDriver = 'PrimaryDriverBasicInfo';
  const { schema } = getFormDefinitions(routeKeyPrimaryDriver);
  const validateCourse = 2;
  const validateCourseMap: Record<string, number> = {
    TN: 55,
    OH: 60,
  };
  const appLocations = getLocations();
  const state: string = appLocations.filter((item: { primary: boolean }) => {
    return item.primary === true;
  })?.[0]?.address?.state;
  useEffect(() => {
    if (!hasComponentMounted.current) {
      const param = {
        driverId: driverId,
      };
      const dataForFormData = getData('EditDriver', param);
      let formData;
      if (dataForFormData?.driver?.driverId) {
        formData = { ...dataForFormData.driver };
        if (Object.keys(validateCourseMap).includes(state?.toUpperCase())) {
          formData.showAccidentPreventionCourse =
            checkAgeToDisplayAccidentPreventionCourse(formData.dob);
        }
        setInitialFormData(formData);
        // validate formData
        const errors = Validate(formData, schema);
        if (updateStatus && !errors) {
          // done: when all required fields have data
          setIsDirty(false);
          updateStatus(index, 'done', 'onLoad');
        }
      }
      hasComponentMounted.current = true;
    }
  }, [driverId, index, schema, updateStatus]);

  const checkAgeToDisplayAccidentPreventionCourse = (dob: string) => {
    const age = getAge(dob);
    return age >= validateCourseMap[state?.toUpperCase()];
  };

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    setShowLoader(true);
    // // const isEmailExist = await isEmailExistInOtherPolicy(initialFormData.email);
    // if (isEmailExist) {
    //   setShowCustomDialog(true);
    //   setValidateMessage(
    //     getMicroCopyResourceItem('q2b.primary.driver.email.validation')
    //   );
    //   return;
    // }
    const driverData = getData('EditDriver', { driverId });
    formData.residenceInfo = driverData.driver.residenceInfo;
    formData.insuranceDetails = driverData.driver.insuranceDetails;
    formData.drivingHistory = driverData.driver.drivingHistory;
    setInitialFormData(formData);
    const dataForPersistance = {
      driver: {
        ...formData,
      },
    };

    if (persistanceService) persistanceService(dataForPersistance);
    setIsDirty(false);
    if (updateStatus) updateStatus(index, 'done', 'onSubmit');
    setShowLoader(false);
  };

  const handleChange = (event: IChangeEvent<unknown>) => {
    if (!isDirty && !equals(initialFormData, event.formData)) {
      // formData just got dirty
      setIsDirty(true);
      if (updateStatus) updateStatus(index, 'open', 'onChange');
    }
    setInitialFormData(event.formData);
  };

  const handleError = async (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleDialogClose = () => {
    setShowCustomDialog(false);
    setShowLoader(false);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    let emailReg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (formData?.showAccidentPreventionCourse) {
      // eslint-disable-next-line max-len
      const courseDate = getValidateDate(formData?.accidentPreventionCourse);
      const futureDateValidate = futureDateValidation(
        formData?.accidentPreventionCourse
      );
      if (courseDate > validateCourse || futureDateValidate) {
        if (errors?.accidentPreventionCourse?.__errors.length === 0) {
          errors?.accidentPreventionCourse.addError(
            'Please enter the date of your most recent 3 years accident prevention course.'
          );
        }
      }
    }

    if (formData?.email && !emailReg.test(formData?.email)) {
      //  check email rules
      if (errors?.email?.__errors.length === 0) {
        errors?.email?.addError(`Not a valid email address`);
      }
    }
    return errors;
  };

  return (
    <>
      <SimpleDialog
        showDialog={showCustomDialog}
        handleDialogClose={handleDialogClose}
        content={{
          title: 'q2b.modal.uneven.unmarried.drivers.title',
          messages: [validateMessage],
        }}
      />
      {showLoader && <MuiLoader />}
      <NovoForm
        disabled={readonly}
        schemaName={routeKeyPrimaryDriver}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        validate={handleValidation}
        buttonName={
          <MicrocopyComponent labelKey={`q2b.global.button.text.looks.good`} />
        }
        //buttonName='looks good'
        formData={initialFormData}
        transitionTo={false}
        shadowButton={true}
        hideSubmitButton={!isDirty}
      />
    </>
  );
}
