import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function CalendarIcon({
  fillColor = 'currentColor',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      {...{ ...defaultProps, ...svgProps }}
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        d='M20.2857 13.5652C20.2857 12.9409 19.774 12.4348 19.1429 12.4348H6.85714C6.22596 12.4348 5.71429 12.9409 5.71429 13.5652C5.71429 14.1895 6.22596 14.6957 6.85714 14.6957H19.1429C19.774 14.6957 20.2857 14.1895 20.2857 13.5652Z'
        fill={fillColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 9.04348C0 6.54619 2.0467 4.52174 4.57143 4.52174H5.74286C5.72412 4.43043 5.71429 4.33592 5.71429 4.23913V1.41304C5.71429 0.63264 6.35388 0 7.14286 0C7.93184 0 8.57143 0.63264 8.57143 1.41304V4.23913C8.57143 4.33592 8.56159 4.43043 8.54285 4.52174H17.4571C17.4384 4.43043 17.4286 4.33592 17.4286 4.23913V1.41304C17.4286 0.63264 18.0682 0 18.8571 0C19.6461 0 20.2857 0.63264 20.2857 1.41304V4.23913C20.2857 4.33592 20.2759 4.43043 20.2571 4.52174H21.4286C23.9533 4.52174 26 6.54619 26 9.04348V21.4783C26 23.9755 23.9533 26 21.4286 26H4.57143C2.0467 26 0 23.9755 0 21.4783V9.04348ZM4.57143 7.34783H21.4286C22.3753 7.34783 23.1429 8.107 23.1429 9.04348V21.4783C23.1429 22.4147 22.3753 23.1739 21.4286 23.1739H4.57143C3.62466 23.1739 2.85714 22.4147 2.85714 21.4783V9.04348C2.85714 8.107 3.62466 7.34783 4.57143 7.34783Z'
        fill={fillColor}
      />
    </svg>
  );
}
