import { Button, Divider, Grid, Typography } from '@mui/material';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import ButtonArrow from 'components/icons/ButtonArrow';
import Page from 'components/Page';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import MuiDialog from 'framework/components/Dialogs/MuiDialog';
import MuiLoader from 'framework/components/MuiLoader';
import PaymentDetailBox from 'framework/components/PaymentDetailBox';
import { getJson, setItem } from 'framework/dataService';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm from 'framework/forms/NovoForm';
import { novoNavigation } from 'framework/routes';
import {
  coveragePlans,
  insuranceApplicationObject,
} from 'library/application/applicationConstants';
import { AlertProps } from 'types/GenericTypes';
import {
  agreement,
  eSignDocument,
  getQuoteServiceCall,
  retrieveApplicationDocument,
  retrieveDisclosures,
  saveApplicationCurrentSessionState,
  saveApplicationObject,
} from 'library/services/utils/index';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, notifyBugsnag, removeMarkUp } from 'utils/util';
import AlertComponent from 'components/AlertComponent';
import MuiAccordion, {
  MuiAccordionProps,
} from 'framework/components/MuiAccordion';
import { PIF } from 'library/application/applicationConstants';
import { getPrimaryDriver } from 'framework/dataService/drivers';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import { incidentFormat } from 'utils/driver/IncidentFormat';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import { uploadDocument } from 'library/services';
import { getMetaDataItem } from 'framework/dataService/insuranceApplication';
import { getAge } from 'utils/dateUtils';
import { getDrivers, getLocations } from 'framework/dataService/drivers';

export default function Disclosures() {
  const navigate = useNavigate();
  const novoRouteKey = 'Disclosures';
  const [disclosures, setDisclosures] = useState([] as any);
  const [initialFormData, setInitialFormData] = useState({} as any);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState('');
  const [policyData, setPolicyData] = useState({});
  const [displayOnLoadDialog, setDisplayOnLoadDialog] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [csrDialog, setCsrDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [documenList, setDocumentList] = useState([]);
  const [alert, setAlert] = useState<AlertProps>({});
  const [customFormContext, setCustomFormContext] = useState({});
  const applicationDrivers = getDrivers();
  const pinDriver = applicationDrivers?.filter((item: any) => {
    return item.primary;
  });
  const pniAge = getAge(pinDriver?.[0].dob);
  const maxAge = 60;
  const pniLocation = getLocations()?.filter((item: any) => {
    return item.primary;
  });
  const pniState = pniLocation?.[0].address?.state;
  const validateStates = ['OH'];

  useEffect(() => {
    setShowLoader(true);
    const dataForFormData = getData(novoRouteKey);
    if (dataForFormData?.metaData?.readonly) {
      setReadonly(true);
    }
    let applicationData = getJson(insuranceApplicationObject);
    let data = {};
    let updatedMetaData: any;
    if (dataForFormData?.metaData) {
      updatedMetaData = dataForFormData.metaData;
      updatedMetaData.showAccidentPreventionCourse =
        applicationData?.drivers[0]?.hasOwnProperty('accidentPreventionCourse');
      // if (updatedMetaData.disclosure?.status) {
      //   // prepare formData for deleted items from handleSubmit
      //   console.log('enetred-----', updatedMetaData.disclosure?.status)
      //   updatedMetaData.agreeToPdf = true;
      //   updatedMetaData.confirmDisclosures = true;
      //   const primaryDriver: any = getPrimaryDriver();
      //   const primaryDriverFullName =
      //     primaryDriver.name.firstName + ' ' + primaryDriver.name.lastName;
      //   updatedMetaData.signature = primaryDriverFullName; //full name
      // }
      if (isEmpty(updatedMetaData.agreement)) {
        setDisplayOnLoadDialog(true);
      }
      // setting data in formContext
      setCustomFormContext({
        ...customFormContext,
        agreeToPdf: updatedMetaData.agreeToPdf,
        confirmDisclosures: updatedMetaData.confirmDisclosures,
      });
    }

    const fetchData = async () => {
      applicationData.drivers.length > 0 &&
        applicationData.drivers.forEach((d: any) => {
          if (
            d?.reconciliation?.claimHistory[0] &&
            d?.reconciliation?.claimHistory[0]['incident-list'] &&
            [0, 1].includes(
              d?.reconciliation?.claimHistory[0]['incident-list'].length
            )
          ) {
            if (
              d?.reconciliation?.claimHistory[0]['incident-list'][0]?.code ===
                'UDR' &&
              d?.reconciliation?.claimHistory[0]['incident-list'][0][
                'incident-on'
              ] === ''
            ) {
              const formatedData = incidentFormat(d.drivingHistory);
              if (formatedData && formatedData.length > 0) {
                d.reconciliation.claimHistory[0]['incident-list'].push(
                  ...formatedData
                );
              }
            }
            if (
              d?.reconciliation?.claimHistory[0]['incident-list'].length === 0
            ) {
              const formatedData = incidentFormat(d.drivingHistory);
              d.reconciliation.claimHistory[0]['incident-list'] = formatedData;
            }
          }
        });
      const applicationResponse = await saveApplicationObject(applicationData);
      if (applicationResponse.error === true) {
        setAlert({
          code: 'error',
          message: applicationResponse.errorMessage,
        });
        setItem('DisclosureViewed', 'true');
        setItem('disclosureApiFailure', 'true');
        navigate(novoNavigation.Disclosures.prevPage);
      }
      const quoteResponse = await getQuoteServiceCall(
        dataForFormData.metaData.updateAndQuote
      );
      if (quoteResponse.error === true) {
        setAlert({
          code: 'error',
          message: quoteResponse.errorMessage,
        });
        setItem('DisclosureViewed', 'true');
        setItem('disclosureApiFailure', 'true');
        navigate(novoNavigation.Disclosures.prevPage);
      } else {
        let selectedPlan = quoteResponse.find(
          (o: { planName: string }) =>
            o.planName === updatedMetaData?.selectedPlanName?.toLowerCase()
        );
        if (selectedPlan) {
          const totalPremiumDue =
            updatedMetaData?.paymentMethodType?.toUpperCase() === PIF
              ? selectedPlan.pifPremium
              : selectedPlan.acpPremium;
          data = {
            ...selectedPlan,
            payment: totalPremiumDue,
            paymentMethod: updatedMetaData?.paymentMethodType?.toLowerCase(),
            policyEffectiveDate: updatedMetaData.policyEffectiveDate,
          };
          setSelectedPlanPrice(totalPremiumDue);
        }
      }

      if (dataForFormData?.drivers) {
        const includedDrivers = dataForFormData?.drivers.filter(function (
          driver: any
        ) {
          return driver.included;
        });
        data = {
          ...data,
          includedDrivers: includedDrivers.length,
        };
      }

      if (dataForFormData?.vehicles) {
        const includedVehicles = dataForFormData?.vehicles.filter(function (
          vehicle: any
        ) {
          return vehicle.included;
        });
        data = {
          ...data,
          includedVehicles: includedVehicles.length,
        };
      }
      setPolicyData(data);

      const list = await retrieveApplicationDocument();
      if (list && list.length > 0) {
        const item: { document: string }[] = [];
        setDocumentList(list);
        list.forEach((element: any) => {
          item.push({
            document: `iconAttachment|${element.title}|${element.url}`,
          });
        });
        updatedMetaData.linkToPdf = item;
      } else {
        setAlert({
          code: 'error',
          message:
            'Unable to retrieve the disclosures (PDF) contained in the sample policy forms.',
        });
        notifyBugsnag('CSR pop up validation - Document', list);
        setCsrDialog(true);
      }
      const disclosuresDefinitions = await retrieveDisclosures();
      if (
        disclosuresDefinitions instanceof Object &&
        disclosuresDefinitions.error === true
      ) {
        setAlert({
          code: 'error',
          message: disclosuresDefinitions.errorMessage,
        });
        notifyBugsnag(
          'CSR pop up validation - Disclosure',
          disclosuresDefinitions
        );
        setCsrDialog(true);
      } else {
        setDisclosures(disclosuresDefinitions);
      }
      setShowLoader(false);
    };

    fetchData();
    setItem('DisclosureViewed', 'true');
    setInitialFormData(updatedMetaData);
  }, []);

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    //upload accident prevention document
    const existingFiles = getJson('certificateFileUpload');
    if (existingFiles) {
      let policyValues = Object.values(existingFiles);
      const applicationId = getMetaDataItem('applicationId');
      policyValues.length > 0 &&
        policyValues.forEach(async (data: any) => {
          const fileData = data.split('-Name:')[0];
          fetch(fileData)
            .then((res) => res.blob())
            .then(async (blob) => {
              const file = new File([blob], 'application/pdf', {
                type: 'application/pdf',
              });
              try {
                const resp = await uploadDocument(applicationId, file);
              } catch {}
            });
        });
    }

    // Delete these keys from metaData (will be recalculated on this page load)
    delete formData.agreeToPdf;
    delete formData.linkToPdf;
    delete formData.signature;
    const dataForPersistance = {
      metaData: {
        ...formData,
      },
    };
    if (persistanceService) persistanceService(dataForPersistance);

    setShowLoader(true);
    const submitted = await eSignDocument(documenList);
    if (submitted) {
      const resp = await saveApplicationCurrentSessionState();
      if (!resp.success) {
        setAlert({
          code: 'error',
        });
      } else {
        navigate(novoNavigation.Disclosures.nextPage);
      }
    } else {
      setAlert({
        code: 'error',
      });
    }
    setShowLoader(false);
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (initialFormData.agreeToPdf !== formData.agreeToPdf) {
      setCustomFormContext({
        ...customFormContext,
        agreeToPdf: formData.agreeToPdf,
      });
    } else if (
      initialFormData.confirmDisclosures !== formData.confirmDisclosures
    ) {
      setCustomFormContext({
        ...customFormContext,
        confirmDisclosures: formData.confirmDisclosures,
      });
    }
    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    // eslint-disable-next-line max-len
    let applicationData = getJson(insuranceApplicationObject);
    const accidentPreventionCourseRequired =
      applicationData?.drivers[0]?.hasOwnProperty('accidentPreventionCourse');
    if (
      pniAge >= maxAge &&
      validateStates.includes(pniState) &&
      accidentPreventionCourseRequired &&
      !formData?.accidentPreventionCourseDocument
    ) {
      //
      errors.accidentPreventionCourseDocument.addError(
        'Please upload your accident prevention course certificate within 3 years'
      );
    }
    return errors;
  };

  const handleOnLoadDialog = async (value: any) => {
    // causes popup reload in else when displaying error
    setShowLoader(true);
    const submitted = await agreement();
    if (submitted) {
      setDisplayOnLoadDialog(value);
    } else {
      setAlert({
        code: 'error',
        message: 'System is not available at this time. Please Try Again!',
      });
    }
    setShowLoader(false);
  };

  return (
    <>
      <CsrDialog
        showDialog={csrDialog}
        handleDialogClose={() => setCsrDialog(false)}
      />
      <Grid container className='quote-wrapper disclosures-wrapper'>
        <Page routeKey={novoRouteKey} disabled={readonly}>
          {showLoader && <MuiLoader />}
          {!showLoader && displayOnLoadDialog && disclosures?.length > 0 && (
            <MuiDialog
              open={displayOnLoadDialog}
              onClose={handleOnLoadDialog}
              alert={alert}
              contentData={disclosures[0]}
              closeOutSide={false}
              closeButtonText={
                <MicrocopyComponent
                  labelKey={`q2b.global.button.text.i.agree`}
                />
              }
            />
          )}
          {alert.code && (
            <AlertComponent message={alert.message} type={alert.code} />
          )}
          {!isEmpty(policyData) && (
            <PaymentDetailBox
              paymentTitle={
                <MicrocopyComponent
                  labelKey={`q2b.disclosures.payment.title`}
                />
              }
              price={selectedPlanPrice}
              policyData={policyData}
            />
          )}
          <Divider
            sx={{ borderBottomWidth: 2 }}
            style={{
              width: '100%',
              borderColor: '#E8E9EE',
              marginTop: '30px',
              marginBottom: '30px',
            }}
          />
          {/* {appDocUrl.length > 0 && <PdfRenderer url={appDocUrl} />} */}
          <NovoForm
            disabled={readonly}
            schemaName={novoRouteKey}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onError={handleError}
            validate={handleValidation}
            formData={initialFormData}
            transitionTo={false}
            formContext={customFormContext}
            button={
              <>
                <Divider
                  style={{
                    width: '100%',
                    borderColor: '#e8e9ee',
                    borderWidth: 'thin',
                    // marginTop: '-1rem',
                    marginBottom: '1.5rem',
                  }}
                />
                <Button
                  id='disclosureButton'
                  className='btn-arrow'
                  type='submit'
                  color='secondary'
                  variant='contained'
                  disabled={!initialFormData.signature}
                  endIcon={<ButtonArrow />}
                >
                  {readonly ? (
                    <MicrocopyComponent
                      labelKey={`q2b.global.button.text.next`}
                    />
                  ) : (
                    <MicrocopyComponent
                      labelKey={`q2b.global.button.text.go.to.payment`}
                    />
                  )}
                </Button>
              </>
            }
          >
            {disclosures?.length > 0 && (
              <MuiAccordion data={disclosures as MuiAccordionProps[]} />
            )}
            <Typography className='page-header-h5' mb={4}>
              Disclosures
            </Typography>
            <Divider
              style={{
                width: '100%',
                borderColor: '#000000',
                borderWidth: 'thin',
              }}
            />
            <Typography
              className='page-header-h5'
              style={{ marginTop: '25px' }}
              mb={4}
            >
              Acknowledgements
            </Typography>
          </NovoForm>
        </Page>
      </Grid>
    </>
  );
}
