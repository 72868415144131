import { Button, Grid } from '@mui/material';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { getCoverageByVehicleId } from 'framework/dataService/coverage';
import { getData } from 'framework/dataService/dataRehydration';
import { getVehicles, setVehicle } from 'framework/dataService/vehicles';
import NovoForm from 'framework/forms/NovoForm';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'utils/util';

interface CarsCoverageProps {
  carsList: any[];
  defaultVehicleCoverage: any;
  coverageIndex?: number;
  coverages: any;
  errorHandling: any;
  updateFormDirtyFlag: any;
  submitBtnRef: any;
  onValidateTabs: any;
  currentTab: any;
  readonly: boolean;
}

const CarsCoverage: React.FC<CarsCoverageProps> = ({
  carsList,
  defaultVehicleCoverage,
  coverageIndex,
  coverages,
  errorHandling,
  updateFormDirtyFlag,
  submitBtnRef,
  onValidateTabs,
  currentTab,
  readonly,
}) => {
  const routeKey = 'VehicleCoverages';
  const [initialFormData, setInitialFormData] = useState({} as any);

  useEffect(() => {
    const prepareFormData = (dataList: any) => {
      // formData with array of objects with driverId and reconciliationList
      const formData: any = [];

      dataList.forEach((car: any) => {
        if (!isEmpty(car.vehicleId)) {
          let coverageToShow =
            getCoverageByVehicleId(car.vehicleId, coverageIndex) ||
            defaultVehicleCoverage;
          const isOwned = car.ownershipStatus === 'Own';
          const allowACPE =
            coverageToShow?.collisionDeductible !== 'DECLINED' ||
            coverageToShow?.comprehensiveDeductible !== 'DECLINED';
          formData.push({
            ...coverageToShow,
            manufacturer: car.manufacturer,
            included: car.included,
            vin: car.vin,
            model: car.model,
            year: car.year,
            isOwned: isOwned,
            allowACPE: allowACPE,
            vehicleId: car.vehicleId,
          });
        }
      });
      return formData;
    };

    const dataForFormData = getData('Cars');
    const formData = prepareFormData(dataForFormData.vehicles);
    setInitialFormData({ vehiclesList: formData });
  }, [defaultVehicleCoverage, carsList]);

  const handleSubmit = (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    const allVehicles = getVehicles();

    const getUpdatedFormData = (item: any, index: number) => {
      //updating included flag in the vehicle list of application object
      allVehicles[index].included = item.included;
      setVehicle(allVehicles[index]);

      delete item.vin;
      delete item.included;
      delete item.manufacturer;
      delete item.model;
      delete item.year;
      //return item;
      if (allVehicles[index].included) {
        return item;
      }
    };
    const vehicleCoverageData = formData.vehiclesList.map(getUpdatedFormData);
    //filtering the vehicle coverage which are undefined
    const filteredVehicleCoverageData = vehicleCoverageData.filter(
      (vehicleCoverageItem: any) => {
        return (
          vehicleCoverageItem !== null &&
          typeof vehicleCoverageItem !== 'undefined'
        );
      }
    );
    const dataForPersistance = {
      vehicleCoverage: filteredVehicleCoverageData,
    };

    if (persistanceService) persistanceService(dataForPersistance);
    if (onValidateTabs) onValidateTabs({ name: '', url: '' });
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    const { vehiclesList } = formData;
    vehiclesList.forEach((car: any) => {
      const allowACPE =
        car.collisionDeductible !== 'DECLINED' ||
        car.comprehensiveDeductible !== 'DECLINED';
      car.allowACPE = allowACPE;
      if (!allowACPE) {
        delete car.acpe;
      }
    });
    setInitialFormData(formData);
    updateFormDirtyFlag(true);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
    errorHandling(errors);
    if (onValidateTabs) onValidateTabs(currentTab);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    const { vehiclesList } = formData;

    const data = vehiclesList.find((vehicle: any) => vehicle.included);
    if (!data) {
      errors.vehiclesList.addError('At least one car must be included.');
      if (onValidateTabs) onValidateTabs(currentTab);
    } else {
      //if (onValidateTabs) onValidateTabs({ name: '', url: '' });
    }

    return errors;
  };

  const getVehicleDataByIndex = (index: number) => {
    if (index >= 0 && index < initialFormData?.vehiclesList?.length) {
      return initialFormData.vehiclesList[index];
    }
  };

  const customFormContext = { getVehicleDataByIndex };

  return (
    <Grid container className=''>
      <NovoForm
        disabled={readonly}
        transitionTo={false}
        schemaName={routeKey}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        formData={initialFormData}
        formContext={customFormContext}
        validate={handleValidation}
        button={
          <Button
            ref={submitBtnRef}
            id='cars-coverage-button'
            className='btn-arrow'
            type='submit'
            style={{ display: 'none' }}
          >
            {/* Next */}
            <MicrocopyComponent labelKey={`q2b.global.button.text.next`} />
          </Button>
        }
      />
    </Grid>
  );
};

export default CarsCoverage;
