import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function DottedTailArrowDown({
  fillColor = 'currentColor',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      {...{ ...defaultProps, ...svgProps }}
    >
      <path
        d='M11.308 13.1382L11.308 23.1684C11.308 23.5043 11.5145 23.8081 11.8301 23.9367C12.1466 24.0653 12.5104 23.9943 12.7524 23.7565L22.9062 13.7813C23.237 13.4564 23.237 12.9309 22.9062 12.606C22.5753 12.2811 22.0406 12.2811 21.7097 12.606L13.0003 21.1614L13.0003 13.139C13.0003 12.6787 12.6213 12.3073 12.1542 12.3073C11.6871 12.3073 11.308 12.6787 11.308 13.1382Z'
        fill={fillColor}
      />
      <path
        d='M1.1543 13.1924C1.1543 13.4047 1.23722 13.618 1.40222 13.7804L11.4139 23.6736C11.7447 23.9985 12.2795 23.9985 12.6103 23.6736C12.9412 23.3487 12.9412 22.8232 12.6103 22.4983L2.59867 12.6051C2.26783 12.2802 1.73306 12.2802 1.40222 12.6051C1.23722 12.7668 1.1543 12.98 1.1543 13.1924Z'
        fill={fillColor}
      />
      <path
        d='M11.308 13.1382L11.308 23.1684C11.308 23.5043 11.5145 23.8081 11.8301 23.9367C12.1466 24.0653 12.5104 23.9943 12.7524 23.7565L22.9062 13.7813C23.237 13.4564 23.237 12.9309 22.9062 12.606C22.5753 12.2811 22.0406 12.2811 21.7097 12.606L13.0003 21.1614L13.0003 13.139C13.0003 12.6787 12.6213 12.3073 12.1542 12.3073C11.6871 12.3073 11.308 12.6787 11.308 13.1382Z'
        stroke={fillColor}
      />
      <path
        d='M1.1543 13.1924C1.1543 13.4047 1.23722 13.618 1.40222 13.7804L11.4139 23.6736C11.7447 23.9985 12.2795 23.9985 12.6103 23.6736C12.9412 23.3487 12.9412 22.8232 12.6103 22.4983L2.59867 12.6051C2.26783 12.2802 1.73306 12.2802 1.40222 12.6051C1.23722 12.7668 1.1543 12.98 1.1543 13.1924Z'
        stroke={fillColor}
      />
      <circle cx='12' cy='1.5' r='1.5' fill={fillColor} />
      <circle cx='12' cy='7.5' r='1.5' fill={fillColor} />
    </svg>
  );
}
