import type { WidgetProps } from '@rjsf/core';
import LabelWithIcon from 'framework/components/LabelWithIcon';
import React from 'react';

const LabelWithIconWidget = (props: WidgetProps) => {
  const { rawErrors } = props;
  return (
    <LabelWithIcon
      {...{
        ...props,
        isError: rawErrors && rawErrors.length > 0,
      }}
    />
  );
};

export default LabelWithIconWidget;
