import { getItem, getJson, setItem, setJson } from 'framework/dataService';
import {
  getMicrocopyResourceObject,
  setMicrocopyResourceObject,
} from 'framework/dataService/contentfulObject';
import Bugsnag from '@bugsnag/js';
import { getPrimaryDriver } from 'framework/dataService/drivers';
import { getPrimaryLocation } from 'framework/dataService/locations';
import {
  getApplicationObject,
  getApplicationObjectItem,
  getMetaDataItem,
  setMetaDataItem,
  setApplicationObject,
  setMetaData,
} from 'framework/dataService/insuranceApplication';
import { prepareLocationObject } from 'framework/dataService/locations';
import { novoRoutes } from 'framework/routes';
import {
  applicationForms,
  coveragePlans,
  paymentConstant,
  policyInfo,
  progressConstant,
} from 'library/application/applicationConstants';
import {
  addDriver,
  addVehicle,
  createApplication,
  getCardConnectToken,
  getDisclosures,
  getQuote,
  isStateInServiceArea,
  /* login, */
  paymentAndBind,
  retrieveApplication,
  retrieveApplicationForms,
  retrieveReconciliation,
  saveApplication,
  /* socialLogin, */
  fetchMicrocopySetFromContentful,
  getClientIP,
  eSignDocumentApiCall,
  agreementApiCall,
  verifyExistingApplication,
  getApplicationDocumentApiCall,
  createUserAccount,
  getActivePolicies,
  getActivePoliciesByVin,
} from 'library/services';
import defaultMicrocopySet from 'messages/MicrocopyDefaults.json';
import { getToday } from 'utils/dateUtils';
import { isEmpty, notifyBugsnag } from 'utils/util';
import trackingService from 'utils/trackingService';
import { capitalCase, pascalCase } from 'change-case';
import {
  annualMileageFormat,
  dlFormat,
  educationFormat,
  residentTypeFormat,
} from './prefillFormat';
import { sendGtmEvent } from 'utils/trackingDataLayer';

export async function createApplicationAndHandleNavigation(formData: any) {
  let isExistingApplication = false;
  let isStateAvailable = false;
  let errorMessage;
  let isApplicationFetched = false;
  let applicationId = '';
  const { location, name, dob, email, apt, city, state, zipCode } = formData;
  const { streetNumber, streetName, defaultAddress, fullAddress } = location;
  const addressForPayload =
    streetNumber && streetName
      ? `${streetNumber} ${streetName}`
      : defaultAddress || fullAddress;
  const everflowDetails = getJson('everflow');
  let userAgentInfo = window?.navigator?.userAgent ?? '';
  if (everflowDetails && everflowDetails.ef_transaction_id) {
    userAgentInfo = userAgentInfo
      ? `${window?.navigator?.userAgent} EF_ID/${everflowDetails.ef_transaction_id}`
      : `EF_ID/${everflowDetails.ef_transaction_id}`;
  }

  let payload = {
    name: name,
    dob: dob,
    address: addressForPayload,
    apt: apt,
    city: city,
    state: state,
    zipCode: zipCode,
    email: email,
    q2bVersion: '2.0',
    partner: getItem('partnerQuote') || '',
    clientMetadata: {
      userAgent: userAgentInfo,
      ipAddress: '',
    },
    producerId: '',
    channelName: '',
  };
  if (getItem('agentId') && getItem('channelName')) {
    payload.producerId = getItem('agentId') as any;
    payload.channelName = getItem('channelName') as any;
  }
  let applicationResponse = {} as any;
  const response = await createApplication(payload);
  if (response?.error) {
    console.error(response.errorMessage);
    errorMessage = response.errorMessage;
    notifyBugsnag('Application create API', response);
  } else {
    applicationResponse = {
      ...response.response,
      metaData: {
        ...response.response.metaData,
        progress: {
          ...response.response.metaData.progress,
          completedRoute: 1,
        },
      },
      accountInformation: {},
      coverage: {},
    };
    window.sessionStorage.setItem(
      'applicationId',
      response?.response?.metaData?.applicationId
    );
    setApplicationObject(applicationResponse);
    isStateAvailable = true;
  }

  return {
    applicationId,
    isApplicationFetched,
    isStateAvailable,
    isExistingApplication,
    errorMessage,
  };
}

export async function makeServiceCallsAndHandleNavigation(
  formData: any,
  addressChanged: boolean
) {
  let isExistingApplication = false;
  let isStateAvailable = false;
  let errorMessage, isError;
  let isApplicationFetched = false;
  /* let fetchedApplicationId = ''; */
  let applicationId = getMetaDataItem('applicationId');
  if (applicationId && !addressChanged) {
    isExistingApplication = true;
    // const existingForms = getJson(applicationForms);
    if (formData?.state) {
      const resp = await retrieveApplicationForms(formData.state);
      if (resp.error === false) {
        if (
          resp.response &&
          typeof resp.response === 'object' &&
          Object.keys(resp.response).includes('message')
        ) {
          isStateAvailable = false;
        } else {
          setJson(applicationForms, resp.response);
          isStateAvailable = true;
        }
      } else {
        notifyBugsnag('Dynamic forms API', resp);
        console.error('Unable to retrieve forms');
      }
    }
    // TODO: Q2B-532 If any data from this page is updated in this submission - we are not handling it
    // Make a saveApplication call
    const resp = await saveApplicationCurrentSessionState(false, formData);
    if (!resp.success) {
      console.error(
        'Car: Search: handleSearch: Update Application Object is FAILED: Backend may still have the Searched Vehicle with bad data.'
      );
    } else {
      console.debug(
        'Car: Search: handleSearch: Update Application Object is done: Removed the Searched Vehicle from backend.'
      );
    }
  } else {
    const { location, name, dob, email, apt, city, state, zipCode } = formData;
    const { streetNumber, streetName, defaultAddress, fullAddress } = location;
    const addressForPayload =
      streetNumber && streetName
        ? `${streetNumber} ${streetName}`
        : defaultAddress || fullAddress;
    /*  let applicationResponse = {} as any; */
    const data = await isStateInServiceArea(state);

    if (
      !isEmpty(data.response) &&
      data.response === 'serviced' &&
      data.error === false
    ) {
      const resp = await retrieveApplicationForms(state);
      isStateAvailable = true;
      if (resp.error === false) {
        if (
          resp.response &&
          typeof resp.response === 'object' &&
          Object.keys(resp.response).includes('message')
        ) {
          isStateAvailable = false;
        } else {
          setJson(applicationForms, resp.response);
          const payload = {
            name: name,
            dob: dob,
            address: addressForPayload,
            apt: apt,
            city: city,
            state: state,
            zipCode: zipCode,
            email: email,
            partner: getItem('partnerQuote') || '',
          };
          const verifyExistingApplicationResponse =
            await verifyExistingApplication(payload);
          if (verifyExistingApplicationResponse?.error) {
            //if
            console.error(verifyExistingApplicationResponse.errorMessage);
            notifyBugsnag(
              'Verify exsisting application API',
              verifyExistingApplicationResponse
            );
            const createApplicationResponse =
              await createApplicationAndHandleNavigation(formData);
            return createApplicationResponse;
          } else {
            const { response } = verifyExistingApplicationResponse;
            if (!isEmpty(response)) {
              applicationId = response.applicationId;
              window.sessionStorage.setItem('applicationId', applicationId);
              if (applicationId) {
                isApplicationFetched = true;
              }
            } else {
              //create application and go to next screen
              const createApplicationResponse =
                await createApplicationAndHandleNavigation(formData);
              return createApplicationResponse;
            }
          }
        }
      } else {
        notifyBugsnag('Dynamic forms API', resp);
      }
    } else if (data.error === true) {
      errorMessage = data.errorMessage;
      isError = data.error;
      notifyBugsnag('State service API', data);
    }
  }

  return {
    applicationId,
    isApplicationFetched,
    isStateAvailable,
    isExistingApplication,
    errorMessage,
    isError,
  };
}

/* export async function retrieveApplicationServiceCall(formData: any) {
  let status = false;
  let data = await login(formData);
  if (isEmpty(data.response) && data.error === true) {
    return {
      success: status,
      message: data.errorMessage.data,
    };
  } else if (!isEmpty(data.response) && data.error === false) {
    const loginResponse: any = data.response;
    const policyId = loginResponse.policy_id;
    const applicationId = loginResponse.application_id;
    if (policyId) {
      return {
        success: status,
        message:
          'You have an existing policy with us. Please login to the policy website.',
      };
    }
    if (applicationId) {
       const existingForms = getJson(applicationForms);
      if (formData?.username) {
        status = await retrieveApplicationById(applicationId);
      }
    }
  }
  return {
    success: status,
  };
} */

export async function retrieveApplicationById(applicationId: string) {
  let status = false;
  const resp = await retrieveApplication(applicationId);
  if (resp.error === false) {
    const applicationResponse: any = resp.response;
    // manipulate application object
    // The below driver attribute conversion should be removed in future and backend needs to send the correct format
    if (applicationResponse?.drivers.length > 0) {
      applicationResponse.drivers.map((d: any) => {
        d.maritalStatus = d.maritalStatus ? capitalCase(d.maritalStatus) : '';
        d.gender = d.gender ? capitalCase(d.gender) : '';
        d.education = d.education ? educationFormat(d.education) : 'Unknown';
        d.driverLicenseStatus = d.driverLicenseStatus
          ? dlFormat(d.driverLicenseStatus)
          : 'Active/Valid';
        d.residenceInfo.residenceType = d.residenceInfo?.residenceType
          ? residentTypeFormat(d.residenceInfo.residenceType)
          : 'Own home';
      });
    }
    if (applicationResponse?.vehicles.length > 0) {
      applicationResponse.vehicles.map((d: any) => {
        d.ownershipStatus = d.ownershipStatus
          ? capitalCase(d.ownershipStatus)
          : '';
        d.annualMileage = d.annualMileage
          ? annualMileageFormat(d.annualMileage)
          : '';
      });
    }
    if (applicationResponse?.locations?.length > 0) {
      // fix location objects
      applicationResponse.locations = applicationResponse.locations.map(
        (loc: any) => {
          return prepareLocationObject(loc);
        }
      );
    }
    if (applicationResponse.metaData.producerId) {
      setItem('agentId', applicationResponse.metaData.producerId);
    }
    if (applicationResponse.metaData.channelName) {
      setItem('channelName', applicationResponse.metaData.channelName);
    }
    setApplicationObject(applicationResponse);
    status = true;

    const locations: any = applicationResponse.locations;
    const primaryLocation = locations.find((location: any) => {
      return location.primary === true;
    });
    if (primaryLocation) {
      const state = primaryLocation.state;
      const respForms = await retrieveApplicationForms(state);
      if (respForms.error === false && state) {
        setJson(applicationForms, respForms.response);
        status = true;
      } else {
        status = false;
        console.error('Unable to retrieve forms');
        notifyBugsnag('Dynamic forms API', respForms);
      }
    }
    return status;
  } else {
    console.error('Unable to retrieve application');
    notifyBugsnag('Application retrive API', resp);
    return status;
  }
}

/* export async function retrieveApplicationForSocialLoginServiceCall(
  formData: any
) {
  let status = false;
  let data = await socialLogin(formData);
  if (isEmpty(data.response) && data.error === true) {
    return {
      success: status,
      message: data.errorMessage.data,
    };
  } else if (!isEmpty(data.response) && data.error === false) {
    const socialLoginResponse: any = data.response;
    const policyId = socialLoginResponse.policy_id;
    const applicationId = socialLoginResponse.application_id;
    const accountId = socialLoginResponse.accountId;
    if (policyId) {
      return {
        success: status,
        message:
          'You have an existing policy with us. Please login to the policy website.',
      };
    }
    if (applicationId && accountId) {
      const existingForms = getJson(applicationForms);
      if (
        formData?.provider === 'google' ||
        formData?.provider === 'facebook'
      ) {
        const resp = await retrieveApplication(applicationId);
        if (resp.error === false) {
          const applicationResponse: any = resp.response;
          setApplicationObject(applicationResponse);
          if (!existingForms) {
            const locations: any = applicationResponse.locations;
            const primaryLocation = locations.find((location: any) => {
              return location.primary === true;
            });
            if (primaryLocation) {
              const state = primaryLocation.state;
              const respForms = await retrieveApplicationForms(state);
              if (respForms.error === false) {
                setJson(applicationForms, respForms.response);
                status = true;
              } else {
                status = false;
                console.error('Unable to retrieve forms');
              }
            }
          }
          return {
            success: status,
          };
        } else {
          console.error('Unable to retrieve application');
          return {
            success: status,
          };
        }
      }
    }
  }
  return {
    success: status,
  };
}
 */

export async function getQuoteServiceCall(customPlan = false) {
  const applicationId = getMetaDataItem('applicationId');
  let data = await getQuote(applicationId, customPlan);
  if (!isEmpty(data.response) && data.error === false) {
    const applicationResponse: any = data.response;
    setJson(coveragePlans, applicationResponse);
    return applicationResponse;
  } else {
    notifyBugsnag('Quote retrive API', data);
    return {
      error: data.error,
      errorMessage: data.errorMessage,
    };
  }
}

export async function saveApplicationObject(payload: any) {
  const applicationId = getMetaDataItem('applicationId');
  if (!applicationId) {
    return {};
  }
  const agentIdSesssion = getItem('agentId');
  const channelNameSesssion = getItem('channelName');
  const partner = getItem('partnerQuote');
  let updatedPayload = payload;
  if (agentIdSesssion) {
    updatedPayload.metaData.producerId = agentIdSesssion || '';
  }
  if (channelNameSesssion) {
    updatedPayload.metaData.channelName = channelNameSesssion || '';
  }
  if (partner) {
    updatedPayload.metaData.partner = partner || '';
  }
  let data = await saveApplication(updatedPayload, false);
  if (!isEmpty(data.response) && data.error === false) {
    const applicationResponse: any = data.response;
    setApplicationObject(applicationResponse);
    return applicationResponse;
  } else {
    notifyBugsnag('Application save API', data);
  }

  return {};
}

export async function addDriverServiceCall(payload: any) {
  const applicationId = getMetaDataItem('applicationId');
  if (!applicationId) {
    return {};
  }
  let originalDrivers = await getApplicationObjectItem('drivers');
  if (!originalDrivers) {
    originalDrivers = [];
  }
  payload.applicationId = applicationId;
  let data = await addDriver(payload);
  let newDriver;
  if (!isEmpty(data.response) && data.error === false) {
    const applicationResponse: any = data.response;
    newDriver = applicationResponse?.drivers?.find(
      (driver: any) =>
        !originalDrivers?.some(
          (oldDriver: any) =>
            driver.dlNumber === oldDriver.dlNumber &&
            driver.state === oldDriver.state
        )
    );
    setApplicationObject(applicationResponse);
    await resetLeftNav(novoRoutes.AddDriver.activeStep);
  } else {
    notifyBugsnag('Add driver API', data);
  }
  return {
    ...data,
    newDriver,
  };
}

export async function addVehicleServiceCall(
  vin: string,
  state: string,
  licensePlate: string
) {
  const applicationId = getMetaDataItem('applicationId');
  let data = await addVehicle(applicationId, vin!, state!, licensePlate!);
  if (!isEmpty(data.response) && data.error === false) {
    // TODO: need to verify that with NOVO endpoints reply when in error
    if (data?.response?.statusCode === 400) {
      notifyBugsnag('Add vehicle API', data);
    }
    const applicationResponse: any = data.response;
    return applicationResponse;
  } else {
    notifyBugsnag('Add vehicle API', data);
  }
  return {};
}

export async function getReconciliationServiceCall() {
  const applicationId = getMetaDataItem('applicationId');
  if (!applicationId) {
    return {};
  }
  let data = await retrieveReconciliation(applicationId);
  if (!isEmpty(data.response) && data.error === false) {
    const applicationResponse: any = data.response;
    setApplicationObject(applicationResponse);
    return applicationResponse;
  } else {
    notifyBugsnag('Reconciliation API', data);
  }
  return {};
}

export async function saveApplicationCurrentSessionState(
  ignoreReadonly: boolean = false,
  formData: any = {}
) {
  let applicationObject = getApplicationObject();
  if (!applicationObject) {
    return {
      success: false,
    };
  }
  const agentIdSesssion = getItem('agentId');
  const partner = getItem('partnerQuote');
  if (agentIdSesssion) {
    applicationObject.metaData.producerId = agentIdSesssion;
  }
  if (partner) {
    applicationObject.metaData.partner = partner;
  }

  if (Object.keys(formData).length > 0) {
    let locationUpdated: any = [];
    let driverUpdated: any = [];
    const { location, name, dob, email, apt, city, state, zipCode } = formData;
    const { streetNumber, streetName, defaultAddress, fullAddress } = location;
    const addressForPayload =
      streetNumber && streetName
        ? `${streetNumber} ${streetName}`
        : defaultAddress || fullAddress;
    applicationObject.locations.forEach((loc: any) => {
      if (loc.primary) {
        const newLocation = {
          address: {
            address: addressForPayload,
            city: city,
            state: state,
            zip: zipCode,
            apt: apt || '',
          },
          locationId: loc.locationId,
          primary: loc.primary,
          type: loc.type,
        };
        locationUpdated.push(newLocation);
      } else {
        locationUpdated.push(loc);
      }
    });

    applicationObject.drivers.forEach((driver: any) => {
      if (driver.primary) {
        let driverInfo = driver;
        driverInfo.dob = dob;
        driverInfo.email = email;
        driverInfo.name = name;
        driverUpdated.push(driverInfo);
      } else {
        driverUpdated.push(driver);
      }
    });

    applicationObject.locations =
      locationUpdated.length > 0
        ? locationUpdated
        : applicationObject.locations;
    applicationObject.drivers =
      driverUpdated.length > 0 ? driverUpdated : applicationObject.drivers;
  }
  // fix bug NDAI-3209
  // if (
  //   applicationObject &&
  //   applicationObject.drivers &&
  //   applicationObject.drivers.length > 0
  // ) {
  //   applicationObject.drivers.map((d: any) => {
  //     if (!d?.primary) {
  //       d.included = d?.driverType
  //         ? ['included', 'permit'].includes(d?.driverType?.toLowerCase())
  //         : false;
  //     }
  //   });
  // }
  let data = await saveApplication(applicationObject, ignoreReadonly);
  if (!isEmpty(data.response) && data.error === false) {
    const applicationResponse: any = data.response;
    setApplicationObject(applicationResponse);
    return {
      success: true,
    };
  } else {
    notifyBugsnag('Application save API', data);
  }
  return {
    success: false,
  };
}
function bindEventLog(application: any, paymentResponse: any) {
  const everflow = JSON.parse(sessionStorage.getItem('everflow') || '{}');
  const everflowId = everflow?.ef_transaction_id;

  const gcpPaymentEvent = {
    page: '/payment',
    paidInFull:
      application?.metaData?.paymentMethodType?.toUpperCase() === 'PIF'
        ? 'true'
        : 'false',
    balance: '',
    dateEffective: new Date(application?.metaData?.policyEffectiveDate!)
      .toISOString()
      .split('T')[0],
    paymentCategory: 'CreditCard', // temporary hard coding
    paymentAmount: paymentResponse.paymentAmount || '',
    currency: 'USD',
    policyTotal: paymentResponse.totalPremium || '',
    policyFees: '',
    policyId: paymentResponse.policyId || '',
    policyHolderId: paymentResponse.profileId || '',
    transactionId: paymentResponse.paymentTransactionId || '',
    everflowId: everflowId || '',
  };
  trackingService.trackEvent('paymentSuccess', gcpPaymentEvent);
  sendGtmEvent('paymentSuccess', gcpPaymentEvent);
}

export async function tokenizeCardAndMakePayment(formData: any) {
  let isError = true;
  let errorMessage = '';
  const { cardNumber, name, expirationDate, cvv, location } = formData;
  const application = getApplicationObject();
  const applicationId = getMetaDataItem('applicationId');

  const billingInfoPayload = {
    paperless: formData.paperlessBilling,
    name: {
      'given-name': name.firstName,
      surname: name.lastName,
    },
    locationId: getPrimaryLocation().locationId,
  };
  application.metaData.billing = billingInfoPayload;
  const saveAppRes = await saveApplication(application);

  if (saveAppRes?.error) {
    notifyBugsnag('Application save API', saveAppRes);
  }

  const monthYear = expirationDate.split('/');
  const twoDigitYear =
    monthYear[1].length === 4 ? monthYear[1].substring(2, 4) : monthYear[1];
  const formattedExpirationDate = `${monthYear[0]}${twoDigitYear}`;

  const response: any = await getCardConnectToken(
    cardNumber,
    cvv,
    formattedExpirationDate
  );

  if (response.error === false) {
    const paymentAndBindPayload = {
      applicationId,
      tokenizedCardInfo: {
        name,
        token: response.response.token,
        expiry: formattedExpirationDate,
      },
      paymentAddress: {
        address: location.address
          ? location.address.address
          : `${location.streetNumber} ${location.streetName}`,
        apt: location.address ? location.address.apt : location.apt,
        city: location.address ? location.address.city : location.city,
        state: location.address ? location.address.state : location.state,
        zipCode: location.address ? location.address.zip : location.zipCode,
      },
    };

    setJson(paymentConstant, paymentAndBindPayload);
    const paymentAndBindResponse: any = await paymentAndBind(
      applicationId,
      paymentAndBindPayload
    );
    if (paymentAndBindResponse.error === false) {
      bindEventLog(application, paymentAndBindResponse.response);
      setJson(policyInfo, paymentAndBindResponse.response);
      isError = false;
    } else {
      notifyBugsnag('Payment bind API', paymentAndBindResponse);
      errorMessage = paymentAndBindResponse.errorMessage;
    }
  } else {
    errorMessage = response?.errorMessage.data?.message;
    notifyBugsnag('Get payment token API', response);
  }

  return {
    isError,
    errorMessage,
  };
}

export async function agentMakePayment() {
  let isError = true;
  let errorMessage: any = '';
  const applicationId = getMetaDataItem('applicationId');
  const application = getApplicationObject();
  const resp = await retrieveApplication(applicationId);
  if (resp.error === false) {
    const applicationResponse: any = resp.response;
    if (
      applicationResponse &&
      applicationResponse.paymentMethod &&
      applicationResponse.paymentMethod.token
    ) {
      const paymentAndBindPayload = {
        applicationId,
        referrer: process.env.REACT_APP_REFERRER,
        tokenizedCardInfo: {
          expiry: applicationResponse.paymentMethod.expiry,
          token: applicationResponse.paymentMethod.token,
        },
        paymentAddress: {
          address: applicationResponse.paymentMethod.address,
          city: applicationResponse.paymentMethod.city,
          state: applicationResponse.paymentMethod.state,
          zipCode: applicationResponse.paymentMethod.zipCode,
        },
      };
      setJson(paymentConstant, paymentAndBindPayload);
      const paymentAndBindResponse: any = await paymentAndBind(
        applicationId,
        paymentAndBindPayload
      );
      if (paymentAndBindResponse.error === false) {
        bindEventLog(application, paymentAndBindResponse.response);
        setJson(policyInfo, paymentAndBindResponse.response);
        isError = false;
      } else {
        notifyBugsnag('Payment bind API', paymentAndBindResponse);
        errorMessage = paymentAndBindResponse.errorMessage;
      }
    } else {
      errorMessage = 'Could not process payment';
    }
  } else {
    errorMessage = resp?.errorMessage;
    notifyBugsnag('Application retrive API', resp);
  }
  return {
    isError,
    errorMessage,
  };
}

export async function updateMicrocopySessionStorage() {
  const microCopyResourceSet = getMicrocopyResourceObject();
  if (isEmpty(microCopyResourceSet)) {
    const response = await fetchMicrocopySetFromContentful();
    const resourceSet = { ...defaultMicrocopySet, ...response };
    setMicrocopyResourceObject(resourceSet);
  } else {
    Bugsnag.notify({
      name: 'Contentfull API',
      message: microCopyResourceSet.toString() as string,
    });
  }
}

export async function retrieveDisclosures() {
  const applicationId = getMetaDataItem('applicationId');
  const response: any = await getDisclosures(applicationId);
  if (response.error !== true) {
    const transformedResponse = response.response.map((disclosure: any) => {
      return {
        title: disclosure.disclosureTitle,
        content: disclosure.disclosureText,
      };
    });
    return transformedResponse;
  } else {
    notifyBugsnag('Disclosure API', response);
  }
  return response;
}

export async function retrieveApplicationDocument() {
  let url = [];
  const applicationId = getMetaDataItem('applicationId');
  const data: any = await getApplicationDocumentApiCall(applicationId);
  // return response;
  if (!isEmpty(data.response) && data.error === false) {
    url = data.response['document-list'];
    const policyId = data.response['policy-id'];
    setJson('policyId', policyId);
    //url = 'https://www-novo-api.vercel.app/pdf/pdf-sample.pdf';
  } else {
    notifyBugsnag('Application document API ', data);
  }
  return url;
}

export async function eSignDocument(documenList: any[]) {
  const applicationId = getMetaDataItem('applicationId');

  if (documenList.length === 0) {
    notifyBugsnag(
      'eSign API',
      'this is no document to esign, applicationId:' + applicationId
    );
    return false;
  }
  const ipAddress = await getClientIP();
  const primaryDriver = getPrimaryDriver();
  const documentNeedSignList = [];
  for (let index = 0; index < documenList.length; index++) {
    const element = documenList[index];
    documentNeedSignList.push({
      'application-id': applicationId,
      source: element,
      'signee-list': [
        {
          // name: primaryDriver.name,
          name: {
            'given-name': primaryDriver?.name?.firstName,
            surname: primaryDriver?.name?.lastName,
          },
          email: primaryDriver.email,
          'ip-address': ipAddress,
        },
      ],
      'return-url': '',
    });
  }
  const payload = {
    'document-list': documentNeedSignList,
  };
  const data: any = await eSignDocumentApiCall(payload);
  // return response;
  if (!isEmpty(data.response) && data.error === false) {
    const value = {
      agreement: {
        status: true,
        ipAddress: ipAddress,
        agreedOn: getToday(),
      },
    };
    setMetaData('metaData', value);
    return true;
  } else {
    notifyBugsnag('eSign API', data);
  }
  return false;
}

export async function agreement() {
  const ipAddress = await getClientIP();
  const applicationId = getMetaDataItem('applicationId');
  const primaryDriver = getPrimaryDriver();
  const payload = {
    'applicant-list': [
      {
        'application-id': applicationId,
        // name: primaryDriver.name,
        name: {
          'given-name': primaryDriver?.name?.firstName,
          surname: primaryDriver?.name?.lastName,
        },
        email: primaryDriver.email,
        'ip-address': ipAddress,
        'agreement-list': [
          {
            id: 'policy-esign',
            type: 'esign',
            text: 'application',
          },
        ],
      },
    ],
  };
  const data: any = await agreementApiCall(payload);
  // return response;
  if (!isEmpty(data.response) && data.error === false) {
    const value = {
      disclosure: {
        status: true,
        agreedOn: getToday(),
        ipAddress: ipAddress,
      },
    };
    setMetaData('metaData', value);
    return true;
  } else {
    notifyBugsnag('Aggrement API', data);
  }
  return false;
}

export async function createAccount(email: string, password: string) {
  const policyDetails = getJson(policyInfo);
  if (!isEmpty(policyDetails)) {
    const payload = {
      username: email,
      password: password,
      email_address: email,
      drivers_license: null,
      policy_id: policyDetails.policyId,
      from_mobile: false,
      brand_id: 'novo',
    };
    const response = await createUserAccount(payload);
    return response;
  } else {
    notifyBugsnag('Create account API', policyDetails);
    return {
      response: {} as any,
      error: true,
      errorCode: '',
      errorMessage: 'q2b.policy.details.not.found',
      apiErrorMsg: 'q2b.policy.details.not.found',
    };
  }
}

export async function resetLeftNav(currentStep: number = 1) {
  const progress = getMetaDataItem(progressConstant);
  if (parseInt(progress.completedRoute) > currentStep) {
    progress.completedRoute = currentStep;
    setMetaDataItem(progressConstant, progress);
    return saveApplicationCurrentSessionState();
  } else {
    return true;
  }
}

export function valueValidation(data: any, value?: any) {
  if (value) {
    return data && data.length > 0 ? data[0][value] : [];
  } else {
    return data && data.length > 0 ? data : [];
  }
}

export const isVinExistInOtherPolicy = async (vin: string) => {
  const activePolicies = await getActivePoliciesByVin(vin);
  if (activePolicies.error !== true) {
    return activePolicies.response.has_active_policies;
  } else {
    notifyBugsnag('getActivePolicies API', activePolicies);
    return null;
  }
};
