import React from 'react';
import { IIconProps } from './AvatarOne';
import TowTruckIcon from './TowTruckIcon';
import FlatTireIcon from './FlatTireIcon';
import MobileIcon from './MobileIcon';
import UnionIcon from './UnionIcon';

export type IconName = 'towTruck' | 'flatTire' | 'mobileIcon' | 'mailIcon';

interface IconProps extends IIconProps {
  icon: IconName;
}

export const isValidIconName = (iconName: string): iconName is IconName => {
  return ['towTruck', 'flatTire', 'mobileIcon', 'mailIcon'].includes(iconName);
};

export default function CoverageIcon({ icon, ...iconProps }: IconProps) {
  switch (icon) {
    case 'towTruck':
      return <TowTruckIcon {...iconProps} />;
    case 'flatTire':
      return <FlatTireIcon {...iconProps} />;
    case 'mobileIcon':
      return <MobileIcon {...iconProps} />;
    case 'mailIcon':
      return <UnionIcon {...iconProps} />;
    default:
      return <></>;
  }
}
