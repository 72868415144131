import {
  getApplicationObjectItem,
  setApplicationObjectItem,
} from './insuranceApplication';

export const getAllCoverages = () => {
  return getApplicationObjectItem('coverage');
};

export const getCoverageByVehicleId = (
  vehicleId: number,
  coverageIndex?: number
) => {
  const quoteEnvelopeItem = getApplicationObjectItem('coverage');
  const quoteEnvelopeItemMeta = getApplicationObjectItem('metaData');
  let selectedIndex = -1;
  if (quoteEnvelopeItemMeta.selectedPlanName) {
    let selectCoverageIndex: any = {
      economy: 0,
      comfort: 1,
      turbo: 2,
    };
    selectedIndex = selectCoverageIndex[quoteEnvelopeItemMeta.selectedPlanName];
  }
  if (
    quoteEnvelopeItem &&
    quoteEnvelopeItem?.vehicleCoverage &&
    quoteEnvelopeItem?.vehicleCoverage.length > 0 &&
    coverageIndex === selectedIndex
  ) {
    let coverage = quoteEnvelopeItem.vehicleCoverage.find(
      (item: { vehicleId: number }) => item.vehicleId === vehicleId
    );
    return coverage;
  }
  return undefined;
};

export const getPolicyCoverage = () => {
  const quoteEnvelopeItem = getApplicationObjectItem('coverage');
  return quoteEnvelopeItem?.policyCoverage;
};

export const setPolicyCoverage = (key: string, value: any) => {
  let existingCoverage = getApplicationObjectItem('coverage');
  let coverage = {
    ...existingCoverage,
    policyCoverage: {
      ...value,
    },
  };

  return setApplicationObjectItem('coverage', coverage);
};

export const setVehicleCoverageItem = (key: string, value: any) => {
  let existingCoverage = getApplicationObjectItem('coverage');
  let coverage = {
    ...existingCoverage,
    vehicleCoverage: value,
  };
  return setApplicationObjectItem('coverage', coverage);
};
