import { Grid } from '@mui/material';
import NovoTab from 'framework/components/NovoTab';
import { novoRoutes } from 'framework/routes';
import React from 'react';
import MicrocopyComponent from './MicrocopyComponent';
import './NovoTabList.scss';

interface NovoTabListProps {
  selectedTabData: any;
  tabData?: any;
  handleSelectedTabData?: any;
}

const NovoTabList: React.FC<NovoTabListProps> = ({
  tabData,
  selectedTabData,
  handleSelectedTabData,
}) => {
  const handleTabClick = (newTabName: string) => {
    const data = tabData.filter((tab: any) => tab.name === newTabName);
    handleSelectedTabData({ name: data[0].name, url: data[0].url });
  };

  return (
    <>
      <Grid item container xs={12}>
        <Grid
          role='tablist'
          item
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            marginBottom: '2.25rem',
            minHeight: '3.75rem',
            border: '2px solid #000',
          }}
        >
          {tabData.map((item: any, index: number) => (
            <NovoTab
              key={index}
              id={`tab-${item.url.replace('/', '')}-${index}`}
              role='tab'
              aria-label={novoRoutes?.PlanOptions?.ariaLabelTabList}
              aria-controls={`panel-${item.name}-${index}`}
              onClick={() => handleTabClick(item.name)}
              aria-selected={item.name === selectedTabData.name}
              text={<MicrocopyComponent labelKey={item.name} />}
              tabIndex={item.name === selectedTabData.name ? -1 : undefined}
            />
          ))}
        </Grid>
        {tabData.map((item: any, index: number) => (
          <Grid
            role='tabpanel'
            id={`panel-${item.url.replace('/', '')}-${index}`}
            aria-labelledby={`tab-${item.url.replace('/', '')}-${index}`}
            key={index}
            sx={{
              display: `${
                item.name === selectedTabData.name ? 'block' : 'none'
              }`,
              width: '100%',
            }}
          >
            {item.content}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default NovoTabList;
