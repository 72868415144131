import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function EyeIcon({
  fillColor = '#737391',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='16'
      viewBox='0 0 24 16'
      fill='none'
      {...{ ...defaultProps, ...svgProps }}
    >
      <path
        d='M11.8516 15.2534C18.7329 15.2534 23.2983 9.7168 23.2983 8.02344C23.2983 6.31348 18.7246 0.785156 11.8516 0.785156C5.06982 0.785156 0.396484 6.31348 0.396484 8.02344C0.396484 9.7168 5.06152 15.2534 11.8516 15.2534ZM11.8516 13.4438C6.66357 13.4438 2.60449 9.16895 2.60449 8.02344C2.60449 7.06055 6.66357 2.59473 11.8516 2.59473C17.0229 2.59473 21.0903 7.06055 21.0903 8.02344C21.0903 9.16895 17.0229 13.4438 11.8516 13.4438ZM11.8516 12.3564C14.2671 12.3564 16.2012 10.3809 16.2012 8.02344C16.2012 5.59961 14.2671 3.68213 11.8516 3.68213C9.42773 3.68213 7.48535 5.59961 7.49365 8.02344C7.50195 10.3809 9.42773 12.3564 11.8516 12.3564ZM11.8433 9.39307C11.0879 9.39307 10.4653 8.77051 10.4653 8.02344C10.4653 7.25977 11.0879 6.64551 11.8433 6.64551C12.6069 6.64551 13.2295 7.25977 13.2295 8.02344C13.2295 8.77051 12.6069 9.39307 11.8433 9.39307Z'
        fill={fillColor}
      />
    </svg>
  );
}
