import { getJson } from 'framework/dataService';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import { getApplicationObject } from 'framework/dataService/insuranceApplication';
import { getLocationById } from 'framework/dataService/locations';
import { getVehicles } from 'framework/dataService/vehicles';
import { AddressProps } from 'framework/forms/interfaces/AddressProps';
import { CustomJsonSchemaProps } from 'framework/forms/interfaces/CustomJsonSchemaProps';
import { CsrRestrictionValidation } from 'framework/forms/lib/CsrRestrictionValidation';
import Validate from 'framework/forms/validations/Validate';
import { novoRoutes } from 'framework/routes';
import getFormDefinitions from 'framework/schemaServer';
import { lienHolder } from 'library/application/applicationConstants';
import { isEmpty, removeMarkUp } from 'utils/util';

export const setOtherAddressFields = (
  location: AddressProps,
  formData: any,
  initialFormData: any
) => {
  if (!isEmpty(location?.fullAddress)) {
    const { apt, city, state, zipCode } = location;
    formData.city = city;
    formData.apt = apt;
    formData.state = state;
    formData.zipCode = zipCode;
  } else if (initialFormData?.location) {
    const { apt, city, state, zipCode } = initialFormData.location;
    formData.location = initialFormData.location;
    formData.city = city;
    formData.apt = apt;
    formData.state = state;
    formData.zipCode = zipCode;
  }
};

export const trackVehicleProgress = (routeKey: string, vehicle: any) => {
  let progressValue = '100';
  if (validateCarData(routeKey, vehicle)) {
    // Validation Errors are there
    progressValue = '50';
  }
  vehicle.progressValue = progressValue;
  return vehicle;
};

export const validateCarData = (routeKey: string, car: any) => {
  const { schema } = getFormDefinitions(routeKey);
  if (car?.locationId) {
    const location = getLocationById(car.locationId);
    if (location?.fullAddress) {
      car.location = location;
    }
  }
  // validate formData
  const isError = Validate(car, schema);
  if (isError) {
    console.error({ validate: isError });
  }
  return isError;
};

export const findFirstRestrictedCar = (routeKey: string, cars: any[]) => {
  const { schema } = getFormDefinitions(routeKey);
  const BreakException = {};
  let firstRestrictedCarIndex = -1;
  if (schema && cars?.length > 0) {
    try {
      cars.forEach((carFormData, carIndex) => {
        if (carFormData.included) {
          // when car is included in the policy
          if (
            CsrRestrictionValidation(
              schema as CustomJsonSchemaProps,
              carFormData
            )
          ) {
            firstRestrictedCarIndex = carIndex;
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw BreakException;
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }
  console.debug('First Restricted Car Index: ', firstRestrictedCarIndex);
  return firstRestrictedCarIndex;
};

export const findFirstIncompleteCar = (routeKey: string, cars: any[]) => {
  const BreakException = {};
  let firstIncompleteCarIndex = -1;
  try {
    cars.forEach((carFormData, carIndex) => {
      if (carFormData.included) {
        // when car is included in the policy
        // validate formData
        if (validateCarData(routeKey, carFormData)) {
          firstIncompleteCarIndex = carIndex;
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw BreakException;
        }
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }
  console.debug('First Incomplete Car Index: ', firstIncompleteCarIndex);
  return firstIncompleteCarIndex;
};

export const calculateDestination = (
  routeKey: string,
  to?: string | null,
  defaultPath?: string
) => {
  let path = novoRoutes.Cars.path;
  if (to && to.length > 0) {
    //user is from planOptions car tab, no need to check cars data
    path = to;
  } else {
    // calculate destination
    const cars = getVehicles();
    const firstIncompleteCarIndex = findFirstIncompleteCar(routeKey, cars);
    if (firstIncompleteCarIndex >= 0) {
      // next button will take user to complete this car data
      path = novoRoutes.EditCar.path;
      path = path.replace(
        ':vehicleId',
        cars[firstIncompleteCarIndex].vehicleId
      );
    } else if (defaultPath) {
      path = defaultPath;
    }
  }
  return path;
};

export function checkIfTooManyVehicle() {
  let tooManyVehicles = false;
  const applicationObject = getApplicationObject();
  if (!isEmpty(applicationObject)) {
    const driversCount = applicationObject.drivers?.length;
    const vehicleCount = applicationObject.vehicles?.length;
    const maxVehicleLimit = removeMarkUp(
      getMicroCopyResourceItem(`q2b.vehicles.list.max.vehicles.count`)
    );
    try {
      if (vehicleCount - driversCount > parseInt(maxVehicleLimit)) {
        tooManyVehicles = true;
      }
    } catch (e) {
      console.error('Vehicle limit restriction is not set correctly ');
    }
  }
  return tooManyVehicles;
}

export function getLienHolderEnums() {
  const lienHolderInfo = getJson(lienHolder);
  let enumValues: any = [];
  if (lienHolderInfo) {
    lienHolderInfo.length > 0 &&
      lienHolderInfo.forEach((data: any) => {
        enumValues.push(data.value);
      });
  }
  if (enumValues.length > 0) {
    enumValues.push('Others');
  }
  return enumValues;
}
