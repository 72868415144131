import React from 'react';
import { Grid, Paper, Box, Divider, Typography } from '@mui/material';
import './TileRow.scss';

interface TileRowProps {
  id?: string;
  heading1: string | JSX.Element;
  heading2: string;
  mode?: 'edit' | 'add';
  avatar: JSX.Element;
  showSwitch: boolean;
  switchComponent?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLElement>;
  variant?: boolean;
  buttonComponent?: JSX.Element;
  screen?: string;
  driverType?: string;
}

export default function TileRow({
  id,
  heading1,
  heading2,
  mode = 'edit',
  showSwitch,
  switchComponent,
  avatar,
  onClick,
  variant,
  buttonComponent,
  screen,
  driverType,
}: TileRowProps) {
  return (
    <Grid
      container
      className={`quote-wrapper tile-row ${
        variant ? 'tile-row_secondary' : ''
      }`}
      sx={{ display: 'flex' }}
    >
      <Paper
        id={id}
        className='tile-row_info-wrapper'
        variant='outlined'
        elevation={0}
      >
        <section style={{ width: '100%' }}>
          <Box
            className='tile-row_item'
            sx={{ ...(buttonComponent && { paddingRight: '0 !important' }) }}
          >
            <Grid className='tile-row_icon'>{avatar}</Grid>
            <Grid className='tile-row_info'>
              <section
                className={onClick ? 'cursor-pointer' : undefined}
                role={onClick ? 'button' : undefined}
                aria-label={onClick ? mode || 'edit' : undefined}
                onClick={onClick}
              >
                <Typography
                  className='tile-row_heading1'
                  variant='body2'
                  sx={{
                    color: '#55566D',
                    fontSize: '0.875rem',
                    letterSpacing: '0.25em',
                  }}
                >
                  {heading1}
                </Typography>
                <Divider className='tile-row_divider' />
                <Typography
                  className='tile-row_heading2'
                  variant='body2'
                  sx={{ fontWeight: 700 }}
                >
                  {heading2}
                </Typography>
              </section>
            </Grid>
            {driverType && (
              <section
                className={onClick ? 'cursor-pointer' : undefined}
                role={onClick ? 'button' : undefined}
                aria-label={onClick ? mode || 'edit' : undefined}
                onClick={onClick}
              >
                <Typography
                  className='tile-row_heading1'
                  variant='body2'
                  sx={{
                    color: '#55566D',
                    fontSize: '0.875rem',
                    letterSpacing: '0.25em',
                  }}
                >
                  {driverType}
                </Typography>
              </section>
            )}
            {showSwitch && (
              <>
                {switchComponent && (
                  <div className='tile-row_switch-wrapper'>
                    {switchComponent}
                  </div>
                )}
              </>
            )}
            {buttonComponent && <>{buttonComponent}</>}
          </Box>
        </section>
      </Paper>
    </Grid>
  );
}
