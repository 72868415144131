import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import Cube2 from 'components/icons/Cube2';
import CubeStack from 'components/icons/CubeStack';
import React from 'react';
import { camelize, currencyFormatter } from 'utils/util';
import { PIF } from 'library/application/applicationConstants';
import { PlanOptionType } from './PlanOptions';

const PlanOption: React.FC<PlanOptionType> = ({
  id,
  planName,
  pifPremium,
  paymentMethodType,
  acpPremium,
  monthlyPremium,
  selectedOptionId,
  handleChange,
  disabled,
  initialPremium,
}) => {
  // eslint-disable-next-line eqeqeq
  const selected = id == selectedOptionId;
  return (
    <React.Fragment key={id}>
      <Grid
        item
        id={`top_grid_${id}`}
        sx={{ flex: '1', maxWidth: '279px', paddingLeft: '0 !important' }}
      >
        <Paper
          className={`tile_wrapper tile_variant2 cursor-pointer ${
            selected ? 'tile_selected' : ''
          }`}
          sx={{
            padding: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          id={`mui_paper_${id}`}
          variant={'outlined'}
          square
          onClick={(event: any) => {
            if (event.target instanceof Element) {
              if (event.target.id) {
                if (!disabled) {
                  handleChange(event.target.id);
                }
              }
            }
          }}
        >
          {selected && (
            <>
              <CubeStack
                id={`cube_stack_${id}`}
                className='tile_selected--cube-stack'
              />
              <Cube2 id={`cube_2_${id}`} className='tile_selected--cube' />
            </>
          )}
          <Box
            id={`box_${id}`}
            sx={{ maxWidth: '10.25rem' }}
            className='plan-box'
          >
            <Typography id={`header_typography_${id}`} variant='h5'>
              {planName && camelize(planName)}
            </Typography>
            <>
              <Divider id={`divider_${id}`} />
              <Grid id={`inside_grid_${id}`} className='plan-price'>
                <Typography
                  variant='subtitle2'
                  fontSize={10}
                  id={`normal_typography_${id}`}
                  sx={{
                    verticalAlign: 'super',
                    fontSize: '1.1rem!important',
                    float: 'left',
                    marginTop: '-6px',
                    marginRight: '5px',
                  }}
                >
                  $
                </Typography>
                <Typography id={`title_typography_${id}`} className='title-1'>
                  <span id={`premium_type_${id}`}>
                    {paymentMethodType?.toUpperCase() === PIF
                      ? currencyFormatter(pifPremium)
                      : currencyFormatter(initialPremium)}
                  </span>
                  {paymentMethodType?.toUpperCase() !== PIF && (
                    <span id={`premium_total_${id}`} className='premium-total'>
                      Total of ${currencyFormatter(acpPremium)} for 6 months
                    </span>
                  )}
                </Typography>
              </Grid>
            </>
          </Box>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default PlanOption;
