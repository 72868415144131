import './SaveProgress.scss';

import SideDrawer from 'components/SideDrawer';
import MuiButton from 'framework/components/MuiButton';
import RetrieveQuote from 'pages/RetrieveQuote';
import SaveQuote from 'pages/SaveQuote';
import React, { useState } from 'react';

import DottedTailArrowDown from './icons/DottedTailArrowDown';
import DottedTailArrowRight from './icons/DottedTailArrowRight';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';

interface PageHeaderProps {
  displayBtnRetrieveQuote?: boolean;
  displayBtnSaveQuote?: boolean;
}

export default function SaveProgress({
  displayBtnRetrieveQuote,
  displayBtnSaveQuote,
}: PageHeaderProps) {
  const handleOpenSaveQuoteDrawer = () => {
    setShowSaveQuoteDrawer(true);
  };

  const handleOpenRetrieveQuoteDrawer = () => {
    setShowRetrieveQuoteDrawer(true);
  };

  const handleCloseSaveQuoteDrawer = () => {
    setShowSaveQuoteDrawer(false);
  };

  const handleCloseRetrieveQuoteDrawer = () => {
    setShowRetrieveQuoteDrawer(false);
  };

  const [showSaveQuoteDrawer, setShowSaveQuoteDrawer] = useState(false);
  const [showRetrieveQuoteDrawer, setShowRetrieveQuoteDrawer] = useState(false);
  return (
    <>
      {displayBtnRetrieveQuote && (
        <MuiButton
          id={'btn-save-quote'}
          className={'btn-header'}
          color='secondary'
          variant={'contained'}
          onClick={() => handleOpenRetrieveQuoteDrawer()}
          startIcon={<DottedTailArrowRight />}
        >
          <span className='btn-header_label'>
            <MicrocopyComponent labelKey='q2b.global.returning.user.button.text' />
          </span>
        </MuiButton>
      )}
      {displayBtnSaveQuote && (
        <MuiButton
          id={'btn-save-quote'}
          className={'btn-header'}
          color='secondary'
          variant={'contained'}
          onClick={() => handleOpenSaveQuoteDrawer()}
          startIcon={<DottedTailArrowDown />}
        >
          <span className='btn-header_label'>
            <MicrocopyComponent labelKey='q2b.global.save.progress.button.text' />
          </span>
        </MuiButton>
      )}
      {showSaveQuoteDrawer && (
        <SideDrawer
          open={showSaveQuoteDrawer}
          onClose={handleCloseSaveQuoteDrawer}
        >
          <SaveQuote toClose={handleCloseSaveQuoteDrawer} />
        </SideDrawer>
      )}
      {showRetrieveQuoteDrawer && (
        <SideDrawer
          open={showRetrieveQuoteDrawer}
          onClose={handleCloseRetrieveQuoteDrawer}
        >
          <RetrieveQuote toClose={handleCloseRetrieveQuoteDrawer} />
        </SideDrawer>
      )}
    </>
  );
}
