import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { FormControl, TextField } from '@mui/material';
import CalendarIcon from 'components/icons/CalendarIcon';
import React from 'react';
import { getUpComingDate, getToday, getPastDate } from 'utils/dateUtils';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface MuiDatePickerProps {
  value?: any;
  onChange?: any;
  label?: any;
  className?: any;
  disableFuture?: boolean;
  maxDays?: number;
  isError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const MuiDatePicker = (props: MuiDatePickerProps) => {
  const [open, setOpen] = React.useState(false);
  const { label, value, onChange, className, isError, errorMessage } = props;
  const { disableFuture = false, maxDays = 0 } = props;

  const handleChange = (date: any) => {
    if (onChange) onChange(dayjs(date).format('YYYY-MM-DD HH:mm'));
  };
  return (
    <FormControl
      className={`${open ? 'date-picker_open' : ''} ${
        className ? className : ''
      }`}
      sx={{ my: 1, minWidth: 120, width: '100%' }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          // eslint-disable-next-line @typescript-eslint/no-shadow
          renderInput={(props: any) => <TextField {...props} />}
          label={label}
          value={value}
          disablePast={false}
          onChange={handleChange}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default MuiDatePicker;
