import { DatePicker } from '@mui/lab';
import { TextField, Box } from '@mui/material';
import type { WidgetProps } from '@rjsf/core';
import { getDateYearsFromToday } from 'utils/dateUtils';
import React, { useEffect, useState } from 'react';
import { dateToMonthYearStr, monthYearStrToDate } from 'utils/util';
import CalendarIcon from 'components/icons/CalendarIcon';
import { EnumOption } from 'types/GenericTypes';

const MuiMonthYearPickerWidget = (props: WidgetProps) => {
  let {
    value,
    onChange,
    label,
    uiSchema,
    rawErrors,
    required,
    options,
    disabled,
    id,
  } = props;
  const enumOptions: EnumOption = JSON.parse(JSON.stringify(options)) || {};

  const formattedValue = monthYearStrToDate(value);
  const [appData, setAppData] = useState<Date | null>(formattedValue);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const formattedDate = monthYearStrToDate(value);
    setAppData(formattedDate);
  }, [value]);

  const dateChange = (
    dateVal: Date | null,
    keyboardInputValue: string | undefined
  ) => {
    setAppData(dateVal);
    let monthYearStr = dateToMonthYearStr(dateVal, keyboardInputValue);
    onChange(monthYearStr);
  };

  const maximumDate =
    (uiSchema as any).maximum !== undefined
      ? getDateYearsFromToday((uiSchema as any).maximum)
      : new Date();
  const minimumDate =
    (uiSchema as any).minimum !== undefined
      ? getDateYearsFromToday((uiSchema as any).minimum)
      : getDateYearsFromToday(-10);

  const requiredLabel = required ? label + ' *' : label;

  return (
    <Box className={`${open ? 'date-picker_open' : ''}`}>
      <DatePicker
        allowSameDateSelection={true}
        disabled={disabled}
        inputFormat='MM/yyyy'
        mask='__/____'
        views={['year', 'month']}
        disableMaskedInput={false}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={requiredLabel}
        minDate={minimumDate ? minimumDate : undefined}
        maxDate={maximumDate ? maximumDate : undefined}
        value={appData || null}
        onChange={dateChange}
        disableFuture={enumOptions?.disableFuture}
        renderInput={(params: any) => (
          <TextField
            {...params}
            defaultValue={null}
            helperText={null}
            error={Boolean(rawErrors?.length)}
            id={id}
          />
        )}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
      />
    </Box>
  );
};

export default MuiMonthYearPickerWidget;
