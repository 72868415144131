import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { getData } from 'framework/dataService/dataRehydration';
import {
  getReconciliationServiceCall,
  valueValidation,
} from 'library/services/utils';
import ReviewHistoryForm from 'pages/Drivers/ReviewHistoryForm';
import React, { useEffect } from 'react';
import { isEmpty } from 'utils/util';
import {
  claimsFormat,
  coverageFormat,
  dlFormat,
  incidentFormatNew,
  incidentFilter,
} from './ReconciliationCompare';

export const DriverReviewHistoryFormDefinitions = (
  updateStatus?: (index: number, status: string, caller: string) => void,
  isReviewHistoryDirty?: any,
  isFormDirty?: boolean,
  shouldcompare?: boolean
) => {
  const prepareFormData = (driversList: any) => {
    // formData with array of objects with driverId and reconciliationList
    const driversReconciliationList: any = [];
    driversList.forEach(
      (driver: {
        reconciliation: any;
        drivingHistory: any;
        driverId: any;
        name: any;
      }) => {
        if (!isEmpty(driver.reconciliation)) {
          const claimData = claimsFormat(driver);
          const incidentData = incidentFormatNew(driver);
          const incidentFilterData = incidentFilter(
            incidentData,
            driver.drivingHistory
          );
          const dlData = dlFormat(driver);
          const coverageData = coverageFormat(driver);
          driversReconciliationList.push({
            driverId: driver.driverId,
            name: driver.name,
            accidentList: valueValidation(incidentData, 'accidentList'),
            trafficViolationList: valueValidation(
              incidentData,
              'violationList'
            ),
            accidentCompareList: valueValidation(
              incidentFilterData,
              'accidentCompareList'
            ),
            accidentUnmatchedList: valueValidation(
              incidentFilterData,
              'accidentUnmatchedList'
            ),
            accidentMatchedList: valueValidation(
              incidentFilterData,
              'accidentMatchedList'
            ),
            violationCompareList: valueValidation(
              incidentFilterData,
              'violationCompareList'
            ),
            violationUnmatchedList: valueValidation(
              incidentFilterData,
              'violationUnmatchedList'
            ),
            violationMatchedList: valueValidation(
              incidentFilterData,
              'violationMatchedList'
            ),
            dlList: valueValidation(dlData),
            coverageList: valueValidation(coverageData),
            claimList: valueValidation(claimData),
          });
        }
      }
    );
    let reconciliationCondition: any = [];
    driversReconciliationList.length > 0 &&
      driversReconciliationList.forEach((item: any) => {
        if (
          item?.accidentCompareList?.length > 0 ||
          item?.accidentUnmatchedList?.length > 0 ||
          item?.claimList?.length > 0 ||
          item?.coverageList?.length > 0 ||
          item?.dlList?.length > 0 ||
          item?.violationCompareList?.length > 0 ||
          item?.violationUnmatchedList?.length > 0
        ) {
          reconciliationCondition.push(item);
        }
      });
    // (driversReconciliationList[0].accidentCompareList.length > 0 ||
    //   driversReconciliationList[0].accidentUnmatchedList.length > 0 ||
    //   driversReconciliationList[0].claimList.length > 0 ||
    //   driversReconciliationList[0].coverageList.length > 0 ||
    //   driversReconciliationList[0].dlList.length > 0 ||
    //   driversReconciliationList[0].violationCompareList.length > 0 ||
    //   driversReconciliationList[0].violationUnmatchedList.length > 0);

    if (reconciliationCondition.length > 0) {
      return reconciliationCondition;
    }
    return undefined;
  };

  const routeKey = 'ReviewHistory';
  const dataForFormData = getData(routeKey);

  if (dataForFormData?.drivers) {
    var formData: any[] = prepareFormData(dataForFormData?.drivers);
    const driverReviewHistoryList: any[] = [];
    if (formData && formData.length > 0) {
      formData.forEach((item: any, index: number) => {
        var status = 'disabled';
        if (formData[index]?.trafficViolationList[0]?.dispute != null) {
          status = 'done';
        } else {
          if (index === 0) {
            status = 'open';
          }
        }
        let accordionTitle;
        accordionTitle = (
          <MicrocopyComponent
            labelKey={`q2b.review.history.primary.driver.title`}
            placeHolderKey='$DriverName$'
            actualValue={item.name.firstName.toUpperCase()}
          />
        );
        driverReviewHistoryList.push({
          title: accordionTitle,
          formName: 'Accordion' + index,
          content: (
            <ReviewHistoryForm
              driversReconciliationList={item}
              index={index}
              formLength={formData.length}
              updateStatus={updateStatus}
              isReviewHistoryDirty={isReviewHistoryDirty}
              isAnyFormDirty={isFormDirty}
            />
          ),
          status: status,
        });
      });
    }
    return driverReviewHistoryList;
  }
};
