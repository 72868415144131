import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function CloseIcon({
  fillColor = 'black',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='24'
      viewBox='0 0 26 24'
      {...{ ...defaultProps, ...svgProps }}
      fill='none'
    >
      <rect
        x='4'
        y='0.707107'
        width='29.0415'
        height='2.89963'
        rx='1.44981'
        transform='rotate(45 4 0.707107)'
        fill={fillColor}
        stroke={fillColor}
      />
      <rect
        x='-2.98023e-08'
        y='0.707107'
        width='29.0415'
        height='2.89963'
        rx='1.44981'
        transform='matrix(-0.707107 0.707107 0.707107 0.707107 21.5 0.207107)'
        fill={fillColor}
        stroke={fillColor}
      />
    </svg>
  );
}
