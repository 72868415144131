export const applicationForms = 'applicationForms';
export const insuranceApplicationObject = 'insuranceApplicationObject';
export const ubiInfo = 'ubiInfo';
export const coveragePlans = 'coveragePlans';
export const paymentConstant = 'payment';
export const policyInfo = 'policy';
export const customPlan = 'customPlan';
export const progressConstant = 'progress';
export const completedRoute = 'completedRoute';
export const stepReached = 'stepReached';
export const metaData = 'metaData';
export const csrRestrictionConstant = 'csrRestriction';
export const vehiclesConstant = 'vehicles';
export const locations = 'locations';
export const drivers = 'drivers';
export const addons = 'addons';
export const lienHolder = 'lienHolder';

export const microcopyContentResources = 'microcopyContentResources';

export const ACP = 'ACP';
export const PIF = 'PIF';

export const nextPaymentDueDateInDays = 30;
