import { FieldProps } from '@rjsf/core';
import AvatarOne from 'components/icons/AvatarOne';
import TileRow from 'components/TileRow';
import React, { CSSProperties } from 'react';

const avatarStyles: CSSProperties = {
  marginBottom: '0.75rem',
  width: '60px',
  height: '60px',
};

const DriverInfoHeader = (props: FieldProps) => {
  const { formData } = props;
  return (
    <TileRow
      heading1={''}
      heading2={`${formData?.firstName} ${formData?.lastName}`}
      avatar={<AvatarOne style={avatarStyles} />}
      showSwitch={false}
    />
  );
};

export default DriverInfoHeader;
