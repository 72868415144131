import { TextField } from '@mui/material';
import { AddressProps } from 'framework/forms/interfaces/AddressProps';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { getLightBackgroundColor } from 'utils/style';
import './PlacesAutoComplete.scss';
import { parseAddress } from 'vladdress';

export interface PlacesAutoCompleteProps {
  className?: string;
  name: string;
  id: string;
  label: string;
  defaultValue: string;
  error: boolean;
  helperText: string;
  required?: boolean;
  disabled?: boolean;
  address?: object;
  setAddress?: React.Dispatch<React.SetStateAction<AddressProps>>;
}

const PlacesAutoComplete: React.FC<PlacesAutoCompleteProps> = ({
  className,
  disabled,
  name,
  id,
  label,
  defaultValue,
  error,
  helperText,
  required,
  address,
  setAddress,
}) => {
  const [defaultAddress, setDefaultAddress] = useState(defaultValue);
  // const methods = useFormContext();
  const methods = useForm();
  const { control } = methods;

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== defaultAddress) {
      setDefaultAddress(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleTextChange = (value: string) => {
    setDefaultAddress(value);
    if (value) {
      setAddress?.({
        fullAddress: value || '',
        streetName: '',
        streetNumber: '',
        zipCode: '',
        city: '',
        state: '',
      });
    }
  };
  const handleError = (status: string, clearSuggestion: () => void) => {
    clearSuggestion();
  };
  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const addressParse = parseAddress(value);
    let streetNumber = '';
    let streetName = '';
    let city = '';
    let state = '';
    let zipCode = '';
    results[0].address_components.forEach((component) => {
      if (component.types.some((c: string) => c.includes('street_number'))) {
        streetNumber = component.long_name;
      }
      if (component.types.some((c: string) => c.includes('route'))) {
        streetName = component.long_name;
      }
      if (component.types.some((c: string) => c.includes('locality'))) {
        city = component.long_name;
      }
      if (
        component.types.some((c: string) =>
          c.includes('administrative_area_level_1')
        )
      ) {
        state = component.short_name;
      }
      if (
        component.types.some(
          (c: string) => c.includes('postal_code') && !c.includes('suffix')
        )
      ) {
        zipCode = component.long_name;
      }
    });

    if (
      addressParse &&
      addressParse.hasOwnProperty('streetNumber') &&
      !streetNumber
    ) {
      streetNumber = addressParse.streetNumber
        ? addressParse.streetNumber
        : streetNumber;
    }

    if (setAddress) {
      const newFormData: AddressProps = {};
      if (value) {
        newFormData.fullAddress = `${value}`;
      }
      if (streetNumber) {
        newFormData.streetNumber = `${streetNumber}`;
      }
      if (streetName) {
        newFormData.streetName = `${streetName}`;
      }
      if (city) {
        newFormData.city = `${city}`;
      }
      if (state) {
        newFormData.state = `${state}`;
      }
      if (zipCode) {
        newFormData.zipCode = `${zipCode}`;
      }
      setAddress(newFormData);
    }
  };

  const handleReset = () => {
    if (setAddress && address) {
      // commented this beacuse city, state, zip are getting cleared
      // const newFormData: AddressProps = {};
      // newFormData.fullAddress = defaultAddress;
      // newFormData.defaultAddress = defaultAddress;
      setAddress({ ...address });
    }
  };

  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { invalid, isTouched, isDirty },
          formState,
        }) => (
          <PlacesAutocomplete
            value={defaultAddress}
            onChange={handleTextChange}
            onSelect={handleSelect}
            onError={handleError}
            searchOptions={{ types: ['address'] }}
            debounce={300}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <TextField
                  required={required}
                  id={id}
                  error={error}
                  helperText={helperText}
                  label={label}
                  fullWidth
                  // variant='outlined'
                  {...getInputProps({
                    disabled: disabled,
                    onBlur: () => {
                      handleReset();
                    },
                  })}
                />

                {suggestions.length > 0 ? (
                  <div className='places-auto-complete-dropdown-wrapper'>
                    {loading ? <div>loading...</div> : null}
                    <div>
                      {suggestions.map((suggestion: any) => (
                        <div
                          className={
                            suggestion.active
                              ? 'active-option google-option'
                              : 'google-option'
                          }
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.description}
                          style={{
                            background: suggestion.active
                              ? getLightBackgroundColor()
                              : '',
                          }}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </PlacesAutocomplete>
        )}
      />
    </div>
  );
};

export default PlacesAutoComplete;
