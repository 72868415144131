import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function SearchIcon({
  fillColor = 'currentColor',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='28'
      viewBox='0 0 22 28'
      fill='none'
      {...{ ...defaultProps, ...svgProps }}
    >
      <path
        d='M21.0024 9.76059C21.0024 15.1512 16.6908 19.5212 11.3723 19.5212C6.05373 19.5212 1.74219 15.1512 1.74219 9.76059C1.74219 4.36997 6.05373 0 11.3723 0C16.6908 0 21.0024 4.36997 21.0024 9.76059ZM4.63122 9.76059C4.63122 13.534 7.64929 16.593 11.3723 16.593C15.0953 16.593 18.1133 13.534 18.1133 9.76059C18.1133 5.98715 15.0953 2.92818 11.3723 2.92818C7.64929 2.92818 4.63122 5.98715 4.63122 9.76059Z'
        fill={fillColor}
      />
      <ellipse cx='2.94' cy='25.0209' rx='2.94' ry='2.97984' fill={fillColor} />
    </svg>
  );
}
