import { FormControlLabel, Grid, Switch } from '@mui/material';
import { FieldProps } from '@rjsf/core';
import TileRow from 'components/TileRow';
import CarAvatar from 'framework/components/CarAvatar';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import {
  getVehicleById,
  getVehicles,
  setVehicle,
} from 'framework/dataService/vehicles';
import { novoRoutes } from 'framework/routes';
import { saveApplicationCurrentSessionState } from 'library/services/utils';
import { default as React } from 'react';
import { useNavigate } from 'react-router-dom';
import { getItemIndexFromArrayFieldId } from 'utils/util';

const CarHeaderIncludedSwitchField = (props: FieldProps) => {
  const navigate = useNavigate();
  const { onChange, idSchema, formContext, formData, disabled } = props;

  const index = getItemIndexFromArrayFieldId(idSchema.$id);

  const { vehicleId, manufacturer, model, year, vin } =
    formContext.getVehicleDataByIndex(index);

  const vehicleAmount = getVehicles()?.length;
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const vehicle = getVehicleById(vehicleId);

    vehicle.included = !vehicle.included;
    setVehicle({ ...vehicle });
    onChange(event.target.checked);
    const resp = await saveApplicationCurrentSessionState();
    if (!resp.success) {
      console.error(
        'Vehicle : Included : Update Application Object is FAILED: Backend may still have the Included Vehicle with bad data.'
      );
    } else {
      console.debug('Vehicle : Included : Update Application Object is done.');
    }
  };

  const handleNavigation = () => {
    navigate(
      `${novoRoutes.EditCar.path.replace(':vehicleId', vehicleId)}?to=${
        novoRoutes.PlanOptionsCars.path
      }`
    );
  };

  return (
    <Grid
      container
      className=''
      sx={{ display: 'flex' }}
      key={vehicleId || 'addVehicle'}
    >
      <TileRow
        id={vehicleId}
        heading1={vin}
        heading2={`${manufacturer} ${model} ${year}`}
        avatar={
          <CarAvatar
            index={index}
            onClick={(event: any) => {
              handleNavigation();
            }}
          />
        }
        onClick={() => {
          if (!disabled) {
            handleNavigation();
          }
        }}
        showSwitch={vehicleAmount > 1 ? true : false}
        switchComponent={
          <Grid className='driver-include'>
            <FormControlLabel
              control={
                <Switch
                  disabled={disabled}
                  id={vehicleId.toString()}
                  checked={formData}
                  name='included'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  className='switch'
                  onChange={handleChange}
                />
              }
              //labelPlacement='end'
              labelPlacement='start'
              label={
                <MicrocopyComponent
                  labelKey={`q2b.global.text.included.in.policy`}
                />
              }
            />
          </Grid>
        }
      />
    </Grid>
  );
};

export default CarHeaderIncludedSwitchField;
