import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Stack, Tooltip } from '@mui/material';
import { FieldTemplateProps } from '@rjsf/core';
import Label from 'framework/components/Label';
/* import HelpIcon from 'components/icons/HelpIcon'; */
import React from 'react';
import './CustomFieldTemplate.scss';

const CustomFieldTemplate = (props: FieldTemplateProps) => {
  const {
    children,
    classNames,
    required,
    description,
    errors,
    help,
    rawHelp,
    uiSchema,
    disabled,
    rawErrors,
  } = props;
  const options: any = uiSchema?.['ui:options'];
  const { fieldTemplate } = options || {};

  const boxWithTooltip = rawHelp && rawHelp.length > 0 && (
    <>
      <Grid className='boxWithTooltip'>
        <Grid>
          <Box
            sx={{
              minWidth: '50%',
            }}
          >
            {description}
            {children}
            {errors}
          </Box>
        </Grid>
        <Grid className='info-icon-wrapper'>
          <Tooltip title={rawHelp}>
            <InfoOutlinedIcon />
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
  const boxWithoutTooltip = (!rawHelp || rawHelp.length === 0) && (
    <>
      <Grid className='boxWithTooltip'>
        <Grid>
          <Box
            sx={{
              minWidth: '50%',
            }}
          >
            {description}
            {children}
            {errors}
          </Box>
        </Grid>
        <Grid className='info-icon-wrapper'></Grid>
      </Grid>
    </>
  );
  /* const boxWithoutTooltip = (!rawHelp || rawHelp.length === 0) && (
    <Box
      sx={{
        minWidth: '50%',
      }}
    >
      {description}
      {children}
      {errors}
    </Box>
  ); */

  if (fieldTemplate?.titleLeft) {
    return (
      <>
        <Grid className={`${classNames} form-field `}>
          <Box
            sx={{
              display: 'flex',
              p: 0,
              bgcolor: 'background.paper',
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                minWidth: 140,
                flexGrow: 1,
              }}
            >
              <Label
                label={fieldTemplate.titleLeft}
                uiSchema={uiSchema}
                isError={rawErrors && rawErrors.length > 0}
                {...{ disabled, required }}
              />
            </Box>
            {boxWithTooltip}
            {boxWithoutTooltip}
          </Box>
        </Grid>
      </>
    );
  } else if (fieldTemplate?.titleTop) {
    return (
      <>
        <Grid className={`${classNames} form-field `}>
          <Stack
            sx={{
              display: 'flex',
              p: 0,
              bgcolor: 'background.paper',
              borderRadius: 1,
            }}
            spacing={2}
          >
            <Box
              sx={{
                minWidth: 140,
                flexGrow: 1,
              }}
            >
              <Label
                label={fieldTemplate.titleTop}
                isError={rawErrors && rawErrors.length > 0}
                {...{ disabled, required }}
              />
            </Box>
            <Box
              sx={{
                minWidth: 140,
                flexGrow: 1,
              }}
            >
              {boxWithTooltip}
              {boxWithoutTooltip}
            </Box>
          </Stack>
        </Grid>
      </>
    );
  } else if (fieldTemplate?.rawHelp) {
    return (
      <>
        <Tooltip title={fieldTemplate.rawHelp}>
          <Grid className={`${classNames} form-field `}>
            {description}
            {children}
            {errors}
            {help}
          </Grid>
        </Tooltip>
      </>
    );
  }
  return (
    <>
      <Grid className={`${classNames} form-field `}>
        {boxWithTooltip}
        {boxWithoutTooltip}
      </Grid>
    </>
  );
};

export default CustomFieldTemplate;
