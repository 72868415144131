import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function EditIcon(props: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      {...{ ...defaultProps, ...props }}
    >
      <circle cx='15' cy='15' r='15' fill='white' />
      <path
        d='M10.111 19.9338L10.7582 22.3489L13.1733 21.7018L18.3901 12.666L15.3279 10.898L10.111 19.9338ZM19.7527 10.306C19.9366 9.98751 19.827 9.57855 19.5085 9.39468L17.6017 8.29379C17.2832 8.10992 16.8743 8.2195 16.6904 8.53798L15.8276 10.0324L18.8899 11.8004L19.7527 10.306Z'
        fill='#6CF96A'
      />
      <path
        d='M9 18.4997V21H11.5003L18.878 13.6223L16.3777 11.122L9 18.4997ZM20.805 11.6954C21.065 11.4353 21.065 11.0119 20.805 10.7519L19.2481 9.19503C18.9881 8.93499 18.5647 8.93499 18.3046 9.19503L17.0845 10.4152L19.5848 12.9155L20.805 11.6954Z'
        fill='black'
      />
    </svg>
  );
}
