import React from 'react';
import { MuiButtonProps } from './MuiButton';
import { IconButton } from '@mui/material';
import { PhotoCamera } from '@material-ui/icons';

const MuiIconButton: React.FC<MuiButtonProps> = ({
  icon,
  ariaLabel,
  // tabIndex,
  // style,
  disabled,
  children,
  className,
  onClick,
}) => {
  let iconTag;
  switch (icon) {
    case 'arrow-up':
      iconTag = <PhotoCamera />;
      break;
    case 'arrow-down':
      iconTag = <PhotoCamera />;
      break;
    case 'remove':
      iconTag = <PhotoCamera />;
      break;
    default:
      iconTag = children;
  }
  return (
    <IconButton
      color='primary'
      aria-label={ariaLabel}
      component='span'
      id='idScanTakePhoto'
    >
      {iconTag}
    </IconButton>
  );
};

export default MuiIconButton;
