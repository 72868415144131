import { Divider, Grid, Typography } from '@mui/material';
import TileAccentBorders from 'components/TileAccentBorders';
import {
  nextPaymentDueDateInDays,
  ACP,
  PIF,
} from 'library/application/applicationConstants';
import React from 'react';
import {
  getDateFromString,
  getFormattedDate,
  getDateAfterDays,
} from 'utils/dateUtils';
import { currencyFormatter } from 'utils/util';
import MicrocopyComponent from './MicrocopyComponent';
export interface PaymentDetailBoxProps {
  paymentTitle: string | JSX.Element;
  price: string;
  policyData: any;
}

const PaymentDetailBox: React.FC<PaymentDetailBoxProps> = ({
  paymentTitle,
  price,
  policyData,
}) => {
  // eslint-disable-next-line max-len
  const dueDate = getFormattedDate(
    getDateFromString(
      getDateAfterDays(
        policyData?.policyEffectiveDate,
        nextPaymentDueDateInDays
      )
    )
  );
  return (
    <>
      <Grid container style={{ border: '4px solid #E8E9EE', padding: '20px' }}>
        <Grid item xs={12} md={6}>
          <Typography className='title-2'>{paymentTitle}</Typography>
          <Divider
            style={{
              width: '100%',
              marginTop: '9px',
              borderColor: '#E8E9EE',
              borderBottomWidth: 2,
            }}
          />
          <Grid item xs={12} sx={{ paddingTop: '10px' }}>
            <Typography
              className='title-1-small'
              variant='subtitle2'
              sx={{
                verticalAlign: 'super',
                fontSize: '2rem',
                paddingRight: '7px',
                float: 'left',
              }}
            >
              $
            </Typography>
            <Typography className='title-1'>
              {currencyFormatter(
                policyData?.paymentMethod?.toUpperCase() === ACP
                  ? policyData?.initialPremium
                  : policyData?.pifPremium
              )}
            </Typography>

            <Typography
              sx={{
                borderTop: '2px solid #E8E9EE',
                paddingTop: '15px',
                color: '#55566D',
                fontFamily: 'Lato',
                fontWeight: 900,
                fontSize: '0.813rem',
                letterSpacing: '0.125em',
                width: 'max-content',
                maxWidth: '100%',
              }}
            >
              / {policyData?.includedDrivers}{' '}
              {policyData?.includedDrivers === 1 ? 'DRIVER' : 'DRIVERS'} /{' '}
              {policyData?.includedVehicles}{' '}
              {policyData?.includedVehicles === 1 ? 'CAR' : 'CARS'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ paddingTop: '25px', justifyContent: 'space-between' }}
      >
        {policyData?.paymentMethod?.toUpperCase() === ACP && (
          <Grid item className='payment-box-items'>
            <TileAccentBorders
              heading={
                <MicrocopyComponent
                  labelKey={`q2b.disclosures.payment.next.payment.due.heading`}
                />
              }
              content={
                <>
                  <span className='super-text'>$</span>
                  <span>{currencyFormatter(policyData?.initialPremium)}</span>
                </>
              }
              caption={`/ ${dueDate}`}
            />
          </Grid>
        )}

        <Grid
          item
          className={`payment-box-info ${
            policyData?.paymentMethod?.toUpperCase() === ACP &&
            'payment-box-items'
          }`}
        >
          <TileAccentBorders
            heading={
              <MicrocopyComponent
                labelKey={`q2b.disclosures.payment.six.month.total.heading`}
              />
            }
            content={
              <>
                <span className='super-text'>$</span>
                <span>
                  {policyData?.paymentMethod?.toUpperCase() === PIF
                    ? currencyFormatter(policyData?.pifPremium)
                    : currencyFormatter(policyData?.acpPremium)}
                </span>
              </>
            }
            caption={
              <MicrocopyComponent
                labelKey={`q2b.disclosures.payment.includes.all.fees.caption`}
              />
            }
          />
        </Grid>
        {policyData?.paymentMethod?.toUpperCase() === ACP && (
          <Grid className='installment-fee'>
            <MicrocopyComponent
              labelKey={`q2b.disclosures.payment.installment.fee`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PaymentDetailBox;
