// Format values can be anything AJV’s addFormat method accepts.
/**
 * Custom Formats
 * https://react-jsonschema-form.readthedocs.io/en/latest/usage/validation/#customformats
 *
 * Format values can be anything AJV’s addFormat method accepts.
 * https://github.com/ajv-validator/ajv/tree/6a671057ea6aae690b5967ee26a0ddf8452c6297#addformatstring-name-stringregexpfunctionobject-format---ajv
 *
 */
const additionalCustomFormats = {
  email:
    // eslint-disable-next-line max-len
    /^.+\@(\[?)[a-zA-Z0-9\-\.]+\.([a-zA-Z]{2,3}|[0-9]{1,3})(\]?)$/,
  phone_us: /\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/,
  credit_card:
    // eslint-disable-next-line max-len
    /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/,
  cvv: /^[0-9]{3,4}$/,
  card_expiration_date: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
  vin: /^$|^[A-HJ-NPR-Za-hj-npr-z\d]{17}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^*_0-9])(?=.{8,})/,
  markup: /(<([^>]+)>)/gi,
};

export default additionalCustomFormats;
