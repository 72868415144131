import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { getFormattedDateWithTimezone } from 'utils/dateUtils';
import MicrocopyComponent from '../framework/components/MicrocopyComponent';
import {
  policyInfo,
  coveragePlans,
  ACP,
} from 'library/application/applicationConstants';
import { getJson } from 'framework/dataService';
import {
  getApplicationObject,
  getMetaData,
} from 'framework/dataService/insuranceApplication';
import { currencyFormatter } from 'utils/util';

export default function PolicyDetailBox(props: any) {
  const policyDetails = getJson(policyInfo);
  const application = getApplicationObject();
  const pniAddress = application.locations.filter((l: any) => l.primary);
  const zipCode = pniAddress[0].address.zip;
  const metaData = getMetaData();
  const selectedCoveragePlan = getJson(coveragePlans).find(
    (plan: any) =>
      plan.planName?.toLowerCase() === metaData.selectedPlanName?.toLowerCase()
  );
  const paidAmount = `$${currencyFormatter(
    metaData?.paymentMethodType?.toUpperCase() === ACP
      ? selectedCoveragePlan?.initialPremium
      : selectedCoveragePlan?.pifPremium
  )}`;
  const transactionId = policyDetails.paymentTransactionId || '';

  return (
    <Grid container style={{ marginLeft: props.marginLeft }}>
      <Grid
        container
        className={'policy-details-info-box'}
        style={{
          border: '4px solid #E8E9EE',
          padding: '20px',
          width: '92%',
        }}
      >
        <Grid item xs={12} md={6}>
          <Typography className='title-2'>
            <MicrocopyComponent labelKey={`q2b.global.policy.number.text`} />
          </Typography>
          <Divider
            style={{
              width: '100%',
              marginTop: '9px',
              borderColor: '#E8E9EE',
              borderBottomWidth: 2,
            }}
          />
          <Grid item xs={12} sx={{ paddingTop: '10px' }}>
            <Typography className='title-2' id='policy-id'>
              {policyDetails.policyId}
            </Typography>

            <Typography
              sx={{
                borderTop: '2px solid #E8E9EE',
                paddingTop: '15px',
                color: '#55566D',
                fontFamily: 'Lato',
                fontWeight: 900,
                fontSize: '0.813rem',
                letterSpacing: '0.125em',
                width: 'max-content',
                maxWidth: '100%',
              }}
            >
              <MicrocopyComponent labelKey={`q2b.global.effective.date.text`} />{' '}
              :{' '}
              {getFormattedDateWithTimezone(
                policyDetails.policyEffectiveDate,
                zipCode
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        className={`payment-and-next-steps`}
        sx={{ paddingTop: '25px', justifyContent: 'space-between' }}
      >
        <Grid item xs={12}>
          <MicrocopyComponent
            labelKey={`q2b.congrats.confirmation.and.next.steps`}
            placeHolderKey={['$PaymentAmount$', '$TransactionID$']}
            actualValue={[paidAmount, transactionId]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
