import React from 'react';
import type { WidgetProps } from '@rjsf/core';
import ToggleRadioButton from 'framework/components/ToggleRadioButton';
import './ToggleLargeRadioButtonWidget.scss';

const ToggleLargeRadioButtonWidget = (props: WidgetProps) => {
  const { rawErrors } = props;
  return (
    <div className='large-radio-button-widget'>
      <ToggleRadioButton
        {...{
          ...props,
          isError: rawErrors && rawErrors.length > 0,
        }}
      />
    </div>
  );
};

export default ToggleLargeRadioButtonWidget;
