import { getItem, getJson } from 'framework/dataService';
import { wiRuleMap } from './WI/CustomSchemaValidation';
import { ohRuleMap } from './OH/CustomSchemaValidation';
import { tnRuleMap } from './TN/CustomSchemaValidation';
import { inRuleMap } from './IN/CustomSchemaValidation';
import type { CustomJsonSchemaProps } from '../../interfaces/CustomJsonSchemaProps';
import { commonRuleMap } from './common/commonCustomSchemaValidation';
import { insuranceApplicationObject } from 'library/application/applicationConstants';

const ruleMap: Record<
  string,
  Record<
    string,
    (
      schema: CustomJsonSchemaProps,
      data: any,
      fieldSchema: any,
      validationType: any,
      fieldName: any,
      rule: any,
      errors?: any
    ) => { isError: boolean; warningMsgs: any[] }
  >
> = {
  WI: {
    ...commonRuleMap,
    ...wiRuleMap,
  },
  OH: {
    ...commonRuleMap,
    ...ohRuleMap,
  },
  TN: {
    ...commonRuleMap,
    ...tnRuleMap,
  },
  IN: {
    ...commonRuleMap,
    ...inRuleMap,
  },
};

export const CustomSchemaValidation = (
  schema: CustomJsonSchemaProps,
  data: any,
  errors?: any
) => {
  let isError = false,
    warningMsgs: any[] = [];
  const stateRuleMap = getStateRule(data);
  if (schema?.properties && Object.keys(schema?.properties).length > 0) {
    for (let [fieldName, fieldSchema] of Object.entries(schema?.properties)) {
      // handling each field in the schema.properties
      if (fieldSchema.validation) {
        for (const [validationType, validationRule] of Object.entries(
          fieldSchema.validation
        )) {
          let rule: any = validationRule;
          if (stateRuleMap?.[validationType]) {
            const validation = stateRuleMap[validationType](
              schema,
              data,
              fieldSchema,
              validationType,
              fieldName,
              rule,
              errors
            );
            isError = isError ? isError : validation.isError;
            warningMsgs.concat(validation.warningMsgs);
          } else {
            console.debug(
              'Validation not yet supported for : ',
              validationType
            );
          }
        }
      }
    }
  }

  return { isError, warningMsgs };
};

const getStateRule = (data: any) => {
  let applicationData = getJson(insuranceApplicationObject);
  const state: string =
    applicationData?.locations?.[0]?.address?.state ??
    applicationData?.locations?.[0]?.state ??
    data?.state ??
    'WI';
  const stateRule = ruleMap?.[state.toUpperCase()]
    ? ruleMap[state.toUpperCase()]
    : ruleMap.WI;
  return stateRule;
};

export const findObjectInSchemaDefinitions = (
  definitions: any,
  ref: string
) => {
  const definitionName = ref.replace('#/definitions/', '');
  return definitions[definitionName];
};

export const checkAdditionalCSRValidation = (
  fieldName: any,
  fieldSchema: any,
  schema: any,
  formData: any
) => {
  let updateFieldName = fieldName;
  let updateFieldSchema = fieldSchema;
  if (fieldName === 'autoInsuranceToday' && !formData.autoInsuranceToday) {
    if (
      schema?.allOf[1] &&
      schema?.allOf[1]?.then?.properties &&
      Object.keys(schema.allOf[1].then.properties).length > 0
    ) {
      if (
        schema.allOf[1].then.properties?.autoInsuranceLastMonth?.csrRestriction
          ?.length > 0
      ) {
        updateFieldName = 'autoInsuranceLastMonth';
        updateFieldSchema =
          schema.allOf[1].then.properties.autoInsuranceLastMonth;
      }
    }
  }
  return {
    fieldName: updateFieldName,
    fieldSchema: updateFieldSchema,
  };
};
