import React from 'react';
import { IIconProps } from './AvatarOne';

export default function PencilIcon({
  fillColor = 'black',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      width='35'
      height='35'
      viewBox='0 0 35 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M23 8L2 29V33H6L27 12L25 10' stroke='black' strokeWidth='2.5' />
      <path
        d='M32.5858 6.41421L30 9L28 7L27 6L26 5L28.5858 2.41421C29.3668 1.63317 30.6332 1.63316 31.4142 2.41421L32.5858 3.58579C33.3668 4.36683 33.3668 5.63316 32.5858 6.41421Z'
        stroke='black'
        strokeWidth='2.5'
      />
    </svg>
  );
}
