import React from 'react';
import { FieldProps } from '@rjsf/core';
import Link from 'framework/components/Link';

const LinkField = (props: FieldProps) => {
  const { formData } = props;
  if (formData) {
    const link = formData.split('|'); // format: linkText|url
    return <Link url={link[2]} linkText={link[1]} linkIcon={link[0]} />;
  }
  return <div />;
};

export default LinkField;
