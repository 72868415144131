import React from 'react';
import { Grid } from '@mui/material';
import { CustomArrayFieldTemplateProps } from './CustomArrayFieldTemplate';
import { removeMarkUp } from 'utils/util';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';

const SimpleArrayFieldTemplate = (props: CustomArrayFieldTemplateProps) => {
  const { items, className, canAdd, onAddClick } = props;
  return (
    <>
      <Grid id='simple' className={className}>
        {items &&
          items.map((element, index) => (
            <Grid key={index} className={element.className}>
              <Grid>{element.children}</Grid>
              {element.hasMoveDown && (
                <button
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1
                  )}
                >
                  {/* Down */}
                  {removeMarkUp(
                    getMicroCopyResourceItem(`q2b.global.button.text.down`)
                  )}
                </button>
              )}
              {element.hasMoveUp && (
                <button
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1
                  )}
                >
                  {/* Up */}
                  {removeMarkUp(
                    getMicroCopyResourceItem(`q2b.global.button.text.up`)
                  )}
                </button>
              )}
              {element.hasRemove && (
                <button onClick={element.onDropIndexClick(element.index)}>
                  {/* Delete */}
                  {removeMarkUp(
                    getMicroCopyResourceItem(`q2b.global.button.text.delete`)
                  )}
                </button>
              )}
            </Grid>
          ))}

        {canAdd && (
          <Grid className='row test'>
            <p className='col-xs-3 col-xs-offset-9 array-item-add text-right'>
              <button onClick={onAddClick} type='button'>
                {/* Add Item */}
                {removeMarkUp(
                  getMicroCopyResourceItem(`q2b.global.button.text.add.item`)
                )}
              </button>
            </p>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SimpleArrayFieldTemplate;
