import { Box, Divider, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import AlertComponent from 'components/AlertComponent';
import CoverageIcon from 'components/icons/CoverageIcon';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import NovoForm from 'framework/forms/NovoForm';
import * as React from 'react';
import parse from 'html-react-parser';
import { removeMarkUp } from 'utils/util';
import MuiButton from '../MuiButton';

interface MuiDialogProps {
  open?: any;
  alert?: any;
  uiSchema?: any;
  selectedValue?: string;
  onClose?: any;
  content?: boolean;
  contentData?: any;
  closeButtonText?: string | JSX.Element;
  closeOutSide?: boolean;
  schemaName?: string;
  muiButtonClassName?: string;
}

export default function MuiDialog({
  onClose,
  alert = undefined,
  open,
  uiSchema,
  content,
  contentData,
  closeButtonText,
  closeOutSide,
  schemaName,
  muiButtonClassName,
}: MuiDialogProps) {
  const body = uiSchema?.body;
  const [initialFormData, setInitialFormData] = React.useState({});
  const handleCloseOnClickOutSide = (shouldClose = true) => {
    shouldClose && onClose(false);
  };

  const handleClickAction = (action: string) => {
    window.open(action, '_blank');
  };

  const handleSubmit = (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    onClose(false);
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    return errors;
  };

  if (contentData) {
    const listOfContents = contentData?.content?.map(
      (contentItem: any, index: number) => (
        <Grid
          className='rich-text_doc-body'
          key={index}
          sx={{ marginBottom: '1rem' }}
        >
          {parse(contentItem)}
        </Grid>
      )
    );

    return (
      <Dialog
        onClose={() => handleCloseOnClickOutSide(closeOutSide)}
        open={open}
        className='app-wrapper'
      >
        <Grid
          container
          className='quote-wrapper'
          sx={{
            px: { xs: '48px', lg: '95px' },
            pt: { xs: '48px', lg: '95px' },
          }}
        >
          {contentData.title && (
            <Typography
              className='muiDialog-title'
              variant='h4'
              component='div'
            >
              {contentData.title}
            </Typography>
          )}
          <Grid className='muiDialog-body' container>
            {listOfContents}
          </Grid>
          {schemaName ? (
            <Grid className='page-wrapper' item xs={12}>
              <NovoForm
                schemaName={schemaName}
                onSubmit={handleSubmit}
                onChange={handleChange}
                onError={handleError}
                validate={handleValidation}
                formData={initialFormData}
                transitionTo={false}
                button={
                  <MuiButton
                    id='continue'
                    className={muiButtonClassName || 'btn-arrow'}
                    type='submit'
                    color='secondary'
                    variant='contained'
                    style={{ width: '100%' }}
                    text={
                      closeButtonText ||
                      removeMarkUp(
                        getMicroCopyResourceItem(`q2b.global.button.text.close`)
                      )
                    }
                    //onClick={() => handleCloseOnClickOutSide(true)}
                  />
                }
              />
            </Grid>
          ) : (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ mb: '45px', pt: '30px' }}
            >
              <Box className='hr-double' sx={{ mb: '45px' }} />
              {alert?.code && (
                <AlertComponent message={alert.message} type={alert.code} />
              )}
              <MuiButton
                id='continue'
                className={muiButtonClassName || 'w-full'}
                type='submit'
                color='secondary'
                text={
                  closeButtonText ||
                  removeMarkUp(
                    getMicroCopyResourceItem(`q2b.global.button.text.close`)
                  )
                }
                onClick={() => handleCloseOnClickOutSide(true)}
              />
            </Grid>
          )}
        </Grid>
      </Dialog>
    );
  }

  if (body) {
    // uiSchema driven dialog
    return (
      <Dialog
        onClose={() => handleCloseOnClickOutSide(uiSchema?.closeOutside)}
        open={open}
      >
        <Grid
          container
          sx={{ padding: '40px 55px', border: '10px solid #000' }}
        >
          <>
            {body.heading1 && (
              <Typography
                variant='h4'
                component='div'
                sx={{ fontWeight: '600' }}
              >
                {body.heading1}
              </Typography>
            )}
            <Divider
              sx={{ borderBottomWidth: 4 }}
              style={{ width: '6%', borderColor: '#000', padding: '7px' }}
            />
            {body.heading2 && (
              <Typography
                variant='subtitle1'
                component='div'
                sx={{ paddingTop: '20px', mb: 4 }}
              >
                {body.heading2}
              </Typography>
            )}
            {body.labelWithIcon
              ? body.labelWithIcon.map((item: any, index: any) => {
                  return (
                    <Box
                      className={'label-with-icon-field'}
                      key={index}
                      sx={{
                        borderRadius: '0px',
                        width: '100%',
                        border: 'solid 2px #E8E9EE',
                        position: 'relative',
                        cursor: 'pointer',
                        mb: 4,
                      }}
                      onClick={() => handleClickAction(item.clickUrl)}
                    >
                      {item && (
                        <Grid
                          container
                          sx={{ padding: '10px 15px', alignItems: 'center' }}
                        >
                          {item.imgsrc && (
                            <Grid item sx={{ mr: '12px' }}>
                              <CoverageIcon icon={item.imgsrc} />
                            </Grid>
                          )}
                          <Grid
                            item
                            sx={{
                              flex: '1 1 0',
                              minWidth: '6rem',
                              textDecoration: item.underline
                                ? 'underline'
                                : 'none',
                              wordBreak: 'break-all',
                            }}
                          >
                            {item.label}
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  );
                })
              : ''}

            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <MuiButton
                  id='continue'
                  className='btn-gray btn-w-unset'
                  type='submit'
                  color='secondary'
                  variant='contained'
                  text={
                    closeButtonText ||
                    removeMarkUp(
                      getMicroCopyResourceItem(`q2b.global.button.text.close`)
                    )
                  }
                  onClick={() => handleCloseOnClickOutSide(true)}
                />
              </Grid>
            </Grid>
          </>
        </Grid>
      </Dialog>
    );
  }

  return <></>;
}
