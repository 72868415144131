import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { removeMarkUp, random } from 'utils/util';
import MicrocopyComponent from '../MicrocopyComponent';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import './Loader.scss';

export default function Loader(props: any) {
  const hasComponentMounted = useRef(false);
  const cubeContainerRef = useRef(null);
  const [message, setMessage] = useState(props.message);

  useEffect(() => {
    if (!hasComponentMounted.current) {
      /* https://greensock.com/react/ */

      const tl = gsap.timeline({ repeat: -1 });
      const ease = 'back'; // https://greensock.com/docs/v3/Eases
      const duration = 0; // Changes default duration to 0, instant
      const q = gsap.utils.selector('#loader-new');
      /* Formation 1 */
      tl.to(q('.cube-1, .cube-4'), {
        yPercent: 50, // Moves object 50% relative to itself transform: translateY(50%)
        ease,
      });

      /* Formation 2 */
      tl.to(q('.cube-3'), {
        xPercent: 50,
        yPercent: -25,
        ease,
      });

      tl.to(
        q('.cube-1'),
        {
          xPercent: -50,
          yPercent: 75,
          ease,
        },
        '<' // Animation starts that the same time as previous
      );
      tl.to(
        q('.sw-2'),
        {
          zIndex: 4,
          duration,
        },
        '<'
      );
      tl.to(
        q('.cube-2'),
        {
          xPercent: -50,
          yPercent: 25,
          ease,
        },
        '<'
      );
      tl.to(
        q('.cube-4'),
        {
          xPercent: 50,
          yPercent: 25,
          ease,
        },
        '<'
      );

      /* Formation 3*/
      tl.to(q('.cube-2'), {
        yPercent: 75,
        ease,
      });
      tl.to(
        q('.sw-1'),
        {
          zIndex: 1,
          duration,
        },
        '<'
      );

      tl.to(
        q('.cube-3'),
        {
          yPercent: 25,
          ease,
        },
        '<'
      );

      /*Formation 4*/
      tl.to(q('.cube-1'), {
        yPercent: 25,
        ease,
      });
      tl.to(
        q('.cube-4'),
        {
          yPercent: -25,
          ease,
        },
        '<'
      );

      /*Formation 5 */
      tl.to(q('.cube-3'), {
        xPercent: 0,
        yPercent: 50,
        ease,
      });
      tl.to(
        q('.sw-4'),
        {
          zIndex: 4,
          duration,
        },
        '<'
      );
      tl.to(
        q('.sw-2'),
        {
          zIndex: 1,
          duration,
        },
        '<'
      );
      tl.to(
        q('.cube-4'),
        {
          xPercent: 0,
          yPercent: 0,
          ease,
        },
        '<'
      );
      tl.to(
        q('.cube-2'),
        {
          xPercent: 0,
          yPercent: 50,
          ease,
        },
        '<'
      );
      tl.to(
        q('.cube-1'),
        {
          xPercent: 0,
          yPercent: 0,
          ease,
        },
        '<'
      );

      /* Formation 6 */

      tl.to(q('.cube-2, .cube-3'), {
        xPercent: 0,
        yPercent: 0,
        ease,
      });
      tl.to(
        q('.sw-3'),
        {
          zIndex: 4,
          duration,
        },
        '<'
      );
      if (!message) {
        let funnyMessage;
        const funnyMessagesArray = removeMarkUp(
          getMicroCopyResourceItem('q2b.loader.funny.message.array')
        )?.split('\n');
        let randomNumber = random(0, funnyMessagesArray.length - 1);
        if (funnyMessagesArray.length <= randomNumber) {
          randomNumber = 0;
        }
        funnyMessage = funnyMessagesArray[randomNumber];
        setMessage(funnyMessage);
      }
      hasComponentMounted.current = true;
    }
  }, [message]);

  return (
    <div ref={cubeContainerRef} className='cube-cont' id='loader-new'>
      <div className='svg-wrap sw-1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='335'
          height='335'
          viewBox='0 0 335 335'
          fill='none'
          className='cube-svg'
        >
          <g className='cube cube-1'>
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 0.500028 3.18351e-05 1 168.213 100.5)'
              fill='#6E6E6E'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 -0.500028 -3.18351e-05 1 129.535 122.832)'
              fill='#5A5A5A'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866041 0.499972 -0.866041 0.499972 168.213 145.16)'
              fill='#AAAAAA'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 0.500028 3.18351e-05 1 129.533 122.824)'
              fill='white'
              stroke='black'
              strokeLinejoin='bevel'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 -0.500028 -3.1835e-05 1 168.213 145.156)'
              fill='#6CF96A'
              stroke='black'
              strokeLinejoin='bevel'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866041 -0.499973 0.866041 0.499973 129.533 122.832)'
              fill='black'
              stroke='black'
              strokeLinejoin='bevel'
            />
          </g>
        </svg>
      </div>
      <div className='svg-wrap sw-2'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='335'
          height='335'
          viewBox='0 0 335 335'
          fill='none'
          className='cube-svg'
        >
          <g className='cube cube-2'>
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 0.500028 3.18351e-05 1 206.18 122.832)'
              fill='#6E6E6E'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 -0.500028 -3.18351e-05 1 167.502 145.164)'
              fill='#5A5A5A'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866041 0.499972 -0.866041 0.499972 206.18 167.492)'
              fill='#AAAAAA'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 0.500028 3.18351e-05 1 167.5 145.156)'
              fill='white'
              stroke='black'
              strokeLinejoin='bevel'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 -0.500028 -3.1835e-05 1 206.18 167.488)'
              fill='#6CF96A'
              stroke='black'
              strokeLinejoin='bevel'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866041 -0.499973 0.866041 0.499973 167.5 145.164)'
              fill='black'
              stroke='black'
              strokeLinejoin='bevel'
            />
          </g>
        </svg>
      </div>
      <div className='svg-wrap sw-3'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='335'
          height='335'
          viewBox='0 0 335 335'
          fill='none'
          className='cube-svg'
        >
          <g className='cube cube-3'>
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 0.500028 3.18351e-05 1 130.246 122.832)'
              fill='#6E6E6E'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 -0.500028 -3.18351e-05 1 91.5684 145.164)'
              fill='#5A5A5A'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866041 0.499972 -0.866041 0.499972 130.246 167.492)'
              fill='#AAAAAA'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 0.500028 3.18351e-05 1 91.5664 145.156)'
              fill='white'
              stroke='black'
              strokeLinejoin='bevel'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 -0.500028 -3.1835e-05 1 130.246 167.488)'
              fill='#6CF96A'
              stroke='black'
              strokeLinejoin='bevel'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866041 -0.499973 0.866041 0.499973 91.5664 145.164)'
              fill='black'
              stroke='black'
              strokeLinejoin='bevel'
            />
          </g>
        </svg>
      </div>
      <div className='svg-wrap sw-4'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='335'
          height='335'
          viewBox='0 0 335 335'
          fill='none'
          className='cube-svg'
        >
          <g className='cube cube-4'>
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 0.500028 3.18351e-05 1 168.213 145.168)'
              fill='#6E6E6E'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.86601 -0.500027 -3.18351e-05 1 129.535 167.5)'
              fill='#5A5A5A'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866041 0.499972 -0.866041 0.499972 168.213 189.828)'
              fill='#AAAAAA'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 0.500028 3.18351e-05 1 129.533 167.492)'
              fill='white'
              stroke='black'
              strokeLinejoin='bevel'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866009 -0.500028 -3.18351e-05 1 168.213 189.824)'
              fill='#6CF96A'
              stroke='black'
              strokeLinejoin='bevel'
            />
            <rect
              width='44.6627'
              height='44.6627'
              transform='matrix(0.866041 -0.499973 0.866041 0.499973 129.533 167.5)'
              fill='black'
              stroke='black'
              strokeLinejoin='bevel'
            />
          </g>
        </svg>
      </div>
      <MicrocopyComponent labelKey={message} />
    </div>
  );
}
