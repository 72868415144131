import React from 'react';
import type { WidgetProps } from '@rjsf/core';
import ToggleRadioButton from 'framework/components/ToggleRadioButton';

const ToggleRadioButtonWidget = (props: WidgetProps) => {
  const { rawErrors } = props;
  return (
    <ToggleRadioButton
      {...{
        ...props,
        isError: rawErrors && rawErrors.length > 0,
      }}
    />
  );
};

export default ToggleRadioButtonWidget;
