import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import MicrocopyComponent from '../framework/components/MicrocopyComponent';
import { getData } from 'framework/dataService/dataRehydration';
import TipsFrame from './icons/TipsFrame';

export interface CheckEmailProps {
  heading1: string;
  heading2?: string;
  placeHolderText?: string;
  actualValue?: string;
  showLine?: boolean;
}

export default function CheckEmailTips(props: CheckEmailProps) {
  const routeKey = 'Congratulations';

  let checkEmail = '';
  const dataForFormData = getData(routeKey);
  if (dataForFormData?.driver?.driverId) {
    checkEmail = dataForFormData.driver.email;
  }

  return (
    <Grid
      container
      justifyContent='flex-start'
      item
      xs={12}
      lg={12}
      className='congratulation-grid'
      style={{ position: 'relative' }}
    >
      <Grid
        item
        container
        xs={12}
        lg={12}
        justifyContent='start'
        style={{ backgroundSize: 'contain' }}
        sx={{
          pt: 0,
          pb: { xs: 8, lg: 0 },
          justifyContent: { xs: 'center', lg: 'flex-start' },
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            background: '#F1F2F5',
            border: '2px solid #000',
            overflow: 'hidden',
            padding: '2rem',
            zIndex: '1',
            height: '80%',
          }}
        >
          {props?.heading1 && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant='h2'
                component='h2'
                className={'common-header-h'}
              >
                {/* {heading1} */}
                <MicrocopyComponent
                  labelKey={props?.heading1}
                  placeHolderKey={props?.placeHolderText}
                  actualValue={props?.actualValue}
                />
              </Typography>
            </Grid>
          )}
          {((props?.heading1 && props?.heading2) || props?.showLine) && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box mb={4} className='text-line-bottom '></Box>
            </Grid>
          )}
          {props?.heading2 && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant='subtitle2'
                component='h2'
                className={'page-header-h2'}
              >
                {/* {heading2} */}
                <MicrocopyComponent
                  labelKey={props?.heading2}
                  placeHolderKey={['$CheckEmail$']}
                  actualValue={[checkEmail]}
                />
              </Typography>
            </Grid>
          )}
        </div>
      </Grid>
      <div
        style={{
          position: 'absolute',
          right: '6px',
          top: '13px',
          width: '100%',
          height: '100%',
        }}
      >
        <TipsFrame isFluid={true}></TipsFrame>
      </div>
    </Grid>
  );
}
