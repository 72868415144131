import AddLogo from 'components/icons/AddAvatar';
import Tile from 'components/Tile';
import { novoRoutes } from 'framework/routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CarAvatar from 'framework/components/CarAvatar';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import { getData } from 'framework/dataService/dataRehydration';
import { setItem, getItem } from 'framework/dataService';
import { notifyBugsnag } from 'utils/util';

export default function Card({
  disabled = false,
  vehicleId = '1',
  year = '2021',
  model = 'Passat',
  manufacturer = 'volkswagen',
  included = true,
  mode = 'add',
  to = '',
  progressValue = 0,
  fillColor = '#83F8FF',
  index = 0,
  updateList,
}: {
  disabled?: boolean;
  vehicleId?: string;
  year?: string;
  model?: string;
  manufacturer?: string;
  included?: boolean;
  mode?: 'add' | 'edit';
  to?: string;
  progressValue?: number;
  fillColor?: string;
  index?: number;
  updateList?: () => void;
}) {
  const navigate = useNavigate();
  const [csrDialog, setCsrDialog] = useState(false);
  const handleEditClick = () => {
    setItem('ReconciliationViewed', 'false');
    let path = novoRoutes.EditCar.path;
    path = path.replace(':vehicleId', vehicleId);

    if (to && to.length > 0) {
      path += '?to=' + to;
    }

    navigate(path);
  };

  const handleAddClick = () => {
    setItem('ReconciliationViewed', 'false');
    const dataForFormDataDriver = getData('Drivers');
    const dataForFormDataCar = getData('Cars');
    const agentIdSesssion = getItem('isAgent');
    let nextStep = true;
    if (
      dataForFormDataCar.vehicles.length > 0 &&
      dataForFormDataDriver.drivers.length > 0
    ) {
      const driverCount = dataForFormDataDriver.drivers.length;
      const vehicleCount = dataForFormDataCar.vehicles.length;
      let excessDriverCount = 2;
      if (dataForFormDataDriver.drivers.length === 1) {
        excessDriverCount = 1;
      }
      if (
        vehicleCount + 1 > driverCount + excessDriverCount &&
        !agentIdSesssion
      ) {
        nextStep = false;
        notifyBugsnag('CSR pop up validation - vehicle count', {
          driver: dataForFormDataDriver,
          vehicle: dataForFormDataCar,
        });
        setCsrDialog(true);
      }
    }
    if (nextStep) {
      let path = novoRoutes.SearchCar.path;
      if (mode === 'add') {
        navigate(path);
      }
    }
  };

  return (
    <>
      <CsrDialog
        showDialog={csrDialog}
        handleDialogClose={() => setCsrDialog(false)}
      />
      <Tile
        disabled={disabled}
        role='button'
        id={vehicleId}
        aria-label={mode === 'edit' ? 'edit car' : 'add car'}
        onClick={(event) => {
          handleAddClick();
        }}
        onEditClick={() => {
          handleEditClick();
        }}
        updateList={updateList}
        itemId={vehicleId}
        isRemoveDisabled={false}
        subject={`${manufacturer} ${model} ${year}`}
        heading1={`${model} ${year}`}
        heading2={`${manufacturer}`}
        avatar={
          mode === 'edit' ? (
            <CarAvatar index={index} style={{ marginBottom: '0.75rem' }} />
          ) : (
            <AddLogo style={{ marginBottom: '0.75rem' }} />
          )
        }
        mode={mode}
        progressValue={Number(progressValue)}
        context={'vehicle'}
      />
    </>
  );
}
