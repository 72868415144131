import { getJson, setJson } from '.';
import { microcopyContentResources } from 'library/application/applicationConstants';

export const getMicrocopyResourceObject = () => {
  const microCopyResourceObject = getJson(microcopyContentResources);
  if (microCopyResourceObject) {
    return microCopyResourceObject;
  }
  return {};
};

export const setMicrocopyResourceObject = (value: any) => {
  return setJson(microcopyContentResources, value);
};

export const getMicroCopyResourceItem = (key: string) => {
  const microCopyObject = getMicrocopyResourceObject();
  if (microCopyObject && microCopyObject[key]) {
    return microCopyObject[key];
  }
  return undefined;
};
