import { ArrayFieldTemplateProps } from '@rjsf/core';
import React from 'react';
import DriversAccidentArrayFieldTemplate from './DriversAccidentArrayFieldTemplate';
import DriversArrayFieldTemplate from './DriversArrayFieldTemplate';
import DriversIncidentReviewHistoryArrayFieldTemplate from './DriversIncidentReviewHistoryArrayFieldTemplate';
import PlanOptionsVehiclesArrayFieldTemplate from './PlanOptionsVehiclesArrayFieldTemplate';
import SimpleArrayFieldTemplate from './SimpleArrayFieldTemplate';

export interface CustomArrayFieldTemplateProps extends ArrayFieldTemplateProps {
  options: any;
}

const CustomArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const { uiSchema } = props;
  const options: any = uiSchema?.['ui:options'];
  /* example uiSchema:
  {
    "ui:options": {
      "arrayTemplate": {
        "type": "drivers"
      }
    }
  } */
  const { arrayTemplate } = options || {};
  const templateName = arrayTemplate?.type || '';
  switch (templateName) {
    case 'drivers':
      return <DriversArrayFieldTemplate {...props} options={options} />;
    case 'driver-incidents':
      return (
        <DriversIncidentReviewHistoryArrayFieldTemplate
          {...props}
          options={options}
          // key={arrayTemplate.buttonTextSuffix || 'keyValue'}
        />
      );
    case 'driver-accidents':
      return (
        <DriversAccidentArrayFieldTemplate
          {...props}
          options={options}
          // key={arrayTemplate.buttonTextSuffix || 'keyValue'}
        />
      );
    case 'plan-options-vehicles':
      return (
        <PlanOptionsVehiclesArrayFieldTemplate {...props} options={options} />
      );
    default:
      return <SimpleArrayFieldTemplate {...props} options={options} />;
  }
};

export default CustomArrayFieldTemplate;
