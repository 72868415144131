import { Grid } from '@mui/material';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import React, { useEffect, useState, useRef } from 'react';
import Validate from 'framework/forms/validations/Validate';
import getFormDefinitions from 'framework/schemaServer';
import { DriverFormParamType } from 'types/Driver';
import { getLocationFromFormData } from 'utils/location';
import MuiLoader from 'framework/components/MuiLoader';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { setOtherAddressFields } from 'utils/util';

export default function ResidenceInfo({
  updateStatus,
  index,
  driverId,
  mode,
  readonly,
}: DriverFormParamType) {
  const hasComponentMounted = useRef(false);
  const routeKey = 'DriverResidenceInfo';
  const [isDirty, setIsDirty] = useState(true as boolean);
  const [showLoader, setShowLoader] = useState(false);

  const [initialFormData, setInitialFormData] = useState({} as any);
  const { schema } = getFormDefinitions(routeKey);

  useEffect(() => {
    if (!hasComponentMounted.current) {
      const param = {
        driverId,
      };
      const dataForFormData = getData('EditResidenceInfo', param);
      let formData;
      if (dataForFormData?.driver) {
        formData = { ...dataForFormData.driver };
        if (dataForFormData?.location && !dataForFormData?.location?.primary) {
          formData.location = dataForFormData.location;
          setOtherAddressFields(
            formData.location,
            formData,
            initialFormData.location
          );
        }
        setInitialFormData(formData);
        // validate formData
        const errors = Validate(formData, schema);
        if (updateStatus && !errors && formData.isDataReviewed) {
          // done: when all required fields have data
          setIsDirty(false);
          updateStatus(index, 'done', 'onLoad');
        }
      }
      hasComponentMounted.current = true;
    }
  }, [driverId, index, schema, updateStatus, initialFormData.location]);

  const handleSubmit = (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    formData.isDataReviewed = true;
    setShowLoader(true);

    const driverData = getData('EditDriver', { driverId });
    const { location, formDataWithoutLocation } =
      getLocationFromFormData(formData);
    driverData.driver.residenceInfo = formDataWithoutLocation;

    let dataForPersistance = {
      driver: {
        ...driverData.driver,
      },
      location: undefined,
    };

    if (location) {
      dataForPersistance.location = {
        ...location,
        primary: false,
        type: 'mailing',
      };
    }
    if (persistanceService) persistanceService(dataForPersistance);

    setIsDirty(false);
    if (updateStatus) updateStatus(index, 'done', 'onSubmit');
    setShowLoader(false);
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (!isDirty && !equals(initialFormData, event.formData)) {
      // formData just got dirty
      setIsDirty(true);
      if (updateStatus) updateStatus(index, 'open', 'onChange');
    }
    if (!equals(formData?.location, initialFormData?.location)) {
      setOtherAddressFields(
        formData.location,
        formData,
        initialFormData.location
      );
    }
    setInitialFormData(formData); // needed for custom fields
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    if (formData.mailingAddressSameAsPrimaryAddress === false) {
      const { location } = formData;
      if (!location || (location && !location.fullAddress)) {
        errors.location.addError('Please enter your address.');
      }
    }
    return errors;
  };

  return (
    <>
      <Grid container className='quote-wrapper'>
        {showLoader && <MuiLoader />}
        <NovoForm
          disabled={readonly}
          schemaName={routeKey}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onError={handleError}
          validate={handleValidation}
          //buttonName='looks good'
          buttonName={
            <MicrocopyComponent
              labelKey={`q2b.global.button.text.looks.good`}
            />
          }
          formData={initialFormData}
          transitionTo={false}
          shadowButton={true}
          hideSubmitButton={!isDirty}
        />
      </Grid>
    </>
  );
}
