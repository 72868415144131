import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function CubeStack(props: IIconProps) {
  return (
    <svg
      {...{ ...defaultProps, ...props }}
      xmlns='http://www.w3.org/2000/svg'
      width='85'
      height='174'
      viewBox='0 0 85 174'
      fill='none'
    >
      <path
        d='M69.1108 172.325C69.7438 172.536 70.3958 172.688 71.0568 172.78C71.6228 172.86 72.1948 172.877 72.7638 172.829C73.2598 172.788 73.7478 172.681 74.2148 172.51C74.6408 172.356 75.0408 172.139 75.4028 171.867L82.1548 166.739C81.8178 166.993 81.4418 167.19 81.0398 167.321C80.5878 167.467 80.1168 167.549 79.6418 167.564C79.0808 167.58 78.5208 167.534 77.9708 167.426C77.3108 167.298 76.6628 167.112 76.0358 166.87C74.2868 166.174 72.6468 165.232 71.1658 164.071C69.4988 162.79 67.9738 161.333 66.6178 159.727C65.2708 158.152 64.0998 156.434 63.1268 154.604C62.2178 152.87 61.5858 151.147 61.3098 149.54C61.2098 148.98 61.1578 148.412 61.1548 147.843C61.1528 147.36 61.2018 146.878 61.3028 146.406C61.3888 145.998 61.5288 145.603 61.7188 145.232C61.8898 144.897 62.1108 144.59 62.3738 144.32L56.4438 150.382C56.1638 150.67 55.9228 150.995 55.7278 151.347C55.5168 151.731 55.3528 152.138 55.2398 152.561C55.1128 153.04 55.0358 153.531 55.0118 154.025C54.9838 154.595 55.0058 155.166 55.0768 155.732C55.3198 157.471 55.8498 159.158 56.6448 160.723C57.5278 162.5 58.6248 164.162 59.9098 165.673C61.2028 167.212 62.6758 168.592 64.2958 169.782C65.7588 170.874 67.3838 171.733 69.1108 172.325Z'
        fill='#6CF96A'
        stroke='black'
      />
      <path
        d='M70.4573 144.432C69.0123 143.694 67.6713 143.244 66.4803 143.074C65.2583 142.905 64.1893 143.027 63.3253 143.429C62.4233 143.849 61.7253 144.609 61.3823 145.543C60.9853 146.562 60.8873 147.878 61.1603 149.465C61.4383 151.091 62.0763 152.835 62.9963 154.59C63.9793 156.447 65.1623 158.19 66.5263 159.788C67.8993 161.414 69.4433 162.889 71.1313 164.186C72.6313 165.361 74.2923 166.313 76.0633 167.015C77.6593 167.619 79.0533 167.818 80.2013 167.672C81.3223 167.529 82.2063 167.061 82.8273 166.325C83.4483 165.588 83.7863 164.628 83.8653 163.462C83.9433 162.316 83.7623 160.972 83.2973 159.469C82.7993 157.919 82.1253 156.431 81.2893 155.035C80.4123 153.554 79.4083 152.153 78.2873 150.848C77.1633 149.531 75.9283 148.312 74.5963 147.205C73.3203 146.134 71.9333 145.205 70.4573 144.432Z'
        fill='white'
        stroke='black'
      />
      <path
        d='M46.0453 81.8598L24.0234 69.1445L24.0242 94.5736L46.0461 107.289L46.0453 81.8598Z'
        fill='#6E6E6E'
      />
      <path
        d='M24.0246 69.1445L2.00276 81.8598L2.00195 107.288L24.0238 94.5736L24.0246 69.1445Z'
        fill='#5A5A5A'
      />
      <path
        d='M46.0453 107.284L24.0226 94.5703L2 107.284L24.0226 119.998L46.0453 107.284Z'
        fill='#AAAAAA'
      />
      <path
        d='M24.0219 94.5707L2 81.8555L2.00081 107.285L24.0227 120L24.0219 94.5707Z'
        fill='black'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M46.0461 81.8555L24.0242 94.5707L24.0234 119.999L46.0453 107.284L46.0461 81.8555Z'
        fill='white'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M24.0226 69.1445L2 81.8584L24.0226 94.5722L46.0453 81.8584L24.0226 69.1445Z'
        fill='white'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M46.0453 56.4262L24.0234 43.7109L24.0242 69.14L46.0461 81.8553L46.0453 56.4262Z'
        fill='#6E6E6E'
      />
      <path
        d='M24.0246 43.7109L2.00276 56.4262L2.00195 81.8553L24.0238 69.14L24.0246 43.7109Z'
        fill='#5A5A5A'
      />
      <path
        d='M46.0453 81.8506L24.0226 69.1367L2 81.8506L24.0226 94.5644L46.0453 81.8506Z'
        fill='#AAAAAA'
      />
      <path
        d='M24.0219 69.1371L2 56.4219L2.00081 81.851L24.0227 94.5662L24.0219 69.1371Z'
        fill='black'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M46.0461 56.4219L24.0242 69.1371L24.0234 94.5662L46.0453 81.851L46.0461 56.4219Z'
        fill='white'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M24.0226 43.7109L2 56.4248L24.0226 69.1386L46.0453 56.4248L24.0226 43.7109Z'
        fill='white'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M67.6605 69.1489L45.6387 56.4336L45.6395 81.8627L67.6613 94.578L67.6605 69.1489Z'
        fill='#6E6E6E'
      />
      <path
        d='M45.6398 56.4336L23.618 69.1488L23.6172 94.5779L45.639 81.8627L45.6398 56.4336Z'
        fill='#5A5A5A'
      />
      <path
        d='M67.6605 94.5732L45.6379 81.8594L23.6152 94.5732L45.6379 107.287L67.6605 94.5732Z'
        fill='#AAAAAA'
      />
      <path
        d='M45.6371 81.8598L23.6152 69.1445L23.616 94.5736L45.6379 107.289L45.6371 81.8598Z'
        fill='black'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M67.6613 69.1445L45.6395 81.8598L45.6387 107.288L67.6605 94.5736L67.6613 69.1445Z'
        fill='#6CF96A'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M45.6379 56.4355L23.6152 69.1493L45.6379 81.8632L67.6605 69.1493L45.6379 56.4355Z'
        fill='white'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M67.6605 43.7153L45.6387 31L45.6395 56.4291L67.6613 69.1444L67.6605 43.7153Z'
        fill='#6E6E6E'
      />
      <path
        d='M45.6398 31L23.618 43.7152L23.6172 69.1443L45.639 56.4291L45.6398 31Z'
        fill='#5A5A5A'
      />
      <path
        d='M67.6605 69.1396L45.6379 56.4258L23.6152 69.1396L45.6379 81.8535L67.6605 69.1396Z'
        fill='#AAAAAA'
      />
      <path
        d='M45.6371 56.4262L23.6152 43.7109L23.616 69.14L45.6379 81.8553L45.6371 56.4262Z'
        fill='black'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M67.6613 43.7109L45.6395 56.4262L45.6387 81.8553L67.6605 69.14L67.6613 43.7109Z'
        fill='white'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M45.6379 31.002L23.6152 43.7158L45.6379 56.4297L67.6605 43.7158L45.6379 31.002Z'
        fill='white'
        stroke='black'
        strokeWidth='1.27157'
        strokeLinejoin='bevel'
      />
      <path
        d='M26.681 71.4215L33.795 78.6946C33.266 78.1597 32.63 77.7433 31.928 77.4729C31.177 77.183 30.381 77.0259 29.576 77.0087C28.658 76.9872 27.742 77.0865 26.85 77.3039C25.813 77.5534 24.803 77.906 23.836 78.3566C22.098 79.1691 20.464 80.1877 18.97 81.3905C17.42 82.6272 15.994 84.0107 14.71 85.5218C13.43 87.0197 12.299 88.6394 11.334 90.3576C10.407 91.9932 9.69099 93.7399 9.20399 95.5559C8.95699 96.4885 8.803 97.4434 8.744 98.4065C8.692 99.2374 8.74699 100.072 8.90699 100.889C9.04799 101.611 9.308 102.306 9.677 102.943C10.027 103.544 10.492 104.07 11.045 104.493L2.94099 98.3393C2.42599 97.945 2.003 97.4425 1.703 96.8672C1.379 96.2405 1.167 95.5616 1.078 94.8616C0.974 94.045 0.974 93.2185 1.078 92.4019C1.193 91.4349 1.39799 90.4806 1.68899 89.5513C2.27899 87.7106 3.078 85.9439 4.071 84.2858C5.113 82.524 6.306 80.8568 7.639 79.3034C8.973 77.737 10.441 76.2887 12.025 74.9746C13.54 73.7051 15.189 72.6032 16.942 71.6882C17.903 71.1887 18.908 70.7797 19.945 70.4665C20.818 70.1987 21.721 70.0441 22.633 70.0063C23.406 69.9719 24.178 70.0781 24.913 70.3199C25.581 70.5431 26.186 70.9206 26.681 71.4215Z'
        fill='black'
      />
      <path
        d='M27.004 71L34.114 78.1656C34.453 78.5096 34.744 78.8963 34.98 79.3158C35.236 79.7723 35.435 80.2582 35.57 80.7625C35.725 81.3323 35.818 81.9167 35.847 82.5058C35.883 83.1847 35.857 83.8653 35.77 84.5397C35.536 86.4553 34.87 88.4851 33.864 90.507C32.791 92.6308 31.458 94.6179 29.895 96.4242C28.324 98.263 26.535 99.9111 24.567 101.333C22.79 102.639 20.818 103.663 18.721 104.371C17.953 104.621 17.164 104.802 16.363 104.912C15.678 105.006 14.984 105.025 14.294 104.968C13.694 104.919 13.103 104.792 12.537 104.59C12.026 104.404 11.546 104.146 11.112 103.822L3 97.7727C3.409 98.075 3.86601 98.3086 4.35201 98.464C4.9 98.6395 5.47001 98.7373 6.04601 98.7546C6.72501 98.7754 7.40501 98.7209 8.07201 98.5923C8.87101 98.4407 9.655 98.2195 10.414 97.931C12.538 97.1023 14.531 95.9773 16.331 94.5907C18.355 93.0584 20.207 91.3172 21.855 89.3969C23.491 87.5137 24.913 85.4595 26.094 83.2712C27.198 81.1993 27.963 79.1394 28.297 77.2198C28.417 76.5516 28.479 75.8746 28.482 75.196C28.484 74.6209 28.424 74.0473 28.303 73.4847C28.198 72.9973 28.029 72.5257 27.798 72.0821C27.589 71.6849 27.322 71.3202 27.004 71Z'
        fill='#6CF96A'
        stroke='black'
        strokeLinejoin='round'
      />
      <path
        d='M17.4459 71.6687C19.2139 70.8025 20.8549 70.2808 22.3139 70.0875C23.8079 69.886 25.1159 70.0351 26.1709 70.5084C27.2729 71.0043 28.1259 71.8989 28.5419 72.9962C29.0269 74.2048 29.1449 75.7398 28.8089 77.6011C28.4659 79.5087 27.6849 81.5532 26.5589 83.6119C25.3549 85.7842 23.9059 87.8234 22.2389 89.6933C20.5599 91.5999 18.6719 93.3287 16.6099 94.8501C14.7759 96.2276 12.7459 97.3462 10.5819 98.1718C8.62787 98.8829 6.92287 99.1166 5.51487 98.9474C4.14387 98.7842 3.05988 98.2363 2.29988 97.3721C1.53888 96.508 1.12187 95.396 1.02287 94.0162C0.923867 92.6726 1.14488 91.0954 1.71388 89.3308C2.32188 87.5098 3.14688 85.7619 4.17088 84.1216C5.24588 82.3821 6.47687 80.7361 7.85087 79.2025C9.22787 77.6528 10.7419 76.22 12.3749 74.9199C13.9379 73.6642 15.6379 72.5741 17.4459 71.6687ZM12.4719 90.8617C13.5219 90.4264 14.5069 89.8587 15.3999 89.1736C16.3849 88.4308 17.2899 87.5946 18.1009 86.6778C18.9039 85.7751 19.6069 84.7956 20.2009 83.7549C20.7389 82.8326 21.1369 81.8418 21.3839 80.812C21.5839 80.0374 21.5839 79.2277 21.3839 78.4532C21.2279 77.8796 20.8469 77.386 20.3209 77.0753C19.7449 76.7706 19.0829 76.6487 18.4309 76.7268C17.5579 76.8211 16.7109 77.0661 15.9289 77.45C14.9709 77.9051 14.0709 78.4647 13.2469 79.1179C12.3709 79.8039 11.5619 80.5644 10.8289 81.3901C10.0999 82.2041 9.45088 83.0807 8.88888 84.0088C8.36188 84.8696 7.94788 85.789 7.65388 86.7463C7.40488 87.5153 7.31987 88.3241 7.40187 89.1253C7.45787 89.7334 7.73487 90.3034 8.18287 90.7368C8.65387 91.1473 9.26587 91.3775 9.90287 91.3834C10.7829 91.3931 11.6539 91.2171 12.4549 90.8677'
        fill='white'
      />
      <path
        d='M12.4719 90.8617C13.5219 90.4264 14.5069 89.8587 15.3999 89.1736C16.3849 88.4308 17.2899 87.5946 18.1009 86.6778C18.9039 85.7751 19.6069 84.7956 20.2009 83.7549C20.7389 82.8326 21.1369 81.8418 21.3839 80.812C21.5839 80.0374 21.5839 79.2277 21.3839 78.4532C21.2279 77.8796 20.8469 77.386 20.3209 77.0753C19.7449 76.7706 19.0829 76.6487 18.4309 76.7268C17.5579 76.8211 16.7109 77.0661 15.9289 77.45C14.9709 77.9051 14.0709 78.4647 13.2469 79.1179C12.3709 79.8039 11.5619 80.5644 10.8289 81.3901C10.0999 82.2041 9.45088 83.0807 8.88888 84.0088C8.36188 84.8696 7.94788 85.789 7.65388 86.7463C7.40488 87.5153 7.31987 88.3241 7.40187 89.1253C7.45787 89.7334 7.73487 90.3034 8.18287 90.7368C8.65387 91.1473 9.26587 91.3775 9.90287 91.3834C10.7829 91.3931 11.6539 91.2171 12.4549 90.8677L12.4719 90.8617ZM17.4459 71.6687C19.2139 70.8025 20.8549 70.2808 22.3139 70.0875C23.8079 69.886 25.1159 70.0351 26.1709 70.5084C27.2729 71.0043 28.1259 71.8989 28.5419 72.9962C29.0269 74.2048 29.1449 75.7398 28.8089 77.6011C28.4659 79.5087 27.6849 81.5532 26.5589 83.6119C25.3549 85.7842 23.9059 87.8234 22.2389 89.6933C20.5599 91.5999 18.6719 93.3287 16.6099 94.8501C14.7759 96.2276 12.7459 97.3462 10.5819 98.1718C8.62787 98.8829 6.92287 99.1166 5.51487 98.9474C4.14387 98.7842 3.05988 98.2363 2.29988 97.3721C1.53888 96.508 1.12187 95.396 1.02287 94.0162C0.923867 92.6726 1.14488 91.0954 1.71388 89.3308C2.32188 87.5098 3.14688 85.7619 4.17088 84.1216C5.24588 82.3821 6.47687 80.7361 7.85087 79.2025C9.22787 77.6528 10.7419 76.22 12.3749 74.9199C13.9379 73.6642 15.6379 72.5741 17.4459 71.6687Z'
        stroke='black'
      />
      <path
        d='M19 18.8484L32.703 29L54 11.1498L40.294 1L19 18.8484Z'
        fill='white'
        stroke='black'
        strokeLinejoin='round'
      />
      <path
        d='M32.5 28.6485L45.159 48L66.5 30.3515L53.841 11L32.5 28.6485Z'
        fill='#6CF96A'
        stroke='black'
        strokeLinejoin='round'
      />
      <path
        d='M19 19L31.469 38.0948L45 48L32.529 28.9035L19 19Z'
        fill='black'
        stroke='black'
        strokeLinejoin='round'
      />
    </svg>
  );
}
