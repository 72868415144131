import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import type { WidgetProps } from '@rjsf/core';
import ChevronDownButton from 'components/icons/ChevronDownButton';
import React from 'react';

const MuiSelectWidget = (props: WidgetProps) => {
  const { id, schema, uiSchema, value, onChange, label, required, disabled } =
    props;

  const handleMenuItemChange = (event: SelectChangeEvent) => {
    if (onChange) onChange(event.target.value as string);
  };

  const listOfOptions = schema?.enum?.map((menuValue: any, index: number) => (
    <MenuItem
      key={index}
      value={menuValue}
      sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
    >
      {(schema as any)?.enumNames?.length > 0
        ? (schema as any)?.enumNames[index]
        : menuValue}
    </MenuItem>
  ));

  const selectVariant: any = uiSchema['ui:options']?.variant
    ? uiSchema['ui:options']?.variant
    : 'outlined';

  return (
    <FormControl
      required={required}
      variant={selectVariant}
      sx={{ minWidth: 120 }}
      className={value ? 'mui-select-widget' : 'mui-select-widget empty-value'}
    >
      <InputLabel id='demo-simple-select-standard-label' sx={{ zIndex: 0 }}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        labelId='demo-simple-select-standard-label'
        id={id}
        value={value || (schema.default as string | undefined) || ''}
        // defaultValue={(schema.default as string | undefined) || ''}
        onChange={handleMenuItemChange}
        label={label}
        IconComponent={ChevronDownButton}
      >
        {listOfOptions}
      </Select>
    </FormControl>
  );
};

export default MuiSelectWidget;
