import axios from 'axios';
import { CardConnectTokenVariables } from 'types/CardConnect';
import { v4 as uuidv4 } from 'uuid';
import { generateHTMLFromMarkDown } from 'utils/util';
import { MicrocopyResourceType } from 'types/GenericTypes';
import { FetchResults } from './MakeServiceCall';
import {
  baseServiceUrl,
  accessToken,
  spaceId,
  envId,
  apiKey,
} from './constants';
import { getItem } from 'framework/dataService';

const apiKeyHeaders: Record<string, string> = { 'x-api-key': apiKey as string };

export const isStateInServiceArea = async (state: string) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/q2b/service/area?state=${state}`,
    method: 'GET',
  });
  return data;
};

export const isStateInServiceUbi = async (state: string) => {
  const token = await retrieveApplicationToken('form-token');
  const data = await FetchResults({
    url: `${baseServiceUrl}/product/${state}/risk/attribute/ubi/categorization/open`,
    headers: { Authorization: `Bearer ${token?.response}` },
    method: 'GET',
  });
  return data;
};

/**
 * @description: to get token for temporary solution
 */
export const retrieveApplicationToken = async (state: string) => {
  const agentInfo = getItem('isAgent');
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/form?state=${state}&from=${
      agentInfo ? 'producer' : 'q2b'
    }`,
    method: 'GET',
  });
  return data;
};

export const retrieveApplicationForms = async (state: string) => {
  const agentInfo = getItem('isAgent');
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/form?state=${state}&from=${
      agentInfo ? 'producer' : 'q2b'
    }`,
    method: 'GET',
  });
  return data;
};

export const retrieveApplication = async (
  applicationId: string,
  policyId?: string
) => {
  const url = policyId
    ? `${baseServiceUrl}/application?policyId=${policyId}`
    : `${baseServiceUrl}/application?applicationId=${applicationId}`;
  const data = await FetchResults({
    url,
    method: 'GET',
  });
  return data;
};

export const createApplication = async (payload: any) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/application`,
    payload: JSON.stringify(payload),
    method: 'POST',
  });
  return data;
};

export const verifyExistingApplication = async (payload: any) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/id`,
    payload: JSON.stringify(payload),
    method: 'POST',
  });
  return data;
};

export const saveApplication = async (
  payload: any,
  ignoreReadonly: boolean = false
) => {
  if (payload?.metaData?.readonly && !ignoreReadonly) {
    return {
      error: false,
      response: {
        ...payload,
      },
    };
  } else {
    const data = await FetchResults({
      url: `${baseServiceUrl}/application`,
      payload: JSON.stringify(payload),
      method: 'PUT',
      shouldRetry: true,
    });
    if (
      payload?.locations?.[0]?.address &&
      Object.keys(payload?.locations?.[0]?.address).length > 0
    ) {
      data.response.locations = payload?.locations;
    } else if (
      payload?.locations?.[0]?.fullAddress &&
      payload?.locations?.[0]?.zipCode &&
      payload?.locations?.[0]?.city &&
      payload?.locations?.[0]?.state
    ) {
      data.response.locations[0] = {
        address: {
          address: payload?.locations?.[0]?.fullAddress,
          city: payload?.locations?.[0]?.city,
          state: payload?.locations?.[0]?.state,
          zip: payload?.locations?.[0]?.zipCode,
        },
        locationId: payload?.locations?.[0]?.locationId,
        primary: payload?.locations?.[0]?.primary,
        type: 'primary',
      };
    }
    return data;
  }
};

export const addDriver = async (payload: any) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/driver`,
    payload: JSON.stringify(payload),
    method: 'POST',
  });
  return data;
};

export const addVehicle = async (
  applicationId: string,
  vin: string,
  state: string,
  licensePlate: string
) => {
  const payload = {
    applicationId,
    vin,
    licensePlate: {
      state,
      id: licensePlate,
    },
    vehicleId: uuidv4(),
  };
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/vehicle`,
    payload: JSON.stringify(payload),
    method: 'POST',
  });
  return data;
};

export const getQuote = async (applicationId: string, customPlan: boolean) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/quote?applicationId=${applicationId}&customPlan=${customPlan}&useHour=true`,
    method: 'GET',
    shouldRetry: true,
  });
  return data;
};

export const retrieveReconciliation = async (applicationId: string) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/reconciliation?applicationId=${applicationId}`,
    method: 'GET',
    shouldRetry: true,
    retryTimes: 2,
  });
  return data;
};

export const getActivePoliciesByVin = async (vin: string) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/mgnt/policy/active-policies?vin=${vin}`,
    method: 'GET',
  });
  return data;
};

export const getDisclosures = async (applicationId: string) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/disclosure?applicationId=${applicationId}`,
    method: 'GET',
  });
  return data;
};
export const getApplicationDocumentApiCall = async (applicationId: string) => {
  const payload = { 'application-id': applicationId };
  const data = await FetchResults({
    url: `${baseServiceUrl}/policy/document`,
    payload: JSON.stringify(payload),
    headers: apiKeyHeaders,
    method: 'POST',
  });
  return data;
};
export const eSignDocumentApiCall = async (payload: any) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/q2b/esign-document`,
    payload: JSON.stringify(payload),
    headers: apiKeyHeaders,
    method: 'POST',
  });
  return data;
};
export const agreementApiCall = async (payload: any) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/q2b/agreement`,
    payload: JSON.stringify(payload),
    headers: apiKeyHeaders,
    method: 'POST',
  });
  return data;
};

export const getCardConnectToken = async (
  account: string,
  cvv: string,
  expiry: string
) => {
  const payload: CardConnectTokenVariables = {
    account: account,
    cvv: cvv,
    expiry: expiry,
  };

  const data = await FetchResults({
    url: `${process.env.REACT_APP_CARD_POINTE_HOST}/cardsecure/api/v1/ccn/tokenize`,
    payload: JSON.stringify(payload),
    method: 'POST',
  });

  return data;
};

export const paymentAndBind = async (applicationId: string, payload: any) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/application/bind?applicationId=${applicationId}`,
    payload: JSON.stringify(payload),
    method: 'POST',
    shouldRetry: true,
  });
  return data;
};

export const createUserAccount = async (payload: any) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/mobilenew/user/sign-up`,
    payload: JSON.stringify(payload),
    method: 'POST',
  });
  return data;
};

export const ScanId = async (imageBase64Encoding: string) => {
  const { data } = await axios.post(
    `${baseServiceUrl}/q2b/id-scan`,
    imageBase64Encoding
  );

  return data;
};

export const uploadDocument = async (applicationId: any, payload: any) => {
  const url = `${baseServiceUrl}/policy/document/upload?applicationId=${applicationId}`;
  const formData = new FormData();
  formData.append('file', payload);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post(url, formData, config);
};

export const lienHolderDetails = async (state: string) => {
  const token = await retrieveApplicationToken('form-token');
  const data = await FetchResults({
    url: `${baseServiceUrl}/product/${state}/risk/attribute/lienholder/category/seguro?from=q2b`,
    headers: { Authorization: `Bearer ${token?.response}` },
    method: 'GET',
  });
  return data;
};

export const getClientIP = async () => {
  const data = await FetchResults({
    url: 'https://geolocation-db.com/json/',
    method: 'GET',
  });
  if (data.error === true) {
    return 'Unknown IP';
  } else {
    return data.response.IPv4;
  }
};

export const getActivePolicies = async (emailAddress: string) => {
  const data = await FetchResults({
    url: `${baseServiceUrl}/mgnt/policy/active-policies?email_address=${emailAddress}`,
    method: 'GET',
  });
  return data;
};

export const fetchMicrocopySetFromContentful = async () => {
  const microcopyResourceCollection: MicrocopyResourceType[] =
    await contentfulGraphqlQuery(0, []);

  const promises = microcopyResourceCollection?.map(
    async (resource: MicrocopyResourceType) => {
      const key = resource.key;
      const value = await generateHTMLFromMarkDown(resource.value);
      return {
        key,
        value,
      };
    }
  );

  const updatedResourceCollection = await Promise.all(promises);
  const finalResourceCollection: Record<string, string> = {};
  updatedResourceCollection.forEach((item: any) => {
    finalResourceCollection[item.key] = item.value;
  });

  return finalResourceCollection;
};

const contentfulGraphqlQuery = async (
  skip: number,
  microcopyResourceCollection: MicrocopyResourceType[]
) => {
  const defaultPageSize = 100;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'content-type': 'application/json',
  };

  const url = `https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${envId}`;

  const query = `query GetMicrocopyResourceSetByName($name: String!, $locale: String!, $skip: Int!){
    microcopyResourceSetCollection(where: {setName: $name },limit : 1){
      items{
        setName,
        resourceCollection(locale: $locale, , skip: $skip){
          total
          items{
            key
            value
          }
        }
      }
    }
  }`;

  // Contentful not Returning more than 100 items in response, so doing recursive call.
  let variables = {
    name: process.env.REACT_APP_CONTENTFUL_MICROCOPY_RESOURCE_SET_NAME,
    locale: 'en-US',
    skip: skip,
  };

  const setData = await FetchResults({
    url: url,
    payload: JSON.stringify({ query, variables }),
    method: 'POST',
    headers: headers,
  });

  const resourceCollection =
    setData?.response?.data?.microcopyResourceSetCollection?.items[0]
      ?.resourceCollection;

  const total = resourceCollection?.total;

  console.debug(
    'The contentful response contains ',
    resourceCollection?.items?.length
  );
  microcopyResourceCollection = [
    ...microcopyResourceCollection,
    ...resourceCollection?.items,
  ];

  if (total > skip + defaultPageSize) {
    skip = skip + defaultPageSize;
    microcopyResourceCollection = await contentfulGraphqlQuery(
      skip,
      microcopyResourceCollection
    );
  }

  return microcopyResourceCollection;
};
