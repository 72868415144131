export const sendGtmEvent = (event: any, props: any) => {
  const gtmPaymentevent = {
    paymentAmount: props.paymentAmount,
    currency: props.currency,
    policyTotal: props.policyTotal,
    policyFees: props.policyFees,
    policyId: props.policyId,
    policyHolderId: props.policyHolderId,
    transactionId: props.transactionId,
    everflowId: props.everflowId,
  };
  if ((window as any).dataLayer && (window as any).dataLayer.push) {
    (window as any).dataLayer.push({
      event: event,
      eventProps: gtmPaymentevent,
    });
  }
};
