import { TextField } from '@mui/material';
import type { WidgetProps } from '@rjsf/core';
import React, { useEffect, useState } from 'react';
import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from 'react-number-format';

export function getNameFromId(id: any) {
  const startIndexOfName = id.lastIndexOf('_') + 1;
  if (startIndexOfName > 1) {
    return id.substring(startIndexOfName);
  }
  return id;
}

const CustomNumberWidget = (props: WidgetProps) => {
  const {
    required,
    disabled,
    value,
    id,
    maxLength,
    minLength,
    onChange,
    preFix,
    schema,
    suffix,
    label,
    thousandSeparator,
    thousandsGroupStyle,
    uiSchema,
  } = props;

  const [appData, setAppData] = useState(value);

  useEffect(() => {
    setAppData(value || '');
  }, [value]);

  const onNumberChange = (
    values: NumberFormatValues,
    sourceInfo: SourceInfo
  ) => {
    let formData: any = values.value;
    if (onChange) {
      if (schema?.type === 'string') {
        if (formData.length === 0) {
          formData = undefined;
        }
      } else {
        formData = values.floatValue;
      }
      setAppData(formData);
      onChange(formData);
    }
  };

  let parentClassName = (uiSchema as any).className;

  let allowEmptyFormatting = false;
  if ((uiSchema as any).allowEmptyFormatting) {
    allowEmptyFormatting = true;
  }

  let maxLen = !(schema as any).maxLength
    ? (uiSchema as any).maxLength
    : (schema as any).maxLength;
  if (!maxLen) {
    maxLen = maxLength;
  }

  let minLen = !(schema as any).minLength
    ? (uiSchema as any).minLength
    : (schema as any).minLength;

  if (!minLen) {
    minLen = minLength;
  }

  let formatString = (uiSchema as any).format;

  if ((uiSchema as any).format) {
    return (
      <div className={parentClassName}>
        <NumberFormat
          format={
            formatString
          } /*todo: find a way to achieve this without a conditional*/
          allowEmptyFormatting={allowEmptyFormatting}
          autoComplete='off'
          className={'form-control' /* fieldClassName */}
          decimalScale={(uiSchema as any).decimalScale}
          decimalSeparator={(uiSchema as any).decimalSeparator}
          required={required}
          disabled={disabled}
          id={id}
          maxLength={maxLen}
          minLength={minLen}
          name={id}
          customInput={TextField}
          onValueChange={onNumberChange}
          placeholder={(uiSchema as any).placeholder}
          prefix={!preFix ? (uiSchema as any).prefix : preFix}
          suffix={!suffix ? (uiSchema as any).suffix : suffix}
          displayType='input'
          type='text'
          label={label}
          thousandSeparator={
            (uiSchema as any).thousandSeparator
              ? (uiSchema as any).thousandSeparator
              : thousandSeparator
          }
          thousandsGroupStyle={
            (uiSchema as any).thousandsGroupStyle
              ? (uiSchema as any).thousandsGroupStyle
              : thousandsGroupStyle
          }
          value={appData}
        />
      </div>
    );
  } else {
    return (
      <div className={parentClassName}>
        <NumberFormat
          allowEmptyFormatting={allowEmptyFormatting}
          autoComplete='off'
          className={'form-control' /* fieldClassName */}
          decimalScale={(uiSchema as any).decimalScale}
          decimalSeparator={(uiSchema as any).decimalSeparator}
          required={required}
          disabled={disabled}
          id={id}
          maxLength={maxLen}
          minLength={minLen}
          name={id}
          customInput={TextField}
          onValueChange={onNumberChange}
          placeholder={(uiSchema as any).placeholder}
          prefix={!preFix ? (uiSchema as any).prefix : preFix}
          suffix={!suffix ? (uiSchema as any).suffix : suffix}
          displayType='input'
          type='text'
          label={label}
          thousandSeparator={
            (uiSchema as any).thousandSeparator
              ? (uiSchema as any).thousandSeparator
              : thousandSeparator
          }
          thousandsGroupStyle={
            (uiSchema as any).thousandsGroupStyle
              ? (uiSchema as any).thousandsGroupStyle
              : thousandsGroupStyle
          }
          value={appData}
        />
      </div>
    );
  }
};

export default CustomNumberWidget;
