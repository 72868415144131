import { FormControlLabel, FormGroup, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { JSONSchema7 } from 'json-schema';
import { WidgetProps } from '@rjsf/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import './NovoCheckboxWidget.scss';

interface CustomerWidgetProps extends WidgetProps {
  schema: JSONSchema7 & {
    optional?: {
      [props: string]: string;
    };
  };
}
const NovoCheckboxWidget = (props: CustomerWidgetProps) => {
  const {
    value = false,
    onChange,
    label,
    disabled,
    id,
    formContext,
    uiSchema,
    rawErrors,
    schema,
  } = props;
  const [disabledUntil, setDisabledUntil] = useState(uiSchema?.disabledUntil);
  const [disabledFieldValue, setDisabledFieldValue] = useState();
  const [disable, setDisable] = useState(typeof disabledUntil === 'object');
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    if (uiSchema?.disabledUntil) {
      setDisabledUntil(uiSchema.disabledUntil);
    }
  }, [uiSchema]);

  useEffect(() => {
    if (
      disabledUntil?.field &&
      formContext[disabledUntil.field] !== disabledFieldValue
    ) {
      setDisabledFieldValue(formContext[disabledUntil.field]);

      if (formContext[disabledUntil.field] === disabledUntil?.value) {
        setDisable(false);
      } else {
        setDisable(true);
        onChange(undefined);
        setChecked(undefined);
      }
    }
  }, [
    disabledFieldValue,
    disabledUntil?.field,
    disabledUntil?.value,
    formContext,
    onChange,
  ]);

  useEffect(() => {
    if (value) {
      setChecked(value);
    } else {
      setChecked(false);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && !disable) {
      if (event.target.checked === false) {
        onChange(undefined);
        setChecked(undefined);
      } else if (event.target.checked === true) {
        onChange(true);
        setChecked(true);
      }
    }
  };

  /* const checkboxLabel = { inputProps: { 'aria-label': label } }; */

  return (
    <>
      {/* <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={label}
      /> */}
      <Grid
        className={(disabled || disable) && !rawErrors ? 'field-disabled' : ''}
      >
        <FormGroup className={schema?.optional?.link ? 'foo-bar' : ''}>
          <FormControlLabel
            className={'checkbox-label'}
            control={
              <Grid className={'checkbox-widget'}>
                <Checkbox checked={checked} onChange={handleChange} id={id} />
              </Grid>
            }
            label={label}
          />
          {schema?.optional?.link && (
            <a href={schema?.optional?.link} target='_blank' rel='noreferrer'>
              {schema?.optional?.linkText}
            </a>
          )}
        </FormGroup>
      </Grid>
    </>
  );
};

export default NovoCheckboxWidget;
