import React, { useState } from 'react';
import MuiButton from 'framework/components/MuiButton';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import {
  Button,
  Dialog,
  Grid,
  Typography,
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  FormControl,
} from '@mui/material';
import ChevronDownButton from './icons/ChevronDownButton';
import { removeMarkUp } from 'utils/util';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import { getDriverById, setDriver } from 'framework/dataService/drivers';
import {
  getCurrentVehicle,
  updateCurrentVehicle,
} from 'framework/dataService/vehicle';
import { saveApplicationCurrentSessionState } from 'library/services/utils';
import AlertComponent from './AlertComponent';

export default function EditAndRemoveComponent({
  disabled = false,
  isRemoveDisabled = false,
  itemId,
  subject,
  context = 'driver',
  onEditClick,
  updateList,
}: {
  disabled?: boolean;
  isRemoveDisabled?: boolean;
  itemId: string;
  subject: string;
  context?: string;
  onEditClick: () => void;
  updateList?: () => void;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [alert, setAlert] = useState('false');

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedReason(event.target.value);
    setAlert('false');
    setButtonState(true);
  };

  const handleCloseClick = () => {
    setButtonState(false);
    setShowDialog(false);
    setAlert('false');
    if (alert === 'success' && updateList) {
      updateList();
    }
  };

  const handleRemoveClick = async () => {
    setButtonState(false);
    if (context === 'vehicle') {
      const vehicle = getCurrentVehicle(itemId);
      vehicle.isDeleted = true;
      vehicle.reasonForDeletion = selectedReason;
      updateCurrentVehicle(itemId, vehicle);
    } else {
      const driver = getDriverById(itemId);
      driver.isDeleted = true;
      driver.reasonForDeletion = selectedReason;
      await setDriver(driver);
    }
    const { success } = await saveApplicationCurrentSessionState();
    success === true ? setAlert('success') : setAlert('error');
    if (success === true) {
      setButtonState(false);
      setShowDialog(false);
      setAlert('success');
      if (updateList) {
        updateList();
      }
    } else {
      setAlert('error');
    }
  };

  const listOfOptions = () => {
    const optionKey =
      context === 'vehicle'
        ? 'q2b.remove.reasons.car'
        : 'q2b.remove.reasons.drivers';
    const options = removeMarkUp(getMicroCopyResourceItem(optionKey))
      .split('\n')
      .map((menuValue: string, index: number) => {
        if (menuValue.length > 0) {
          return (
            <MenuItem
              key={index}
              value={menuValue}
              sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
            >
              {menuValue}
            </MenuItem>
          );
        }
        return false;
      });
    return options;
  };

  const removeReasonDialog = () => {
    return (
      <Dialog
        className='remove-reason-dialog'
        open={showDialog}
        onClose={() => handleCloseClick()}
      >
        <Grid
          container
          sx={{ padding: '40px 55px', border: '10px solid #000' }}
        >
          <>
            <Typography
              variant='h2'
              component='h2'
              className={'page-header-h text-line-bottom'}
            >
              <MicrocopyComponent
                labelKey={'q2b.remove.reason.dialog.header'}
                placeHolderKey={`$SUBJECT$`}
                actualValue={subject}
              />
            </Typography>
            <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    id='remove-reason-select-label-id'
                    sx={{ zIndex: 0 }}
                  >
                    {removeMarkUp(
                      getMicroCopyResourceItem(
                        'q2b.remove.reasons.select.placeholder'
                      )
                    )}
                  </InputLabel>
                  <Select
                    labelId='remove-reason-select-label-id'
                    id={'delete-reason-select'}
                    onChange={handleChange}
                    label={removeMarkUp(
                      getMicroCopyResourceItem(
                        'q2b.remove.reasons.select.placeholder'
                      )
                    )}
                    IconComponent={ChevronDownButton}
                  >
                    {listOfOptions()}
                  </Select>
                </FormControl>
              </Grid>
              {alert === 'error' && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <AlertComponent type={'error'} />
                </Grid>
              )}
              <Grid container className={`button-group`}>
                <Grid item xs={4}>
                  <Button
                    id='remove-reason-cancel-button'
                    disabled={false}
                    onClick={() => handleCloseClick()}
                    className={'no-hover'}
                  >
                    <MicrocopyComponent
                      labelKey={`q2b.global.button.text.cancel`}
                    />
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    id='remove-reason-remove-button'
                    className='w-full'
                    color='primary'
                    variant='contained'
                    disabled={!buttonState}
                    onClick={() => handleRemoveClick()}
                  >
                    <MicrocopyComponent
                      labelKey={`q2b.global.button.text.yes.remove`}
                    />
                  </Button>
                </Grid>
              </Grid>
            </>
          </>
        </Grid>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container className={'edit-remove-component'}>
        <Grid item xs={6}>
          <MuiButton
            className={'edit-button'}
            disabled={disabled}
            variant={`outlined`}
            id={`Edit_${itemId}`}
            sx={{ letterSpacing: 1 }}
            text={
              <MicrocopyComponent labelKey={`q2b.global.button.text.edit`} />
            }
            onClick={onEditClick}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiButton
            className={'remove-button'}
            disabled={disabled || isRemoveDisabled}
            variant={`outlined`}
            id={`Remove_${itemId}`}
            sx={{ letterSpacing: 1 }}
            text={
              <MicrocopyComponent labelKey={`q2b.global.button.text.remove`} />
            }
            onClick={() => setShowDialog(true)}
          />
        </Grid>
      </Grid>
      {showDialog && removeReasonDialog()}
    </>
  );
}
