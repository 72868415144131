import type { WidgetProps } from '@rjsf/core';
import NovoFileUpload from 'framework/components/NovoFileUpload';
import React from 'react';

const UploadCertificateWidget = (props: WidgetProps) => {
  return (
    <NovoFileUpload
      {...{
        ...props,
      }}
      sessionKey='certificateFileUpload'
      description='Upload your Accident Prevention Course Certificate (PDF)'
      moreInfo='Accident Prevention course certificate/proof as a PDF document is required.'
    />
  );
};

export default UploadCertificateWidget;
