import { PhotoCamera } from '@material-ui/icons';
import { Button, IconButton, Modal, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { ReactComponent as ToolTipIcon } from '../images/tool-tip.svg';
import { ScanId } from 'library/services';
import './IdScan.scss';
import MotionToast from './MotionToast';
import MuiLoader from 'framework/components/MuiLoader';
import Bugsnag from '@bugsnag/js';
import { notifyBugsnag } from 'utils/util';

export interface IdScanProps {
  handleIdScan: (value: any) => void;
  driverId?: number;
}

const IdScan: React.FC<IdScanProps> = ({ handleIdScan, driverId }) => {
  const [autoImport, setAutoImport] = useState(false);
  const [pictureData, setPictureData] = useState<string>();
  const [base64Encoding, setBase64Encoding] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleImageSubmit = async () => {
    if (!pictureData || !base64Encoding) {
      handleDisplayToast(
        'error',
        "Oops, it doesn't look like you have taken a picture yet."
      );
      return;
    }
    setIsSubmitting(true);
    let idData;
    try {
      idData = await ScanId(base64Encoding);
    } catch (error) {
      setIsSubmitting(false);
      handleDisplayToast(
        'error',
        "Oops, it looks something went wrong. Please make sure the image isn't blurry and the ID's barcode is clearly visible."
      );
    }
    if (idData) {
      setIsSubmitting(false);
      setAutoImport(false);
      handleDisplayToast(
        'success',
        "Successfully imported info from your driver's license!",
        3000
      );
      const name = {
        firstName: idData.firstName,
        lastName: idData.lastName,
      };
      const dob = idData.birthdate;
      const location = {
        city: idData.city || '',
        fullAddress: idData.address1 || '',
        state: idData.state || '',
        streetName: '',
        streetNumber: '',
        zipCode: idData.postalCode || '',
      };
      const driverData = {
        name,
        dob,
        driverId: driverId || 0,
        location: location,
        idScan: idData,
        gender: idData.gender,
        licenseNumber: idData.licenseNumber,
        licenseState: idData.issuedBy,
      };
      handleIdScan(driverData);
    } else {
      notifyBugsnag('ScanId API', idData);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.addEventListener('load', () => {
        if (reader.result) {
          setPictureData(reader.result.toString());
          setBase64Encoding(reader.result.toString().split('base64,')[1]);
        }
      });
    }
  };

  const [idScanErrorMessage, setIdScanErrorMessage] = useState('');
  const [idScanErrorToast, setIdScanErrorToast] = useState(false);
  const [createAccountSeverity, setCreateAccountSeverity] = useState(
    'success' as 'success' | 'error' | 'info' | 'warning'
  );

  const handleDisplayToast = (
    severity: 'success' | 'error' | 'info' | 'warning',
    message: string,
    ttl = 6000
  ) => {
    setCreateAccountSeverity(severity);
    setIdScanErrorToast(true);
    setIdScanErrorMessage(message);
    setTimeout(() => {
      setIdScanErrorToast(false);
    }, ttl);
  };

  return (
    <>
      <MotionToast
        severity={
          createAccountSeverity as 'success' | 'error' | 'info' | 'warning'
        }
        message={idScanErrorMessage}
        open={idScanErrorToast}
      />
      <Modal
        open={autoImport}
        onClose={() => {}}
        className='id-scan-modal-root'
      >
        <>
          <div className='id-scan-modal-wrapper'>
            {autoImport && !pictureData && (
              <div>
                <div className='id-scan-image-row id-scan-message'>
                  Take a picture of the back of your driver's license. For best
                  results image must be clear without any glares. If your image
                  is too blurry autofill will fail.
                </div>
              </div>
            )}
            {pictureData && (
              <div className='id-scan-image-wrapper'>
                <div className='id-scan-image-column'>
                  <div className='id-scan-image-wrapper'>
                    <img
                      src={pictureData}
                      alt='idPicture'
                      className='id-scan-image'
                    />
                  </div>
                  <div className='id-scan-image-row'>
                    Submit a clear image of the back of your Driver License for
                    best results
                  </div>
                </div>
              </div>
            )}
            <div className='id-scan-button-wrapper'>
              {isSubmitting && <MuiLoader />}
              <div className='id-scan-spacer'>
                <Button
                  id='idScanCancel'
                  color='secondary'
                  variant='contained'
                  onClick={() => setAutoImport(false)}
                >
                  Cancel
                </Button>
              </div>
              <div className='id-scan-spacer'>
                <input
                  accept='image/*'
                  //className={classes.input}
                  id='id-image-upload'
                  type='file'
                  onChange={(e) => handleImageUpload(e)}
                />
              </div>
              {!isSubmitting && !pictureData && (
                <div className='id-scan-spacer'>
                  <div>
                    <label htmlFor='id-image-upload'>
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                        id='idScanTakePhoto'
                      >
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </div>
                </div>
              )}

              {pictureData && !isSubmitting && (
                <div className='id-scan-spacer'>
                  <label htmlFor='id-image-upload'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                      id='idScanRetakePhoto'
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </div>
              )}

              <div className='id-scan-spacer'>
                <Button
                  id='idScanSubmit'
                  color='primary'
                  variant='contained'
                  onClick={handleImageSubmit}
                  disabled={!pictureData || isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </>
      </Modal>
      {/* {!isDesktop && (
        <div className='id-scan-wrapper'>
          <div>
            <Button
              id='idScanButton'
              color='primary'
              variant='contained'
              onClick={() => setAutoImport(true)}
            >
              Autofill with ID
            </Button>
          </div>
          <div>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3500}
              className='tooltip'
              title="Use the barcode on the back of your driver's license to auto-fill some of the form fields needed to provide you an insurance quote"
              placement='top-start'
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginLeft: '0.5em',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <ToolTipIcon />
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      )} */}
    </>
  );
};

export default IdScan;
