import { Box, Button, Grid, Typography } from '@mui/material';
import ButtonArrow from 'components/icons/ButtonArrow';
import Page from 'components/Page';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { getData } from 'framework/dataService/dataRehydration';
import { checkMinOneCarIncluded } from 'framework/dataService/vehicles';
import { novoNavigation, novoRoutes } from 'framework/routes';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionToProps from 'types/Transition';
import {
  calculateDestination,
  trackVehicleProgress,
  checkIfTooManyVehicle,
  findFirstRestrictedCar,
} from 'utils/car';
import Card from './Card';
import './Cars.scss';
import CarsCoverage from './CarsCoverage';
import Dialog from '@mui/material/Dialog';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import { findFirstRestrictedDriver } from 'utils/driver';
import { getLocations } from 'framework/dataService/drivers';
import {
  isStateInServiceUbi,
  lienHolderDetails,
  retrieveApplicationToken,
} from 'library/services';
import { notifyBugsnag } from 'utils/util';
import { getJson, setItem, setJson } from 'framework/dataService';
import { lienHolder } from 'library/application/applicationConstants';
export default function Cars({
  to = '',
  view,
  coverageIndex,
  showCoverages = false,
  defaultVehicleCoverage,
  coverages,
  errorHandling,
  updateFormDirtyFlag,
  updateCoverage,
  submitBtnRef,
  onValidateTabs,
  currentTab,
  readonly,
}: TransitionToProps) {
  const routeKey = 'Cars';
  const navigate = useNavigate();

  const [cars, setCars] = useState([] as any[]);
  const [minOneCarIncluded, setMinOneCarIncluded] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const [disable, setDisable] = useState(readonly);
  const [showVehicleCountDialog, setShowVehicleCountDialog] = useState(false);
  const [showCsrDialog, setShowCsrDialog] = useState(false);

  useEffect(() => {
    const dataForFormData = getData(routeKey);
    const pniLocation = getLocations()?.filter((item: any) => {
      return item.primary;
    });
    const lienHolderInfo = getJson(lienHolder);
    if (dataForFormData?.metaData) {
      setDisable(true);
    }
    if (dataForFormData?.vehicles?.length >= 0) {
      const carsList: React.SetStateAction<any[]> = [];
      dataForFormData.vehicles.forEach((vehicle: any) => {
        carsList.push(trackVehicleProgress('EditCar', vehicle));
      });

      setCars(carsList);
      setMinOneCarIncluded(checkMinOneCarIncluded());
    }

    const getLienHolderDetails = async (state: string) => {
      let lienHolderResponse = await lienHolderDetails(state);
      if (
        !lienHolderResponse.error &&
        lienHolderResponse.response &&
        lienHolderResponse.response?.items?.length > 0
      ) {
        setJson(lienHolder, lienHolderResponse?.response?.items ?? []);
      }
    };
    if (!lienHolderInfo) {
      const pniState =
        pniLocation && pniLocation.length > 0 && pniLocation[0]?.address?.state
          ? pniLocation[0].address.state
          : '';
      getLienHolderDetails(pniState);
    }
  }, [deleteCount]);

  const handleDialogClose = () => {
    setShowCsrDialog(false);
  };

  const handleNextButton = async () => {
    // calculate destination
    // CSR
    setItem('ReconciliationViewed', 'false');
    const { firstRestrictedDriverIndex } = findFirstRestrictedDriver();
    const routeKeyEditCar = 'EditCar';
    const firstRestrictedCarIndex = findFirstRestrictedCar(
      routeKeyEditCar,
      cars
    );
    if (firstRestrictedDriverIndex < 0 && firstRestrictedCarIndex < 0) {
      const path = calculateDestination(
        routeKeyEditCar,
        to,
        novoNavigation.Cars.nextPage
      );

      if (novoNavigation.Cars.nextPage !== path || !checkIfTooManyVehicle()) {
        let appLocations = await getLocations();
        const state: string = appLocations.filter(
          (item: { primary: boolean }) => {
            return item.primary === true;
          }
        )?.[0]?.address?.state;
        const isOpenUbi = await isStateInServiceUbi(state);
        if (path.includes('cars/edit/')) {
          navigate(path);
        } else if (isOpenUbi?.response && !isOpenUbi?.error) {
          navigate(path);
        } else {
          navigate(novoNavigation.UBI.nextPage);
        }
      } else {
        setShowVehicleCountDialog(true);
      }
    } else {
      notifyBugsnag('CSR pop up validation - vehicle', cars);
      setShowCsrDialog(true);
    }
  };

  const listOfVehicles = cars.map((car: any, index: number) => (
    <React.Fragment key={car.vehicleId}>
      <Card
        disabled={disable}
        index={index}
        vehicleId={car.vehicleId}
        year={car.year}
        model={car.model}
        manufacturer={car.manufacturer}
        included={car.included}
        fillColor={car.fillColor}
        progressValue={car.progressValue}
        mode='edit'
        to={to}
        updateList={() => setDeleteCount(deleteCount + 1)}
      />
    </React.Fragment>
  ));

  const handleCloseVehicleLimitRestrictionDialog = (
    addDriver: boolean = false
  ) => {
    setShowVehicleCountDialog(false);
    if (addDriver) {
      navigate(novoRoutes.AddDriver.path);
    }
  };

  const showVehicleRestrictionDialogue = () => {
    return (
      <Dialog
        className='vehicle-restriction-count'
        open={showVehicleCountDialog}
        onClose={() => handleCloseVehicleLimitRestrictionDialog()}
      >
        <Grid
          container
          sx={{ padding: '40px 55px', border: '10px solid #000' }}
        >
          <Grid item xs={12}>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ paddingTop: '20px', mb: 4 }}
            >
              <MicrocopyComponent labelKey='q2b.vehicles.list.max.vehicle.error.message' />
            </Typography>

            <Grid container sx={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <Button
                  id='driverInitialInfo'
                  className='btn-arrow w-full'
                  color='secondary'
                  variant='contained'
                  disabled={false}
                  onClick={() => handleCloseVehicleLimitRestrictionDialog(true)}
                  endIcon={<ButtonArrow />}
                >
                  <MicrocopyComponent labelKey={`q2b.add.driver`} />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className='btn-gray btn-w-unset novo-hyperlink'
                  onClick={() =>
                    handleCloseVehicleLimitRestrictionDialog(false)
                  }
                >
                  <MicrocopyComponent
                    labelKey={'q2b.global.button.text.close'}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  return (
    <Grid container className='quote-wrapper'>
      <CsrDialog
        showDialog={showCsrDialog}
        handleDialogClose={handleDialogClose}
      />
      {view === 'row' ? (
        <>
          <CarsCoverage
            carsList={cars}
            defaultVehicleCoverage={defaultVehicleCoverage}
            coverageIndex={coverageIndex}
            coverages={coverages}
            errorHandling={errorHandling}
            updateFormDirtyFlag={updateFormDirtyFlag}
            submitBtnRef={submitBtnRef}
            currentTab={currentTab}
            onValidateTabs={onValidateTabs}
            readonly={disable!}
          />
        </>
      ) : (
        <>
          <Page routeKey={routeKey} disabled={disable}>
            <>
              {showVehicleCountDialog && showVehicleRestrictionDialogue()}
              <Typography variant='h5' className='page-header-h5'>
                <MicrocopyComponent labelKey={`q2b.cars.list.title`} />
              </Typography>
              <Box className='container_tile'>
                {listOfVehicles} {!disable && <Card />}
              </Box>
              {view === 'row' ? null : (
                <>
                  <Box m={1} />
                  <Typography
                    className='page-header-h2'
                    sx={{
                      color: '#55566D',
                      marginBottom: '2.25rem',
                    }}
                  >
                    {novoRoutes?.Cars?.body1 && (
                      <MicrocopyComponent labelKey={novoRoutes?.Cars?.body1} />
                    )}
                  </Typography>
                  <Box m={1} />
                  <Button
                    id='continue'
                    className='car btn-arrow'
                    type='submit'
                    color='secondary'
                    variant='contained'
                    disabled={!minOneCarIncluded}
                    onClick={handleNextButton}
                    endIcon={<ButtonArrow />}
                  >
                    <MicrocopyComponent
                      labelKey={`q2b.global.button.text.next`}
                    />
                  </Button>
                </>
              )}
            </>
          </Page>
        </>
      )}
    </Grid>
  );
}
