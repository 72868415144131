import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function TowTruckIcon({
  fillColor = undefined,
  ...svgProps
}: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='96'
      height='64'
      viewBox='0 0 96 64'
      fill='none'
      {...{ ...defaultProps, ...svgProps }}
    >
      <path
        d='M63.0469 16.3252V10.7852C70.9719 10.7852 73.5036 15.907 73.7787 18.8722H65.5939C64.1872 18.8722 63.0469 17.7319 63.0469 16.3252Z'
        fill='#6CF96A'
      />
      <path
        d='M78.1296 21.1302C76.9277 10.563 67.362 7.16646 62.7294 6.78906H60.1001M78.1296 21.1302L85.3459 25.4145C85.9952 25.8 86.3932 26.4992 86.3932 27.2543V37.7356H51.4609V8.9286C51.4609 7.74696 52.4188 6.78906 53.6005 6.78906H60.1001M78.1296 21.1302H63.9207C61.8106 21.1302 60.1001 19.4196 60.1001 17.3096V6.78906M65.0631 28.3007H57.5508'
        stroke='#55566D'
        strokeWidth='1.9103'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='2.2989'
        y='38.5274'
        width='92.7448'
        height='13.1856'
        rx='6.5928'
        stroke='#6CF96A'
        strokeWidth='1.9103'
      />
      <rect
        x='0.95515'
        y='37.1856'
        width='92.7448'
        height='13.1856'
        rx='6.5928'
        fill='#F7F7F9'
        stroke='#55566D'
        strokeWidth='1.9103'
      />
      <path
        d='M40.9422 26.4178L15.6581 2.38693C14.2563 1.05466 12.0518 1.07003 10.6688 2.42171V2.42171C9.31531 3.74446 9.21912 5.89002 10.4488 7.32858L31.9274 32.4562'
        stroke='#55566D'
        strokeWidth='1.9103'
      />
      <path
        d='M4.73242 22.9301C4.73242 25.6888 6.91862 27.9251 9.61542 27.9251C12.3122 27.9251 14.4984 25.6888 14.4984 22.9301C14.4984 20.1715 12.3122 17.9352 9.61542 17.9352V5.28125'
        stroke='#55566D'
        strokeWidth='1.9103'
        strokeLinecap='round'
      />
      <path
        d='M35.6933 53.8089C35.6933 58.9132 31.5761 63.0434 26.5068 63.0434C21.4376 63.0434 17.3204 58.9132 17.3204 53.8089C17.3204 48.7045 21.4376 44.5743 26.5068 44.5743C31.5761 44.5743 35.6933 48.7045 35.6933 53.8089Z'
        fill='#F7F7F9'
        stroke='#6CF96A'
        strokeWidth='1.9103'
      />
      <path
        d='M79.6953 53.8089C79.6953 58.9132 75.5781 63.0434 70.5088 63.0434C65.4395 63.0434 61.3223 58.9132 61.3223 53.8089C61.3223 48.7045 65.4395 44.5743 70.5088 44.5743C75.5781 44.5743 79.6953 48.7045 79.6953 53.8089Z'
        fill='#F7F7F9'
        stroke='#6CF96A'
        strokeWidth='1.9103'
      />
      <path
        d='M34.3535 52.4593C34.3535 57.5636 30.2363 61.6938 25.167 61.6938C20.0977 61.6938 15.9805 57.5636 15.9805 52.4593C15.9805 47.3549 20.0977 43.2247 25.167 43.2247C30.2363 43.2247 34.3535 47.3549 34.3535 52.4593Z'
        fill='#F7F7F9'
        stroke='#55566D'
        strokeWidth='1.9103'
      />
      <path
        d='M78.3535 52.4593C78.3535 57.5636 74.2363 61.6938 69.167 61.6938C64.0977 61.6938 59.9805 57.5636 59.9805 52.4593C59.9805 47.3549 64.0977 43.2247 69.167 43.2247C74.2363 43.2247 78.3535 47.3549 78.3535 52.4593Z'
        fill='#F7F7F9'
        stroke='#55566D'
        strokeWidth='1.9103'
      />
      <line
        x1='6.21296'
        y1='63.0449'
        x2='87.6893'
        y2='63.0448'
        stroke='#55566D'
        strokeWidth='1.9103'
        strokeLinecap='round'
      />
      <path
        d='M51.084 36.6096C51.084 30.982 46.5435 26.4199 40.9424 26.4199C35.3413 26.4199 30.8008 30.982 30.8008 36.6096'
        stroke='#55566D'
        strokeWidth='1.9103'
      />
      <path
        d='M28.573 49.1855L22.302 55.4863M22.1328 49.1855L28.4038 55.4863'
        stroke='#55566D'
        strokeWidth='1.9103'
        strokeLinecap='round'
      />
      <path
        d='M72.5788 49.1855L66.3079 55.4863M66.1387 49.1855L72.4096 55.4863'
        stroke='#55566D'
        strokeWidth='1.9103'
        strokeLinecap='round'
      />
    </svg>
  );
}
