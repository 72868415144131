import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import ChevronDownButton from 'components/icons/ChevronDownButton';

interface MuiDropDownProps {
  id: string;
  schema?: any;
  uiSchema?: any;
  value?: any;
  onChange?: any;
  label?: any;
  className?: any;
  disabled?: boolean;
}

const MuiDropDown = (props: MuiDropDownProps) => {
  const { id, schema, onChange, className, value, disabled } = props;

  const handleMenuItemChange = (event: SelectChangeEvent) => {
    if (onChange) onChange(id, schema.id, event.target.value as string);
  };

  const listOfOptions = schema?.enum?.map((menuOption: any, index: number) => (
    <MenuItem key={index} value={menuOption}>
      {schema.enumOptions ? schema.enumOptions[index] : menuOption}
    </MenuItem>
  ));

  return (
    <FormControl
      sx={{ my: 1, minWidth: 120, width: '100%' }}
      className={className}
      disabled={disabled}
    >
      <InputLabel id='demo-simple-select-standard-label' sx={{ zIndex: 0 }}>
        {schema.title}
      </InputLabel>
      <Select
        labelId='demo-simple-select-standard-label'
        id={id}
        value={value || schema.default}
        // defaultValue={schema.default}
        onChange={handleMenuItemChange}
        label={schema.title}
        IconComponent={ChevronDownButton}
      >
        {listOfOptions}
      </Select>
    </FormControl>
  );
};

export default MuiDropDown;
