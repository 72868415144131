/* eslint-disable @typescript-eslint/naming-convention */
import React, { Component } from 'react';
import includes from 'core-js-pure/es/array/includes';
import { utils } from '@rjsf/core';

import { nanoid } from 'nanoid';
import { Grid } from '@mui/material';
import MuiButton from 'framework/components/MuiButton';
import MuiIconButton from 'framework/components/MuiIconButton';
import { getSchemaAndRequired } from 'utils/util';
import Validate from '../validations/Validate';

const {
  getWidget,
  getDefaultFormState,
  getUiOptions,
  isMultiSelect,
  isFilesArray,
  isFixedItems,
  allowAdditionalItems,
  isCustomWidget,
  optionsList,
  retrieveSchema,
  toIdSchema,
  getDefaultRegistry,
} = utils;

const getAddBtn = (btnText, onAddClick, disabled, className) => {
  return (
    <Grid className='row test'>
      <p className='col-xs-3 col-xs-offset-9 array-item-add text-right'>
        <MuiButton
          onClick={onAddClick}
          type='button'
          disabled={disabled}
          className={className}
        >
          {btnText}
        </MuiButton>
      </p>
    </Grid>
  );
};

function ArrayFieldTitle({ TitleField, idSchema, title, required }) {
  if (!title) {
    return null;
  }
  const id = `${idSchema.$id}__title`;
  return <TitleField id={id} required={required} title={title} />;
}

function ArrayFieldDescription({ DescriptionField, idSchema, description }) {
  if (!description) {
    return null;
  }
  const id = `${idSchema.$id}__description`;
  return <DescriptionField description={description} id={id} />;
}

// Used in the two templates
function DefaultArrayItem(props) {
  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold',
  };
  return (
    <div className={props.className} key={props.index}>
      <div className={props.hasToolbar ? 'col-xs-9' : 'col-xs-12'}>
        {props.children}
      </div>

      {props.hasToolbar && (
        <div className='col-xs-3 array-item-toolbox'>
          <div
            className='btn-group'
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {(props.hasMoveUp || props.hasMoveDown) && (
              <MuiIconButton
                icon='arrow-up'
                aria-label='Move up'
                className='array-item-move-up'
                tabIndex='-1'
                style={btnStyle}
                disabled={props.disabled || props.readonly || !props.hasMoveUp}
                onClick={props.onReorderClick(props.index, props.index - 1)}
              />
            )}

            {(props.hasMoveUp || props.hasMoveDown) && (
              <MuiIconButton
                icon='arrow-down'
                className='array-item-move-down'
                aria-label='Move down'
                tabIndex='-1'
                style={btnStyle}
                disabled={
                  props.disabled || props.readonly || !props.hasMoveDown
                }
                onClick={props.onReorderClick(props.index, props.index + 1)}
              />
            )}

            {props.hasRemove && (
              <MuiIconButton
                type='danger'
                icon='remove'
                aria-label='Remove'
                className='array-item-remove'
                tabIndex='-1'
                style={btnStyle}
                disabled={props.disabled || props.readonly}
                onClick={props.onDropIndexClick(props.index)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function DefaultFixedArrayFieldTemplate(props) {
  return (
    <fieldset className={props.className} id={props.idSchema.$id}>
      <ArrayFieldTitle
        TitleField={props.TitleField}
        idSchema={props.idSchema}
        key={`array-field-title-${props.idSchema.$id}`}
        required={props.required}
        title={props.uiSchema['ui:title'] || props.title}
      />

      {(props.uiSchema['ui:description'] || props.schema.description) && (
        <div
          className='field-description'
          key={`field-description-${props.idSchema.$id}`}
        >
          {props.uiSchema['ui:description'] || props.schema.description}
        </div>
      )}

      <div
        className='row array-item-list'
        key={`array-item-list-${props.idSchema.$id}`}
      >
        {props.items && props.items.map(DefaultArrayItem)}
      </div>

      {props.canAdd &&
        getAddBtn(
          'Add Another One',
          props.onAddClick,
          props.disabled || props.readonly,
          'array-item-add'
        )}
      {/* <AddButton
            className='array-item-add'
            disabled={props.disabled || props.readonly}
            onClick={props.onAddClick}
          /> */}
    </fieldset>
  );
}

function DefaultNormalArrayFieldTemplate(props) {
  return (
    <fieldset className={props.className} id={props.idSchema.$id}>
      <ArrayFieldTitle
        TitleField={props.TitleField}
        idSchema={props.idSchema}
        key={`array-field-title-${props.idSchema.$id}`}
        required={props.required}
        title={props.uiSchema['ui:title'] || props.title}
      />

      {(props.uiSchema['ui:description'] || props.schema.description) && (
        <ArrayFieldDescription
          DescriptionField={props.DescriptionField}
          description={
            props.uiSchema['ui:description'] || props.schema.description
          }
          idSchema={props.idSchema}
          key={`array-field-description-${props.idSchema.$id}`}
        />
      )}

      <div
        className='row array-item-list'
        key={`array-item-list-${props.idSchema.$id}`}
      >
        {props.items && props.items.map((p) => DefaultArrayItem(p))}
      </div>

      {props.canAdd &&
        getAddBtn(
          'Add Another One',
          props.onAddClick,
          props.disabled || props.readonly,
          'array-item-add'
        )}
      {/* <AddButton
            className='array-item-add'
            disabled={props.disabled || props.readonly}
            onClick={props.onAddClick}
          /> */}
    </fieldset>
  );
}

class ArrayField extends Component {
  static defaultProps = {
    uiSchema: {},
    formData: [],
    idSchema: {},
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
  };

  constructor(props) {
    super(props);

    this.addItem = this.addItem.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onBlurForIndex = this.onBlurForIndex.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onSaveEditClick = this.onSaveEditClick.bind(this);
    this.onCancelEditClick = this.onCancelEditClick.bind(this);
  }

  get itemTitle() {
    const { schema } = this.props;
    return schema.items.title || schema.items.description || 'Item';
  }

  isItemRequired(itemSchema) {
    if (Array.isArray(itemSchema.type)) {
      // While we don't yet support composite/nullable jsonschema types, it's
      // future-proof to check for requirement against these.
      return !includes(itemSchema.type, 'null');
    }
    // All non-null array item types are inherently required by design
    return itemSchema.type !== 'null';
  }

  canAddItem(formItems) {
    const { schema, uiSchema } = this.props;
    let { addable } = getUiOptions(uiSchema);
    if (addable !== false) {
      // if ui:options.addable was not explicitly set to false, we can add
      // another item if we have not exceeded maxItems yet
      if (schema.maxItems !== undefined) {
        addable = formItems.length < schema.maxItems;
      } else {
        addable = true;
      }
    }
    return addable;
  }

  onAddClick = (event, defaultFormData) => {
    if (event) event.preventDefault();
    this.addItem(defaultFormData);
  };

  addItem(defaultFormData) {
    const {
      schema,
      formData,
      errorSchema,
      registry = getDefaultRegistry(),
      idSchema,
      uiSchema,
    } = this.props;
    const { definitions } = registry;
    let itemSchema = schema.items;
    if (isFixedItems(schema) && allowAdditionalItems(schema)) {
      itemSchema = schema.additionalItems;
    }
    // this.resetErrorSchema(errorSchema, idSchema);
    const newItemDefaultFromState = getDefaultFormState(
      itemSchema,
      undefined,
      definitions
    );
    const newArrFormData = [
      ...formData,
      { ...newItemDefaultFromState, ...defaultFormData, inAddMode: true },
    ];
    this.props.onChange(newArrFormData, {});
    this.setState({
      savedFormData: JSON.parse(JSON.stringify(formData)),
    });
    const arrayFieldType =
      uiSchema?.['ui:options']?.arrayTemplate?.buttonTextSuffix;
    if (
      arrayFieldType === 'Accident' &&
      this.props.formContext.updateEditModeIndexAccident
    ) {
      this.props.formContext.updateEditModeIndexAccident(
        formData.length,
        'addItem',
        newArrFormData,
        idSchema,
        uiSchema
      );
    } else if (this.props.formContext.updateEditModeIndex) {
      this.props.formContext.updateEditModeIndex(
        formData.length,
        'addItem',
        newArrFormData,
        idSchema,
        uiSchema
      );
    }
  }

  resetErrorSchema = (errorSchema, idSchema) => {
    const id = idSchema['$id'];
    if (id.indexOf('workers_compensation_coverage') === -1) {
      (errorSchema[Object.keys(errorSchema)[0]] || {}).garageId = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).vehicleType = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).manufacturer = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).autoModel = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).originalCostNew =
        undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).vinAndYear = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).additionalFields =
        undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).firstName = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).lastName = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).dob = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).stateLicensed =
        undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).dl = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).state = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).role = undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).nameOfIndividual =
        undefined;
      (errorSchema[Object.keys(errorSchema)[0]] || {}).individualsToBe =
        undefined;
    } else if (id.indexOf('workers_compensation_coverage') !== -1) {
      errorSchema[Object.keys(errorSchema)[0]] = undefined;
      //(errorSchema[Object.keys(errorSchema)[0]] || {}).payroll = undefined;
      //(errorSchema[Object.keys(errorSchema)[0]] || {}).WCClassification = undefined;
    }
  };

  onDropIndexClick = (index) => {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      const { formData, onChange } = this.props;
      let savedFormData;
      if (this.state && this.state.savedFormData) {
        savedFormData = this.state.savedFormData;
      } else {
        savedFormData = formData;
      }
      savedFormData.splice(index, 1);
      this.setState({
        savedFormData: savedFormData,
      });
      // refs #195: revalidate to ensure properly reindexing errors
      let newErrorSchema;
      if (this.props.errorSchema) {
        newErrorSchema = {};
        const errorSchema = this.props.errorSchema;
        for (let i in errorSchema) {
          i = parseInt(i, 10);
          if (i < index) {
            newErrorSchema[i] = errorSchema[i];
          } else if (i > index) {
            newErrorSchema[i - 1] = errorSchema[i];
          }
        }
      }

      // onChange(savedFormData, newErrorSchema);
      onChange(
        //formData.filter((_, i) => i !== index),
        savedFormData,
        newErrorSchema
      );
    };
  };

  onEditClick = (index, formData) => {
    const { idSchema, uiSchema } = this.props;
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      let savedFormData;
      if (this.state && this.state.savedFormData) {
        savedFormData = this.state.savedFormData;
        savedFormData[index] = formData;
      } else {
        savedFormData = this.props.formData;
      }
      this.setState({
        savedFormData: savedFormData,
      });
      const arrayFieldType =
        uiSchema?.['ui:options']?.arrayTemplate?.buttonTextSuffix;
      if (
        arrayFieldType === 'Accident' &&
        this.props.formContext.updateEditModeIndexAccident
      ) {
        this.props.formContext.updateEditModeIndexAccident(
          index,
          'edit',
          formData,
          idSchema,
          uiSchema
        );
      } else if (this.props.formContext.updateEditModeIndex) {
        this.props.formContext.updateEditModeIndex(
          index,
          'edit',
          formData,
          idSchema,
          uiSchema
        );
      }
    };
  };

  onReorderClick = (index, newIndex) => {
    return (event) => {
      if (event) {
        event.preventDefault();
        event.target.blur();
      }
      const { formData, onChange } = this.props;
      let newErrorSchema;
      if (this.props.errorSchema) {
        newErrorSchema = {};
        const errorSchema = this.props.errorSchema;
        for (const i in errorSchema) {
          if (i === index) {
            newErrorSchema[newIndex] = errorSchema[index];
          } else if (i === newIndex) {
            newErrorSchema[index] = errorSchema[newIndex];
          } else {
            newErrorSchema[i] = errorSchema[i];
          }
        }
      }

      function reOrderArray() {
        // Copy item
        const newFormData = formData.slice();

        // Moves item from index to newIndex
        newFormData.splice(index, 1);
        newFormData.splice(newIndex, 0, formData[index]);

        return newFormData;
      }

      onChange(reOrderArray(), newErrorSchema);
    };
  };

  resetStateFormData = (savedFormData, index) => {
    if (savedFormData[index] && savedFormData[index].inEditMode) {
      savedFormData[index].inEditMode = undefined;
    }
    if (savedFormData[index] && savedFormData[index].inAddMode) {
      savedFormData[index].inAddMode = undefined;
    }
    return savedFormData;
  };

  onCancelEditClick = (index) => {
    const { idSchema, uiSchema } = this.props;
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      const { savedFormData } = this.state;
      const stateSavedData = this.resetStateFormData(savedFormData, index);
      this.setState(
        {
          savedFormData: stateSavedData,
        },
        () => {
          /* if(this.props.formContext.setLiveValidate){
            this.props.formContext.setLiveValidate(false);
          } */
          const arrayFieldType =
            uiSchema?.['ui:options']?.arrayTemplate?.buttonTextSuffix;
          if (
            arrayFieldType === 'Accident' &&
            this.props.formContext.updateEditModeIndexAccident
          ) {
            this.props.formContext.updateEditModeIndexAccident(
              index,
              'cancel',
              savedFormData,
              idSchema,
              uiSchema
            );
          } else if (this.props.formContext.updateEditModeIndex) {
            this.props.formContext.updateEditModeIndex(
              index,
              'cancel',
              savedFormData,
              idSchema,
              uiSchema
            );
          }
          this.props.onChange(savedFormData);
        }
      );
    };
  };

  onSaveEditClick = (index, elementFormData) => {
    const { idSchema, uiSchema, schema } = this.props;
    return (event) => {
      if (event) {
        event.preventDefault();
      }

      const errors = Validate(elementFormData, schema.items);

      if (errors) {
        /* if (this.props.formContext.updateEditModeIndex) {
          return this.props.formContext.updateEditModeIndex(
            index,
            'errors',
            errors,
            idSchema,
            uiSchema
          );
        } */
        if (this.props.formContext.setLiveValidate) {
          this.props.formContext.setLiveValidate(true);
        }
      } else {
        const { savedFormData } = this.state;
        // add/update item in savedFormData
        if (savedFormData[index]) {
          savedFormData[index] = elementFormData;
        } else {
          savedFormData.push(elementFormData);
        }
        const stateSavedData = this.resetStateFormData(savedFormData, index);
        this.setState({
          savedFormData: stateSavedData,
        });
        /* if(this.props.formContext.setLiveValidate){
          this.props.formContext.setLiveValidate(false);
        } */
        const arrayFieldType =
          uiSchema?.['ui:options']?.arrayTemplate?.buttonTextSuffix;
        if (
          arrayFieldType === 'Accident' &&
          this.props.formContext.updateEditModeIndexAccident
        ) {
          this.props.formContext.updateEditModeIndexAccident(
            index,
            'save',
            stateSavedData,
            idSchema,
            uiSchema
          );
        } else if (this.props.formContext.updateEditModeIndex) {
          return this.props.formContext.updateEditModeIndex(
            index,
            'save',
            stateSavedData,
            idSchema,
            uiSchema
          );
        }
      }
    };
  };

  onChangeForIndex = (index) => {
    return (value, errorSchema) => {
      const { formData, onChange } = this.props;
      const newFormData = formData.map((item, i) => {
        // We need to treat undefined items as nulls to have validation.
        // See https://github.com/tdegrunt/jsonschema/issues/206
        const jsonValue = typeof value === 'undefined' ? null : value;
        return index === i ? jsonValue : item;
      });
      onChange(
        newFormData,
        errorSchema &&
          this.props.errorSchema && {
            ...this.props.errorSchema,
            [index]: errorSchema,
          }
      );
    };
  };

  onBlurForIndex = (index) => {
    return (event) => {
      if (event && event.preventDefault) {
        event.preventDefault();
      }

      if (this.props.formContext.onSaveEditHandler) {
        this.props.formContext.onSaveEditHandler(
          this.props.uiSchema.type,
          index,
          false
        );
      }
    };
  };

  onSelectChange = (value) => {
    this.props.onChange(value);
  };

  renderNormalArray() {
    const {
      schema,
      uiSchema,
      formData,
      errorSchema,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry = getDefaultRegistry(),
      onBlur,
      onFocus,
      idPrefix,
      rawErrors,
      enableMoveDown,
      enableMoveUp,
    } = this.props;
    const title = schema.title === undefined ? name : schema.title;
    const { ArrayFieldTemplate, definitions, fields, formContext } = registry;
    const { TitleField, DescriptionField } = fields;
    const itemsSchema = retrieveSchema(schema.items, definitions);
    const arrayProps = {
      canAdd: this.canAddItem(formData),
      addItem: this.addItem,
      items: formData.map((item, index) => {
        const useUiSchema = uiSchema.items;
        const itemSchema = retrieveSchema(schema.items, definitions, item);
        const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;
        const itemIdPrefix = idSchema.$id + '_' + index;
        const itemIdSchema = toIdSchema(
          itemSchema,
          itemIdPrefix,
          definitions,
          item,
          idPrefix
        );

        return this.renderArrayFieldItem({
          index,
          canMoveUp: enableMoveUp === true && index > 0,
          canMoveDown: enableMoveDown === true && index < formData.length - 1,
          itemSchema: itemSchema,
          itemIdSchema,
          itemErrorSchema,
          itemData: item,
          itemUiSchema: useUiSchema,
          autofocus: autofocus && index === 0,
          onBlur,
          onFocus,
        });
      }),
      className: `field field-array field-array-of-${itemsSchema.type}`,
      DescriptionField,
      disabled,
      idSchema,
      uiSchema,
      onAddClick: this.onAddClick,
      readonly,
      required,
      schema,
      title,
      TitleField,
      formContext,
      formData,
      rawErrors,
    };

    // Check if a custom render function was passed in
    const Component = ArrayFieldTemplate || DefaultNormalArrayFieldTemplate;
    return <Component {...arrayProps} />;
  }

  renderMultiSelect() {
    const {
      schema,
      idSchema,
      uiSchema,
      formData,
      disabled,
      readonly,
      autofocus,
      onBlur,
      onFocus,
      registry = getDefaultRegistry(),
      rawErrors,
    } = this.props;
    const items = this.props.formData;
    const { widgets, definitions, formContext } = registry;
    const itemsSchema = retrieveSchema(schema.items, definitions, formData);
    const enumOptions = optionsList(itemsSchema);
    const { widget = 'select', ...options } = {
      ...getUiOptions(uiSchema),
      enumOptions,
    };
    const Widget = getWidget(schema, widget, widgets);
    return (
      <Widget
        autofocus={autofocus}
        disabled={disabled}
        formContext={formContext}
        id={idSchema && idSchema.$id}
        multiple
        onBlur={onBlur}
        onChange={this.onSelectChange}
        onFocus={onFocus}
        options={options}
        rawErrors={rawErrors}
        readonly={readonly}
        schema={schema}
        value={items}
      />
    );
  }

  renderFiles() {
    const {
      schema,
      uiSchema,
      idSchema,
      name,
      disabled,
      readonly,
      autofocus,
      onBlur,
      onFocus,
      registry = getDefaultRegistry(),
      rawErrors,
    } = this.props;
    const title = schema.title || name;
    const items = this.props.formData;
    const { widgets, formContext } = registry;
    const { widget = 'files', ...options } = getUiOptions(uiSchema);
    const Widget = getWidget(schema, widget, widgets);
    return (
      <Widget
        autofocus={autofocus}
        disabled={disabled}
        formContext={formContext}
        id={idSchema && idSchema.$id}
        multiple
        onBlur={onBlur}
        onChange={this.onSelectChange}
        onFocus={onFocus}
        options={options}
        rawErrors={rawErrors}
        readonly={readonly}
        schema={schema}
        title={title}
        value={items}
      />
    );
  }

  renderFixedArray() {
    const {
      schema,
      uiSchema,
      formData,
      errorSchema,
      idPrefix,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry = getDefaultRegistry(),
      onBlur,
      onFocus,
      rawErrors,
    } = this.props;
    const title = schema.title || name;
    let items = this.props.formData;
    const { ArrayFieldTemplate, definitions, fields, formContext } = registry;
    const { TitleField } = fields;
    const itemSchemas = schema.items.map((item, index) =>
      retrieveSchema(item, definitions, formData[index])
    );
    const additionalSchema = allowAdditionalItems(schema)
      ? retrieveSchema(schema.additionalItems, definitions, formData)
      : null;

    if (!items || items.length < itemSchemas.length) {
      // to manufacturer sure at least all fixed items are generated
      items = items || [];
      items = items.concat(new Array(itemSchemas.length - items.length));
    }

    // These are the props passed into the render function
    const arrayProps = {
      canAdd: this.canAddItem(items) && additionalSchema,
      className: 'field field-array field-array-fixed-items',
      disabled,
      idSchema,
      formData,
      items: items.map((item, index) => {
        const additional = index >= itemSchemas.length;
        const itemSchema = additional
          ? retrieveSchema(schema.additionalItems, definitions, item)
          : itemSchemas[index];
        const itemIdPrefix = idSchema.$id + '_' + index;
        const itemIdSchema = toIdSchema(
          itemSchema,
          itemIdPrefix,
          definitions,
          item,
          idPrefix
        );
        const itemUiSchema = additional
          ? uiSchema.additionalItems || {}
          : Array.isArray(uiSchema.items)
          ? uiSchema.items[index]
          : uiSchema.items || {};
        const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;

        return this.renderArrayFieldItem({
          index,
          canRemove: additional,
          canMoveUp: index >= itemSchemas.length + 1,
          canMoveDown: additional && index < items.length - 1,
          itemSchema,
          itemData: item,
          itemUiSchema,
          itemIdSchema,
          itemErrorSchema,
          autofocus: autofocus && index === 0,
          onBlur,
          onFocus,
        });
      }),
      onAddClick: this.onAddClick,
      readonly,
      required,
      schema,
      uiSchema,
      title,
      TitleField,
      formContext,
      rawErrors,
    };

    // Check if a custom template template was passed in
    const Template = ArrayFieldTemplate || DefaultFixedArrayFieldTemplate;
    return <Template {...arrayProps} />;
  }

  renderArrayFieldItem(props) {
    const {
      index,
      canEdit = true,
      canRemove = true,
      canMoveUp = false,
      canMoveDown = false,
      itemSchema,
      itemData,
      itemUiSchema,
      itemIdSchema,
      itemErrorSchema,
      autofocus,
      onBlur,
      onFocus,
      rawErrors,
    } = props;
    const {
      disabled,
      readonly,
      uiSchema,
      registry = getDefaultRegistry(),
    } = this.props;
    const {
      fields: { SchemaField },
    } = registry;
    const { orderable, removable, editable } = {
      orderable: true,
      removable: true,
      editable: true,
      ...uiSchema['ui:options'],
    };
    const has = {
      moveUp: orderable && canMoveUp,
      moveDown: orderable && canMoveDown,
      remove: removable && canRemove,
      edit: editable && canEdit,
    };
    has.toolbar = false; // Disabled for SBOP
    const onBlurArrayFieldItem = function (
      id,
      value,
      fieldSchema,
      isFieldRequired,
      errorMsgToDisplay,
      localValidationErrorType
    ) {
      if (!fieldSchema || isFieldRequired === undefined) {
        const schemaAndRequired = getSchemaAndRequired(id + '', itemSchema);
        fieldSchema = schemaAndRequired.fieldSchema;
        isFieldRequired = schemaAndRequired.isFieldRequired;
      }
      onBlur(
        id,
        value,
        fieldSchema,
        isFieldRequired,
        errorMsgToDisplay,
        localValidationErrorType
      );
    };
    return {
      children: (
        <SchemaField
          autofocus={autofocus}
          disabled={this.props.disabled}
          errorSchema={itemErrorSchema}
          formData={itemData}
          idSchema={itemIdSchema}
          onBlur={onBlurArrayFieldItem}
          onChange={this.onChangeForIndex(index)}
          onFocus={onFocus}
          rawErrors={rawErrors}
          readonly={this.props.readonly}
          registry={this.props.registry}
          required={this.isItemRequired(itemSchema)}
          schema={itemSchema}
          uiSchema={itemUiSchema}
        />
      ),
      className: 'array-item',
      disabled,
      hasToolbar: has.toolbar,
      hasMoveUp: has.moveUp,
      hasMoveDown: has.moveDown,
      hasRemove: has.remove,
      hasEdit: has.edit,
      index,
      onEditClick: this.onEditClick,
      onSaveEditClick: this.onSaveEditClick,
      onCancelEditClick: this.onCancelEditClick,
      onDropIndexClick: this.onDropIndexClick,
      onReorderClick: this.onReorderClick,
      readonly,
    };
  }
  render() {
    const {
      schema,
      uiSchema,
      idSchema,
      registry = getDefaultRegistry(),
    } = this.props;
    const { definitions } = registry;
    if (!schema.hasOwnProperty('items')) {
      return (
        <UnsupportedField
          idSchema={idSchema}
          reason='Missing items definition'
          schema={schema}
        />
      );
    }
    if (isFixedItems(schema)) {
      return this.renderFixedArray();
    }
    if (isFilesArray(schema, uiSchema, definitions)) {
      return this.renderFiles();
    }
    if (isMultiSelect(schema, definitions)) {
      return this.renderMultiSelect();
    }
    return this.renderNormalArray();
  }
}

/* if (process.env.REACT_APP_ENV !== 'production') {
  ArrayField.propTypes = types.fieldProps;
} */

export default ArrayField;
