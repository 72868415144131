import { getJson, setJson, removeItem } from '.';
import {
  insuranceApplicationObject,
  metaData,
  csrRestrictionConstant,
  ubiInfo,
} from 'library/application/applicationConstants';
import additionalCustomFormats from 'framework/forms/lib/CustomFormats';

export const getApplicationObject = () => {
  const applicationObject = getJson(insuranceApplicationObject);
  if (applicationObject) {
    return applicationObject;
  }
  return {};
};

export const getUbiInfo = () => {
  return getJson(ubiInfo);
};

export const getApplicationId = () => {
  return window.sessionStorage.getItem('applicationId') ?? '';
};

export const setUbiInfo = (object: any) => {
  return setJson(ubiInfo, object);
};

export const deleteUbiInfo = () => {
  return removeItem(ubiInfo);
};
export const setApplicationObject = (value: any) => {
  let applicationObject = value;
  let driverList: any = [];
  let vehicleList: any = [];
  const params = window.location.href.split('?')[1];
  const paramsArray = new URLSearchParams(params);
  const applicationIdParam = paramsArray.get('transactionId');
  const policyIdParam = paramsArray.get('policyId');
  const isPartnerFlow = getJson('isPartnerFlow');
  applicationObject &&
    applicationObject.drivers &&
    applicationObject.drivers.length > 0 &&
    applicationObject.drivers.forEach((d: any) => {
      if (
        d.insuranceDetails &&
        !d.insuranceDetails.isDataReviewed &&
        !applicationIdParam &&
        !policyIdParam &&
        !isPartnerFlow
      ) {
        console.log(
          'Enetred if loop-------',
          d.insuranceDetails.isDataReviewed,
          d.insuranceDetails
        );
        d.insuranceDetails = {
          autoInsuranceLastMonth: null,
          autoInsuranceToday: null,
          currentAutoInsurance: {},
          currentlyInsurance: null,
          isDataReviewed: false,
        };
        driverList.push(d);
      } else {
        let carrierInfo = d.insuranceDetails?.currentInsuranceProvider
          ? d.insuranceDetails?.currentInsuranceProvider.split(' ')
          : [];
        if (d.insuranceDetails.autoInsuranceLastMonth) {
          carrierInfo = d.insuranceDetails?.previousInsuranceProvider
            ? d.insuranceDetails?.previousInsuranceProvider.split('/')
            : carrierInfo;
        }
        let carrier = '';
        if (carrierInfo.length > 0) {
          if (carrierInfo.length > 1) {
            carrier = `${carrierInfo[0]}  ${carrierInfo[1]}`;
          } else {
            carrier = `${carrierInfo[0]}`;
          }
        }
        let coverageStartDateSplit = d.insuranceDetails?.currentCoverageStart
          ? d.insuranceDetails?.currentCoverageStart.split('/')
          : [];
        if (d.insuranceDetails.autoInsuranceLastMonth) {
          coverageStartDateSplit = d.insuranceDetails?.previousCoverageStart
            ? d.insuranceDetails?.previousCoverageStart.split('/')
            : coverageStartDateSplit;
        }
        d.insuranceDetails = {
          ...d.insuranceDetails,
          carrier,
          monthContinuouslyInsured:
            coverageStartDateSplit.length > 0 ? coverageStartDateSplit[0] : '',
          yearContinuouslyInsured:
            coverageStartDateSplit.length > 0 ? coverageStartDateSplit[1] : '',
        };
        driverList.push(d);
      }
    });

  if (driverList.length > 0) {
    applicationObject.drivers = driverList;
  }

  applicationObject &&
    applicationObject.vehicles &&
    applicationObject.vehicles.length > 0 &&
    applicationObject.vehicles.forEach((v: any) => {
      if (v.vin && additionalCustomFormats.vin.test(v.vin)) {
        vehicleList.push(v);
      }
    });
  applicationObject.vehicles = vehicleList;
  return setJson(insuranceApplicationObject, applicationObject);
};

export const getApplicationObjectItem = (key: string) => {
  const applicationObject = getApplicationObject();
  if (applicationObject && applicationObject[key]) {
    return applicationObject[key];
  }
  return undefined;
};

export const setApplicationObjectItem = (key: string, value: any) => {
  let applicationObject = getApplicationObject();
  if (!applicationObject) {
    applicationObject = {};
  }
  applicationObject[key] = value;
  return setApplicationObject(applicationObject);
};

export const getMetaDataItem = (key: string) => {
  const quoteEnvelopeItem = getApplicationObjectItem(metaData);
  if (quoteEnvelopeItem && quoteEnvelopeItem[key]) {
    return quoteEnvelopeItem[key];
  }
  return undefined;
};

export const setMetaDataItem = (key: string, value: any) => {
  let quoteEnvelopeItem = getApplicationObjectItem(metaData);
  if (!quoteEnvelopeItem) {
    quoteEnvelopeItem = {};
  }
  quoteEnvelopeItem[key] = value;
  return setApplicationObjectItem(metaData, quoteEnvelopeItem);
};

export const setCsrRestrictionItem = (key: string, value: any) => {
  let csrRestriction = getMetaDataItem(csrRestrictionConstant);
  if (!csrRestriction) {
    csrRestriction = {};
  }
  csrRestriction[key] = value;
  return setMetaDataItem(csrRestrictionConstant, csrRestriction);
};

export const setCsrRestriction = (value: any) => {
  setMetaDataItem(csrRestrictionConstant, value);
};

export const getCsrRestriction = () => {
  return getMetaDataItem(csrRestrictionConstant);
};

export const getMetaData = () => {
  return getApplicationObjectItem(metaData);
};

export const setMetaData = (key: string, value: any) => {
  let existingMetaData = getApplicationObjectItem(metaData);
  let metaDataObject = {
    ...existingMetaData,
    ...value,
  };

  return setApplicationObjectItem(metaData, metaDataObject);
};
