import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import 'pages/Drivers/Driver.scss';
import PropTypes from 'prop-types';
import * as React from 'react';
import parse from 'html-react-parser';
import MicrocopyComponent from './MicrocopyComponent';

export interface MuiAccordionProps {
  title?: any;
  content: any;
  titleClassName?: string;
  contentClassName?: string;
  status?: string;
  driverId?: number;
  disableNext?: boolean;
  formName?: string;
  isReviewHistoryDirty?: any;
  isFormDirty?: boolean;
}

export interface MuiAccordionArrayProps {
  data: MuiAccordionProps[];
}

const MuiAccordion = ({ data }: MuiAccordionArrayProps) => {
  const accordionItems: React.ReactNode[] = [];
  const [activeAccordionExpanded, setActiveAccordionExpanded] =
    React.useState(null);
  const handleChange = (index: any) => {
    setActiveAccordionExpanded(index);
  };

  data.map((item, index) => {
    accordionItems.push(
      <AccordionItem
        data={item}
        index={index}
        key={index}
        type={`array`}
        isLastItem={index === data.length - 1}
        page={'disclosures'}
        onChange={handleChange}
        expanded={activeAccordionExpanded === index}
        showOnlyOne={true}
      />
    );
    return item;
  });
  return <div className='w-full'>{accordionItems}</div>;
};
MuiAccordion.propTypes = {
  config: PropTypes.arrayOf(PropTypes.object),
};

export default MuiAccordion;

interface AccordionItemProps {
  data: MuiAccordionProps;
  index: number;
  disabled?: boolean;
  expanded?: boolean;
  onClick?: (index: number) => void;
  type: string;
  isLastItem?: boolean;
  page?: string;
  isExpanded?: any;
  onChange?: any;
  showOnlyOne?: boolean;
  mode?: boolean;
}

const renderArrayOfStrings = (data: MuiAccordionProps) => {
  const items = data.content.map((item: string, index: number) => {
    return (
      <Typography
        className='rich-text_doc-body'
        key={index}
        sx={{ marginBottom: 2, whiteSpace: 'pre-line' }}
      >
        {parse(item)}
      </Typography>
    );
  });
  return items;
};

export function AccordionItem({
  data,
  disabled,
  index,
  type,
  isLastItem,
  page,
  isExpanded,
  expanded,
  onChange,
  showOnlyOne,
  mode,
}: AccordionItemProps) {
  const [expandedStatus, setExpandedStatus] = React.useState(
    data.status === 'open' ? true : expanded && showOnlyOne ? true : false
  );
  if (data.status === 'open') {
    disabled = false;
  }
  React.useEffect(() => {
    if (expanded && !disabled && showOnlyOne && mode) {
      setExpandedStatus(true);
    } else if (
      showOnlyOne &&
      !expanded &&
      !disabled &&
      mode &&
      data.status !== 'open'
    ) {
      setExpandedStatus(false);
    } else if (data.status === 'done' && expanded) {
      setExpandedStatus(true);
    } else if (data.status === 'done') {
      setExpandedStatus(false);
    } else if (data.status === 'open') {
      setExpandedStatus(true);
    } else if (data.status === 'open' && !expanded) {
      setExpandedStatus(false);
    } else if (disabled) {
      setExpandedStatus(false);
    } else if (expanded) {
      setExpandedStatus(true);
    }
  }, [data.status, disabled, expanded, mode, showOnlyOne]);

  const onClickHandler = (e: any) => {
    if (showOnlyOne) {
      onChange(index);
    }
    if (data.status === 'done' && !disabled) {
      //status is done, allow to work as accordion
      setExpandedStatus(!expandedStatus);
    } else if (data.status === undefined) {
      // for disclosures, etc where data does not have status
      setExpandedStatus(!expandedStatus);
    }
    // if (onClick) onClick(e);
  };

  if (page !== 'disclosures') {
    if (isExpanded) isExpanded(expandedStatus);
  }

  return (
    <>
      <Accordion
        onChange={onChange}
        expanded={expandedStatus}
        // expanded = {(data.status === 'open')}
        className={
          page === 'disclosures'
            ? 'accordion-done'
            : disabled
            ? 'disabled'
            : data.status === 'done'
            ? isLastItem
              ? 'last-accorion accordion-done'
              : 'accordion-done'
            : data.status === 'open'
            ? isLastItem
              ? 'last-accorion accordion-open'
              : 'accordion-open'
            : ''
        }
        sx={isLastItem ? { marginBottom: '1.5rem' } : undefined}
        disabled={disabled}
        square
      >
        <div onClick={onClickHandler}>
          <AccordionSummary
            expandIcon={data.status !== 'open' && <ExpandMoreIcon />}
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            {typeof data.title === 'string' ? (
              <Typography className={`${data.titleClassName} page-header-h5`}>
                <MicrocopyComponent labelKey={data.title} />
              </Typography>
            ) : (
              <Typography className={`${data.titleClassName} page-header-h5`}>
                {data.title}
              </Typography>
            )}
          </AccordionSummary>
        </div>
        <AccordionDetails>
          {type !== 'form' && typeof data.content === 'string' && (
            <Typography className={`${data.contentClassName} Accordion-title`}>
              {data.content}
            </Typography>
          )}
          {type !== 'form' && typeof data.content !== 'string' && (
            <Typography className={`${data.contentClassName}`}>
              {renderArrayOfStrings(data)}
            </Typography>
          )}
          {type === 'form' && data.content}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
