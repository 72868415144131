import { getJson, setJson } from 'framework/dataService';
import { paymentConstant } from 'library/application/applicationConstants';

export const setPayment = (value: any) => {
  return setJson(paymentConstant, value);
};

export const getPayment = () => {
  return getJson(paymentConstant);
};
