import React from 'react';
import Grid from '@mui/material/Grid';
import './TileAccentBorders.scss';

interface TileAccentBordersProps {
  heading?: string | JSX.Element;
  content?: string | JSX.Element;
  caption?: string | JSX.Element;
}
export default function TileAccentBorders({
  heading,
  content,
  caption,
}: TileAccentBordersProps) {
  return (
    <Grid container className='tile-accent-borders'>
      <Grid
        container
        spacing={3}
        justifyContent='space-between'
        className='tile-accent-borders_bodyWrapper'
      >
        <Grid item lg={9} xs={9} md={9} className='heading'>
          {heading && <p className='tile-accent-borders_heading'>{heading}</p>}
        </Grid>
        <Grid item lg={3} xs={3} md={3} className='content'>
          {content && <p className='tile-accent-borders_content'>{content}</p>}
        </Grid>
      </Grid>
      <div className='tile-accent-borders_caption-wrapper'>
        {caption && <p className='tile-accent-borders_caption'>{caption}</p>}
        <div className='tile-accent-borders_divider'></div>
      </div>
    </Grid>
  );
}
