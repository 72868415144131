import { getVehicleIndex } from 'framework/dataService/vehicles';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CarFormWrapper from './CarFormWrapper';

const CarFormWrapperModule = () => {
  let params = useParams();

  const [vehicleId, setVehicleId] = useState<string>();
  const [vehicleIndex, setVehicleIndex] = useState<number>();

  useEffect(() => {
    if (params.vehicleId && params.vehicleId.length > 0) {
      setVehicleId(params.vehicleId);
      setVehicleIndex(getVehicleIndex(params.vehicleId));
    }
  }, [params.vehicleId]);

  return (
    <CarFormWrapper
      vehicleId={vehicleId}
      key={vehicleId}
      vehicleIndex={vehicleIndex}
    />
  );
};
export default CarFormWrapperModule;
