import React from 'react';
import { IIconExtendedProps, defaultProps } from './AvatarOne';

export default function ChevronDownButton({
  fillColor = 'currentColor',
  bgFillColor = '#E8E9EE',
  ...svgProps
}: IIconExtendedProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      {...{ ...defaultProps, ...svgProps }}
    >
      <rect
        width='60'
        height='60'
        transform='matrix(1 0 0 -1 0 60)'
        fill={bgFillColor}
      />
      <path
        d='M20.9874 25.5126C20.304 24.8291 19.196 24.8291 18.5126 25.5126C17.8291 26.196 17.8291 27.304 18.5126 27.9874L29.0126 38.4874C29.696 39.1709 30.804 39.1709 31.4874 38.4874L41.9874 27.9874C42.6709 27.304 42.6709 26.196 41.9874 25.5126C41.304 24.8291 40.196 24.8291 39.5126 25.5126L30.25 34.7751L20.9874 25.5126Z'
        fill={fillColor}
      />
    </svg>
  );
}
