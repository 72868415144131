import '../Drivers/Driver.scss';

import { Grid } from '@mui/material';
import { AjvError, IChangeEvent, ISubmitEvent } from '@rjsf/core';
import Page from 'components/Page';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import React, { useEffect, useState } from 'react';
import MuiDialog from 'framework/components/Dialogs/MuiDialog';
import { retrieveDisclosures } from 'library/services/utils';

export interface TestingProps {
  updateStatus?: any;
  index?: number;
}

const Testing: React.FC<TestingProps> = ({ updateStatus, index }) => {
  const routeKey = 'Testing';
  const [isDirty, setIsDirty] = useState(false as boolean);
  const [initialFormData, setInitialFormData] = useState({} as any);
  const [open, setOpen] = React.useState(false);
  const [disclosures, setDisclosures] = useState([] as any);

  const handleClose = (value: any) => {
    setOpen(value);
  };

  useEffect(() => {
    let formData: any = {};
    const dataForFormData = getData(routeKey);
    if (dataForFormData?.testing) {
      formData = { ...dataForFormData.testing };
      if (updateStatus && formData) {
        setIsDirty(false);
        updateStatus(index, 'done', 'onLoad');
      }
    }

    setInitialFormData(formData);

    const fetchData = async () => {
      const disclosuresDefinitions = await retrieveDisclosures();
      setDisclosures(disclosuresDefinitions);
    };

    fetchData();
  }, [index, updateStatus]);

  const handleSubmit = (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    setIsDirty(false);
    if (updateStatus) updateStatus(index, 'done', 'onSubmit');
    if (persistanceService) persistanceService({ Testing: formData });
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (
      initialFormData?.accidents?.accidents &&
      !formData?.accidents?.accidents
    ) {
      //remove array list
      formData.accidents.accidentList = [];
    }
    if (
      initialFormData?.traffic?.trafficViolations &&
      !formData?.traffic?.trafficViolations
    ) {
      //remove array list
      formData.traffic.trafficViolationList = [];
    }
    if (updateStatus && !isDirty && !equals(initialFormData, formData)) {
      // formData just got dirty
      setIsDirty(true);
      if (updateStatus) updateStatus(index, 'open', 'onChange');
    }
    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  if (updateStatus) {
    return (
      <Grid container className='quote-wrapper'>
        <NovoForm
          formData={initialFormData}
          schemaName={routeKey}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onError={handleError}
          transitionTo={false}
          //button = {true}
          buttonName='LOOKS GOOD'
          shadowButton={true}
        />
      </Grid>
    );
  }
  return (
    <Grid container className='quote-wrapper'>
      {open && disclosures?.length > 0 && (
        <MuiDialog
          open={open}
          onClose={handleClose}
          content={true}
          contentData={disclosures[0]}
        />
      )}
      <Page routeKey={routeKey}>
        <NovoForm
          formData={initialFormData}
          schemaName={routeKey}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onError={handleError}
          transitionTo={true}
        />
      </Page>
    </Grid>
  );
};

export default Testing;
