import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function FlatTireIcon({
  fillColor = undefined,
  ...svgProps
}: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='84'
      height='72'
      viewBox='0 0 84 72'
      fill='none'
      {...{ ...defaultProps, ...svgProps }}
    >
      <path
        d='M17.8068 61.4511C12.746 55.7206 9.6875 48.2673 9.6875 40.1189C9.6875 34.4501 11.1678 29.1178 13.7728 24.4678M69.0334 61.2752C74.0021 55.5684 76.9985 48.1842 76.9985 40.1189C76.9985 32.1509 74.0738 24.8475 69.2121 19.1696L69.9706 17.2048C70.5476 15.7099 70.1768 14.0149 69.0284 12.8974V12.8974C67.7588 11.6621 65.828 11.4088 64.2828 12.275L62.568 13.2363C57.1179 9.53916 50.4906 7.37109 43.343 7.37109C32.173 7.37109 22.274 12.6659 16.1522 20.8169'
        stroke='#6CF96A'
        strokeWidth='1.70004'
        strokeLinecap='round'
      />
      <path
        d='M17.8084 61.8418H17.0092C14.48 61.8418 12.4297 63.8921 12.4297 66.4213V66.4213C12.4297 68.9505 14.48 71.0008 17.0092 71.0008H69.7058C72.235 71.0008 74.2853 68.9505 74.2853 66.4213V66.4213C74.2853 63.8921 72.235 61.8418 69.7058 61.8418H68.9066'
        stroke='#6CF96A'
        strokeWidth='1.70004'
        strokeLinecap='round'
      />
      <path
        d='M15.1193 60.1386C10.0585 54.4081 7 46.9548 7 38.8064C7 33.1376 8.48029 27.8053 11.0853 23.1553M66.3459 59.9627C71.3146 54.2559 74.311 46.8717 74.311 38.8064C74.311 30.8384 71.3863 23.535 66.5246 17.8571L67.2831 15.8923C67.8601 14.3974 67.4893 12.7024 66.3409 11.5849V11.5849C65.0713 10.3496 63.1405 10.0963 61.5953 10.9625L59.8805 11.9238C54.4304 8.22666 47.8031 6.05859 40.6555 6.05859C29.4855 6.05859 19.5865 11.3534 13.4647 19.5044'
        stroke='#55566D'
        strokeWidth='1.70004'
        strokeLinecap='round'
      />
      <path
        d='M15.1209 60.5293H14.3217C11.7925 60.5293 9.74219 62.5796 9.74219 65.1088V65.1088C9.74219 67.638 11.7925 69.6883 14.3217 69.6883H67.0183C69.5475 69.6883 71.5978 67.638 71.5978 65.1088V65.1088C71.5978 62.5796 69.5475 60.5293 67.0183 60.5293H66.2191'
        stroke='#55566D'
        strokeWidth='1.70004'
        strokeLinecap='round'
      />
      <path
        d='M55.9557 37.6386C55.9557 45.8188 49.1339 52.4896 40.6695 52.4896C32.205 52.4896 25.3832 45.8188 25.3832 37.6386C25.3832 29.4583 32.205 22.7875 40.6695 22.7875C49.1339 22.7875 55.9557 29.4583 55.9557 37.6386Z'
        fill='#F7F7F9'
        stroke='#55566D'
        strokeWidth='1.70004'
      />
      <path
        d='M40.6698 41.1052C42.6025 41.1052 44.2092 39.5748 44.2092 37.6383C44.2092 35.7019 42.6025 34.1715 40.6698 34.1715C38.7371 34.1715 37.1305 35.7019 37.1305 37.6383C37.1305 39.5748 38.7371 41.1052 40.6698 41.1052Z'
        fill='#6CF96A'
        stroke='#55566D'
        strokeWidth='1.70004'
      />
      <ellipse
        rx='1.34408'
        ry='1.30905'
        transform='matrix(0.991905 0.126985 -0.133997 0.990982 41.8433 28.55)'
        fill='#6CF96A'
      />
      <ellipse
        rx='1.34408'
        ry='1.30905'
        transform='matrix(0.991905 0.126985 -0.133997 0.990982 31.2867 36.4386)'
        fill='#6CF96A'
      />
      <ellipse
        rx='1.34408'
        ry='1.30905'
        transform='matrix(0.991905 0.126985 -0.133997 0.990982 49.9508 38.8312)'
        fill='#6CF96A'
      />
      <ellipse
        rx='1.34408'
        ry='1.30905'
        transform='matrix(0.991905 0.126985 -0.133997 0.990982 39.3863 46.716)'
        fill='#6CF96A'
      />
      <ellipse
        rx='1.34408'
        ry='1.30905'
        transform='matrix(0.991905 0.126985 -0.133997 0.990982 33.0738 43.2707)'
        fill='#6CF96A'
      />
      <ellipse
        rx='1.34408'
        ry='1.30905'
        transform='matrix(0.991905 0.126985 -0.133997 0.990982 34.8277 30.296)'
        fill='#6CF96A'
      />
      <ellipse
        rx='1.34408'
        ry='1.30905'
        transform='matrix(0.991905 0.126985 -0.133997 0.990982 48.1597 31.9992)'
        fill='#6CF96A'
      />
      <ellipse
        rx='1.34408'
        ry='1.30905'
        transform='matrix(0.991905 0.126985 -0.133997 0.990982 46.4078 44.9738)'
        fill='#6CF96A'
      />
      <path
        d='M61.2835 37.6398C61.2835 48.7106 52.0535 57.7246 40.6185 57.7246C29.1835 57.7246 19.9535 48.7106 19.9535 37.6398C19.9535 26.5691 29.1835 17.5551 40.6185 17.5551C52.0535 17.5551 61.2835 26.5691 61.2835 37.6398Z'
        stroke='#55566D'
        strokeWidth='1.70004'
      />
      <path
        d='M63.5273 4.92527L64.872 1'
        stroke='#55566D'
        strokeWidth='1.70004'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M71.5963 12.1229L75.7622 11.2933'
        stroke='#55566D'
        strokeWidth='1.70004'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M68.9082 7.54212L75.6316 1'
        stroke='#55566D'
        strokeWidth='1.70004'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45.6651 11.4209C46.9373 11.7022 46.825 11.6881 48.0134 12.1218M66.9447 44.303C69.9441 31.459 63.1626 18.6722 51.3751 13.3209'
        stroke='#55566D'
        strokeWidth='1.70004'
        strokeLinecap='round'
      />
      <ellipse
        cx='40.6181'
        cy='28.4764'
        rx='1.34469'
        ry='1.30842'
        fill='#55566D'
      />
      <ellipse
        cx='31.2021'
        cy='37.6365'
        rx='1.34469'
        ry='1.30842'
        fill='#55566D'
      />
      <ellipse
        cx='50.0302'
        cy='37.6365'
        rx='1.34469'
        ry='1.30842'
        fill='#55566D'
      />
      <ellipse
        cx='40.6181'
        cy='46.7928'
        rx='1.34469'
        ry='1.30842'
        fill='#55566D'
      />
      <ellipse
        cx='33.8935'
        cy='44.1776'
        rx='1.34469'
        ry='1.30842'
        fill='#55566D'
      />
      <ellipse
        cx='33.8935'
        cy='31.0936'
        rx='1.34469'
        ry='1.30842'
        fill='#55566D'
      />
      <ellipse
        cx='47.3408'
        cy='31.0936'
        rx='1.34469'
        ry='1.30842'
        fill='#55566D'
      />
      <ellipse
        cx='47.3408'
        cy='44.1776'
        rx='1.34469'
        ry='1.30842'
        fill='#55566D'
      />
      <line
        x1='0.95515'
        y1='70.0449'
        x2='82.4314'
        y2='70.0449'
        stroke='#55566D'
        strokeWidth='1.9103'
        strokeLinecap='round'
      />
    </svg>
  );
}
