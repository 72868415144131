import { Button, Grid } from '@mui/material';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import SearchIcon from 'components/icons/SearchIcon';
import Page from 'components/Page';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import MuiLoader from 'framework/components/MuiLoader';
import { getData } from 'framework/dataService/dataRehydration';
import {
  getApplicationObjectItem,
  setApplicationObject,
} from 'framework/dataService/insuranceApplication';
import { getPrimaryLocation } from 'framework/dataService/locations';
import additionalCustomFormats from 'framework/forms/lib/CustomFormats';
import NovoForm from 'framework/forms/NovoForm';
import { novoRoutes } from 'framework/routes';
import {
  addVehicleServiceCall,
  saveApplicationCurrentSessionState,
  resetLeftNav,
  isVinExistInOtherPolicy,
} from 'library/services/utils';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertProps } from 'types/GenericTypes';
export interface CarSearchType {
  vin?: string;
  licensePlate?: string;
  stateCode?: string;
}

export default function SearchCar() {
  const hasComponentMounted = useRef(false);
  const navigate = useNavigate();
  const routeKey = 'SearchCar';

  const [showLoader, setShowLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [error, setError] = useState<AlertProps>({});
  /* const [cars, setCars] = useState([] as any[]); */
  const [initialFormData, setInitialFormData] = useState<CarSearchType>({
    vin: '',
    licensePlate: '',
    stateCode: '',
  });
  const [existingVins, setExistingVins] = useState<string[]>([]);

  useEffect(() => {
    if (!hasComponentMounted.current) {
      const dataForFormData = getData(routeKey);
      let vins: string[] = [];
      dataForFormData.vehicles.forEach((vehicle: { vin: string }) =>
        vins.push(vehicle.vin)
      );
      setExistingVins(vins);
      let location = getPrimaryLocation();
      let stateSelected = location?.state ? location?.state : 'WI';
      setInitialFormData({
        ...initialFormData,
        stateCode: stateSelected,
      });
      hasComponentMounted.current = true;
    }
  }, [initialFormData]);

  const handleSearch = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    setShowLoader(true);
    // commented this as the API is not yet deployed to UAT

    // if (formData?.vin) {
    //   const isVinExist = await isVinExistInOtherPolicy(formData.vin);
    //   if (isVinExist) {
    //     setError({
    //       message: 'q2b.primary.car.vin.validation',
    //     });
    //     setShowLoader(false);
    //     return;
    //   }
    // }

    let applicationResponse = await addVehicleServiceCall(
      formData.vin!,
      formData.stateCode!,
      formData.licensePlate!
    );
    setShowLoader(false);
    const originalVehicles = await getApplicationObjectItem('vehicles');
    let newCarData;
    if (originalVehicles?.length < applicationResponse.vehicles?.length) {
      newCarData = applicationResponse?.vehicles?.find(
        (vehicle: any) =>
          !originalVehicles.some(
            (oldVehicle: any) => vehicle.vehicleId === oldVehicle.vehicleId
          )
      );
    }

    let removeVehicle = true;
    if (!newCarData || Object.keys(newCarData).length === 0) {
      setError({
        message: 'q2b.car.search.error.message.no.car.found',
      });
    } else if (newCarData.vin && existingVins.includes(newCarData.vin)) {
      setError({
        message: 'q2b.car.search.error.message.car.already.exists',
      });
    } else if (
      newCarData.manufacturer &&
      newCarData.model &&
      newCarData.year &&
      newCarData.vin &&
      additionalCustomFormats.vin.test(newCarData.vin) === true
    ) {
      // Everything looks good, save this vehicle
      removeVehicle = false;
      if (
        applicationResponse.coverage &&
        applicationResponse.coverage.vehicleCoverage &&
        applicationResponse.coverage.vehicleCoverage.length > 0
      ) {
        applicationResponse.coverage = {};
        setApplicationObject(applicationResponse);
      } else {
        setApplicationObject(applicationResponse);
      }

      const vehicleId = newCarData.vehicleId;
      const dataForPersistance = {
        vehicle: {
          vehicleId,
          ...newCarData,
          included: true,
        },
      };

      if (persistanceService) persistanceService(dataForPersistance);
      console.debug(
        'Car: Search: handleSearch: Vehicle is found - add more detail.'
      );
      const status = await resetLeftNav(novoRoutes.Cars.activeStep);
      console.debug('Status of Reset Left Nav', status);
      let path = novoRoutes.AddCar.path;
      path = path.replace(':vehicleId', vehicleId);
      navigate(path);
    } else {
      setError({
        message: 'q2b.car.search.error.message.no.car.found',
      });
    }
    if (removeVehicle && newCarData && Object.keys(newCarData).length > 0) {
      // newCarData has bad data - remove it
      // session never saved it - so no action needed
      // removing it from backend application object
      const resp = await saveApplicationCurrentSessionState();
      if (!resp.success) {
        console.error(
          'Car: Search: handleSearch: Update Application Object is FAILED: Backend may still have the Searched Vehicle with bad data.'
        );
      } else {
        console.debug(
          'Car: Search: handleSearch: Update Application Object is done: Removed the Searched Vehicle from backend.'
        );
      }
    }
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    let targetData = { ...initialFormData };
    Object.assign(targetData, formData);
    setInitialFormData(targetData);
    setDisableButton(
      !(formData.licensePlate || formData.vin) ||
        (!formData.licensePlate && !formData.vin)
    );
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    const { vin, licensePlate, stateCode } = formData;
    let vinExists = vin && vin.length > 0;
    let plateAndStateExists =
      licensePlate &&
      licensePlate.length > 0 &&
      stateCode &&
      stateCode.length > 0;
    let validDataCondition = vinExists || plateAndStateExists;
    if (!validDataCondition) {
      console.debug(errors);
      errors.vin.addError(
        'Please include your VIN or State & License Plate to look up your vehicle.'
      );
    }
    return errors;
  };

  const handleCancelButton = () => {
    navigate(novoRoutes.Cars.path);
  };

  return (
    <Grid container className='quote-wrapper search-car'>
      <Page routeKey={routeKey}>
        <>
          {showLoader && <MuiLoader />}
          <NovoForm
            alert={error}
            schemaName={routeKey}
            onSubmit={handleSearch}
            onChange={handleChange}
            onError={handleError}
            validate={handleValidation}
            transitionTo={false}
            formData={initialFormData}
            button={
              <Button
                sx={{ marginBottom: '12px' }}
                id='carSearchButton'
                type='submit'
                color='secondary'
                variant='contained'
                disabled={disableButton}
                endIcon={<SearchIcon />}
              >
                <MicrocopyComponent
                  labelKey={`q2b.global.button.text.search.now`}
                />
              </Button>
            }
          />
          <Button
            id='cancelSearch'
            className='btn-grey'
            type='button'
            color='secondary'
            variant='contained'
            onClick={handleCancelButton}
          >
            <MicrocopyComponent labelKey={`q2b.global.button.text.cancel`} />
          </Button>
        </>
      </Page>
    </Grid>
  );
}
