import additionalCustomFormats from 'framework/forms/lib/CustomFormats';
import { remark } from 'remark';
import html from 'remark-html';
import remarkParse from 'remark-parse';
import { isValidDate } from './dateUtils';
import { AddressProps } from 'framework/forms/interfaces/AddressProps';
import Bugsnag from '@bugsnag/js';

declare const hasOwnProperty: any;

export const random = (min: number = 0, max: number = 100) =>
  Math.floor(Math.random() * (max - min)) + min;

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const zeroPadLeft = (num: number, places: number) =>
  String(num).padStart(places, '0');

export function getSchemaAndRequired(id: string, schema: any) {
  let indexOfNumber;
  const idParts: string[] = id.split('_');
  if (idParts.length > 2) {
    //its an id with array
    idParts.forEach((idPart, index) => {
      if (!isNaN(Number(idPart))) {
        //its a number
        indexOfNumber = index;
      }
    });
  }
  let fieldSchema, isFieldRequired;
  if (indexOfNumber) {
    //find schema for fieldName
    if (idParts.length - indexOfNumber === 2) {
      //limit_coverages_form_displayDrivers_0_firstName
      fieldSchema = schema.properties[idParts[indexOfNumber + 1]];
      isFieldRequired =
        schema.required && schema.required.includes(idParts[indexOfNumber + 1]);
    } else if (idParts.length - indexOfNumber === 3) {
      //limit_coverages_form_displayDrivers_0_spouse_firstName
      fieldSchema =
        schema.properties[idParts[indexOfNumber + 1]].properties[
          idParts[indexOfNumber + 2]
        ];
      isFieldRequired =
        schema.required && schema.required.includes(idParts[indexOfNumber + 1]);
    }
  }
  return {
    fieldSchema: fieldSchema,
    isFieldRequired: isFieldRequired,
  };
}

export interface SortArrayProps<T> {
  arr: Array<T>;
  sortFieldName: string;
}
export const sortArray: any = (arr: any, sortFieldName: string) => {
  return arr.sort(function (a: any, b: any) {
    if (a[sortFieldName] === undefined) {
      return b[sortFieldName] === undefined ? 0 : 1;
    }
    return b[sortFieldName] === undefined
      ? -1
      : a[sortFieldName] - b[sortFieldName];
  });
};

export function isEmptyArray(arr: any) {
  let flag = true;
  if (arr && Array.isArray(arr) && arr.length > 0) {
    const BreakException = {};
    try {
      arr.forEach((item: any) => {
        // check for non-empty array item
        if (!isEmpty(item)) {
          flag = false;
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw BreakException;
        }
      });
      // all array items are null/undefined
      flag = true;
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }
  return flag;
}

export function isEmpty(obj: any) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // If value is a string
  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If value is a boolean
  if (obj === true) return false;
  if (obj === false) return false;

  // If value is a number
  if (typeof obj === 'number') return false;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export const camelize = (str: string) => {
  return (
    str.substring(0, 1).toUpperCase() +
    str.substring(1, str.length).toLowerCase()
  );
};

export const fillTemplate = (template: string, valueStore: any) => {
  Object.keys(valueStore).forEach(function (key: string, index: number) {
    template = template.replaceAll('{' + key + '}', valueStore[key]);
  });
  return template;
};

export const dateToMonthYearStr = (
  dateVal: any,
  keyboardInputValue: string | undefined
) => {
  let monthYearStr = '00';
  if (
    isValidDate(dateVal) &&
    (keyboardInputValue === undefined ||
      (keyboardInputValue &&
        additionalCustomFormats.card_expiration_date.test(keyboardInputValue)))
  ) {
    monthYearStr =
      (dateVal.getMonth() > 8
        ? dateVal.getMonth() + 1
        : '0' + (dateVal.getMonth() + 1)) +
      '/' +
      dateVal?.getFullYear();
  }
  return monthYearStr;
};

export const monthYearStrToDate = (dateStr: string): Date | null => {
  if (dateStr) {
    const dat = new Date();
    const datTokens: string[] = dateStr.split('/');
    dat.setDate(1);
    dat.setMonth(Number(datTokens[0]) - 1);
    dat.setFullYear(Number(datTokens[1]));
    return dat;
  }
  return null;
};

export function getNameFromId(id: string) {
  const startIndexOfName = id.lastIndexOf('_') + 1;
  if (startIndexOfName > 1) {
    return id.substring(startIndexOfName);
  }
  return id;
}

export function getParentIdFromId(id: string) {
  const endIndexOfParentId = id.lastIndexOf('_');
  if (endIndexOfParentId > 0) {
    return id.substring(0, endIndexOfParentId);
  }
  return id;
}

export function getArrayIdFromFieldId(id: string) {
  let arrayId;
  //id = limit_coverages_form_displayDrivers_0_firstName
  //id = limit_coverages_form_displayDrivers_0_spouse_firstName
  //returns = limit_coverages_form_displayDrivers
  const idParts = id.split('_');
  if (idParts.length > 2) {
    idParts.forEach((idPart: any, index) => {
      if (!isNaN(idPart)) {
        //its a number
        //remove id part after array's id
        idParts.splice(index);
        //return array's id
        arrayId = idParts.join('_');
      }
    });
  }
  return arrayId;
}

export function getItemIndexFromArrayFieldId(id: string) {
  let arrayId;
  //id = limit_coverages_form_displayDrivers_0_firstName
  //id = limit_coverages_form_displayDrivers_0_spouse_firstName
  //returns = limit_coverages_form_displayDrivers
  const idParts = id.split('_');
  if (idParts.length > 0) {
    idParts.forEach((idPart: any, index) => {
      if (!isNaN(idPart)) {
        //its a number
        //remove id part after array's id
        arrayId = idPart;
      }
    });
  }
  return arrayId;
}

export const addTargetBlank = (content: string) => {
  // Don't want to breakup regular expression into multiple lines
  const exp =
    // eslint-disable-next-line max-len
    /href="(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?."/gi;
  return content.replaceAll(exp, ' $&target="_blank"');
};

export async function generateHTMLFromMarkDown(content: string) {
  const processedContent = await remark()
    .use(remarkParse)
    .use(html)
    .process(content);
  let contentHtml = await processedContent.toString();
  contentHtml = addTargetBlank(contentHtml);
  return contentHtml;
}

export const currencyFormatter = (number: any) => {
  return (number?.toFixed(2) + '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const isElementComesInViewport = (element: any) => {
  const elementRect = element?.getBoundingClientRect();
  if (elementRect && elementRect.y !== 0) {
    return -elementRect.top < elementRect.height;
  }
  return false;
};

export const isElementGoesOutFromViewport = (element: any) => {
  const elementRect = element?.getBoundingClientRect();
  if (elementRect && elementRect.y !== 0) {
    return elementRect.top < -elementRect.height;
  }
  return false;
};

export const removeMarkUp = (content: string) => {
  const markupRegex = additionalCustomFormats.markup;
  return content.replace(markupRegex, '');
};

export const getOnlyNumbers = (content: string) => {
  return content.replace(/[^0-9]/g, '');
};

export function setOtherAddressFields(
  location: AddressProps,
  formData: any,
  initialFormDataLocation: any
) {
  if (location.fullAddress !== '') {
    const { apt, city, state, zipCode } = location;
    formData.city = city;
    formData.apt = apt;
    formData.state = state;
    formData.zipCode = zipCode;
    if (initialFormDataLocation?.locationId) {
      formData.location.locationId = initialFormDataLocation.locationId;
    }
  } else if (initialFormDataLocation) {
    const { apt, city, state, zipCode } = initialFormDataLocation;
    formData.location = initialFormDataLocation;
    formData.city = city;
    formData.apt = apt;
    formData.state = state;
    formData.zipCode = zipCode;
  }
}

export const notifyBugsnag = (name: any, message: any) => {
  Bugsnag.notify({
    name,
    message: JSON.stringify(message),
  });
};
