import Box from '@mui/material/Box';
import { IStep, Status, steps } from 'framework/components/LeftNav';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import MicrocopyComponent from './MicrocopyComponent';
import { ReactComponent as SlashIcon } from '../../images/slash-icon.svg';
import './Status.scss';
import { getCompletedStep } from 'components/RoutesContainer';
// import { LinearProgress } from '@mui/material';

interface StatusProps {
  activeStepIndex: number;
}

export default function HorizontalNav({ activeStepIndex }: StatusProps) {
  const navElements = useRef<HTMLDivElement[]>([]);

  const createNavRefs = (e: HTMLDivElement | null, index: number) => {
    if (e != null) {
      navElements.current[index] = e;
    }
  };
  useEffect(() => {
    if (navElements.current) {
      navElements.current[activeStepIndex].scrollIntoView({
        inline: 'start',
      });
    }
  }, [activeStepIndex]);
  const stepReached = getCompletedStep();

  return (
    <Box sx={{ width: '100vw' }}>
      <Box sx={{ paddingTop: '2rem' }}>
        <div className='mobile-nav'>
          {steps.map((step, index) => {
            const status: Status =
              index === activeStepIndex
                ? 'active'
                : index < stepReached - 1
                ? 'complete'
                : 'incomplete';
            return (
              <Link
                to={step.path}
                aria-disabled={status === 'incomplete'}
                key={step.path}
              >
                <div
                  className={`mobile-nav-item ${
                    activeStepIndex === index ? 'activeMobileNavItem' : ''
                  }`}
                  ref={(e) => createNavRefs(e, index)}
                >
                  {index > 0 && <SlashIcon className='slash-icon' />}
                  <MicrocopyComponent
                    labelKey={step.title}
                    placeHolderKey='$STEP$'
                    actualValue={index + 1}
                  />
                </div>
              </Link>
            );
          })}
          {/* {`step ${step}/${totalSteps} • ${steps[activeStep].title} `} */}
        </div>
      </Box>
    </Box>
  );
}
