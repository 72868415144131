import { Box, Button, Grid, IconButton } from '@mui/material';
import AccidentAvatar from 'components/icons/AccidentAvatar';
import PencilIcon from 'components/icons/PencilIcon';
import ViolationAvatar from 'components/icons/ViolationAvatar';
import ReconciliationTile from 'components/ReconciliationTile';
import TileRow from 'components/TileRow';
import Label from 'framework/components/Label';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import MuiButton from 'framework/components/MuiButton';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import React, { useEffect, useRef, useState } from 'react';
import { incidentDescription } from 'utils/driver/IncidentFormat';
import { removeMarkUp } from 'utils/util';
import { CustomArrayFieldTemplateProps } from './CustomArrayFieldTemplate';
const formatMonthYear = (monthYear: string) => {
  const timestamp = Date.parse(monthYear);
  if (isNaN(timestamp)) {
    return 'No Date Reported';
  }
  const dateArray = monthYear.split('-');
  const date = new Date(`${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`);
  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    year: 'numeric',
  }).format(date);
};
const formatMonthYearNumbers = (monthYear: string) => {
  const dateArray = monthYear.split('/');
  const date = new Date(`${dateArray[0]}/1/${dateArray[1]}`);
  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    year: 'numeric',
  })
    .format(date)
    .replace(' ', ' - ');
};

const getItemReviewHistoryDetailView = (formData: any) => {
  const {
    status,
    type,
    foundDate,
    foundDescription,
    reportedDate,
    reportedDescription,
    date,
    description,
    source,
    loss,
    foundBodilyInjuryLimits,
    foundCoverageStart,
    foundInsuranceProvider,
    reportedBodilyInjuryLimits,
    reportedCoverageStart,
    reportedInsuranceProvider,
  } = formData;
  let found, reported;
  let color = '#000';
  let useAlternateHeader = false;

  found = {
    heading1: '',
    heading2: '',
  };

  if (type === 'accident' || type === 'violation') {
    const useDate = status ? foundDate : date;
    const useDescription = status ? foundDescription : description;
    color = type === 'accident' ? '#F47500' : '#6B04EB';
    const formattedDate = formatMonthYear(useDate);

    found = {
      heading1: formattedDate,
      heading2: useDescription,
    };
    if (status === 'compare') {
      const formattedDateReported = formatMonthYear(reportedDate);
      reported = {
        heading1: formattedDateReported,
        heading2: reportedDescription,
      };
    }
  }

  if (foundBodilyInjuryLimits) {
    useAlternateHeader = true;
    const foundCarrierDate = formatMonthYear(foundCoverageStart);
    found = {
      heading1: foundInsuranceProvider,
      heading2: `Expire on: ${foundCarrierDate}`,
      heading3: `BI Limit: ${foundBodilyInjuryLimits}`,
    };
    if (reportedBodilyInjuryLimits) {
      const reportedCarrierDate = formatMonthYear(reportedCoverageStart);
      reported = {
        heading1: reportedInsuranceProvider,
        heading2: `First Started: ${reportedCarrierDate}`,
        heading3: `BI Limit: ${reportedBodilyInjuryLimits}`,
      };
    }
  }

  if (source === 'Claim History') {
    const claimDescription = loss['loss-description'];
    const claimDate = formatMonthYear(loss['loss-on']);
    return (
      <TileRow
        heading1={claimDescription}
        heading2={claimDate}
        avatar={<AccidentAvatar />}
        showSwitch={false}
        variant
      />
    );
  }

  return (
    <ReconciliationTile
      found={found}
      reported={reported}
      color={color}
      useAlternateHeader={useAlternateHeader}
    />
  );
};
const getListViewItem = (
  elementFormData: any,
  index: number,
  handleEdit: any,
  onEditClick: any,
  handleDelete: any,
  onDropIndexClick: any,
  disabled: boolean
) => {
  const { monthYear, typeOfAccident, typeOfViolation } = elementFormData;

  if (monthYear) {
    const formattedDate = formatMonthYearNumbers(monthYear);

    return (
      <div className={`form-field `}>
        <TileRow
          heading1={incidentDescription(
            typeOfAccident ? 'accident' : 'violation',
            typeOfAccident || typeOfViolation
          )}
          heading2={formattedDate}
          avatar={
            typeOfAccident?.length > 0 ? (
              <AccidentAvatar />
            ) : (
              <ViolationAvatar />
            )
          }
          showSwitch={false}
          variant
          buttonComponent={
            <>
              {/* <MuiButton
              className='tile-row_button'
              type='button'
              aria-label='Delete Entry'
              onClick={() => handleDelete(index, onDropIndexClick)}
            >
              <IconButton color='primary' component='span' id='delete'>
                <TrashIcon />
              </IconButton>
            </MuiButton> */}
              <MuiButton
                disabled={disabled}
                className='tile-row_button'
                type='button'
                aria-label='Edit Entry'
                onClick={() => handleEdit(index, onEditClick)}
              >
                <IconButton color='primary' component='span' id='edit'>
                  <PencilIcon />
                </IconButton>
              </MuiButton>
            </>
          }
        />
      </div>
    );
  }
};

const getIncidentItems = (
  items: any,
  formData: any,
  screen: string,
  handleEdit: any,
  handleDelete: any,
  buttonTextSuffix: string,
  disabled: boolean
) => {
  return items.map((element: any, index: number) => {
    const elementFormData = formData[element.index];
    if (elementFormData) {
      return (
        <Grid
          key={index}
          className={`driver-incident-review-history-temp ${
            element.className ? element.className : ''
          }`}
        >
          {/* Render Item: Non-Form View */}
          {screen === 'review-history' && (
            <>
              {/* Render Item: Custom Detail View */}
              {index > 0 && <Box sx={{ marginTop: '2.25rem' }} />}
              {getItemReviewHistoryDetailView(elementFormData)}
              <Grid className='review-history-form'>{element.children}</Grid>
            </>
          )}
          {screen === 'list-view' && (
            <>
              {/* Render Item: List View */}
              {!elementFormData?.inEditMode &&
                !elementFormData?.inAddMode &&
                getListViewItem(
                  elementFormData,
                  element.index,
                  handleEdit,
                  element.onEditClick,
                  handleDelete,
                  element.onDropIndexClick,
                  disabled
                )}
              {/* Render Item: Form View */}
              {(elementFormData?.inEditMode || elementFormData?.inAddMode) && (
                <Grid>
                  {element.children}
                  {elementFormData?.inAddMode && formData.length === 1 ? (
                    <MuiButton
                      className='btn-mui-custom'
                      id='btnSave'
                      text={
                        removeMarkUp(
                          getMicroCopyResourceItem(
                            `q2b.global.button.text.save`
                          )
                        ) + buttonTextSuffix
                      }
                      onClick={element.onSaveEditClick(
                        element.index,
                        elementFormData
                      )}
                    />
                  ) : (
                    <Grid
                      container
                      spacing={3}
                      justifyContent='right'
                      sx={{ marginTop: '1.25rem', marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} md={3} lg={3}>
                        {elementFormData?.inEditMode && (
                          <MuiButton
                            className='btn-transparent no-transition'
                            color='secondary'
                            //text='Delete'
                            text={removeMarkUp(
                              getMicroCopyResourceItem(
                                `q2b.global.button.text.delete`
                              )
                            )}
                            onClick={() =>
                              handleDelete(
                                element.index,
                                element.onDropIndexClick
                              )
                            }
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={0}
                        md={3}
                        lg={3}
                        className='sm_display-hidden'
                      ></Grid>

                      <Grid item xs={6} md={3} lg={3}>
                        <MuiButton
                          className='btn-transparent no-transition'
                          color='secondary'
                          //text='Cancel'
                          text={removeMarkUp(
                            getMicroCopyResourceItem(
                              `q2b.global.button.text.cancel`
                            )
                          )}
                          onClick={element.onCancelEditClick(element.index)}
                        />
                      </Grid>
                      <Grid item xs={6} md={3} lg={3}>
                        <MuiButton
                          className='btn-gray w-full no-transition'
                          color='secondary'
                          id='btnSave'
                          //text='Save'
                          text={removeMarkUp(
                            getMicroCopyResourceItem(
                              `q2b.global.button.text.save`
                            )
                          )}
                          onClick={element.onSaveEditClick(
                            element.index,
                            elementFormData
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
          {element.hasMoveDown && (
            <MuiButton
              onClick={element.onReorderClick(element.index, element.index + 1)}
            >
              {/* Down */}
              {removeMarkUp(
                getMicroCopyResourceItem(`q2b.global.button.text.down`)
              )}
            </MuiButton>
          )}
          {element.hasMoveUp && (
            <MuiButton
              onClick={element.onReorderClick(element.index, element.index - 1)}
            >
              {/* Up */}
              {removeMarkUp(
                getMicroCopyResourceItem(`q2b.global.button.text.up`)
              )}
            </MuiButton>
          )}
          {element.hasRemove && (
            <MuiButton onClick={element.onDropIndexClick(element.index)}>
              {/* Delete */}
              {removeMarkUp(
                getMicroCopyResourceItem(`q2b.global.button.text.delete`)
              )}
            </MuiButton>
          )}
        </Grid>
      );
    }
    return null;
  });
};

const checkIfInAddEditMode = (updatedFormData: any) => {
  let displayAddBtn = true;
  const itemInAddEditMode = updatedFormData.find(
    (item: { inEditMode: any; inAddMode: any }) =>
      item.inEditMode === true || item.inAddMode === true
  );
  if (itemInAddEditMode) {
    // hide add button
    displayAddBtn = false;
  }
  return displayAddBtn;
};

const getAddBtn = (
  btnTextSuffix: string,
  handleAdd: any,
  updatedFormData: any,
  items: any,
  addBtnRef: any
) => {
  if (items.length === 0 || checkIfInAddEditMode(updatedFormData)) {
    const btnText =
      items.length === 0
        ? `q2b.global.button.text.add`
        : `q2b.global.button.text.add.another`;
    return (
      <Grid className='row test' sx={{ marginTop: '2.25rem' }}>
        <p className='col-xs-3 col-xs-offset-9 array-item-add text-right'>
          <Button
            ref={addBtnRef}
            className='btn-gray w-full btn-mui-custom'
            color='secondary'
            onClick={handleAdd}
          >
            <MicrocopyComponent
              labelKey={btnText}
              placeHolderKey='$BtnTextSuffix$'
              actualValue={btnTextSuffix}
            />
          </Button>
        </p>
      </Grid>
    );
  }
  return <div />;
};

const DriversIncidentReviewHistoryArrayFieldTemplate = (
  props: CustomArrayFieldTemplateProps
) => {
  const {
    items,
    className,
    canAdd,
    onAddClick,
    formData,
    schema,
    uiSchema,
    options,
    formContext,
    disabled,
  } = props;
  const addBtnRef = useRef<HTMLButtonElement>(null);
  const [updatedFormData, setUpdatedFormData] = useState(formData);
  const { arrayTemplate } = options || {};
  const screen = arrayTemplate?.screen || 'review-history';
  const buttonTextSuffix = arrayTemplate?.buttonTextSuffix || 'ONE';

  const handleAdd = (e: any) => {
    if (!disabled) {
      onAddClick(e);
    }
  };

  const handleEdit = (index: number, onEditClick: any) => {
    if (!updatedFormData[index]?.inEditMode) {
      updatedFormData[index].inEditMode = true;
      setUpdatedFormData([...updatedFormData]);
      if (onEditClick) {
        onEditClick(index, updatedFormData[index])();
      }
    }
  };
  const handleDelete = (index: number, onDropIndexClick: any) => {
    const updatedFormData2 = updatedFormData;
    updatedFormData2.splice(index, 1);

    setUpdatedFormData([...updatedFormData2]);
    if (onDropIndexClick) {
      onDropIndexClick(index)();
    }
  };

  const updateEditModeIndex = (
    index: number,
    mode: string = 'not saving',
    arrFormData: any = []
  ) => {
    let updatedFormData2 = updatedFormData;
    if (mode === 'addItem') {
      updatedFormData2 = arrFormData;
    } else if (mode === 'cancel') {
      if (updatedFormData2[index] && arrFormData[index]) {
        updatedFormData2[index] = arrFormData[index];
        // updatedFormData2[index].inEditMode = undefined;
      } else if (!arrFormData[index] && updatedFormData2[index]) {
        // Remove The Added New Item from FormData
        updatedFormData2.splice(index, 1);
      }
    } else if (mode === 'edit') {
      // may not need to do anything
      // updatedFormData2[index] = arrFormData;
    } else if (mode === 'save') {
      updatedFormData2 = arrFormData;
    } else if (mode === 'errors') {
      // display errors
      console.debug('errors: ', arrFormData);
    }

    setUpdatedFormData([...updatedFormData2]);
  };

  formContext.updateEditModeIndex = updateEditModeIndex;

  useEffect(() => {
    setUpdatedFormData(formData);
  }, [formData]);

  useEffect(() => {
    if (updatedFormData?.length === 0 && items?.length === 0) {
      console.debug('addBtnRef?.current: ', addBtnRef?.current);
      setTimeout(() => {
        addBtnRef?.current?.click();
      }, 50);
      // onAddClick(null);
    }
  }, [updatedFormData, items.length]);

  const getUiItems = (updatedFormData2: any) => {
    if (items?.length > 0 && updatedFormData2?.length > 0) {
      // known issue: sometimes when updatedFormData2 is not empty but formData and items are empty (Form.js line 290 has formData but 292 does not - delay issue)
      // temp solution: showing add button
      return getIncidentItems(
        items,
        updatedFormData2,
        screen,
        handleEdit,
        handleDelete,
        buttonTextSuffix,
        disabled
      );
    }
  };

  return (
    <>
      <Grid sx={{ pt: 0 }}>
        <Grid className={className}>
          {formData.length > 0 && (
            <Grid className='mb-1'>
              <Label label={schema.title} uiSchema={uiSchema} />
            </Grid>
          )}
          {getUiItems(updatedFormData)}
          {canAdd &&
            !disabled &&
            getAddBtn(
              buttonTextSuffix,
              handleAdd,
              updatedFormData,
              items,
              addBtnRef
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default DriversIncidentReviewHistoryArrayFieldTemplate;
