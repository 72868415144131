import { MuiAccordionProps } from 'framework/components/MuiAccordion';
import React from 'react';

import BasicInfo from 'pages/Drivers/BasicInfo';
import DrivingHistory from 'pages/Drivers/DrivingHistory';
import InsuranceInfo from 'pages/Drivers/InsuranceInfo';
import PrimaryBasicInfo from 'pages/Drivers/PrimaryBasicInfo';
import ResidenceInfo from 'pages/Drivers/ResidenceInfo';

export const nonPrimaryDriverFormDefinitions = (
  updateStatus?: (index: number, status: string, caller: string) => void,
  driverId?: string,
  mode?: string,
  readonly?: boolean
): MuiAccordionProps[] => [
  {
    //panel: 'panel1',
    title: 'q2b.driver.accordion.basic.info.title',
    formName: 'DriverBasicInfo',
    content: !updateStatus ? (
      ''
    ) : (
      <BasicInfo
        updateStatus={updateStatus}
        index={0}
        driverId={driverId}
        mode={mode}
        readonly={readonly}
      />
    ),
    status: 'open',
  },
  {
    //panel: 'panel4',
    title: 'q2b.driver.accordion.driving.history.title',
    formName: 'DrivingHistory',
    content: !updateStatus ? (
      ''
    ) : (
      <DrivingHistory
        updateStatus={updateStatus}
        index={1}
        driverId={driverId}
        mode={mode}
        readonly={readonly}
      />
    ),
    status: 'disabled',
  },
];

export const primaryDriverFormDefinitions = (
  updateStatus?: (index: number, status: string, caller: string) => void,
  driverId?: string,
  mode?: string,
  readonly?: boolean
): MuiAccordionProps[] => [
  {
    //panel: 'panel1',
    title: 'q2b.driver.accordion.basic.info.title',
    formName: 'PrimaryDriverBasicInfo',
    content: !updateStatus ? (
      ''
    ) : (
      <PrimaryBasicInfo
        updateStatus={updateStatus}
        index={0}
        driverId={driverId}
        mode={mode}
        readonly={readonly}
      />
    ),
    status: 'open',
  },
  {
    //panel: 'panel2',
    title: 'q2b.driver.accordion.residence.info.title',
    formName: 'DriverResidenceInfo',
    content: !updateStatus ? (
      ''
    ) : (
      <ResidenceInfo
        updateStatus={updateStatus}
        index={1}
        driverId={driverId}
        mode={mode}
        readonly={readonly}
      />
    ),
    status: 'disabled',
  },
  {
    //panel: 'panel3',
    title: 'q2b.driver.accordion.insurance.info.title',
    formName: 'PrimaryDriverInsuranceInfo',
    content: !updateStatus ? (
      ''
    ) : (
      <InsuranceInfo
        updateStatus={updateStatus}
        index={2}
        driverId={driverId}
        mode={mode}
        readonly={readonly}
        routeKey='PrimaryDriverInsuranceInfo'
      />
    ),
    status: 'disabled',
  },
  {
    //panel: 'panel5',
    title: 'q2b.driver.accordion.driving.history.title',
    formName: 'DrivingHistory',
    content: !updateStatus ? (
      ''
    ) : (
      <DrivingHistory
        updateStatus={updateStatus}
        index={3}
        driverId={driverId}
        mode={mode}
        readonly={readonly}
      />
    ),
    status: 'disabled',
  },
];
