import {
  getApplicationObjectItem,
  setApplicationObjectItem,
} from './insuranceApplication';
import {
  drivers,
  addons,
  locations,
} from 'library/application/applicationConstants';
import { deleteLocation, setLocation } from './locations';

export const getLocations = () => {
  return getApplicationObjectItem(locations);
};

export const getDrivers = () => {
  const driversList = getApplicationObjectItem(drivers);
  return driversList?.filter((driver: any) => driver.isDeleted !== true);
};

export const setDrivers = async (value: any) => {
  return setApplicationObjectItem(drivers, value);
};

export const setAddons = async (value: any) => {
  return setApplicationObjectItem(addons, value);
};

export const getAddons = async (): Promise<
  { name: string; properties: { optIn: boolean } }[]
> => {
  return getApplicationObjectItem(addons);
};

export const getDriverById = (driverId: string) => {
  const quoteEnvelopeItem = getDrivers();
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    return quoteEnvelopeItem.find(
      (item: { driverId: string }) => item.driverId === driverId
    );
  }
  return undefined;
};

export const getPrimaryDriver = () => {
  const quoteEnvelopeItem = getDrivers();
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    return quoteEnvelopeItem.find((item: { primary: boolean }) => item.primary);
  }
  return undefined;
};

export const getCountOfCoveredDrivers = () => {
  const quoteEnvelopeItem = getDrivers();
  const coveredDrivers = quoteEnvelopeItem?.filter(
    (driver: any) => driver.included === true
  );
  if (coveredDrivers) {
    return coveredDrivers.length;
  }
  return 0;
};

export const setDriver = async (value: any) => {
  let quoteEnvelopeItem = getDrivers();
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    const existingIndex = quoteEnvelopeItem.findIndex(
      (item: { driverId: any }) => item.driverId === value.driverId
    );
    if (existingIndex !== -1) {
      quoteEnvelopeItem[existingIndex] = value;
    }
    if (quoteEnvelopeItem[existingIndex].isDeleted) {
      quoteEnvelopeItem.splice(existingIndex, 1);
    }
  }
  return setApplicationObjectItem(drivers, quoteEnvelopeItem);
};

export const setDriverLocation = async (value: any) => {
  let driver = value.driver;
  let location = value.location;
  let quoteEnvelopeItem = getDrivers();
  let existingIndex = -1;
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    existingIndex = quoteEnvelopeItem.findIndex(
      (item: { driverId: any }) => item.driverId === driver.driverId
    );
  }
  if (existingIndex > -1) {
    let existingDriver = quoteEnvelopeItem[existingIndex];
    if (
      existingDriver.residenceInfo.mailingAddressSameAsPrimaryAddress ===
        false &&
      driver.residenceInfo.mailingAddressSameAsPrimaryAddress === true &&
      location
    ) {
      let locationIdToBeDeleted = existingDriver.residenceInfo.locationId;
      deleteLocation(locationIdToBeDeleted);
      delete driver.residenceInfo.locationId;
    } else if (
      driver.residenceInfo.mailingAddressSameAsPrimaryAddress === false &&
      location
    ) {
      setLocation(location);
    }
  }
  await setDriver(driver);
};
