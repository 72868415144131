import { Button, Grid } from '@mui/material';
import { AjvError, IChangeEvent, ISubmitEvent } from '@rjsf/core';
import ButtonArrow from 'components/icons/ButtonArrow';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm from 'framework/forms/NovoForm';
import { saveApplicationCurrentSessionState } from 'library/services/utils';
import React, { useEffect, useState } from 'react';
import Header from 'framework/components/Header';
import { novoRoutes } from 'framework/routes';
import { RetrieveQuoteProps } from './RetrieveQuote';

export default function SaveQuote({ toClose }: RetrieveQuoteProps) {
  const routeKey = 'SaveQuote';
  const routeKeyWithAccount = 'SaveQuoteWithAccount';
  const [initialFormData, setInitialFormData] = useState({ email: '' });
  const [accountHolderExists, setAccountHolderExists] = useState(true);
  const novoRoute = novoRoutes[routeKey];

  useEffect(() => {
    async function fetchData() {
      let formData: any = {};
      const dataForFormData = getData(routeKey);
      if (!dataForFormData?.accountInformation?.emailAddress) {
        setAccountHolderExists(false);
        if (dataForFormData?.driver?.driverId) {
          formData = { ...formData, email: dataForFormData.driver.email };
        }
      } else {
        formData = {
          ...formData,
          email: dataForFormData.accountInformation.emailAddress,
        };
      }

      setInitialFormData(formData);
    }
    fetchData();
  }, []);

  const handleSubmit = (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    let res: boolean = false;
    async function submitData() {
      const status = await saveApplicationCurrentSessionState();
      res = status.success;
      if (res === true) {
        if (toClose) toClose();
      }
    }
    submitData();
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  return accountHolderExists === false ? (
    <Grid container className='quote-wrapper quote-field'>
      <Header
        routeKey={routeKey}
        className={novoRoute.displayPageCubes ? 'header-underline_drum' : ''}
      />
      <NovoForm
        schemaName={routeKey}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        formData={initialFormData}
        button={
          <Button
            id='button'
            className='btn-arrow'
            type='submit'
            color='secondary'
            variant='contained'
            disabled={false}
            endIcon={<ButtonArrow />}
          >
            Next
          </Button>
        }
      />
    </Grid>
  ) : (
    <Grid container className='quote-wrapper quote-field'>
      <Header
        routeKey={routeKey}
        className={novoRoute.displayPageCubes ? 'header-underline_drum' : ''}
      />
      <NovoForm
        schemaName={routeKeyWithAccount}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        formData={initialFormData}
        button={
          <Button
            id='button'
            className='btn-arrow'
            type='submit'
            color='secondary'
            variant='contained'
            disabled={false}
            endIcon={<ButtonArrow />}
          >
            Next
          </Button>
        }
      />
    </Grid>
  );
}
