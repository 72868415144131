import { notifyBugsnag } from 'utils/util';
import { getCurrentVehicleByVin } from 'framework/dataService/vehicle';
import type { CustomJsonSchemaProps } from '../../../interfaces/CustomJsonSchemaProps';

export const tnRuleMap = {
  propertyDamage: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];

    // WI OH TN IN  can reuse
    const pdLimit = data?.propertyDamage;
    const bodilyInjury = data?.bodilyInjury?.split('/');
    if (bodilyInjury.length > 0 && pdLimit) {
      let errorValidation = false;
      const biPerPerson = bodilyInjury[0];
      if (parseInt(biPerPerson) === 25) {
        if (parseInt(pdLimit) > 25) {
          errorValidation = true;
        }
      } else if (parseInt(biPerPerson) === 50) {
        if (parseInt(pdLimit) > 50 || parseInt(pdLimit) < 25) {
          errorValidation = true;
        }
      } else if (parseInt(biPerPerson) === 100) {
        if (parseInt(pdLimit) > 100 || parseInt(pdLimit) < 50) {
          errorValidation = true;
        }
      }
      if (errorValidation) {
        const messages: any = fieldSchema.messages;
        let errorMessage = messages?.[validationType];
        isError = true;
        if (errors && errorMessage && data[fieldName] !== undefined) {
          notifyBugsnag('Custom Form validation Error - propertyDamage', {
            errorMessage,
            inputData: data,
          });
          errors[fieldName].addError(errorMessage);
        }
      }
    }
    return { isError, warningMsgs };
  },
  umbiCompare: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const bodilyInjury = data?.bodilyInjury?.split('/');
    const umBodilyInjury = data?.umbi?.split('/');
    if (
      bodilyInjury?.length > 0 &&
      umBodilyInjury?.length > 0 &&
      parseInt(bodilyInjury[0], 10) < parseInt(umBodilyInjury[0], 10)
    ) {
      const messages: any = fieldSchema.messages;
      let errorMessage = messages?.[validationType];
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag('Custom Form validation Error - umbiCompare', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  umpdCoverage: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const pdLimit = data?.propertyDamage;
    const umbiLimit = data?.umbi;
    const umbiLimitNotDeclined =
      data?.umbi?.toLowerCase() !== 'declined' ? data?.umbi?.split('/') : [];

    const isUmbiDeclined =
      data?.umbi && data?.umbi?.toLowerCase() === 'declined';
    const isUmpdDeclined =
      data?.umpd && data?.umpd?.toLowerCase() === 'declined';
    const umpdLimit =
      data?.umpd?.toLowerCase() !== 'declined' ? data?.umpd?.split(' ') : [];
    let umpdValidation = false;
    let umpdUimValidation = false;
    let errorValidation = false;
    if (
      pdLimit &&
      umpdLimit.length > 0 &&
      parseInt(umpdLimit[0], 10) > pdLimit
    ) {
      umpdValidation = true;
    }
    if (!isUmpdDeclined && isUmbiDeclined) {
      umpdUimValidation = true;
    }
    if (umbiLimit.toLowerCase() == 'declined') {
      if (data?.umpd?.toLowerCase() != 'declined') {
        errorValidation = true;
        umpdUimValidation = true;
      }
    } else if (parseInt(umbiLimitNotDeclined?.[0], 10) == 25) {
      if (
        !(
          parseInt(umpdLimit[0], 10) == 25 ||
          data?.umpd?.toLowerCase() == 'declined'
        )
      ) {
        errorValidation = true;
        umpdUimValidation = true;
      }
    } else if (parseInt(umbiLimitNotDeclined?.[0], 10) == 50) {
      if (
        !(
          parseInt(umpdLimit[0], 10) == 25 ||
          parseInt(umpdLimit[0], 10) == 50 ||
          data?.umpd?.toLowerCase() == 'declined'
        )
      ) {
        errorValidation = true;
        umpdUimValidation = true;
      }
    } else if (parseInt(umbiLimitNotDeclined?.[0], 10) == 100) {
      if (
        !(
          parseInt(umpdLimit[0], 10) == 50 ||
          parseInt(umpdLimit[0], 10) == 100 ||
          data?.umpd?.toLowerCase() == 'declined'
        )
      ) {
        errorValidation = true;
        umpdUimValidation = true;
      }
    }
    if (umpdValidation || umpdUimValidation || errorValidation) {
      const messages: any = fieldSchema.messages;
      let errorMessage = umpdUimValidation
        ? messages?.umpdUimCoverage
        : messages?.[validationType];
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag('Custom Form validation Error - umpdCoverage', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  comprehensiveDeductible: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let errorFieldsExists = false;
    const financeCase = ['financed', 'lease'];
    data.vehiclesList &&
      data?.vehiclesList.forEach((vehicle: any, index: number) => {
        const currentVin = getCurrentVehicleByVin(vehicle?.vin);
        const compDed =
          vehicle?.comprehensiveDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.comprehensiveDeductible?.split(' DED');
        const collDed =
          vehicle?.collisionDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.collisionDeductible?.split(' DED');

        if (compDed?.length > 0 && collDed?.length > 0) {
          let showErrorMessage = false;
          let financedErrorMessage = false;
          if (
            currentVin &&
            financeCase.includes(currentVin?.ownershipStatus?.toLowerCase()) &&
            compDed[0] === 'declined'
          ) {
            showErrorMessage = true;
            financedErrorMessage = true;
          }
          if (collDed[0] !== 'declined' && compDed[0] === 'declined') {
            showErrorMessage = true;
          }
          if (showErrorMessage) {
            const messages: any = fieldSchema.messages;
            let errorMessage = financedErrorMessage
              ? messages?.comprehensiveDecline
              : messages?.[validationType];
            errorFieldsExists = true;
            if (errors && errorMessage && data[fieldName] !== undefined) {
              notifyBugsnag(
                'Custom Form validation Error - comprehensiveDeductible',
                { errorMessage, inputData: data }
              );
              errorFieldsExists = true;
              if (errorMessage === messages?.[validationType]) {
                errors[fieldName][index].collisionDeductible.addError(
                  errorMessage
                );
              } else {
                errors[fieldName][index].comprehensiveDeductible.addError(
                  errorMessage
                );
              }
            }
          }
        }
      });
    if (errorFieldsExists) {
      isError = true;
    }
    return { isError, warningMsgs };
  },
};
