import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';

export interface MuiButtonProps extends ButtonProps {
  children?: React.ReactNode;
  text?: string | JSX.Element;
  isError?: boolean;
  icon?: string;
  ariaLabel?: string;
  ref?: any;
  // tabIndex?: string;
  // style?: string;
}

const MuiButton: React.FC<MuiButtonProps> = ({
  children,
  id = 'button',
  disabled = false,
  text = 'NEXT',
  className,
  onClick,
  variant = 'contained',
  color = 'primary',
  type = 'submit',
  isError = false,
  ref,
  ...props
}) => {
  const refField = !ref ? React.createRef() : ref;
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(event);
    }
  };
  if (isError) {
    className += ' Mui-error';
  }
  return (
    <Button
      data-testid={id}
      id={id}
      disabled={disabled}
      className={className}
      type={type}
      color={color}
      variant={variant}
      onClick={handleClick}
      ref={refField}
      {...props}
    >
      {children || text}
    </Button>
  );
};

export default MuiButton;
