import { TextField } from '@mui/material';
import type { WidgetProps } from '@rjsf/core';
import React, { useEffect } from 'react';

const SocialSecurityWidget = (props: WidgetProps) => {
  const ssnMasking = (value: string) => {
    const stars = (len: number) =>
      len > 0
        ? len > 1
          ? len > 2
            ? len > 3
              ? len > 4
                ? '••• •• '
                : '••• •'
              : '••• '
            : '••'
          : '•'
        : '';
    return stars(value.length) + value.substring(5);
  };

  const [fieldValues, setFieldValues] = React.useState({
    ssn: '',
    mask: '',
  });

  useEffect(() => {
    if (props.value && props.value !== fieldValues.ssn) {
      const str = props.value;
      setFieldValues({
        ssn: str,
        mask: ssnMasking(str),
      });
    }
  }, [props.value, fieldValues.ssn]);

  useEffect(() => {
    if (props.value !== fieldValues.ssn) {
      let formData: any =
        fieldValues.ssn && fieldValues.ssn.length > 0
          ? fieldValues.ssn
          : undefined; //string
      if (props.schema?.type === 'number') {
        formData = Number(formData);
      }
      props.onChange(formData);
    }
  }, [fieldValues.ssn, props]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 11) return; // SSN + 2 hyphens

    setFieldValues((prev) => {
      let newSsn = fieldValues.ssn;
      if (prev.mask.length < value.length) {
        let countOfDigitsAdded: number =
          value.replaceAll(' ', '').length -
          prev.mask.replaceAll(' ', '').length;
        if (
          value.substring(0, value.length - countOfDigitsAdded) === prev.mask
        ) {
          const newDigits = value.substring(value.length - countOfDigitsAdded);
          if (Number(newDigits)) {
            newSsn = prev.ssn + newDigits;
          }
        }
      } else {
        if (prev.mask.substring(0, value.length) === value) {
          let countOfDigitsRemoved =
            prev.mask.replaceAll('-', '').length -
            value.replaceAll('-', '').length;
          if (countOfDigitsRemoved < 1) {
            countOfDigitsRemoved = 1;
          }
          newSsn = prev.ssn.substring(
            0,
            prev.ssn.length - countOfDigitsRemoved
          );
        }
      }
      const newMask = ssnMasking(newSsn);
      console.debug('newSsn: ', newSsn);
      return { ssn: newSsn, mask: newMask };
    });
  };
  const { schema, id } = props;

  return (
    <TextField
      disabled={props.disabled}
      id={id}
      label={schema?.title}
      variant='outlined'
      inputProps={{ max: 11, pattern: '^•{3}-?•{2}-?d{4}$' }}
      value={fieldValues.mask}
      onChange={handleChange}
      required={props.required}
    />
  );
};

export default SocialSecurityWidget;
