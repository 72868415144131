import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import axiosRetry from 'axios-retry';

interface FetchResultsProps {
  url: string;
  payload?: any;
  method?: Method;
  headers?: Record<string, string>;
  shouldRetry?: boolean;
  retryTimes?: number;
}

const MakeServiceCall = async ({
  url,
  payload,
  method,
  headers,
  shouldRetry,
  retryTimes,
}: FetchResultsProps) => {
  const axiosClient = axios.create();
  let requestObj: AxiosRequestConfig = {
    // timeout: 60000, // 60 seconds timeout
    url: url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  if (method) {
    requestObj.method = method;
  } else {
    requestObj.method = payload ? 'POST' : 'GET';
  }

  if (payload) {
    requestObj.data = payload;
  }

  axiosRetry(axiosClient, {
    retries: retryTimes ? retryTimes : 10, // number of retries
    retryDelay: (retryCount) => {
      console.debug(`retry attempt: ${retryCount}`);
      return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error) => {
      return (
        (shouldRetry &&
          (error?.response?.status === 504 ||
            error?.response?.status === 503 ||
            error?.toString().toLowerCase()?.includes('network error'))) ||
        false
      );
    },
  });
  const resp: AxiosResponse = await axiosClient(requestObj);
  return resp;
};

export const FetchResults = async ({
  url,
  payload,
  method,
  headers,
  shouldRetry,
  retryTimes,
}: FetchResultsProps) => {
  let response;
  try {
    response = await MakeServiceCall({
      url,
      payload,
      method,
      headers,
      shouldRetry,
      retryTimes,
    });
    let respData = {};
    const checkIfErrorResponse = isErrorResponse(response);
    if (checkIfErrorResponse.isError) {
      throw new Error(checkIfErrorResponse.errorMessage);
    } else {
      respData = response.data;
    }
    return {
      response: respData,
      error: false,
    };
  } catch (e: any) {
    const apiErrorMsg =
      e?.response?.data?.errorMessage ||
      e?.response?.data?.message ||
      response?.data?.errorMessage ||
      response?.data?.message ||
      e.message ||
      'q2b.default.error.message';

    console.error(apiErrorMsg);
    const errorCode = e?.response?.data?.error_code
      ? e.response.data.error_code
      : '';
    return {
      response: {} as any,
      apiErrorMsg: apiErrorMsg,
      error: true,
      errorCode: errorCode,
      errorMessage: 'q2b.default.error.message',
    };
  }
};

function isErrorResponse(response: any) {
  let retValue = {
    isError: false,
    errorMessage: undefined,
  };
  if (Array.isArray(response?.data) && response?.data[0]) {
    const messageList = response?.data[0]['message-list'];
    retValue = messageListError(messageList);
  } else if (Array.isArray(response?.data['message-list'])) {
    const messageList = response?.data['message-list'];
    retValue = messageListError(messageList);
  } else if (response?.data?.error) {
    retValue.isError = true;
  }
  return retValue;
}

function messageListError(messageList: any) {
  let isError = false;
  let errorMessage;
  if (messageList && messageList[0]) {
    // we are only checking message on the 1st node item
    isError = messageList[0]['response-type'] === 'error';
    errorMessage = messageList[0].error?.message;
    if (errorMessage instanceof Object) {
      errorMessage = errorMessage?.errors;
    }
  }
  return {
    isError,
    errorMessage,
  };
}
