import { FormControlLabel, Grid, Switch } from '@mui/material';
import { getDriverById, setDriver } from 'framework/dataService/drivers';
import { novoRoutes } from 'framework/routes';
import React, { CSSProperties, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TileRow from 'components/TileRow';
import DriverAvatar from 'framework/components/DriverAvatar';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { saveApplicationCurrentSessionState } from 'library/services/utils';

const avatarStyles: CSSProperties = {
  marginBottom: '0.75rem',
  width: '60px',
  height: '60px',
};

interface DriverRowCardType {
  driverId: string;
  firstName: string;
  lastName: string;
  gender: any;
  primary: boolean;
  included: boolean;
  mode: string;
  to?: string;
  readonly: boolean;
  index: number;
  updateFormDirtyFlag?: any;
  updateCoverage?: any;
  driverType?: string;
}

export default function DriverRowCards({
  driverId = '1',
  firstName = 'Jacob',
  lastName = 'Smith',
  gender = 'Male',
  primary = true,
  included = true,
  mode = 'add',
  to = '',
  readonly = false,
  index = 0,
  updateFormDirtyFlag,
  driverType,
}: DriverRowCardType) {
  const navigate = useNavigate();

  const [isIncluded, setIsIncluded] = useState<boolean>(included);

  const handleNavigation = () => {
    let path = novoRoutes.AddDriver.path;
    if (mode === 'add') {
      navigate(path);
    } else {
      path = novoRoutes.EditDriver.path;
      path = path.replace(':driverId', driverId);

      if (to && to.length > 0) {
        path += '?to=' + to;
      }

      navigate(path);
    }
  };

  return (
    <TileRow
      id={driverId}
      mode={mode as 'edit' | 'add'}
      heading1={primary ? 'PRIMARY DRIVER' : 'ADDITIONAL DRIVER'}
      heading2={`${firstName} ${lastName}`}
      avatar={<DriverAvatar index={index} style={avatarStyles} />}
      onClick={(event) => {
        handleNavigation();
      }}
      showSwitch={!primary}
      driverType={driverType || 'INCLUDED'}
    />
  );
}
