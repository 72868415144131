import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DriverFormWrapperParamType } from 'types/Driver';
import './Driver.scss';
import DriverFormWrapper from './DriverFormWrapper';
import { v4 as uuidv4 } from 'uuid';

const DriverFormWrapperModule = ({ mode }: DriverFormWrapperParamType) => {
  let params = useParams();
  const [searchParams] = useSearchParams();
  let to = null;
  if (searchParams.get('to')) {
    to = searchParams.get('to');
  }

  const [driverId, setDriverId] = useState(
    mode === 'edit' ? params.driverId : uuidv4()
  );

  useEffect(() => {
    if (mode === 'edit') {
      setDriverId(params.driverId);
    }
  }, [params.driverId, mode]);

  return (
    <DriverFormWrapper mode={mode} driverId={driverId} key={driverId} to={to} />
  );
};
export default DriverFormWrapperModule;
