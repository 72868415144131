import React, { useEffect, useState } from 'react';
import { FieldProps } from '@rjsf/core';
import { TextField } from '@mui/material';
import { getFormattedDateFromString } from 'utils/dateUtils';

const CustomTextField = ({
  uiSchema,
  schema,
  rawErrors,
  idSchema,
  name,
  formData,
  onChange,
  formContext,
  autofocus,
  disabled,
  readonly,
  required,
}: FieldProps) => {
  const label: string = schema.title ? schema.title : '';
  const isError = rawErrors && rawErrors.length > 0;

  const [hiddenUntil, setHiddenUntil] = useState(uiSchema?.hiddenUntil);
  const [hidden, setHidden] = useState(typeof hiddenUntil === 'object');
  const [hiddenFieldValue, setHiddenFieldValue] = useState();

  const [disabledUntil, setDisabledUntil] = useState(uiSchema?.disabledUntil);
  const [disable, setDisable] = useState(typeof disabledUntil === 'object');
  const [disabledFieldValue, setDisabledFieldValue] = useState();

  const getFormattedValue = (value: string) => {
    let newValue = value;
    if (value?.length === 10 && uiSchema?.formatValue === 'formatDate') {
      newValue = getFormattedDateFromString(formData);
    }
    return newValue;
  };
  const [value, setValue] = useState(getFormattedValue(formData));
  useEffect(() => {
    setValue(getFormattedValue(formData));
  }, [formData, uiSchema?.formatValue]);

  useEffect(() => {
    if (uiSchema?.hiddenUntil) {
      setHiddenUntil(uiSchema.hiddenUntil);
    }
    if (uiSchema?.disabledUntil) {
      setDisabledUntil(uiSchema.disabledUntil);
    }
  }, [uiSchema]);

  useEffect(() => {
    if (
      hiddenUntil?.field &&
      formContext[hiddenUntil.field] !== hiddenFieldValue
    ) {
      setHiddenFieldValue(formContext[hiddenUntil.field]);

      if (formContext[hiddenUntil.field] === hiddenUntil?.value) {
        setHidden(false);
      } else {
        setHidden(true);
        onChange(undefined);
      }
    }

    if (
      disabledUntil?.field &&
      formContext[disabledUntil.field] !== disabledFieldValue
    ) {
      setDisabledFieldValue(formContext[disabledUntil.field]);

      if (formContext[disabledUntil.field] === disabledUntil?.value) {
        setDisable(false);
      } else {
        setDisable(true);
        onChange(undefined);
      }
    }

    if (disabledUntil?.always) {
      setDisable(true);
    }
  }, [
    hiddenFieldValue,
    hiddenUntil?.field,
    hiddenUntil?.value,
    disabledFieldValue,
    disabledUntil?.always,
    disabledUntil?.field,
    disabledUntil?.value,
    formContext,
    onChange,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && !disable) {
      onChange(event.target.value);
    }
  };
  if (hidden) return <div className={'hidden'}></div>;

  return (
    <TextField
      className={`w-full ${isError ? 'Mui-error' : ''}`}
      id={idSchema.$id}
      disabled={disable || disabled || readonly}
      name={name}
      label={label}
      error={isError}
      required={required}
      helperText={''}
      fullWidth
      variant='outlined'
      value={value}
      onChange={handleChange}
    />
  );
};

export default CustomTextField;
