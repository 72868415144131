import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AjvError, IChangeEvent, ISubmitEvent } from '@rjsf/core';
import NovoForm from 'framework/forms/NovoForm';
import Page from 'components/Page';
import { getData } from 'framework/dataService/dataRehydration';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { AlertProps } from 'types/GenericTypes';
import { createAccount } from 'library/services/utils';
import MuiLoader from 'framework/components/MuiLoader';
import CongratDetail from 'components/congratulationDetails';
import CommonHeader from 'components/commonHeader';
import CheckEmailTips from 'components/CheckEmailTips';
import CheckEmailDetail from 'components/CheckEmailDetail';
export default function Congratulations() {
  const routeKey = 'Congratulations';
  const [initialFormData, setInitialFormData] = useState({});
  const [serviceCallStatus, setServiceCallStatus] = useState(false);
  const [alert, setAlert] = useState<AlertProps>({});
  const [accountCreated, setAccountCreated] = useState(false);
  const [passwordValidate, setPasswordValidate] = useState(true);

  const accountForm = {
    heading1: 'SETUP YOUR ACCOUNT',
    heading2:
      "Now that your've purchased your policy, secure your account by creating a password.",
  };
  useEffect(() => {
    let formData: any = {};
    const dataForFormData = getData(routeKey);
    if (dataForFormData?.driver?.driverId) {
      formData = { ...formData, email: dataForFormData.driver.email };
    }

    setInitialFormData(formData);
  }, []);

  const [minVerified, setMinVerified] = useState(false);
  const [numVerified, setNumVerified] = useState(false);
  const [upperVerified, setUpperVerified] = useState(false);
  const [lowerVerified, setLowerVerified] = useState(false);
  const [specialVerified, setSpecialVerified] = useState(false);

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    if (persistanceService) persistanceService({});
    setServiceCallStatus(true);
    const response = await createAccount(formData.email, formData.password);
    setServiceCallStatus(false);
    if (response.error === true) {
      let alertErrorMessage = response?.apiErrorMsg || response?.errorMessage;
      if (response.errorCode && response.errorCode === 'user-unconfirmed') {
        alertErrorMessage = 'q2b.account.create.unconfirmed';
      } else if (
        response.errorCode &&
        response.errorCode === 'user-already-exists'
      ) {
        alertErrorMessage = 'q2b.account.create.exists';
      }
      setAlert({
        message: alertErrorMessage,
        code: 'error',
      });
    } else {
      setAccountCreated(true);
      setPasswordValidate(true);
      setAlert({
        message: 'q2b.account.create.successful',
        code: 'success',
      });
    }
  };

  const checkValidation = (formData: { password: string }) => {
    const regNum = /[0-9]+/;
    const regUpper = /[A-Z]/;
    const regLower = /[a-z]/;
    const regSpecial = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    const minRegResult = formData?.password?.length >= 8;
    const numRegResult = regNum.test(formData?.password);
    const upperRegResult = regUpper.test(formData?.password);
    const lowerRegResult = regLower.test(formData?.password);
    const specialRegResult = regSpecial.test(formData?.password);
    if (minRegResult) {
      setMinVerified(true);
    } else {
      setMinVerified(false);
    }
    if (formData?.password !== undefined) {
      if (numRegResult) {
        setNumVerified(true);
      } else {
        setNumVerified(false);
      }

      if (upperRegResult) {
        setUpperVerified(true);
      } else {
        setUpperVerified(false);
      }

      if (lowerRegResult) {
        setLowerVerified(true);
      } else {
        setLowerVerified(false);
      }

      if (specialRegResult) {
        setSpecialVerified(true);
      } else {
        setSpecialVerified(false);
      }
    }
    // eslint-disable-next-line max-len
    if (
      minRegResult &&
      numRegResult &&
      upperRegResult &&
      lowerRegResult &&
      specialRegResult
    ) {
      setPasswordValidate(false);
      console.log('validate passed');
    } else {
      console.log('validate not passed');

      setPasswordValidate(true);
    }
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    setInitialFormData(formData);
    checkValidation(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };
  const getCheckEmailPart = () => {
    return (
      <>
        <CheckEmailTips
          heading1={`CHECK YOUR EMAIL!`}
          heading2={`q2b.congrats.checkemail`}
          showLine={true}
        ></CheckEmailTips>
        <CheckEmailDetail marginLeft={`1.5rem`} marginTop={'1.5rem'} />
      </>
    );
  };

  const getFormPart = () => {
    return (
      <>
        <Grid
          container
          justifyContent='space-between'
          item
          xs={12}
          lg={6}
          sx={{ maxWidth: { xs: 'unset', lg: '445px' } }}
          className='congratulation-grid'
        >
          <CommonHeader
            heading1={accountForm.heading1}
            heading2={accountForm.heading2}
            showLine={true}
          ></CommonHeader>
          <NovoForm
            alert={alert}
            schemaName={routeKey}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onError={handleError}
            formData={initialFormData}
            transitionTo={false}
            disabled={accountCreated}
            information={
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{ mt: '1.5rem' }}
                columnSpacing={0}
                flexWrap='wrap'
                justifyContent='space-between'
                style={{ marginBottom: '2rem' }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        size='small'
                        className='congratulation'
                        disabled
                        sx={{
                          color: '#afb2b3',
                          '&.Mui-checked': {
                            color: minVerified ? '#6CF96A' : '#afb2b3',
                          },
                        }}
                      />
                    }
                    label='8 characters minimum &nbsp;&nbsp;&nbsp;&nbsp;'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        size='small'
                        className='congratulation'
                        disabled
                        sx={{
                          color: '#afb2b3',
                          '&.Mui-checked': {
                            color: numVerified ? '#6CF96A' : '#afb2b3',
                          },
                        }}
                      />
                    }
                    label='One number'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        size='small'
                        className='congratulation'
                        disabled
                        sx={{
                          color: '#afb2b3',
                          '&.Mui-checked': {
                            color: upperVerified ? '#6CF96A' : '#afb2b3',
                          },
                        }}
                      />
                    }
                    label='One uppercase character'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        size='small'
                        className='congratulation'
                        disabled
                        sx={{
                          color: '#afb2b3',
                          '&.Mui-checked': {
                            color: specialVerified ? '#6CF96A' : '#afb2b3',
                          },
                        }}
                      />
                    }
                    label='One special character'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        size='small'
                        className='congratulation'
                        disabled
                        sx={{
                          color: '#afb2b3',
                          '&.Mui-checked': {
                            color: lowerVerified ? '#6CF96A' : '#afb2b3',
                          },
                        }}
                      />
                    }
                    label='One lowercase character'
                  />
                </FormGroup>
              </Grid>
            }
            button={
              <Button
                id='button'
                className='btn-arrow'
                type='submit'
                color='secondary'
                variant='contained'
                disabled={passwordValidate}
              >
                <MicrocopyComponent
                  labelKey={`q2b.global.button.text.create.account`}
                />
              </Button>
            }
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          lg={6}
          justifyContent='center'
          style={{ backgroundSize: 'contain' }}
          sx={{
            backgroundImage: {
              xs: 'none',
              lg: 'url(/access-your-policy.svg)',
            },
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0 90%',
            backgroundSize: 'contain',
            pt: 0,
            pb: { xs: 8, lg: 0 },
            justifyContent: { xs: 'center', lg: 'flex-end' },
            alignItems: 'flex-start',
          }}
        ></Grid>
        <CongratDetail marginLeft={`1.5rem`} marginTop={'1.5rem'} />
      </>
    );
  };
  return (
    <Grid container className='quote-wrapper'>
      {serviceCallStatus && <MuiLoader />}
      <Page routeKey={routeKey}>
        <Grid
          container
          item
          xs={12}
          columnSpacing={0}
          flexWrap='wrap'
          justifyContent='flex-start'
        >
          {!accountCreated && getFormPart()}
          {accountCreated && getCheckEmailPart()}
        </Grid>
      </Page>
    </Grid>
  );
}
