import { ReactComponent as AvatarGreen } from '../../images/AvatarGreen.svg';
import { ReactComponent as AvatarPurple } from '../../images/AvatarPurple.svg';
import { ReactComponent as AvatarYellow } from '../../images/AvatarYellow.svg';
import { ReactComponent as AvatarPink } from '../../images/AvatarPink.svg';

import React from 'react';
const DriverAvatar = (props: any) => {
  return (() => {
    switch ((props.index + 1) % 4) {
      case 1:
        return <AvatarGreen style={props.style} />;
      case 2:
        return <AvatarPurple style={props.style} />;
      case 3:
        return <AvatarYellow style={props.style} />;
      case 0:
        return <AvatarPink style={props.style} />;
      default:
        return <AvatarGreen style={props.style} />;
    }
  })();
};

export default DriverAvatar;
