import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  MenuItem,
  TextField,
} from '@mui/material';
import type { WidgetProps } from '@rjsf/core';
import ChevronDownButton from 'components/icons/ChevronDownButton';
import React, { SyntheticEvent, useEffect, useState } from 'react';

interface AutoCompleteOptionProps {
  label: string;
  id: string | number;
}

const getLabelByIndex = (
  schema: any,
  menuValue: string | number,
  index: number
) => {
  const retVal =
    (schema as any)?.enumNames?.length > 0
      ? (schema as any)?.enumNames[index]
      : menuValue;
  return retVal;
};

const getLabelByName = (schema: any, menuValue: string | number) => {
  if ((schema as any)?.enumNames?.length > 0) {
    const index = schema.enum?.findIndex((item: any) => item === menuValue);
    if (index !== -1) {
      return (schema as any)?.enumNames[index];
    }
  }
  return menuValue;
};

const MuiAutoCompleteWidget = (props: WidgetProps) => {
  const [selectedValue, setSelectedValue] = useState<AutoCompleteOptionProps>();
  const { id, schema, value, onChange, label, required, disabled } = props;

  useEffect(() => {
    const tempValue = value || schema.default || undefined;
    if (tempValue) {
      setSelectedValue({
        label: getLabelByName(schema, tempValue as string),
        id: tempValue,
      });
    }
  }, [value, schema]);

  const listOfOptions = schema?.enum?.map((menuValue: any, index: number) => {
    const menuLabel: string = getLabelByIndex(schema, menuValue, index);
    return { label: menuLabel, id: menuValue };
  });

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    selectedOption: any,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<any> | undefined
  ) => {
    if (onChange) onChange(selectedOption.id);
  };

  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      fullWidth={true}
      id={id}
      options={listOfOptions || []}
      onChange={handleChange}
      value={selectedValue || null}
      renderOption={(optionProps, option) => (
        <MenuItem
          key={option.id}
          value={option.id}
          sx={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            height: '4.5rem',
          }}
          {...optionProps}
        >
          {option.label}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} />
      )}
      popupIcon={<ChevronDownButton />}
    />
  );
};

export default MuiAutoCompleteWidget;
