import { Box, Grid } from '@mui/material';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
  utils,
  withTheme,
} from '@rjsf/core';
import { Theme5 as Mui5Theme } from '@rjsf/material-ui';
import AlertComponent from 'components/AlertComponent';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import GreenShadowButton from 'framework/components/GreenShadowButton';
import {
  setPolicyCoverage,
  setVehicleCoverageItem,
} from 'framework/dataService/coverage';
import {
  setDriver,
  setDriverLocation,
  setDrivers,
} from 'framework/dataService/drivers';
import {
  setApplicationObjectItem,
  setMetaData,
  setMetaDataItem,
} from 'framework/dataService/insuranceApplication';
import { setLocation } from 'framework/dataService/locations';
import { setPayment } from 'framework/dataService/payment';
import { setVehicle, setVehicleLocation } from 'framework/dataService/vehicles';
import { novoNavigation } from 'framework/routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MuiButton from '../components/MuiButton';
import getFormDefinitions from '../schemaServer';
import additionalFields from './fields';
import type { CustomJsonSchemaProps } from './interfaces/CustomJsonSchemaProps';
import type { NovoFormProps } from './interfaces/NovoFormProps';
import { CsrRestrictionValidation } from './lib/CsrRestrictionValidation';
import additionalCustomFormats from './lib/CustomFormats';
import { CustomSchemaValidation } from './lib/customValidation/index';
import { transform } from './lib/ErrorHandler';
import { CustomObjectFieldTemplate } from './templates';
import CustomArrayFieldTemplate from './templates/CustomArrayFieldTemplate';
import CustomFieldTemplate from './templates/CustomFieldTemplate';
import additionalWidgets from './widgets';
import { removeMarkUp } from 'utils/util';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import SimpleDialog from 'framework/components/Dialogs/SimpleDialog';
import { WarningValidation } from './lib/WarningValidation';
import Validate from './validations/Validate';
import { getLienHolderEnums } from 'utils/car';

export const equals = utils.deepEquals;
export const { retrieveSchema } = utils;
// Make modifications to the theme with your own fields and widgets
const JsonForm = withTheme(Mui5Theme);

const NovoForm = ({
  shadowButton = false,
  children,
  className,
  disabled,
  readonly,
  enctype,
  fields,
  formContext,
  formData,
  id,
  idPrefix,
  name,
  onChange,
  onError,
  onFocus,
  onSubmit,
  schemaName,
  validate,
  widgets,
  buttonName,
  information,
  button,
  hideSubmitButton,
  dynamicUiSchema = undefined,
  alert = undefined,
  omitExtraData = false,
  liveOmit = false,
  transitionTo = true,
  getLienHolder = false,
}: NovoFormProps<unknown>) => {
  const navigate = useNavigate();
  const [liveValidate, setLiveValidate] = useState(false);
  let { schema, uiSchema } = getFormDefinitions(schemaName, formData);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [warningDialogClosedByUser, setWarningDialogClosedByUser] =
    useState(false);
  const [warningMessages, setWarningMessages] = useState([] as any[]);
  const [showCsrDialog, setShowCsrDialog] = useState(false);
  const [fmData, setFmData] = useState({});
  const allCustomFormats = { ...additionalCustomFormats };

  if (getLienHolder) {
    schema?.allOf?.length > 0 &&
      schema.allOf.forEach((key: any) => {
        if (Object.keys(key.then.properties).includes('lienHolderName')) {
          key.then.properties.lienHolderName.enum = getLienHolderEnums();
        }
      });
  }

  const customFields = {
    ...additionalFields,
    ...fields,
  };

  const customWidgets = { ...additionalWidgets, ...widgets };

  const customFormContext = { ...formContext, setLiveValidate };

  const handleChange = (event: IChangeEvent<unknown>) => {
    const data = JSON.parse(JSON.stringify(event.formData));

    onChange(event, data);
  };

  const handleError = (errors: AjvError[]) => {
    // get called whenever there is any error (rjsf/custom)
    setLiveValidate(true);
    if (onError) onError(errors);
  };

  const errorTransform = (errors: AjvError[]) => {
    // always get called during submission with errors (rjsf only - no custom validation errors will be part of it)
    // cannot set state here

    const rootSchema = schema;
    const computedSchema = retrieveSchema(schema, rootSchema, formData);
    return transform(computedSchema as CustomJsonSchemaProps, errors, formData);
  };

  const handleValidation = (appData: any, errors: FormValidation) => {
    // always get called during submission - allowing us to perform custom validations
    // errors: will not have rjsf errors

    // CSR
    if (CsrRestrictionValidation(schema as CustomJsonSchemaProps, appData)) {
      setShowCsrDialog(true);
      setLiveValidate(false); //to stop validation when csrPopup is closed
      // restrict form submission
      errors.addError(
        removeMarkUp(getMicroCopyResourceItem(`q2b.csr.restriction.message`))
      );
    }

    // Custom Validations
    // Warning driven by: fieldSchema.validation:{ warning: true }
    const { isError, warningMsgs } = CustomSchemaValidation(
      schema as CustomJsonSchemaProps,
      appData,
      errors
    );

    if (!warningDialogClosedByUser && !isError && warningMsgs?.length > 0) {
      // display warning only if not already shown AND no other error on the form
      // revalidate for rjsf errors
      const rjsfErrors = Validate(appData, schema, true);
      if (!rjsfErrors) {
        // show warning only when NO rjsf and custom validation errors
        setShowWarningDialog(true);
        setWarningMessages(warningMsgs);
        setFmData(appData);
        setLiveValidate(false); //to stop validation when warning is triggered
      }
    }

    if (validate) validate(appData, errors);
    return errors;
  };

  const handleSubmit = (event: ISubmitEvent<unknown>) => {
    if (!showWarningDialog) {
      // no warning triggered by custom validation section,
      // Lets check for Warnings driven by: fieldSchema.warning:[values array]
      const warningMsgs = WarningValidation(
        schema as CustomJsonSchemaProps,
        event.formData
      );
      if (warningMsgs?.length > 0) {
        setShowWarningDialog(warningMsgs?.length > 0);
        setWarningMessages(warningMsgs);
      } else {
        processSubmission(event);
      }
    } else {
      processSubmission(event);
    }
  };

  const processSubmission = (event: ISubmitEvent<unknown>) => {
    setShowWarningDialog(false);
    setWarningDialogClosedByUser(true);
    setLiveValidate(true); //to start validation when warning popup is closed
    const formDataCopy = JSON.parse(JSON.stringify(event?.formData || fmData));
    if (onSubmit && formDataCopy) {
      onSubmit(event, formDataCopy, handleDataPersistance);
    }
  };

  const handleDataPersistance = (dataForPersistance: any, path?: string) => {
    // persist data generically using mapping in session storage
    Object.entries(dataForPersistance).forEach(([key, data]) => {
      switch (key) {
        case 'driver':
          setDriver(data);
          break;
        case 'drivers':
          setDrivers(data);
          break;
        case 'location':
          if (data) {
            setLocation(data);
          }
          break;
        case 'vehicle':
          setVehicle(data);
          break;
        case 'payment':
          setPayment(data);
          break;
        case 'metaDataItem':
          Object.entries(data as any).forEach(
            ([metaDataKey, metaDataValue]) => {
              setMetaDataItem(metaDataKey, metaDataValue);
            }
          );
          break;
        case 'metaData':
          setMetaData('metaData', data);
          break;
        case 'vehicleCoverage':
          setVehicleCoverageItem('vehicleCoverage', data);
          break;
        case 'policyCoverage':
          setPolicyCoverage('policyCoverage', data);
          break;
        case 'driverLocation':
          setDriverLocation(data);
          break;
        case 'vehicleLocation':
          setVehicleLocation(data);
          break;
        default:
          setApplicationObjectItem('Testing', data);
          console.debug('Data Persistance Key not found!');
      }
    });

    // transition after data persistance

    if (transitionTo) transitionToNextPage(path);
  };

  const transitionToNextPage = (path?: string) => {
    if (path) {
      navigate(path);
    } else {
      navigate(novoNavigation[schemaName].nextPage);
    }
  };

  const handleDialogClose = () => {
    setShowCsrDialog(false);
  };

  return (
    <>
      <SimpleDialog
        showDialog={showWarningDialog}
        handleDialogClose={processSubmission}
        content={{
          title: 'q2b.modal.warning.title',
          messages: warningMessages,
        }}
      />
      <CsrDialog
        showDialog={showCsrDialog}
        handleDialogClose={handleDialogClose}
      />
      {children}
      {alert?.message && (
        <AlertComponent message={alert.message} type={alert.code || 'error'} />
      )}
      <JsonForm
        ArrayFieldTemplate={CustomArrayFieldTemplate}
        FieldTemplate={CustomFieldTemplate}
        className={className}
        customFormats={allCustomFormats}
        disabled={disabled ? disabled : false}
        readonly={readonly ? readonly : false}
        enctype={enctype}
        fields={customFields}
        formContext={customFormContext}
        formData={formData}
        id={id}
        idPrefix={idPrefix}
        name={name}
        noHtml5Validate
        liveValidate={liveValidate}
        omitExtraData={omitExtraData}
        liveOmit={liveOmit}
        autoComplete={'OFF'}
        schema={schema as CustomJsonSchemaProps}
        uiSchema={dynamicUiSchema ?? uiSchema}
        ObjectFieldTemplate={CustomObjectFieldTemplate}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onFocus={onFocus}
        onError={handleError}
        validate={handleValidation}
        showErrorList={false}
        transformErrors={errorTransform}
        widgets={customWidgets}
        // ref={setForm}
      >
        <Box mt={3} />
        {information}
        {!hideSubmitButton &&
          (shadowButton ? (
            <GreenShadowButton text={buttonName} />
          ) : button ? (
            button
          ) : (
            <Grid item lg={4} md={4} sm={4} xs={4} className=''>
              <MuiButton text={buttonName} />
            </Grid>
          ))}
      </JsonForm>
    </>
  );
};

export default NovoForm;
