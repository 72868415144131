export const educationFormat = (data: any) => {
  const dataLowerCase = data.toLowerCase();
  if (dataLowerCase && dataLowerCase.includes('doctor')) {
    return 'Unknown';
  } else if (
    dataLowerCase &&
    (dataLowerCase.includes('master') || dataLowerCase.includes('bachelor'))
  ) {
    return 'Graduate work or graduate degree';
  } else if (dataLowerCase && dataLowerCase.includes('no')) {
    return 'No high school diploma or GED';
  } else if (dataLowerCase && dataLowerCase.includes('school')) {
    return 'High school diploma or GED';
  } else {
    return data;
  }
};

export const dlFormat = (data: any) => {
  const dataLowerCase = data.toLowerCase();
  if (dataLowerCase && dataLowerCase === 'valid') {
    return 'Active/Valid';
  } else if (
    dataLowerCase &&
    (dataLowerCase === 'suspended' || dataLowerCase === 'invalid')
  ) {
    return 'Suspended/Invalid';
  } else if (dataLowerCase && dataLowerCase === 'revoked') {
    return 'Revoked';
  } else if (dataLowerCase && dataLowerCase === 'expired') {
    return 'Expired';
  } else {
    return data;
  }
};

export const residentTypeFormat = (data: any) => {
  const dataLowerCase = data.toLowerCase();
  if (
    dataLowerCase &&
    (dataLowerCase === 'own home' || dataLowerCase === 'own condo')
  ) {
    return 'Own home';
  } else if (dataLowerCase && dataLowerCase === 'rent') {
    return 'Rent';
  } else if (dataLowerCase && dataLowerCase === 'other') {
    return 'Other';
  } else {
    return data;
  }
};

export const annualMileageFormat = (data: any) => {
  if (!data.includes('-') && !data.includes('than')) {
    const dataInt = parseInt(data);
    if (dataInt < 1000) {
      return 'less than 1000';
    } else if (dataInt >= 1000 && dataInt < 3000) {
      return '1000-3000';
    } else if (dataInt >= 3000 && dataInt < 7000) {
      return '3000-7000';
    } else if (dataInt >= 7000 && dataInt < 12000) {
      return '7000-12000';
    } else if (dataInt >= 12000 && dataInt < 18000) {
      return '12000-18000';
    } else if (dataInt >= 18000) {
      return 'more than 18000';
    } else {
      return data;
    }
  } else {
    return data;
  }
};
