import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import AlertComponent from 'components/AlertComponent';
import { AlertProps } from 'types/GenericTypes';
import ButtonArrow from 'components/icons/ButtonArrow';
import Page from 'components/Page';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import MuiDatePicker from 'framework/components/MuiDatePicker';
import MuiDateTimePicker from 'framework/components/MuiDateTimePicker';

import MuiDropDown from 'framework/components/MuiDropDown';
import MuiLoader from 'framework/components/MuiLoader';
import { getData } from 'framework/dataService/dataRehydration';
import {
  getApplicationObject,
  getApplicationObjectItem,
} from 'framework/dataService/insuranceApplication';
import { novoNavigation, novoRoutes } from 'framework/routes';
import {
  getQuoteServiceCall,
  saveApplicationObject,
} from 'library/services/utils';
// import PlanTelematics from 'pages/Coverages/PlanTelematics';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  compareWithMaxDate,
  compareWithMinDate,
  dateToString,
  formatDate,
  getCalendarFormattedDate,
  getDateAfterDaysFromToday,
  getDateFromString,
  getPastDate,
  getToday,
  getUpComingDate,
  isValidDate,
} from 'utils/dateUtils';
import {
  currencyFormatter,
  getOnlyNumbers,
  isElementComesInViewport,
  isElementGoesOutFromViewport,
  notifyBugsnag,
} from 'utils/util';
import PlanCoverages from './PlanCoverages';
import PlanDescription from './PlanDescription';
import PlanOption from './PlanOption';
import PlanOptionsCarousel from './PlanOptionsCarousel';
import { ACP, PIF } from 'library/application/applicationConstants';
import PolicyDetailBox from 'components/PolicyDetailBox';
import { getItem, setItem } from 'framework/dataService';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import SimpleDialog from 'framework/components/Dialogs/SimpleDialog';
import { driverValidation } from 'utils/driver';
import UdrDialog from 'framework/components/Dialogs/UdrDialog';

export interface PlanOptionType {
  id: string;
  planName?: string;
  text?: string;
  pifPremium?: string;
  driverCount?: number;
  vehicleCount?: number;
  savings?: string;
  selectedOptionId?: string;
  handleChange: (value: string) => void;
  currentTab?: string;
  defaultVehicleCoverage?: any;
  defaultPolicyCoverage?: any;
  disabled?: boolean;
  acpPremium?: string;
  monthlyPremium?: string;
  paymentMethodType?: string;
  initialPremium?: number;
}

export default function PlanOptions({
  currentTab = `q2b.plan.options.plan.coverages.tab.data.customize.coverage.name`,
}) {
  // const submitBtnTelematicsRef = useRef<HTMLButtonElement>(null);
  const submitBtnPlanCoveragesRef = useRef<HTMLButtonElement>(null);
  const submitBtnVehicleCoveragesRef = useRef<HTMLButtonElement>(null);
  const routeKey = 'PlanOptions';
  const agentIdSesssion = getItem('isAgent');
  const datePickerMaxDays = agentIdSesssion ? 31 : 15;
  const [selectedCoverages, setSelectedCoverages] = useState({} as any);
  const [metaData, setMetaData] = useState({} as any);
  const [quote, setQuote] = useState([] as any[]);
  const [csrDialog, setCsrDialog] = useState(false);
  const [selectedId, setSelectedId] = useState<string>('2');
  const [paymentMethodType, setPaymentMethodType] = useState(ACP);
  const [formDirty, setFormDirty] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [showCustomDialog, setShowCustomDialog] = useState(false);
  // const [enrollTelematics, setEnrollTelematics] = useState('');
  const [policyEffectiveDate, setPolicyEffectiveDate] = React.useState(
    getDateAfterDaysFromToday(1)
  );
  const [readonly, setReadonly] = useState(false);
  const [isValidPED, setIsValidPED] = useState(true as boolean);
  const [pedErrorMessage, setPedErrorMessage] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [stopQuote, setStopQuote] = useState(false);
  const [showUdrDialog, setShowUdrDialog] = useState(false);
  const [showInvalidDateDialog, setShowInvalidDateDialog] = useState(false);
  const [udrDialogCotent, setUdrDialogCotent] = useState({});
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState<AlertProps>({});
  const [disablePurchase, setDisablePurchase] = useState({
    name: currentTab,
    url: '',
  } as any);
  const [selectedTabData, setSelectedTabData] = useState({
    name: currentTab,
    url: '',
  });
  // const [validEnrollTelematics, setValidEnrollTelematics] = useState(false);

  const [inSubmission, setInSubmission] = useState(false);
  const [stickyClass, setStickyClass] = useState('');
  const [planOptionPaddingClass, setPlanOptionPaddingClass] = useState('');

  const navigate = useNavigate();
  const path = novoRoutes.PlanOptions.path;
  setItem('DisclosureViewed', 'false');
  // const onValidateEnrollTelematics = (flag: boolean) => {
  //   setValidEnrollTelematics(flag);
  // };

  const onValidateTabs = (errorTabData: any) => {
    if (errorTabData.name !== '') {
      setSelectedTabData(errorTabData);
    }

    if (errorTabData.name !== disablePurchase.name) {
      setDisablePurchase({ ...errorTabData });
    }

    if (errorTabData.name === '' && disablePurchase.name === '') {
      setDisablePurchase(errorTabData);
    }
  };

  const errorHandling = (errorList: any) => {
    if (errorList.length > 0) {
      setStopQuote(true);
    } else {
      setStopQuote(false);
    }
  };

  const handleSelectedTabData = (tabData: any) => {
    window.history.replaceState(
      null,
      '',
      path.substring(0, path.lastIndexOf('/')) + tabData.url
    );
    setSelectedTabData(tabData);
  };

  useEffect(() => {
    const disclosureApiFailure = getItem('disclosureApiFailure');
    if (disclosureApiFailure && disclosureApiFailure === 'true') {
      notifyBugsnag('CSR pop up validation - Disclosure', disclosureApiFailure);
      setCsrDialog(true);
    }
    if (metaData?.selectedPlanName) {
      //get selected option id from coverage plan
      if (quote && quote.length > 0) {
        const data = quote.findIndex(
          (item: any) => item.planName === metaData.selectedPlanName
        );
        if (data !== -1) {
          setSelectedId(data + 1 + '');
        }
      }
    }

    const isUdrPresent = getItem('isUdrPresent');
    if (isUdrPresent && isUdrPresent === 'true') {
      setShowUdrDialog(true);
      setItem('isUdrPresent', 'false');
    }
    const dataForFormDataDriver = getApplicationObjectItem('drivers');
    const dataForFormDataLocation = getApplicationObjectItem('locations');
    const pniDriver = dataForFormDataDriver?.filter((d: any) => d.primary);
    const pniLocation = dataForFormDataLocation?.filter((d: any) => d.primary);
    if (pniDriver.length > 0 && pniLocation.length > 0) {
      const data = {
        fullName: `${pniDriver[0].name.firstName} ${pniDriver[0].name.lastName}`,
        address1: `${pniLocation[0].address.address},`,
        address2: `${pniLocation[0].address.city} ${pniLocation[0].address.state},`,
        zipCode: pniLocation[0].address.zip,
        dlNumber: pniDriver[0].dlNumber,
        dob: pniDriver[0].dob,
      };
      setUdrDialogCotent(data);
    }
  }, [quote, metaData]);

  useEffect(() => {
    const handlePurchaseButton = async () => {
      const dataForFormData = getData(routeKey);
      const applicationObject = getApplicationObject();
      let metaDataForPersistance = getApplicationObjectItem('metaData');
      if (formDirty) {
        metaDataForPersistance = {
          ...metaDataForPersistance,
          updateAndQuote: true,
        };
      }
      metaDataForPersistance = {
        ...metaDataForPersistance,
        policyEffectiveDate: policyEffectiveDate,
        paymentMethodType: paymentMethodType.toLowerCase(),
        enrollTelematics: dataForFormData?.metaData?.enrollTelematics,
        selectedPlanName: quote[Number.parseInt(selectedId, 10) - 1].planName,
      };
      const dataForPersistance = {
        ...applicationObject,
        coverage: dataForFormData?.coverage,
        metaData: metaDataForPersistance,
      };

      if (!stopQuote) {
        setShowLoader(true);
        if (formDirty) {
          // save application and quote again
          await saveApplicationObject(dataForPersistance);
          fetchQuote();
          setFormDirty(false);
          setSelectedId('2');
        } else {
          await saveApplicationObject(dataForPersistance);
          setShowLoader(false);
          nextPageClick(true);
        }
      }
    };

    if (inSubmission) {
      if (!disablePurchase.name /* && validEnrollTelematics */) {
        handlePurchaseButton();
        setInSubmission(false);
      } else if (disablePurchase.name && disablePurchase.url) {
        setSelectedTabData({
          name: disablePurchase.name,
          url: disablePurchase.url,
        });
        window.history.replaceState(
          null,
          '',
          path.substring(0, path.lastIndexOf('/')) + disablePurchase.url
        );
        setInSubmission(false);
      }
    }
  }, [
    disablePurchase,
    // validEnrollTelematics,
    formDirty,
    stopQuote,
    inSubmission,
    navigate,
    path,
    policyEffectiveDate,
    paymentMethodType,
    quote,
    selectedId,
  ]);

  const handleSubmission = async () => {
    console.log('update application before quote');

    const { shouldStopQuote } = validateDrivers();
    if (shouldStopQuote) {
      setShowCustomDialog(true);
    } else if (!policyEffectiveDate || policyEffectiveDate == 'Invalid Date') {
      setShowInvalidDateDialog(true);
    } else {
      setTimeout(() => {
        setInSubmission(true);
      }, 600);
      await submitBtnPlanCoveragesRef?.current?.click();
      await submitBtnVehicleCoveragesRef?.current?.click();
      // await submitBtnTelematicsRef?.current?.click();
    }
  };

  const validateDrivers = () => {
    console.log('validateDrivers');
    let validationMsg = '';
    let fieldType = '';
    let shouldStopQuote = false;
    if (!agentIdSesssion) {
      const response = driverValidation();
      shouldStopQuote = response.shouldStopQuote;
      if (shouldStopQuote) {
        validationMsg = response.validationMessage;
        fieldType = response.fieldType;
        notifyBugsnag(
          `validation of drivers popup- ${fieldType}`,
          validationMsg
        );
      }

      setValidationMessage(response.validationMessage);
    }
    return { shouldStopQuote };
  };

  const validatePED = (ped: string) => {
    const maxPED = getUpComingDate(getToday(), datePickerMaxDays);
    const minPED = getPastDate(getToday(), 0);

    if (isValidDate(getDateFromString(ped))) {
      if (compareWithMaxDate(ped, maxPED)) {
        setIsValidPED(false);
        notifyBugsnag('CSR pop up validation - Effective Date', ped);
        setPedErrorMessage(
          `You cannot request a quote with an effective date more than ${datePickerMaxDays} days in the future`
        );
        return false;
      }
      if (compareWithMinDate(ped, minPED)) {
        setIsValidPED(false);
        setPedErrorMessage('Effective date must be after today');
        return false;
      }
      setIsValidPED(true);
      setPedErrorMessage('');
      return true;
    } else {
      setIsValidPED(false);
      setPedErrorMessage('Please enter date only in mm/dd/yyyy format');
      return false;
    }
  };

  const fetchQuote = async () => {
    const dataForFormData = getData(routeKey);
    if (!dataForFormData.metaData.readonly) {
      if (dataForFormData) {
        setSelectedCoverages(dataForFormData.coverage);
      }
      if (dataForFormData?.metaData) {
        setMetaData(dataForFormData.metaData);
        if (dataForFormData?.metaData?.policyEffectiveDate) {
          setPolicyEffectiveDate(
            dataForFormData?.metaData?.policyEffectiveDate
          );
        }
        if (dataForFormData?.metaData?.paymentMethodType) {
          setPaymentMethodType(
            dataForFormData?.metaData?.paymentMethodType?.toUpperCase()
          );
        }
        // if (dataForFormData?.metaData?.enrollTelematics) {
        //   setEnrollTelematics(dataForFormData.metaData.enrollTelematics);
        // }
      }

      const quoteResponse = await getQuoteServiceCall(
        dataForFormData.metaData.updateAndQuote
      );
      if (quoteResponse.error !== true) {
        setCsrDialog(false);
        setItem('disclosureApiFailure', 'false');
        const customQuote = quoteResponse.find((quoteItem: any) => {
          return quoteItem.customPlan;
        });
        if (customQuote) {
          quoteResponse[1] = customQuote;
          quoteResponse.pop();
        }
        setQuote(quoteResponse);
        quoteResponse.forEach(
          (quoteItem: { id: number; planName: string }, index: number) => {
            quoteItem.id = index + 1;
          }
        );
      } else {
        setError(true);
        setAlert({
          message: quoteResponse.errorMessage,
          code: 'error',
        });
      }
      setShowLoader(false);
      // setValidEnrollTelematics(false);
    } else {
      setReadonly(true);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchQuote();
    window.addEventListener('scroll', toggleStickyFooter);
  }, []);

  const toggleStickyFooter = () => {
    let stickyClassName = '';
    const boxPlanOptionList =
      document.getElementsByClassName('plan-options-list');
    const boxPlanOptionSlider =
      document.getElementsByClassName('slider-wrapper');
    if (
      (boxPlanOptionList && boxPlanOptionList.length > 0) ||
      (boxPlanOptionSlider && boxPlanOptionSlider.length > 0)
    ) {
      if (
        isElementComesInViewport(boxPlanOptionList[0]) ||
        isElementComesInViewport(boxPlanOptionSlider[0])
      ) {
        setStickyClass('');
        setPlanOptionPaddingClass('');
      }
      if (
        isElementGoesOutFromViewport(boxPlanOptionList[0]) ||
        isElementGoesOutFromViewport(boxPlanOptionSlider[0])
      ) {
        stickyClassName = 'plan-option-final-payment-grid-sticky';
        setStickyClass(stickyClassName);
        setPlanOptionPaddingClass('pb-150');
      }
    }
  };

  useEffect(() => {
    const onPageLoad = () => {
      if (selectedId !== '1') {
        const scrollAmount = selectedId === '2' ? 200 : 400;
        const carousel = document.getElementById('plan-options-carousel');
        carousel?.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const handlePlanOptionChange = (planOptionId: string) => {
    console.debug('[][][][][][][][]', planOptionId);
    let scrollDirection = 0;
    if (getOnlyNumbers(planOptionId) !== selectedId) {
      scrollDirection =
        getOnlyNumbers(planOptionId) > selectedId
          ? parseInt(getOnlyNumbers(planOptionId)) * 130
          : (400 / parseInt(getOnlyNumbers(planOptionId))) * -1;
      const carousel = document.getElementById('plan-options-carousel');
      carousel?.scrollBy({ left: scrollDirection, behavior: 'smooth' });
    }
    setSelectedId(getOnlyNumbers(planOptionId));
    // setValidEnrollTelematics(false);
  };

  const listOfOptions = quote.map((quoteItem, index) => (
    <React.Fragment key={index}>
      <PlanOption
        disabled={readonly}
        {...quoteItem}
        handleChange={handlePlanOptionChange}
        selectedOptionId={selectedId}
        paymentMethodType={paymentMethodType}
        key={`plan-option-${index + 1}`}
      />
    </React.Fragment>
  ));

  const handleChangeDatePicker = (date: any) => {
    if (date) {
      console.log('dae', date);
      setPolicyEffectiveDate(date);
    } else {
      setIsValidPED(false);
      setPedErrorMessage('Please enter date only in mm/dd/yyyy format');
    }
  };

  const paymentSchema = {
    id: 1,
    schema: {
      enum: [PIF, ACP],
      enumOptions: ['Pay in Full', 'Monthly'],
      title: 'Payment',
    },
  };

  const handleIsDirty = (data: any) => {
    setFormDirty(data);
    setStopQuote(false);
  };

  const handlePaymentMethodTypeChange = async (data: string) => {
    if (quote && Array.isArray(quote) && quote.length > 0) {
      const shouldUpdateData = quote?.filter(
        (el) => el.pifPremium === el?.acpPremium
      );
      if (data === ACP && shouldUpdateData.length > 0) {
        setShowLoader(true);
        try {
          for (let i = 0; i < shouldUpdateData.length; i++) {
            const applicationObject = getApplicationObject();
            let metaDataForPersistance = getApplicationObjectItem('metaData');
            metaDataForPersistance.paymentMethodType = data.toLocaleLowerCase();
            const dataForPersistance = {
              ...applicationObject,
              metaData: metaDataForPersistance,
            };
            await saveApplicationObject(dataForPersistance);
            setTimeout(async () => {
              await fetchQuote();
              setShowLoader(false);
            }, 1500);
          }
        } catch (e) {
          setShowLoader(false);
        }
      }
    }
  };
  const handlePaymentChange = async (
    index: string,
    coverageId: string,
    data: string
  ) => {
    setTimeout(() => {
      setPaymentMethodType(data);
    }, 0);
    handlePaymentMethodTypeChange(data);
  };

  const updateCoverage = (coverageData: any) => {
    let updatedCoverageData = selectedCoverages;
    if (coverageData?.policyCoverage) {
      updatedCoverageData = {
        ...updatedCoverageData,
        policyCoverage: coverageData.policyCoverage,
      };
    } else if (coverageData?.vehicleCoverage) {
      const { vehicleCoverage } = updatedCoverageData;
      if (vehicleCoverage && vehicleCoverage.length > 0) {
        var foundIndex = vehicleCoverage.findIndex(
          (x: { vehicleId: any }) =>
            x.vehicleId === coverageData.vehicleCoverage.vehicleId
        );
        if (foundIndex === -1) {
          vehicleCoverage.push(coverageData.vehicleCoverage);
        } else {
          vehicleCoverage[foundIndex] = coverageData.vehicleCoverage;
        }
        updatedCoverageData.vehicleCoverage = vehicleCoverage;
      } else {
        updatedCoverageData = {
          ...updatedCoverageData,
          vehicleCoverage: [coverageData.vehicleCoverage],
        };
      }
    }
    setSelectedCoverages(updatedCoverageData);
  };
  const handleDialogClose = () => {
    setShowUdrDialog(false);
  };
  const handleDialogEdit = () => {
    if (novoNavigation.PlanOptions.driverPage) {
      navigate(novoNavigation.PlanOptions.driverPage);
    }
    setShowUdrDialog(false);
  };
  const handleDialogNext = () => {
    setItem('isUdrPresent', 'false');
    setItem('ReconciliationViewed', 'true');
    setShowUdrDialog(false);
  };

  const nextPageClick = (forward?: any) => {
    const reconViewed = getItem('ReconciliationViewed');
    if (reconViewed && reconViewed === 'true') {
      if (forward) {
        navigate(novoNavigation.ReviewHistory.nextPage);
      } else {
        return navigate(novoNavigation.ReviewHistory.nextPage);
      }
    } else {
      if (forward) {
        navigate(novoNavigation.PlanOptions.nextPage);
      } else {
        return navigate(novoNavigation.PlanOptions.nextPage);
      }
    }
  };
  return (
    <Grid container className='quote-wrapper plan-option'>
      <Page routeKey={routeKey} disabled={readonly}>
        <UdrDialog
          showDialog={showUdrDialog}
          handleDialogEdit={handleDialogEdit}
          handleDialogNext={handleDialogNext}
          handleDialogClose={handleDialogClose}
          content={udrDialogCotent}
        />
        <SimpleDialog
          showDialog={showCustomDialog}
          handleDialogClose={() => setShowCustomDialog(false)}
          content={{
            title: 'q2b.modal.uneven.unmarried.drivers.title',
            messages: [validationMessage],
          }}
        />
        <SimpleDialog
          showDialog={showInvalidDateDialog}
          handleDialogClose={() => setShowInvalidDateDialog(false)}
          content={{
            title: 'Policy effective date',
            messages: ['Invalid policy effective date'],
          }}
        />
        <CsrDialog
          showDialog={csrDialog}
          handleDialogClose={() => setCsrDialog(false)}
        />
        {error && <AlertComponent type={alert.code} message={alert.message} />}
        {!error && showLoader && <MuiLoader />}
        {!error && !showLoader && (
          <>
            {readonly ? (
              <Grid>
                <PolicyDetailBox marginLeft={`0`} />
                <Button
                  id='paymentButton'
                  type='submit'
                  color='secondary'
                  variant='contained'
                  disabled={false}
                  endIcon={<ButtonArrow />}
                  onClick={nextPageClick}
                >
                  <MicrocopyComponent
                    labelKey={`q2b.global.button.text.next`}
                  />
                </Button>
              </Grid>
            ) : (
              <Grid
                container
                mt={2}
                className={`plan-options-wrapper ${planOptionPaddingClass}`}
              >
                <Grid container spacing={3}>
                  <Grid
                    className='policy-effective-date'
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ margin: '0.5rem 0rem' }}
                  >
                    <Grid item container mb={1}>
                      <label className='plan-options-section-labels'>
                        <MicrocopyComponent
                          labelKey={`q2b.plan.options.policy.effective.date.label`}
                        />
                      </label>
                    </Grid>
                    <MuiDateTimePicker
                      disabled={readonly}
                      className={'plan-options-date-picker'}
                      value={policyEffectiveDate}
                      onChange={handleChangeDatePicker}
                      label={'STARTING FROM'}
                    />
                  </Grid>
                  <Grid
                    className='billing-cycle'
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ margin: '0.5rem 0rem' }}
                  >
                    <Grid item container mb={1}>
                      <label className='plan-options-section-labels'>
                        <MicrocopyComponent
                          labelKey={`q2b.plan.options.billing.cycle.label`}
                        />
                      </label>
                    </Grid>
                    <MuiDropDown
                      disabled={readonly}
                      className={'coverage-dropdown'}
                      id={'paymentMethodType'}
                      value={paymentMethodType}
                      schema={paymentSchema.schema}
                      onChange={handlePaymentChange}
                      label={'PAYMENT'}
                    />
                  </Grid>
                </Grid>
                <Box m={1} />
                <Grid container>
                  <Typography
                    className='heading-title'
                    variant='subtitle1'
                    component='h4'
                    style={{
                      fontWeight: 900,
                      fontSize: '20px',
                      letterSpacing: '4px',
                      textTransform: 'uppercase',
                      lineHeight: '40px',
                      fontFamily: 'Lato',
                    }}
                  >
                    <MicrocopyComponent
                      labelKey={`q2b.plan.options.the.packages.label`}
                    />
                  </Typography>
                </Grid>
                <Grid
                  className='plan-options-list'
                  container
                  spacing={2}
                  sx={{ marginBottom: '2.25rem' }}
                >
                  {listOfOptions}
                </Grid>
                <Grid className='plan-options_carousel' container>
                  <PlanOptionsCarousel
                    data={quote}
                    selectedId={selectedId}
                    handleChange={handlePlanOptionChange}
                    slides={listOfOptions}
                  />
                </Grid>
                <Grid className='sticky-class-wrapper'>
                  {quote[Number.parseInt(selectedId, 10) - 1]
                    ?.planDescription &&
                    quote[Number.parseInt(selectedId, 10) - 1]?.planDescription
                      .length > 0 && (
                      <Grid>
                        <Grid
                          container
                          className='plan-description'
                          sx={{ marginBottom: '2.25rem' }}
                        >
                          <Grid item>
                            <Typography
                              className='heading-title'
                              variant='subtitle1'
                              component='p'
                              style={{
                                fontWeight: 900,
                                fontSize: '20px',
                                letterSpacing: '4px',
                                textTransform: 'uppercase',
                                lineHeight: '40px',
                                fontFamily: 'Lato',
                              }}
                            >
                              {quote && quote.length > 0 && (
                                <MicrocopyComponent
                                  labelKey='q2b.plan.options.packages.coverage.examples.title'
                                  placeHolderKey='$CoverageName$'
                                  actualValue={
                                    quote[Number.parseInt(selectedId, 10) - 1]
                                      .planName
                                  }
                                />
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            columnGap: { md: '1rem' },
                            rowGap: 3,
                          }}
                          className='plan-descriptions'
                        >
                          <PlanDescription
                            descriptions={
                              quote[Number.parseInt(selectedId, 10) - 1]
                                ?.planDescription
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  {/* <PlanTelematics
                  disabled={readonly}
                  value={enrollTelematics}
                  submitBtnRef={submitBtnTelematicsRef}
                  updateFormDirtyFlag={handleIsDirty}
                  onValidateEnrollTelematics={onValidateEnrollTelematics}
                /> */}
                  <PlanCoverages
                    readonly={readonly}
                    selectedTabData={selectedTabData}
                    selectedCoverages={selectedCoverages} // user's selections using our screen
                    quote={quote} // defaults on page load
                    quoteIndex={Number.parseInt(selectedId, 10) - 1}
                    updateFormDirtyFlag={handleIsDirty}
                    updateCoverage={updateCoverage}
                    errorHandling={errorHandling}
                    submitBtnRef={submitBtnPlanCoveragesRef}
                    submitBtnRef3={submitBtnVehicleCoveragesRef}
                    onValidateTabs={onValidateTabs}
                    handleSelectedTabData={handleSelectedTabData}
                  />
                </Grid>

                <Grid
                  className={`plan-option-final-payment-grid ${stickyClass}`}
                  container
                  spacing={3}
                  sx={{ paddingTop: '32px' }}
                >
                  <div className='plan-option-underline'></div>
                  <Grid
                    className='plan-options-footer-wrapper'
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <Grid
                      className='payment-detail'
                      item
                      xs={6}
                      lg={7}
                      sx={{ fontFamily: 'Rubik' }}
                    >
                      <Typography
                        className='value'
                        // mb={2}
                        sx={{
                          fontWeight: 500,
                          fontFamily: 'Rubik',
                          color: 'black',
                          fontSize: '28px',
                          letterSpacing: '1px',
                          lineHeight: '26px',
                        }}
                      >
                        {quote[
                          Number.parseInt(selectedId, 10) - 1
                        ].planName?.toUpperCase()}
                      </Typography>
                      <Divider
                        style={{
                          width: '100%',
                          borderColor: '#000000',
                          borderWidth: 'thin',
                        }}
                      />
                      <Grid container>
                        {/*  <Typography
                        className='value'
                        sx={{
                          paddingRight: '4px',
                          fontWeight: 500,
                          verticalAlign: 'super',
                        }}
                      >
                        $
                      </Typography> */}
                        <Typography
                          className='value'
                          sx={{
                            fontWeight: 500,
                            fontSize: '1.875rem',
                            letterSpacing: '0.063em',
                          }}
                        >
                          <Typography
                            component={'span'}
                            className='value'
                            sx={{
                              paddingRight: '4px',
                              fontWeight: 500,
                              verticalAlign: 'super',
                            }}
                          >
                            $
                          </Typography>
                          {paymentMethodType?.toUpperCase() === PIF
                            ? currencyFormatter(
                                quote[Number.parseInt(selectedId, 10) - 1]
                                  .pifPremium
                              )
                            : currencyFormatter(
                                quote[Number.parseInt(selectedId, 10) - 1]
                                  .initialPremium
                              )}
                        </Typography>
                        <Box
                          sx={{ display: 'flex', marginLeft: '0.25rem' }}
                          alignItems={'flex-end'}
                        >
                          <Typography
                            className='method-type'
                            sx={{
                              fontFamily: 'Lato',
                              fontWeight: 700,
                              fontSize: '0.875rem',
                              lineHeight: '2.3rem',
                              letterSpacing: '0.063em',
                            }}
                          >
                            /{' '}
                            {`${
                              paymentMethodType?.toUpperCase() === PIF
                                ? 'six months'
                                : 'month'
                            }`}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      className='plan-option-button-grid'
                      sx={{
                        paddingTop: '32px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      item
                      xs={6}
                      lg={5}
                    >
                      <Button
                        id='continue'
                        className={'plan-option-button'}
                        type='submit'
                        color='secondary'
                        variant='contained'
                        onClick={handleSubmission}
                        endIcon={<ButtonArrow />}
                      >
                        {readonly ? (
                          <MicrocopyComponent
                            labelKey={`q2b.global.button.text.next`}
                          />
                        ) : formDirty ? (
                          <MicrocopyComponent
                            labelKey={`q2b.global.button.text.update.quote`}
                          />
                        ) : (
                          <MicrocopyComponent
                            labelKey={`q2b.global.button.text.purchase.now`}
                            customStyle={true}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Page>
    </Grid>
  );
}
