import { ReactComponent as Car1Cyan } from '../../images/Car1_Cyan.svg';
import { ReactComponent as Car2Yellow } from '../../images/Car2_Yellow.svg';
import { ReactComponent as Car3Blue } from '../../images/Car3_Blue.svg';
import { ReactComponent as Car4Red } from '../../images/Car4_Red.svg';

import React from 'react';
const CarAvatar = (props: any) => {
  return (() => {
    switch ((parseInt(props.index) + 1) % 4) {
      case 1:
        return <Car1Cyan style={props.style} />;
      case 2:
        return <Car2Yellow style={props.style} />;
      case 3:
        return <Car3Blue style={props.style} />;
      case 0:
        return <Car4Red style={props.style} />;
      default:
        return <Car1Cyan style={props.style} />;
    }
  })();
};

export default CarAvatar;
