import { Button, Grid } from '@mui/material';
import { AjvError, IChangeEvent, ISubmitEvent } from '@rjsf/core';
//import { AlertProps } from 'types/GenericTypes';
import ButtonArrow from 'components/icons/ButtonArrow';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm from 'framework/forms/NovoForm';
import { /* novoNavigation, */ novoRoutes } from 'framework/routes';
/* import { retrieveApplicationServiceCall } from 'library/services/utils'; */
import React, { useEffect, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import AlertComponent from 'components/AlertComponent';
import { infoMessages } from 'messages';
import Header from 'framework/components/Header';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';

export interface RetrieveQuoteProps {
  toClose?: any;
}

export default function RetrieveQuote({ toClose }: RetrieveQuoteProps) {
  const routeKey = 'RetrieveQuote';
  const [initialFormData, setInitialFormData] = useState({});
  const [info, showInfo] = useState(false);
  /* const [error, setError] = useState<AlertProps>({}); */
  //const navigate = useNavigate();
  const novoRoute = novoRoutes[routeKey];

  useEffect(() => {
    let formData: any = {};
    const dataForFormData = getData(routeKey);
    if (dataForFormData?.driver?.driverId) {
      showInfo(true);
      formData = { ...formData, email: dataForFormData.driver.email };
    }
    setInitialFormData(formData);
  }, []);

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    /* let res: boolean = false;
    const status = await retrieveApplicationServiceCall(formData);
    res = status.success;
    if (res === true) {
      if (toClose) toClose();
      navigate(novoNavigation.InitialInfo.nextPage);
    } else {
      let message = status.message;
      showInfo(false);
      setError({
        message,
      });
    } */
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  return (
    <Grid container className='quote-wrapper quote-field'>
      <Header
        routeKey={routeKey}
        className={novoRoute.displayPageCubes ? 'header-underline_drum' : ''}
      />
      {info && (
        <AlertComponent
          message={infoMessages.overrideExistingApplication}
          type='info'
        />
      )}
      <NovoForm
        alert={{}}
        schemaName={routeKey}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        formData={initialFormData}
        button={
          <Button
            id='button'
            className='btn-arrow'
            type='submit'
            color='secondary'
            variant='contained'
            disabled={false}
            endIcon={<ButtonArrow />}
          >
            <MicrocopyComponent labelKey={`q2b.global.button.text.next`} />
          </Button>
        }
      />
    </Grid>
  );
}
