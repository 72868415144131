import { Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { getCompletedStep } from 'components/RoutesContainer';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CheckMark } from '../../images/check-mark.svg';
import './LeftNav.scss';

interface LeftNavProps {
  activeStepIndex: number;
  totalStep?: number;
}

export type Status = 'incomplete' | 'active' | 'complete';

export interface IStep {
  title: string;
  path: string;
}

export const steps: IStep[] = [
  {
    title: 'q2b.left.navigation.step.1',
    path: '/initial-info',
  },
  {
    title: 'q2b.left.navigation.step.2',
    path: '/drivers',
  },
  {
    title: 'q2b.left.navigation.step.3',
    path: '/cars',
  },
  {
    title: 'q2b.left.navigation.step.4',
    path: '/plan-options/customize-coverage',
  },
  {
    title: 'q2b.left.navigation.step.5',
    path: '/disclosures',
  },
];

export default function LeftNav({ activeStepIndex }: LeftNavProps) {
  const stepReached = getCompletedStep();

  return (
    <Stepper
      alternativeLabel
      orientation='vertical'
      activeStep={activeStepIndex}
      sx={{ minHeight: 125, minWidth: '100%', marginTop: 0 }}
    >
      {steps.map((step, index) => {
        const status: Status =
          index === activeStepIndex
            ? 'active'
            : index < stepReached - 1
            ? 'complete'
            : 'incomplete';

        return (
          <React.Fragment key={index}>
            <Link
              to={step.path}
              className={`left-nav_step ${status}`}
              aria-disabled={status === 'incomplete'}
            >
              <div className='step-border'>
                <Step
                  key={index}
                  sx={{
                    minHeight: 105,
                    flexDirection: 'row',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    console.log('Left Nav: Step Clicked: ' + index + 1)
                  }
                >
                  {
                    <Typography className='step-icon'>
                      {status !== 'complete' ? `${index + 1}.` : <CheckMark />}
                    </Typography>
                  }
                  <StepLabel
                    className='step-title'
                    sx={{ flexDirection: 'row !important' }}
                  >
                    <MicrocopyComponent labelKey={step.title} />
                  </StepLabel>
                </Step>
              </div>
            </Link>
          </React.Fragment>
        );
      })}
    </Stepper>
  );
}
