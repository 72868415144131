import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PlanOptionsCarousel.scss';

interface PlanOptionsCarouselProps {
  slides: JSX.Element[];
  handleChange: (id: string) => void;
  data: any[];
  selectedId: string;
}

export default function PlanOptionsCarousel({
  data,
  slides,
  handleChange,
  selectedId,
}: PlanOptionsCarouselProps) {
  return (
    <>
      <div className='plan-options-carousel' id='plan-options-carousel'>
        {slides}
      </div>
      <div className='control-dots'>
        {slides.map((step, index) => {
          const handleSelected = (
            e:
              | React.MouseEvent<Element, MouseEvent>
              | React.KeyboardEvent<Element>
          ) => {
            handleChange(`${data[index].id}`);
          };
          if ((index + 1).toString() === selectedId) {
            return (
              <li
                className={'is-selected'}
                aria-label={`Selected: ${index + 1}`}
                key={`Selected: ${index + 1}`}
              />
            );
          }
          return (
            <li
              onClick={handleSelected}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSelected(e);
                }
              }}
              value={index}
              key={index}
              role='button'
              tabIndex={0}
              aria-label={`carousel-nav-${index + 1}`}
            />
          );
        })}
      </div>
    </>
  );
}
