import Bugsnag from '@bugsnag/js';
import { v4 as uuidv4 } from 'uuid';
import { GA4React } from 'ga-4-react';
import { getPartnerUrlParams, Partner } from './queryParams';
import { string } from 'yup';
import { getJson } from 'framework/dataService';

const TRACKING_URL = process.env.REACT_APP_UNVANITY_WEBHOOK || '';
if (!TRACKING_URL) throw new Error('Missing track webhook url');
const GA4_MEASUREMENT_ID =
  process.env.REACT_APP_ENV === 'production' ? 'G-7EG67Z4X36' : 'G-3JPMH3TE9M';

// Typings to copy into tracking webhook

type TrackingEvent = 'formProgress' | 'paymentSuccess';
interface PageViewProperties {
  page: string;
}
interface PaymentSuccessProperties {
  policyId: string;
  page: string;
  paidInFull: string;
  balance: number;
  dateEffective: string;
  paymentAmount: number;
  paymentCategory: string;
  holderZip: string;
  currency: string;
  policyTotal: number;
  policyFees: number;
  policyHolderId: string;
  transactionId: string;
  everflowId: string;
}
type TrackCallProperties = PageViewProperties | PaymentSuccessProperties;
interface Payload {
  pageLocation: string;
  pageReferrer: string;
  pageTitle: string;
  language: string;
  clientId: string;
  userID?: string;
  applicationId: string;
  sessionId: string;
  event: TrackingEvent;
  partner?: Partner;
  brand?: String;
  everflow: {
    ef_transaction_id?: string | null;
    rate_id?: string | null;
    policy_id?: string | null;
  };
  utm: {
    source?: string | null;
    medium?: string | null;
    campaign?: string | null;
    term?: string | null;
    content?: string | null;
  };
  properties?: TrackCallProperties;
}

// End of Typings

class TrackingService {
  private clientId: string = '';

  private userID: string | undefined;

  private partnerData: Partner;

  private ga4react: GA4React;

  private cliendIDReady: Promise<void>;

  constructor() {
    if (window.sessionStorage.getItem('partner')) {
      this.partnerData = JSON.parse(
        window.sessionStorage.getItem('partner') as string
      ) as Partner;
    } else {
      const newPartner = getPartnerUrlParams(
        window.location.href,
        document.referrer
      );
      window.sessionStorage.setItem('partner', JSON.stringify(newPartner));
      this.partnerData = newPartner;
    }
    this.ga4react = new GA4React(
      GA4_MEASUREMENT_ID,
      {
        send_page_view: false,
      },
      [],
      5000
    );
    this.cliendIDReady = new Promise((resolve) => {
      this.ga4react
        .initialize()
        .then((value) => {
          try {
            value.gtag(
              'get',
              GA4_MEASUREMENT_ID,
              'client_id',
              (client_id: string) => {
                if (!client_id) {
                  this.clientId = this.getAnonID();
                  window.sessionStorage.setItem('gaClientId', this.clientId);
                  // console.log('Got no clientid, setting anon id', this.clientId)
                } else {
                  this.clientId = client_id;
                  window.sessionStorage.setItem('gaClientId', this.clientId);
                  // console.log('Set client id', this.clientId)
                }
                resolve();
              }
            );
          } catch (err) {
            // console.log(`Setting anon ID ${err}`)
            this.clientId = this.getAnonID();
            resolve();
          }
        })
        .catch((err) => {
          // console.log(`Setting anon ID`)
          this.clientId = this.getAnonID();
          resolve();
        });
    });
  }

  private getAnonID(): string {
    const sessionId = window.localStorage.getItem('anonIdentifier');
    if (sessionId) return sessionId;
    // Typescript typings imply this can return null?
    let newSessionId = uuidv4();
    if (!newSessionId) {
      // This should never happen. Documentation for the library says that it always returns a string, but typescript says it can return null
      // If it returns null in production consider using a different uuidv4 library
      console.log('Session ID creation returned null, using a random string');
      Bugsnag.notify({
        name: 'Tracking Webhook Error',
        message: 'Session ID creation returned null, using a random string',
      });
      newSessionId = Math.random().toString(36).slice(2);
    }
    window.localStorage.setItem('anonIdentifier', newSessionId);
    return newSessionId as string;
  }

  // To be called once we have a source of truth identifier that is used to identify a user. Currently this is the policy id
  setUserID(userID: string) {
    this.userID = userID;
  }

  public async trackEvent(
    event: TrackingEvent,
    properties?: TrackCallProperties
  ) {
    if (!this.clientId) await this.cliendIDReady;
    if (properties) {
      const undefinedProps: string[] = [];
      Object.entries(properties).forEach(([k, v]) => {
        if (v === undefined || v === null) undefinedProps.push(k);
      });
      if (undefinedProps.length)
        Bugsnag.notify({
          name: 'Tracking Webhook Error',
          message: `Sending ${undefinedProps.join(', ')} pops as undefined`,
        });
    }
    if (!window.sessionStorage.getItem('sessionId')) {
      const sessionId = uuidv4();
      window.sessionStorage.setItem('sessionId', sessionId);
    }
    const sessionId = window.sessionStorage.getItem('sessionId');
    const applicationId = window.sessionStorage.getItem('applicationId');
    const payload: Payload = {
      clientId: this.clientId,
      userID: this.userID || undefined,
      sessionId: sessionId || 'none',
      applicationId: applicationId || 'none',
      event,
      partner: this.partnerData,
      pageLocation: window.location.pathname,
      pageReferrer: document.referrer,
      pageTitle: document.title,
      language: navigator.language,
      brand: 'motion',
      everflow: {},
      utm: {},
      properties,
    };

    const urlOrigin = window.location.origin;
    const brand = urlOrigin.includes('novo') ? 'novo' : 'motion';
    if (brand) {
      payload.brand = brand;
    }

    const queryString = window.location.search;
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      payload.utm.source = urlParams.get('utm_source');
      payload.utm.medium = urlParams.get('utm_medium');
      payload.utm.campaign = urlParams.get('utm_campaign');
      payload.utm.term = urlParams.get('utm_term');
      payload.utm.content = urlParams.get('utm_content');

      if (urlParams.get('ef_transaction_id')) {
        payload.everflow.ef_transaction_id = urlParams.get('ef_transaction_id');
        sessionStorage.setItem('everflow', JSON.stringify(payload.everflow));
      }
    }

    const policyInfo = getJson('policy');
    if (sessionStorage.getItem('everflow')) {
      const everflow = JSON.parse(sessionStorage.getItem('everflow') || '{}');
      payload.everflow.ef_transaction_id = everflow?.ef_transaction_id;
      payload.everflow.rate_id = sessionStorage.getItem('applicationId') || '';
      payload.everflow.policy_id =
        policyInfo && policyInfo.policyId ? policyInfo.policyId : '';
    }

    fetch(TRACKING_URL, {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json,text/*',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          // console.log(`Track failed for ${event}, code ${res.status}`, payload);
          return Bugsnag.notify({
            name: 'Tracking Webhook Error',
            message: `Got ${res.status} response from tracking webhook`,
          });
        }
        // Event tracking
        // console.log(`Tracked ${event}`, payload);
      })
      .catch((e) => {
        // console.log(`Track failed for ${event}`, e);
        Bugsnag.notify({
          name: 'Tracking Webhook Error',
          message: `Fetch request error: ${e.message || e}`,
        });
      });
  }
}

export default new TrackingService();
