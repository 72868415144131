import { Box, BoxProps } from '@mui/material';
import { WidgetProps } from '@rjsf/core';
import React from 'react';
import Label from './Label';
import MuiButton from './MuiButton';

interface EnumOption {
  label: string;
  value: string;
}

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        minWidth: 140,
        ...sx,
      }}
      {...other}
    />
  );
}

const ToggleButton = (props: WidgetProps) => {
  const {
    id,
    disabled,
    options,
    onChange,
    value,
    label,
    required,
    isError,
    uiSchema,
  } = props;

  const enumOptions: EnumOption[] = options.displayOptions
    ? JSON.parse(JSON.stringify(options.displayOptions))
    : JSON.parse(JSON.stringify(options.enumOptions));

  const handleClick = (data: any) => {
    onChange(data);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        p: 0,
        bgcolor: 'background.paper',
        borderRadius: 1,
      }}
    >
      <Item sx={{ flexGrow: 1 }}>
        <Label {...{ disabled, label, required, isError, uiSchema }} />
      </Item>
      {enumOptions[0] && (
        <Item>
          <MuiButton
            id={`${id}_${enumOptions[0].label}`}
            disabled={disabled}
            className={`toggle-button-class mr-0_25 ${
              uiSchema.className ? uiSchema.className : ''
            } ${enumOptions[0].value === value ? 'selected' : ''}`}
            text={enumOptions[0].label}
            variant={'outlined'}
            onClick={() => handleClick(enumOptions[0].value)}
            type={'button'}
            isError={isError}
          />
        </Item>
      )}
      {enumOptions[1] && (
        <Item sx={{ ml: 1 }}>
          <MuiButton
            id={`${id}_${enumOptions[1].label}`}
            className={`toggle-button-class ml-0_25 ${
              uiSchema.className ? uiSchema.className : ''
            } ${enumOptions[1].value === value ? 'selected' : ''}`}
            disabled={disabled}
            text={enumOptions[1].label}
            variant={'outlined'}
            onClick={() => handleClick(enumOptions[1].value)}
            type={'button'}
            isError={isError}
          />
        </Item>
      )}
    </Box>
  );
};

export default ToggleButton;
