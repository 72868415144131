import { Grid, Typography } from '@mui/material';
import React from 'react';
import CoverageIcon, { isValidIconName } from 'components/icons/CoverageIcon';

interface IPlanDescription {
  description: string;
  icon?: string;
}
interface PlanDescriptionProps {
  descriptions: IPlanDescription[];
}
const PlanDescription: React.FC<PlanDescriptionProps> = ({ descriptions }) => {
  const listDescription = descriptions?.map((item, index: number) => (
    <React.Fragment key={index}>
      {item?.description && Boolean(item?.description) && (
        <Grid
          item
          container
          flexWrap='nowrap'
          xs={12}
          lg={6}
          columnSpacing={3}
          sx={{
            flexGrow: { xs: '0', md: '1 !important' },
            ml: '0 !important',
            maxWidth: { xs: '100%', lg: 'calc((100% / 2) - 0.5rem)' },
          }}
        >
          {item.icon && Boolean(item.icon) && isValidIconName(item.icon) && (
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'center'}
              id={index.toString()}
              sx={{
                ml: '0 !important',
                pl: '0 !important',
                width: { xs: '5rem', md: '10rem' },
                height: { xs: '5rem', md: '6.875rem' },
                backgroundColor: '#F1F2F5',
                backgroundImage: 'url("/stroke-bg.svg")',
                backgroundPosition: { xs: '50% 0%', md: 'top left' },
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CoverageIcon className='coverage-icon' icon={item.icon} />
            </Grid>
          )}
          <Grid
            item
            id={index.toString()}
            sx={{
              flex: '1 1 0',
              ml: '0 !important',
              pl: 2,
              ...(index > descriptions?.length - 1 && { mr: 3 }),
            }}
          >
            <Typography className='text-line-top_small' variant='h5'>
              {item.description}
            </Typography>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  ));

  return <>{listDescription}</>;
};

export default PlanDescription;
