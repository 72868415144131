import React from 'react';

export default function ButtonArrow() {
  return (
    <div aria-hidden='true' className='arrow'>
      <div className='mover' style={{ backgroundColor: 'currentColor' }}></div>
      <svg
        className='longer'
        width='18'
        height='17'
        viewBox='0 0 18 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.9998 15L2.43457 2'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
      </svg>
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2 2H38L24.4348 15'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
      </svg>
    </div>
  );
}
