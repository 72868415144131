import { Dialog, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import MicrocopyComponent from '../MicrocopyComponent';
import MuiButton from '../MuiButton';

interface SimpleDialogProps {
  showDialog: boolean;
  handleDialogClose?: any;
  content: any;
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  showDialog,
  handleDialogClose,
  content,
}) => {
  const getContent = (msg: string) => {
    if (msg.startsWith('q2b.')) {
      return (
        <div className='msgItem' key={msg}>
          <MicrocopyComponent labelKey={msg} />
        </div>
      );
    }
    return <div className='msgItem'>{msg}</div>;
  };
  const getContentsSection = () => {
    const contentsList: any[] = [];
    content.messages.forEach((msg: string) => {
      contentsList.push(getContent(msg));
    });

    return contentsList;
  };

  return (
    <Dialog onClose={() => handleDialogClose()} open={showDialog}>
      <Grid container sx={{ padding: '40px 55px', border: '10px solid #000' }}>
        <>
          <Typography
            variant='h1'
            component='h1'
            className={'page-header-h text-line-bottom'}
          >
            <MicrocopyComponent labelKey={content.title} />
          </Typography>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='subtitle2' className={'page-header-h2'}>
              {getContentsSection().map((item) => item)}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <MuiButton
                id='continue'
                className='btn-gray btn-w-unset'
                type='submit'
                color='secondary'
                variant='contained'
                text={
                  <MicrocopyComponent
                    labelKey={`q2b.global.button.text.close`}
                  />
                }
                onClick={() => handleDialogClose()}
              />
            </Grid>
          </Grid>
        </>
      </Grid>
    </Dialog>
  );
};

export default SimpleDialog;
