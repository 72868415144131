import React from 'react';
import './PageFooter.scss';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';

const PageFooter: React.FC = () => {
  return (
    <div className='footer-wrapper'>
      <div className='footer-copyright'>
        {/* <span>
          {`© ${new Date().getFullYear()}`} <strong>Novo Insurance</strong>,
          LLC.
        </span>
        <span>All Rights Reserved.</span> */}
        <MicrocopyComponent
          labelKey='q2b.left.navigation.footer'
          placeHolderKey='$YEAR$'
          actualValue={new Date().getFullYear()}
        />
      </div>
    </div>
  );
};

export default PageFooter;
