const trackingData = window.sessionStorage.getItem('tracking');
const trackingDataJson = JSON.parse(trackingData!);

export interface Partner {
  partner: string | null;
  trackingId: string | null;
  publisher: string | null;
  campaign: string | null;
  channel: string | null;
  policyId: string | null;
  url: string | null;
  agentId: string | null;
  state: string | null;
  visitorId: string | null;
}

const partnerData: Partner = {
  partner: null,
  trackingId: null,
  publisher: null,
  campaign: null,
  channel: null,
  policyId: null,
  url: null,
  agentId: null,
  state: null,
  visitorId: null,
};

/*
 * Parse partner url params
 */
export const getPartnerUrlParams = (url: string, referrer: string) => {
  const params = url.split('?')[1];
  const paramsArray = new URLSearchParams(params);
  partnerData.url = referrer;

  partnerData.partner = getPartner(paramsArray);
  partnerData.publisher = getPublisher(paramsArray);
  partnerData.campaign = getCampaign(paramsArray);
  partnerData.trackingId = getTrackingId(paramsArray);
  partnerData.channel = getChannel(paramsArray);
  partnerData.agentId = getAgentId(paramsArray);
  partnerData.state = getState(paramsArray);

  return partnerData;
};

const getState = (paramsArray: URLSearchParams) => {
  let result = null;
  const state = paramsArray.get('state');
  const stateName = paramsArray.get('state_name');
  const utmContent = paramsArray.get('utm_content');

  if (state !== null && result === null) {
    result = state;
  } else if (stateName !== null && result === null) {
    result = stateName;
  } else if (utmContent !== null && result === null) {
    result = utmContent;
  }

  if (result !== null) {
    result = result.toLowerCase();
  }

  return result;
};

const getAgentId = (paramsArray: URLSearchParams) => {
  let result = '0';
  const agentId = paramsArray.get('agent_id');

  if (agentId !== null && result === '0') {
    result = agentId;
  }

  return result;
};

const getChannel = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const channel = paramsArray.get('channel');

  if (channel !== null && result === null) {
    result = channel;
  }

  return result;
};

const getTrackingId = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const token = paramsArray.get('token');
  const clickKey = paramsArray.get('clickkey');
  const subValue = paramsArray.get('sub1');

  if (token !== null && result === null) {
    result = token;
  } else if (clickKey !== null && result === null) {
    result = clickKey;
  } else if (subValue !== null && result === null) {
    result = subValue;
  } else if (trackingDataJson && trackingDataJson.quoteId) {
    result = trackingDataJson.quoteId;
  }

  return result;
};

const getCampaign = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const campaign = paramsArray.get('campaign');
  const utmCampaign = paramsArray.get('utm_campaign');

  if (campaign !== null && result === null) {
    result = campaign;
  } else if (utmCampaign !== null && result === null) {
    result = utmCampaign;
  } else if (trackingDataJson && trackingDataJson.marketSegmentId) {
    result = trackingDataJson.marketSegmentId;
  }

  return result;
};

const getPublisher = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const publisher = paramsArray.get('publisher');
  const utmMedium = paramsArray.get('utm_medium');
  const medium = paramsArray.get('medium');
  const sourceId = paramsArray.get('source_id');

  if (publisher !== null && result === null) {
    result = publisher;
  } else if (medium !== null && result === null) {
    result = medium;
  } else if (utmMedium !== null && result === null) {
    result = utmMedium;
  } else if (sourceId !== null && result === null) {
    result = sourceId;
  }

  if (result !== null) {
    result = result.toLowerCase();
  }

  return result;
};

const getPartner = (paramsArray: URLSearchParams) => {
  let result: string | null = null;
  const source = paramsArray.get('source');
  const utmSource = paramsArray.get('utm_source');
  const partner = paramsArray.get('partner');

  if (source !== null && result === null) {
    result = source;
  } else if (utmSource !== null && result === null) {
    result = utmSource;
  } else if (partner !== null && result === null) {
    result = partner;
  }

  if (result !== null) {
    result = result.toLowerCase();
  }

  return result;
};
