import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';

export interface CommonHeaderProps {
  heading1: string;
  heading2?: string;
  placeHolderText?: string;
  actualValue?: string;
  showLine?: boolean;
}

// eslint-disable-next-line max-len
const CommonHeader: React.FC<CommonHeaderProps> = ({
  heading1,
  heading2,
  placeHolderText,
  actualValue,
  showLine = false,
}) => {
  return (
    <>
      {heading1 && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant='h2' component='h2' className={'common-header-h'}>
            {/* {heading1} */}
            <MicrocopyComponent
              labelKey={heading1}
              placeHolderKey={placeHolderText}
              actualValue={actualValue}
            />
          </Typography>
        </Grid>
      )}
      {((heading1 && heading2) || showLine) && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box mb={4} className='text-line-bottom '></Box>
        </Grid>
      )}
      {heading2 && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant='subtitle2'
            component='h2'
            className={'page-header-h2'}
          >
            {/* {heading2} */}
            <MicrocopyComponent
              labelKey={heading2}
              placeHolderKey={placeHolderText}
              actualValue={actualValue}
            />
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default CommonHeader;
