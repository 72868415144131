import { getDrivers } from 'framework/dataService/drivers';
import { getLocationById } from 'framework/dataService/locations';
import { CustomJsonSchemaProps } from 'framework/forms/interfaces/CustomJsonSchemaProps';
import { CsrRestrictionValidation } from 'framework/forms/lib/CsrRestrictionValidation';
import Validate from 'framework/forms/validations/Validate';
import { /*  novoNavigation, */ novoRoutes } from 'framework/routes';
import getFormDefinitions from 'framework/schemaServer';
import {
  nonPrimaryDriverFormDefinitions,
  primaryDriverFormDefinitions,
} from './AccordionFormDefinitions';
import { getIncidentCode } from './IncidentFormat';

const getDriverFormData = (formData: any, formName: string) => {
  // prepare formData
  let driverDataToValidate = formData;
  switch (formName) {
    case 'DriverResidenceInfo':
      driverDataToValidate = formData.residenceInfo;
      if (driverDataToValidate?.locationId) {
        const location = getLocationById(driverDataToValidate.locationId);
        if (location?.fullAddress) {
          driverDataToValidate.location = location;
        }
      }
      break;
    case 'PrimaryDriverInsuranceInfo':
      driverDataToValidate = formData.insuranceDetails;
      break;
    case 'DriverInsuranceInfo':
      driverDataToValidate = formData.insuranceDetails;
      break;
    case 'DrivingHistory':
      driverDataToValidate = formData.drivingHistory;
      break;
  }
  return driverDataToValidate;
};

export const findFirstIncompleteDriverForm = (driver: any) => {
  const BreakException = {};
  let incompleteFormIndex;
  let formCompletionPercentage = 100;
  try {
    // when driver is included in the policy
    const formsList = driver?.primary
      ? primaryDriverFormDefinitions()
      : nonPrimaryDriverFormDefinitions();
    formsList.forEach((form, formIndex) => {
      // retrieve schema
      const { schema } = getFormDefinitions(form.formName || '');
      const driverDataToValidate = getDriverFormData(
        driver,
        form.formName || ''
      );
      // validate formData
      let errors = Validate(driverDataToValidate, schema);
      console.debug('errors', errors);
      if (!errors) {
        if (driverDataToValidate.isDataReviewed !== undefined) {
          // validate if isDataReviewed is true (if present)
          // will be true once driver's section forms are submitted by user
          errors = driverDataToValidate.isDataReviewed === false;
        }
      }

      if (errors) {
        incompleteFormIndex = formIndex;
        formCompletionPercentage = Math.ceil(
          (formIndex / formsList.length) * 100
        );
        if (formCompletionPercentage === 0) {
          formCompletionPercentage = 15;
        }
        console.debug('formCompletionPercentage: ', formCompletionPercentage);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }
  console.debug('First Incomplete Driver Form: ', incompleteFormIndex);
  return { incompleteFormIndex, formCompletionPercentage };
};

export const findFirstIncompleteDriver = (drivers: any[]) => {
  const BreakException = {};
  let firstIncompleteDriverIndex;
  let firstIncompleteFormIndex;
  try {
    drivers.forEach((driverFormData, driverIndex) => {
      if (driverFormData.included) {
        // when driver is included in the policy
        // validate formData
        const { incompleteFormIndex } =
          findFirstIncompleteDriverForm(driverFormData);
        if (incompleteFormIndex !== undefined) {
          firstIncompleteFormIndex = incompleteFormIndex;
          firstIncompleteDriverIndex = driverIndex;
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw BreakException;
        }
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }
  console.debug('First Incomplete Driver Index: ', firstIncompleteDriverIndex);
  return { firstIncompleteDriverIndex, firstIncompleteFormIndex };
};

const findFirstRestrictedDriverForm = (formData: any) => {
  const BreakException = {};
  let restrictedFormIndex;
  try {
    // when driver is included in the policy
    const formsList = formData?.primary
      ? primaryDriverFormDefinitions()
      : nonPrimaryDriverFormDefinitions();
    formsList.forEach((form, formIndex) => {
      // retrieve schema
      const { schema } = getFormDefinitions(form.formName || '');
      const driverDataToValidate = getDriverFormData(
        formData,
        form.formName || ''
      );
      // validate formData
      if (
        CsrRestrictionValidation(
          schema as CustomJsonSchemaProps,
          driverDataToValidate
        )
      ) {
        restrictedFormIndex = formIndex;
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }
  return { restrictedFormIndex };
};
export const findFirstRestrictedDriver = () => {
  const drivers = getDrivers() as any[];
  const BreakException = {};
  let firstRestrictedDriverIndex = -1;
  let firstRestrictedFormIndex = -1;
  try {
    drivers.forEach((driverFormData, driverIndex) => {
      if (driverFormData.included) {
        // when driver is included in the policy
        // validate formData
        const { restrictedFormIndex } =
          findFirstRestrictedDriverForm(driverFormData);
        if (restrictedFormIndex !== undefined) {
          firstRestrictedFormIndex = restrictedFormIndex;
          firstRestrictedDriverIndex = driverIndex;
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw BreakException;
        }
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }
  return {
    firstRestrictedDriverIndex,
    firstRestrictedFormIndex,
  };
};
export const calculateDestination = (
  routeKey: string,
  to?: string | null,
  defaultPath?: string
) => {
  console.debug('routKey', routeKey + 'to', to + 'defaultPath', defaultPath);
  let path = novoRoutes.Drivers.path;
  if (to && to.length > 0) {
    path = to;
  } else {
    // calculate destination
    const drivers = getDrivers();
    const { firstIncompleteDriverIndex } = findFirstIncompleteDriver(drivers);
    if (firstIncompleteDriverIndex !== undefined) {
      // next button will take user to complete this driver data
      path = novoRoutes.EditDriver.path;
      path = path.replace(
        ':driverId',
        drivers[firstIncompleteDriverIndex].driverId
      );
    } else if (defaultPath) {
      path = defaultPath;
    }
  }
  return path;
};

export const isUnevenMarriedDriversCount = () => {
  const drivers = getDrivers() as any[];
  let marriedDriversCount = 0;

  drivers.forEach((driverFormData, driverIndex) => {
    if (driverFormData.included) {
      // when driver is included in the policy
      if (driverFormData.maritalStatus === 'Married') {
        marriedDriversCount += 1;
      }
    }
  });
  return marriedDriversCount % 2 > 0;
};

export const incidentRiskValidation = (incidentData: any) => {
  const majorAndDwiThreshold = 1;
  const accidentsThreshold = 2;
  const minorAndSpeedingThreshold = 3;
  let majorAndDwiCount = 0;
  let accidentsCount = 0;
  let minorAndSpeedingCount = 0;
  let overThreshold = false;
  incidentData.length > 0 &&
    incidentData.forEach((i: any) => {
      let incidentDataFormat = i;
      if (!i.category || i.category === '') {
        incidentDataFormat = getIncidentCode(i);
      }
      if (i.code && i.code !== 'UDR') {
        if (
          incidentDataFormat.category.toLowerCase().indexOf('dwi') >= 0 ||
          incidentDataFormat.category.toLowerCase().indexOf('maj') >= 0
        ) {
          majorAndDwiCount += 1;
        }
        if (
          incidentDataFormat.category.toLowerCase().indexOf('min') >= 0 ||
          incidentDataFormat.category.toLowerCase().indexOf('spd') >= 0
        ) {
          minorAndSpeedingCount += 1;
        }
        if (incidentDataFormat?.type?.toLowerCase() === 'accident') {
          accidentsCount += 1;
        }
      }
      overThreshold = majorAndDwiCount > majorAndDwiThreshold;
      overThreshold = overThreshold || accidentsCount > accidentsThreshold;
      overThreshold =
        overThreshold || minorAndSpeedingCount > minorAndSpeedingThreshold;
    });
  return overThreshold;
};

export const multiDriverIncidentRiskValidation = () => {
  const drivers = getDrivers() as any[];
  const check: any =
    drivers.length > 0 &&
    drivers.map((d: any) => {
      const incidentList =
        d.reconciliation &&
        d.reconciliation.claimHistory &&
        d.reconciliation.claimHistory[0] &&
        d.reconciliation.claimHistory[0]['incident-list']
          ? d.reconciliation.claimHistory[0]['incident-list']
          : [];
      if (incidentList.length > 0) {
        return incidentRiskValidation(incidentList);
      }
    });
  return check.includes(true);
};

export const marriedSpouseRule = (drivers: any) => {
  const pniDriverInfo = drivers.filter(
    (d: any) => d.relationToPrimaryDriver.toLowerCase() === 'self'
  )[0];
  let hasMarriedPni = false;
  if (Object.keys(pniDriverInfo).length > 0) {
    hasMarriedPni = ['married', 'm'].includes(
      pniDriverInfo.maritalStatus?.toLowerCase()
    );
  }
  const hasPniSpouse = drivers.some(
    (d: any) => d.relationToPrimaryDriver.toLowerCase() === 'spouse'
  );
  const hasMarriedPniAndSpouse = (marriedPni: boolean, pniSpouse: boolean) => {
    return marriedPni && pniSpouse;
  };
  const marriedPniAndSpouse = hasMarriedPniAndSpouse(
    hasMarriedPni,
    hasPniSpouse
  );
  if (hasMarriedPni && !marriedPniAndSpouse) {
    return true;
  }
  return false;
};

export const driverLicenseRule = (drivers: any) => {
  if (drivers.length > 1) {
    const dlAndStateCount: any = {};
    drivers.forEach((item: any) => {
      const key = `${item.dlNumber.toLowerCase()}-${item.licenseState.toLowerCase()}`;
      dlAndStateCount[key] = (dlAndStateCount[key] || 0) + 1;
    });
    if (Object.keys(dlAndStateCount).length !== drivers.length) {
      return true;
    }
  }
  return false;
};

export const driverValidation = () => {
  const drivers = getDrivers() as any[];
  let shouldStopQuote: any = false;
  let validationMessage: any = '';
  let fieldType: any = '';
  let marriedCount = 0;
  drivers.length > 0 &&
    drivers
      .filter((d) => d.included)
      .map((d: any) => {
        // Odd number married driver rule
        if (
          d?.maritalStatus?.toLowerCase() === 'married' ||
          d?.maritalStatus?.toLowerCase() === 'm'
        ) {
          marriedCount = marriedCount + 1;
        }
      });

  const incidentValidation = multiDriverIncidentRiskValidation();
  const marriedSpouseValidation = marriedSpouseRule(drivers);
  const driverLicenseValidation = driverLicenseRule(drivers);
  if (incidentValidation) {
    shouldStopQuote = true;
    fieldType = 'Incident';
  }
  if (marriedSpouseValidation) {
    shouldStopQuote = true;
    fieldType = 'Married';
  }
  if (driverLicenseValidation) {
    shouldStopQuote = true;
    validationMessage = 'q2b.modal.same.license.drivers.message';
    fieldType = 'DriverLicense';
  }
  if (marriedCount !== 0 && marriedCount % 2 !== 0) {
    shouldStopQuote = true;
    validationMessage = 'q2b.modal.uneven.unmarried.drivers.message';
    fieldType = 'Odd number of Married';
  }
  return {
    shouldStopQuote,
    validationMessage,
    fieldType,
  };
};
