import React from 'react';
import Label from 'framework/components/Label';
import { FieldProps } from '@rjsf/core';

const LabelField = (props: FieldProps) => {
  const { rawErrors } = props;
  return (
    <Label
      {...{
        ...props,
        isError: rawErrors && rawErrors.length > 0,
      }}
    />
  );
};

export default LabelField;
