export const baseMockUrl = process.env.REACT_APP_Q2B_V2_MOCK_SERVICE_URL;
export const producerUrl = process.env.REACT_APP_PRODUCER_REDIRECT_URL;
export const baseServiceUrl = process.env.REACT_APP_Q2B_V2_SERVICE_URL;
export const socialLoginAppIdForFacebook =
  process.env.REACT_APP_Q2B_V2_FACEBOOK_APP_ID;
export const socialLoginAppIdForGoogle =
  process.env.REACT_APP_Q2B_V2_GOOGLE_APP_ID;
export const appEnv = process.env.REACT_APP_ENV;
export const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
export const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
export const envId = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID;
export const apiKey = process.env.REACT_APP_X_API_KEY;
