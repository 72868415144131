import { getVehicles, getVehicleById, setVehicle } from './vehicles';

export const getCurrentVehicle = (vehicleId: string) => {
  let currentVehicle = getVehicleById(vehicleId);
  if (!currentVehicle) {
    currentVehicle = {
      vehicleId: -1,
      included: true,
    };
  }
  return currentVehicle;
};

export const getCurrentVehicleByVin = (vin: string) => {
  let vehicles = getVehicles();
  let matchingVIN;
  if (vehicles && vehicles.length > 0) {
    matchingVIN = vehicles.find((vehicle: any) => {
      return vehicle.vin === vin;
    });
    if (matchingVIN) {
      return matchingVIN;
    }
  }
  return undefined;
};

export const setCurrentVehicle = (vehicleId: number, value: any) => {
  let currentVehicle = { ...value };
  let vehicles = getVehicles();
  if (vehicles && vehicles.length > 0) {
    let matchingVINs = vehicles.filter((vehicle: any) => {
      return vehicle.vin === currentVehicle.vin;
    });
    if (matchingVINs && matchingVINs.length > 0) {
      return;
    }
  }
  return setVehicle(currentVehicle);
};

export const updateCurrentVehicle = (vehicleId: string, value: any) => {
  if (value && Object.keys(value).length > 0) {
    let currentVehicle = getCurrentVehicle(vehicleId);
    let updatedVehicle = { ...currentVehicle, ...value };
    return setVehicle(updatedVehicle);
  }
};
