import { Grid } from '@mui/material';
import { novoRoutes } from 'framework/routes';
import React from 'react';
import Drivers from '../Drivers';

export default function PlanCoverageDrivers(props: any) {
  return (
    <Grid container className='quote-wrapper'>
      <Drivers
        to={novoRoutes.PlanOptionsDrivers.path}
        view={'row'}
        updateFormDirtyFlag={props.updateFormDirtyFlag}
        updateCoverage={props.updateCoverage}
      />
    </Grid>
  );
}
