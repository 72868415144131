import type { FieldProps } from '@rjsf/core';
import PlacesAutoComplete from 'components/PlacesAutoComplete';
import type { AddressProps } from 'framework/forms/interfaces/AddressProps';
import React, { useEffect, useState } from 'react';

import { equals } from '../NovoForm';

const createDefaultAddress = (address: AddressProps) => {
  let defaultValues = undefined;
  if (address.defaultAddress) {
    defaultValues = address.defaultAddress;
  } else if (address.streetNumber && address.streetName) {
    defaultValues = `${address.streetNumber} ${address.streetName}`;
  } else if (address.streetName) {
    defaultValues = `${address.streetName}`;
  } else {
    defaultValues = String(address.fullAddress);
  }

  return defaultValues;
};

const PlacesAutoCompleteField = ({
  uiSchema,
  registry,
  schema,
  rawErrors,
  errorSchema,
  idSchema,
  formData,
  onChange,
  formContext,
  autofocus,
  disabled,
  readonly,
  required,
}: FieldProps) => {
  if (!formData.fullAddress) {
    formData.fullAddress = '';
  }
  const [address, setAddress] = useState(formData as AddressProps);
  const [defaultAddress, setDefaultAddress] = useState('');

  useEffect(() => {
    // hydrate field with onload address
    if (!equals(formData, address)) {
      setAddress(formData);
      setDefaultAddress(createDefaultAddress(formData));
    }
    // no need to hydrate other fields of formData object in state

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    onChange(address);
    setDefaultAddress(createDefaultAddress(address));
  }, [address]);

  const label: string = schema.title ? schema.title : 'Address, zip';

  const isError = rawErrors && rawErrors.length > 0;

  return (
    <>
      <PlacesAutoComplete
        className={`w-full ${isError ? 'Mui-error' : ''}`}
        id={idSchema.$id}
        disabled={disabled}
        name={idSchema.$id}
        defaultValue={defaultAddress}
        label={label}
        error={isError}
        required={required}
        helperText={''}
        address={address}
        setAddress={setAddress}
      />
    </>
  );
};

PlacesAutoCompleteField.defaultProps = {
  formData: {
    fullAddress: '',
    streetNumber: '',
    streetName: '',
    city: '',
    state: '',
    zipCode: '',
  },
};

export default PlacesAutoCompleteField;
