import { Grid, Typography } from '@mui/material';
import React from 'react';
import type { JSONSchema7 } from 'json-schema';
import { IdSchema, UiSchema } from '@rjsf/core';
import parse from 'html-react-parser';

interface LabelProps {
  disabled?: boolean;
  label?: string;
  required?: boolean;
  isError?: boolean;
  schema?: JSONSchema7;
  idSchema?: IdSchema;
  name?: string;
  labelId?: string;
  uiSchema?: UiSchema;
}

const Label: React.FC<LabelProps> = ({
  disabled,
  label,
  required,
  isError,
  schema,
  idSchema,
  name = 'label',
  labelId,
  uiSchema,
}) => {
  const title = label || schema?.title;
  if (title) {
    let muiLabelClasses =
      'MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-1jdql9x-MuiFormLabel-root-MuiInputLabel-root ';
    if (required) {
      muiLabelClasses += 'Mui-required ';
    }
    if (isError) {
      muiLabelClasses += 'Mui-error ';
    }
    if (disabled) {
      muiLabelClasses += 'disabled ';
    }
    if (uiSchema?.['ui:layout']?.title?.className) {
      muiLabelClasses += uiSchema?.['ui:layout']?.title?.className;
    } else if (uiSchema?.items?.['ui:layout']?.title?.className) {
      muiLabelClasses += uiSchema?.items?.['ui:layout']?.title?.className + ' ';
    }

    return (
      <Grid
        id={idSchema?.$id}
        sx={{ marginBottom: '0.3rem' }}
        className='h-full'
      >
        <Typography
          id={labelId}
          variant='h5'
          component='h5'
          sx={{
            ...(uiSchema?.underline && {
              textDecoration: 'underline',
              textUnderlineOffset: '0.2em',
            }),
          }}
          className={`${muiLabelClasses} white-space-inherit position-inherit transform-inherit h-full`}
        >
          {parse(`${title}${required ? '<span>&nbsp;*</span>' : ''}`)}
        </Typography>
      </Grid>
    );
  }
  return null;
};

export default Label;
