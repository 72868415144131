import { Box, Grid } from '@mui/material';
import { WidgetProps } from '@rjsf/core';
import CoverageIcon from 'components/icons/CoverageIcon';
import { CustomJsonSchemaProps } from 'framework/forms/interfaces/CustomJsonSchemaProps';
import React from 'react';
import CsrDialog from './Dialogs/CsrDialog';
import Label from './Label';
import './LabelWithIcon.scss';
import MuiDialog from './Dialogs/MuiDialog';

const LabelWithIcon = (props: WidgetProps) => {
  const schema: CustomJsonSchemaProps = props.schema as CustomJsonSchemaProps;
  const [csrDialog, setCsrDialog] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const { uiSchema } = props;

  const handleClick = (data: any) => {
    const { modal } = schema;
    if (modal === 'CsrDialog') {
      setCsrDialog(true);
    } else if (modal) {
      setOpenDialog(true);
    }
  };

  const handleClose = (value: any) => {
    setOpenDialog(value);
  };

  return (
    <>
      <CsrDialog
        showDialog={csrDialog}
        handleDialogClose={() => setCsrDialog(false)}
      />
      <MuiDialog
        uiSchema={uiSchema.modal}
        open={openDialog}
        onClose={handleClose}
        muiButtonClassName={'btn-gray btn-w-unset'}
      />
      <Box
        className={'label-with-icon-field'}
        sx={{
          borderRadius: '0px',
          width: '100%',
          border: 'solid 2px #E8E9EE',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <Grid container sx={{ padding: '10px 15px', alignItems: 'center' }}>
          {uiSchema?.imgsrc && (
            <Grid item sx={{ mr: '12px' }}>
              <CoverageIcon icon={uiSchema.imgsrc} />
            </Grid>
          )}
          <Grid item sx={{ flex: '1 1 0', minWidth: '6rem' }}>
            <Box>
              <Label {...props} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LabelWithIcon;
