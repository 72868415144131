import { Divider, Grid } from '@mui/material';
import Label from 'framework/components/Label';
import MuiButton from 'framework/components/MuiButton';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import React, { useEffect, useState } from 'react';
import { removeMarkUp } from 'utils/util';
import { CustomArrayFieldTemplateProps } from './CustomArrayFieldTemplate';

const getItems = (items: any, formData: any) => {
  return items.map((element: any, index: number) => {
    const elementFormData = formData[element.index];
    if (elementFormData) {
      return (
        <Grid key={index} className={element.className}>
          {/* Render Item: Form View */}
          <>
            <Grid>{element.children}</Grid>
          </>
          {element.hasMoveDown && (
            <MuiButton
              onClick={element.onReorderClick(element.index, element.index + 1)}
            >
              {/* Down */}
              {removeMarkUp(
                getMicroCopyResourceItem(`q2b.global.button.text.down`)
              )}
            </MuiButton>
          )}
          {element.hasMoveUp && (
            <MuiButton
              onClick={element.onReorderClick(element.index, element.index - 1)}
            >
              {/* Up */}
              {removeMarkUp(
                getMicroCopyResourceItem(`q2b.global.button.text.up`)
              )}
            </MuiButton>
          )}
          {element.hasRemove && (
            <MuiButton onClick={element.onDropIndexClick(element.index)}>
              {/* Delete */}
              {removeMarkUp(
                getMicroCopyResourceItem(`q2b.global.button.text.delete`)
              )}
            </MuiButton>
          )}
          {element.index < formData.length - 1 && (
            <Divider sx={{ borderWidth: '2px', margin: '1rem 0 1.2rem' }} />
          )}
        </Grid>
      );
    }
    return null;
  });
};

const PlanOptionsVehiclesArrayFieldTemplate = (
  props: CustomArrayFieldTemplateProps
) => {
  const { items, className, formData, schema, uiSchema } = props;
  const [updatedFormData, setUpdatedFormData] = useState(formData);

  useEffect(() => {
    setUpdatedFormData(formData);
  }, [formData]);

  const getUiItems = (updatedFormData2: any) => {
    if (items?.length > 0 && updatedFormData2?.length > 0) {
      return getItems(items, updatedFormData2);
    }
  };

  return (
    <Grid sx={{ pt: 0 }}>
      <Grid className={className}>
        <Grid className='mb-1'>
          <Label label={schema.title} uiSchema={uiSchema} />
        </Grid>
        {getUiItems(updatedFormData)}
      </Grid>
    </Grid>
  );
};

export default PlanOptionsVehiclesArrayFieldTemplate;
