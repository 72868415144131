import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import IdScan from 'components/IdScan';
import { AlertProps } from 'types/GenericTypes';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import MuiLoader from 'framework/components/MuiLoader';
import { getData } from 'framework/dataService/dataRehydration';
import { defaultDriverFields } from 'framework/dataService/driver';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import Validate from 'framework/forms/validations/Validate';
import getFormDefinitions from 'framework/schemaServer';
import { addDriverServiceCall } from 'library/services/utils';
import React, { useEffect, useState, useRef } from 'react';
import { getItem } from 'framework/dataService';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import { DriverFormParamType } from 'types/Driver';
import { getDrivers, getLocations } from 'framework/dataService/drivers';
import { futureDateValidation, getAge, getValidateDate } from 'utils/dateUtils';
import { notifyBugsnag } from 'utils/util';

export default function BasicInfo({
  updateStatus,
  index,
  driverId,
  mode,
  readonly,
}: DriverFormParamType) {
  const hasComponentMounted = useRef(false);
  const [alert, setAlert] = useState<AlertProps>({});
  const [isDirty, setIsDirty] = useState(true as boolean);
  const [showLoader, setShowLoader] = useState(false);
  const [csrDialog, setCsrDialog] = React.useState(false);
  const [initialFormData, setInitialFormData] = useState({
    ...defaultDriverFields,
    primary: false,
    driverId: driverId,
  } as any);
  const routeKey = 'DriverBasicInfo';
  const { schema } = getFormDefinitions(routeKey);
  const validateCourse = 2;
  const validateCourseMap: Record<string, number> = {
    TN: 55,
    OH: 60,
  };
  const appLocations = getLocations();
  const state: string = appLocations.filter((item: { primary: boolean }) => {
    return item.primary === true;
  })?.[0]?.address?.state;

  useEffect(() => {
    if (!hasComponentMounted.current) {
      const param = {
        driverId: driverId,
      };
      const dataForFormData = getData('EditDriver', param);
      let formData;
      if (dataForFormData?.driver?.driverId) {
        formData = { ...dataForFormData.driver };
        if (Object.keys(validateCourseMap).includes(state?.toUpperCase())) {
          formData.showAccidentPreventionCourse =
            checkAgeToDisplayAccidentPreventionCourse(formData.dob);
        }
        setInitialFormData(formData);
        // validate formData
        const errors = Validate(formData, schema);
        if (updateStatus && !errors) {
          // done: when all required fields have data
          setIsDirty(false);
          updateStatus(index, 'done', 'onLoad');
        }
      }
      hasComponentMounted.current = true;
    }
  }, [driverId, index, schema, updateStatus]);

  const checkAgeToDisplayAccidentPreventionCourse = (dob: string) => {
    const age = getAge(dob);
    return age >= validateCourseMap[state?.toUpperCase()];
  };

  const getExcludedDrivers = (drivers: any[]) => {
    const excludedDriverType = 'Excluded';
    const excludedDrivers = drivers.filter((driver) => {
      return driver?.driverType == excludedDriverType;
    });
    return excludedDrivers;
  };
  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    setShowLoader(true);
    const driverData = getData('EditDriver', { driverId });
    const applicationDrivers = getDrivers();
    formData.insuranceDetails = driverData.driver.insuranceDetails;
    formData.drivingHistory = driverData.driver.drivingHistory;
    formData.included = formData.included ? formData.included : true;
    setInitialFormData(formData);

    const formDataDriverId = formData.driverId;
    const exsistingDriver = applicationDrivers.filter(
      (driver: any) => driver.driverId === formDataDriverId
    );

    const agentIdSesssion = getItem('isAgent');
    if (!agentIdSesssion) {
      // check excluded driver >2 show csr dialog ;
      const excludedNum = getExcludedDrivers(applicationDrivers).length;
      if (excludedNum > 2) {
        setCsrDialog(true);
        setShowLoader(false);
        return;
      }
    }

    if (exsistingDriver && exsistingDriver.length > 0) {
      mode = 'done';
    }
    let response: any;
    if (mode === 'add') {
      const age = getAge(formData.dob);
      if (age >= 90) {
        notifyBugsnag('CSR pop up validation - Age above 90', formData);
        setCsrDialog(true);
        setShowLoader(false);
      } else {
        response = await addDriverServiceCall(formData);
      }
    } else {
      const age = getAge(formData.dob);
      if (age >= 90) {
        notifyBugsnag('CSR pop up validation - Age above 90', formData);
        setCsrDialog(true);
        setShowLoader(false);
      } else {
        const dataForPersistance = {
          driver: {
            ...formData,
          },
        };
        if (persistanceService) persistanceService(dataForPersistance);
      }
    }

    if ((mode === 'add' && response.error === false) || mode !== 'add') {
      setIsDirty(false);
      if (updateStatus) updateStatus(index, 'done', 'onSubmit', mode === 'add');
    } else {
      setAlert({
        message: response.errorMessage,
        code: 'error',
      });
    }
    setShowLoader(false);
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (mode === 'edit' && !isDirty && !equals(initialFormData, formData)) {
      // formData just got dirty
      setIsDirty(true);
      if (updateStatus) updateStatus(index, 'open', 'onChange');
    } else if (!isDirty && !equals(initialFormData, formData)) {
      setIsDirty(true);
    }

    // eslint-disable-next-line max-len
    if (
      Object.keys(validateCourseMap).includes(state?.toUpperCase()) &&
      checkAgeToDisplayAccidentPreventionCourse(formData?.dob)
    ) {
      formData.showAccidentPreventionCourse = true;
    } else {
      formData.showAccidentPreventionCourse = false;
      formData.accidentPreventionCourse = '';
    }

    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    let emailReg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (formData?.showAccidentPreventionCourse) {
      // eslint-disable-next-line max-len
      const courseDate = getValidateDate(formData?.accidentPreventionCourse);
      const futureDateValidate = futureDateValidation(
        formData?.accidentPreventionCourse
      );
      if (courseDate > validateCourse || futureDateValidate) {
        if (errors?.accidentPreventionCourse?.__errors.length === 0) {
          errors?.accidentPreventionCourse.addError(
            'Please enter the date of your most recent 3 years accident prevention course.'
          );
        }
      }
    }
    if (formData?.email && !emailReg.test(formData?.email)) {
      //  check email rules
      if (errors?.email?.__errors.length === 0) {
        errors?.email?.addError(`Not a valid email address`);
      }
    }
    return errors;
  };

  const handleIdScan = (data: any) => {
    const updatedDriverData = { ...data };
    updatedDriverData.driverId = driverId;
    delete updatedDriverData.location;
    setInitialFormData(data);
  };
  return (
    <>
      <IdScan handleIdScan={handleIdScan} driverId={driverId} />
      {showLoader && <MuiLoader />}
      <CsrDialog
        showDialog={csrDialog}
        handleDialogClose={() => setCsrDialog(false)}
      />
      <NovoForm
        alert={alert}
        disabled={readonly}
        schemaName={routeKey}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        validate={handleValidation}
        //buttonName='looks good'
        buttonName={
          <MicrocopyComponent labelKey={`q2b.global.button.text.looks.good`} />
        }
        formData={initialFormData}
        transitionTo={false}
        shadowButton={true}
        hideSubmitButton={!isDirty}
      />
    </>
  );
}
