import { Button, Grid, Typography } from '@mui/material';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import { AlertProps } from 'types/GenericTypes';
import ButtonArrow from 'components/icons/ButtonArrow';
import IdScan from 'components/IdScan';
import Page from 'components/Page';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import { novoNavigation } from 'framework/routes';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'components/icons/CloseIcon';
import {
  createApplicationAndHandleNavigation,
  makeServiceCallsAndHandleNavigation,
  retrieveApplicationById,
} from 'library/services/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MuiLoader from 'framework/components/MuiLoader';
import { v4 as uuidv4 } from 'uuid';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import Dialog from '@mui/material/Dialog';
import { notifyBugsnag, setOtherAddressFields } from 'utils/util';
import {
  getApplicationObjectItem,
  getMetaDataItem,
  setMetaDataItem,
} from 'framework/dataService/insuranceApplication';
import {
  insuranceApplicationObject,
  progressConstant,
} from 'library/application/applicationConstants';
import { getItem, getJson, setItem, setJson } from 'framework/dataService';
import { retrieveApplication } from 'library/services';
import { novoRoutes } from 'framework/routes';
import { getAge } from 'utils/dateUtils';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';

export default function InitialInfo() {
  const navigate = useNavigate();
  const routeKey = 'InitialInfo';
  const [initialFormData, setInitialFormData] = useState({} as any);
  const [insuranceNotAvailable, setInsuranceNotAvailable] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [error, setError] = useState<AlertProps>({});
  const [showLoader, setShowLoader] = useState(false);
  const [foundExistingApplication, setFoundExistingApplication] =
    useState(false);
  const [fetchedApplicationId, setFetchedApplicationId] = useState('');
  const [submitFormData, setSubmitFormData] = useState({});
  const [driverName, setDriverName] = useState();
  const [isFetch, setIsFetch] = useState(false);
  const [driverIdFetch, setDriverIdFetch] = useState('');
  const [locationIdFetch, setLocationIdFetch] = useState('');
  const novoRoute = novoRoutes[routeKey];
  const [csrDialog, setCsrDialog] = React.useState(false);

  useEffect(() => {
    let formData: any = {};
    setItem('ReconciliationViewed', 'false');
    const dataForFormData = getData(routeKey);
    const params = window.location.href.split('?')[1];
    const paramsArray = new URLSearchParams(params);
    const agentParam = paramsArray.get('agent');
    const agentIdParam = paramsArray.get('agentId');
    const applicationIdParam = paramsArray.get('transactionId');
    const policyIdParam = paramsArray.get('policyId');
    const partner = paramsArray.get('partner');
    if (partner) {
      setItem('partnerQuote', partner);
    }
    if (agentParam) {
      setJson('isAgent', agentParam);
    }
    if (agentIdParam) {
      setItem('agentId', agentIdParam);
    }

    if (applicationIdParam || policyIdParam) {
      if (applicationIdParam) {
        setJson('isPartnerFlow', applicationIdParam);
      } else if (policyIdParam) {
        setJson('isPartnerFlow', policyIdParam);
      }
    }

    if (applicationIdParam || policyIdParam) {
      setIsFetch(true);
      const retrievData = async () => {
        const applicationResp = policyIdParam
          ? await retrieveApplication('', policyIdParam)
          : await retrieveApplication(
              applicationIdParam ? applicationIdParam : ''
            );

        if (applicationResp.error === false) {
          setJson(insuranceApplicationObject, applicationResp.response);
          const driverLength = applicationResp?.response?.drivers.length || 0;
          const pniDriver = applicationResp?.response?.drivers?.filter(
            (d: any) => d.primary
          );
          const pniAddress = applicationResp?.response?.locations?.filter(
            (l: any) => l.primary
          );
          if ((pniDriver && pniDriver.length > 0) || driverLength === 1) {
            setDriverIdFetch(pniDriver[0].driverId);
            formData.name = pniDriver[0].name;
            formData.dob = pniDriver[0].dob;
            formData.email = pniDriver[0].confirmEmail;

            if (pniAddress && pniAddress.length > 0)
              setLocationIdFetch(pniAddress[0].locationId);
            formData.defaultAddress = pniAddress[0].address.address;
            formData.state = pniAddress[0].address.state;
            formData.zipCode = pniAddress[0].address.zip;
            formData.city = pniAddress[0].address.city;

            const fullAddress = `${pniAddress[0].address.address}, ${pniAddress[0].address.city}, ${pniAddress[0].address.state}, USA`;
            const fullAddressSplit = fullAddress.split(',');
            const streetNumber = fullAddress.split(' ')[0];
            const streetName = fullAddressSplit[0].replace(streetNumber, '');
            formData.location = {
              city: pniAddress[0].address.city,
              fullAddress: fullAddress,
              state: pniAddress[0].address.state,
              streetName: streetName,
              streetNumber: streetNumber,
              zipCode: pniAddress[0].address.zip,
            };
            console.log('form data after API call', formData);
            setInitialFormData(formData);
          }
          if (applicationResp?.response?.metaData?.producerId) {
            setItem('agentId', applicationResp.response.metaData.producerId);
          }
          if (applicationResp?.response?.metaData?.channelName) {
            setItem(
              'channelName',
              applicationResp?.response?.metaData?.channelName
            );
          }
        } else {
          notifyBugsnag('Application retrive API', applicationResp);
        }
      };
      retrievData();
    }
    if (dataForFormData?.driver?.driverId) {
      formData = { ...dataForFormData.driver };
      if (dataForFormData?.location?.locationId) {
        formData.location = dataForFormData.location;
        setOtherAddressFields(formData.location, formData, null);
      }
    }
    if (dataForFormData?.metaData) {
      setReadonly(true);
    }
    console.log(
      'applicationIdParam and formData',
      applicationIdParam,
      formData
    );
    if (applicationIdParam || policyIdParam) {
      console.log('formdata load started', formData);
      setShowLoader(true);
      setTimeout(() => {
        setInitialFormData(formData);
        setShowLoader(false);
        console.log('formdata load completed', formData);
      }, 2000);
    } else {
      console.log('deafult formdata', formData);
      setInitialFormData(formData);
    }
  }, []);

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    const insuredAge = getAge(formData.dob);
    if (insuredAge >= 90) {
      notifyBugsnag('CSR pop up validation - Age above 90', formData);
      setCsrDialog(true);
      return;
    }
    // if (formData.email) {
    //   const isEmailExist = await isEmailExistInOtherPolicy(formData.email);
    //   if (isEmailExist) {
    //     setError({
    //       message: getMicroCopyResourceItem(
    //         'q2b.primary.driver.email.validation'
    //       ),
    //     });
    //     return;
    //   }
    // }

    setShowLoader(true);
    let driverId = uuidv4();
    let locationId = uuidv4();

    if (initialFormData?.location?.locationId) {
      locationId = initialFormData.location.locationId;
    }

    if (initialFormData?.driverId) {
      driverId = initialFormData.driverId;
    }

    if (isFetch) {
      locationId = locationIdFetch;
      driverId = driverIdFetch;
    }
    const pastLocation = getApplicationObjectItem('locations')?.[0];
    let addressChanged = false;
    if (pastLocation && Object.keys(pastLocation).length > 0) {
      const pastAddress = pastLocation.address;
      const currentLocation = initialFormData.location;
      if (
        pastAddress &&
        Object.keys(pastAddress).length > 0 &&
        currentLocation &&
        Object.keys(currentLocation).length > 0
      ) {
        let currentStreet = '';
        if (currentLocation.defaultAddress) {
          currentStreet = currentLocation.defaultAddress;
        } else if (currentLocation.streetNumber && currentLocation.streetName) {
          currentStreet = `${currentLocation.streetNumber} ${currentLocation.streetName}`;
        } else {
          currentStreet = currentLocation.streetName;
        }
        if (
          pastAddress?.state?.toLowerCase() !==
          currentLocation?.state?.toLowerCase()
        ) {
          addressChanged = true;
        }
      }
    }
    const dataForPersistance = {
      driver: {
        ...formData,
        name: {
          firstName: formData.name.firstName.trim(),
          lastName: formData.name.lastName.trim(),
        },
        driverId,
        primary: true,
        included: true,
      },
      location: {
        locationId,
        ...formData.location,
        primary: true,
        apt: formData.apt,
      },
    };

    delete dataForPersistance.driver.location;
    delete dataForPersistance.driver.apt;
    delete dataForPersistance.driver.city;
    delete dataForPersistance.driver.state;
    delete dataForPersistance.driver.zipCode;
    if (persistanceService) persistanceService(dataForPersistance);
    setSubmitFormData({
      ...formData,
      name: {
        firstName: formData.name.firstName.trim(),
        lastName: formData.name.lastName.trim(),
      },
    });
    const {
      isStateAvailable,
      isExistingApplication,
      isApplicationFetched,
      errorMessage,
      applicationId,
    } = await makeServiceCallsAndHandleNavigation(
      {
        ...formData,
        name: {
          firstName: formData.name.firstName.trim(),
          lastName: formData.name.lastName.trim(),
        },
      },
      addressChanged
    );

    setShowLoader(false);
    if (errorMessage) {
      setError({
        message: errorMessage,
      });
    } else if (isStateAvailable && isApplicationFetched) {
      const everflowDetails = getJson('everflow');
      const agentIdParam = getItem('isAgent');
      setDriverName(formData.name.firstName);
      setFetchedApplicationId(applicationId);
      if (
        (everflowDetails && everflowDetails.ef_transaction_id) ||
        agentIdParam
      ) {
        setFoundExistingApplication(false);
        handleFetchApplication(true, applicationId);
      } else {
        setFoundExistingApplication(true);
      }
    } else if (isExistingApplication && !isStateAvailable) {
      setInsuranceNotAvailable(true);
    } else if (isStateAvailable || isExistingApplication) {
      navigate(novoNavigation.InitialInfo.nextPage);
    } else {
      setInsuranceNotAvailable(true);
    }
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (!equals(formData?.location, initialFormData?.location)) {
      setOtherAddressFields(
        formData.location,
        formData,
        initialFormData.location
      );
    }

    setInitialFormData(formData); // needed for custom fields
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    const { location, email } = formData;
    let emailReg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!location || (location && !location.fullAddress)) {
      errors.location.addError('Please enter your address.');
    }
    if (formData && formData.city === '') {
      errors.city.addError('Please enter your city.');
    }
    return errors;
  };

  const handleIdScan = (data: any) => {
    if (data?.driverId === 0) {
      if (!equals(data?.location, initialFormData?.location)) {
        setOtherAddressFields(data.location, data, initialFormData.location);
      }
    }
    setInitialFormData(data);
  };

  const showInsuranceNADialog = () => {
    return (
      <Dialog
        className='insurance-not-available'
        open={insuranceNotAvailable}
        onClose={() => setInsuranceNotAvailable(false)}
      >
        <Grid container>
          <Grid item xs={11}>
            <iframe
              title='cheetah-form'
              src={`https://us-d.wayin.com/display/container/dc/f347c833-8e82-4189-9d74-97aadf3ba34f/details
                ?mode=responsive&first_name=${initialFormData.name.firstName.trim()}&last_name=${initialFormData.name.lastName.trim()}&state=${
                initialFormData.state
              }`}
              frameBorder='0'
              scrolling='yes'
              width='100%'
              height='850'
              id='ngxFramef347c833-8e82-4189-9d74-97aadf3ba34f'
              allowTransparency={true}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => setInsuranceNotAvailable(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  const existingApplicationDialog = () => {
    return (
      <Dialog
        className='found-existing-application'
        open={foundExistingApplication}
        onClose={() => setFoundExistingApplication(false)}
      >
        <Grid
          container
          sx={{ padding: '40px 55px', border: '10px solid #000' }}
        >
          <>
            <Typography
              variant='h1'
              component='h1'
              className={'page-header-h text-line-bottom'}
            >
              <MicrocopyComponent
                labelKey={'q2b.found.existing.heading1'}
                placeHolderKey={`$DriverName$`}
                actualValue={driverName}
              />
            </Typography>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='subtitle2' className={'page-header-h2'}>
                <MicrocopyComponent labelKey={'q2b.found.existing.heading2'} />
              </Typography>
            </Grid>
            <Grid container sx={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <Button
                  id='driverInitialInfo'
                  className='btn-arrow w-full'
                  color='secondary'
                  variant='contained'
                  disabled={false}
                  onClick={() => handleFetchApplication(true)}
                  endIcon={<ButtonArrow />}
                >
                  <MicrocopyComponent labelKey={`q2b.global.continue.quote`} />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant='subtitle2'
                  style={{ paddingTop: 10, marginBottom: 0 }}
                  className={'page-header-h2'}
                >
                  <MicrocopyComponent labelKey={'q2b.global.or.text'} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className='btn-gray btn-w-unset novo-hyperlink'
                  onClick={() => handleFetchApplication(false)}
                >
                  <MicrocopyComponent labelKey={'q2b.global.start.over.text'} />
                </Button>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Dialog>
    );
  };

  const handleFetchApplication = async (
    retrieveApplicationAction: boolean,
    exsistApplicationId?: string
  ) => {
    setFoundExistingApplication(false);
    setShowLoader(true);
    const createApplication = async () => {
      setShowLoader(true);
      const { isStateAvailable, isExistingApplication, errorMessage } =
        await createApplicationAndHandleNavigation(submitFormData);
      if (errorMessage) {
        setError({ message: errorMessage });
      } else if (isStateAvailable || isExistingApplication) {
        navigate(novoNavigation.InitialInfo.nextPage);
      } else {
        // navigate(novoRoutes.InsuranceNotAvailable.path);
        setInsuranceNotAvailable(true);
      }
      setShowLoader(false);
    };

    if (retrieveApplicationAction) {
      const fetchApplication = exsistApplicationId
        ? exsistApplicationId
        : fetchedApplicationId;
      const success = await retrieveApplicationById(fetchApplication);
      if (success) {
        navigate(novoNavigation.InitialInfo.nextPage);

        //Can't skip to Final Review if existing application was loaded
        const progress: any = getMetaDataItem(progressConstant);
        const completedSteps = Number(progress?.completedRoute || 0) + 1;
        if (completedSteps === 5) {
          setMetaDataItem(progressConstant, { completedRoute: '4' });
        }
        setShowLoader(false);
      } else {
        createApplication();
      }
    } else {
      createApplication();
    }
  };

  return (
    <Grid container className='quote-wrapper'>
      <Page routeKey={routeKey} disabled={readonly}>
        {showLoader && <MuiLoader />}
        <IdScan handleIdScan={handleIdScan} />
        <CsrDialog
          showDialog={csrDialog}
          handleDialogClose={() => setCsrDialog(false)}
        />
        {insuranceNotAvailable && showInsuranceNADialog()}
        {foundExistingApplication && existingApplicationDialog()}
        <Grid sx={{ mb: '1.5rem' }}>
          <Grid container>
            <Typography className='page-header-h5' variant='h6'>
              <MicrocopyComponent labelKey={`q2b.initial.info.basic.text`} />
            </Typography>
          </Grid>
        </Grid>
        <NovoForm
          disabled={readonly}
          alert={error}
          schemaName={routeKey}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onError={handleError}
          validate={handleValidation}
          formData={initialFormData}
          transitionTo={false}
          information={
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: '1.5rem' }}>
              <Typography
                variant='subtitle2'
                component='h2'
                className={'page-header-h2 page-header-h2-font'}
              >
                {/* {heading2} */}
                <MicrocopyComponent labelKey={novoRoute.disclosure as string} />
              </Typography>
            </Grid>
          }
          button={
            <Button
              id='driverInitialInfo'
              className='btn-arrow'
              type='submit'
              color='secondary'
              variant='contained'
              disabled={false}
              endIcon={<ButtonArrow />}
            >
              <MicrocopyComponent labelKey={`q2b.global.button.text.next`} />
            </Button>
          }
        />
      </Page>
    </Grid>
  );
}
