import { getItem } from 'framework/dataService';
import { getPrimaryDriver } from 'framework/dataService/drivers';
import {
  getGaragingAddressCheck,
  getPrimaryLocation,
} from 'framework/dataService/locations';
import { getCurrentVehicleByVin } from 'framework/dataService/vehicle';
import {
  getAge,
  isMonthYearInRange,
  getFormattedDate,
  getFullDateFromMMYYYY,
  isAccidentDate,
  isValidExpirationDate,
  getToday,
} from 'utils/dateUtils';
import { notifyBugsnag } from 'utils/util';
import validateLicenseNumberByState from './../../DriverLicenseFormats';

import type { CustomJsonSchemaProps } from '../../../interfaces/CustomJsonSchemaProps';
import additionalCustomFormats from './../../CustomFormats';
import { getData } from 'framework/dataService/dataRehydration';

export const commonRuleMap = {
  firstName: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const firstName = data?.name?.firstName;
    let reg;
    reg = new RegExp(
      /^[A-Za-z0-9][A-Za-z0-9(\s?)(-?)]{0,}[A-Za-z0-9](\s{0,})$/
    );
    const isFirstNameValid = reg.test(firstName);
    if (!isFirstNameValid) {
      const messages: any = fieldSchema?.messages;
      let errorMessage = messages?.[validationType];
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag('Custom Form validation Error  - firstName', {
          errorMessage,
          inputData: data,
        });
        errors?.[fieldName]?.firstName?.addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  lastName: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const lastName = data?.name?.lastName;
    const reg = new RegExp(/^[A-Za-z][A-Za-z(\s?)(-?)]{0,}[A-Za-z](\s{0,})$/);
    const isLastNameValid = reg.test(lastName);
    if (!isLastNameValid) {
      const messages: any = fieldSchema?.messages;
      let errorMessage = messages?.[validationType];
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag('Custom Form validation Error  - lastName', {
          errorMessage,
          inputData: data,
        });
        errors?.[fieldName]?.lastName?.addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  driverLicenseNumber: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let sameDlError = false;
    //grab licensing state
    const licensingState = data.licenseState;
    const inputtedDriverLicenseNumber = data.dlNumber;
    const isValid = validateLicenseNumberByState(
      licensingState,
      inputtedDriverLicenseNumber
    );
    const applicationDriverData = getData('Drivers');
    if (applicationDriverData?.drivers.length > 0 && !data.primary) {
      const filterData = applicationDriverData.drivers.filter(
        (d: any) =>
          d?.licenseState?.toLowerCase() ===
            data?.licenseState?.toLowerCase() &&
          d?.dlNumber?.toLowerCase() === data?.dlNumber?.toLowerCase() &&
          d?.driverId !== data?.driverId
      );
      sameDlError = filterData && filterData.length > 0 ? true : false;
    }
    if (!isValid || sameDlError) {
      const messages: any = fieldSchema.messages;
      let errorMessage = sameDlError
        ? messages.commonDriverLicenseNumber
        : messages?.[validationType];
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag('Custom Form validation Error  - driverLicenseNumber', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  garagingStateDifferentFromOfferedState: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const dataToCompareWith = getPrimaryLocation()?.state;
    const addressCheck = getGaragingAddressCheck(
      data.state,
      data.vehicleId,
      data.garagingLocationId
    );
    if (
      data !== undefined &&
      data.garagingAddressSameAsPrimaryAddress === false &&
      data.state !== dataToCompareWith
    ) {
      const messages: any = fieldSchema.messages;
      let errorMessage = messages?.[validationType];
      if (addressCheck) {
        errorMessage = messages?.garagingStateDifferentFromOtherVehicle;
      }
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag(
          'Custom Form validation Error  - garagingStateDifferentFromOfferedState',
          { errorMessage, inputData: data }
        );
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  isOutOfState: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const dataToCompareWith = getPrimaryLocation().state;
    if (data[fieldName] !== dataToCompareWith) {
      const messages: any = fieldSchema.messages;
      const errorMessage = messages?.[validationType];
      if (rule.warning) {
        warningMsgs.push(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  futureExpirationDate: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const coverageStartSplit = data?.currentCoverageStart?.split('/');
    const currentDate = getToday();
    const currentDateSplit = currentDate?.split('-');
    if (coverageStartSplit?.length > 1 && currentDateSplit?.length > 1) {
      let showValidation = false;
      let showYearMsg = false;
      const monthCheck = coverageStartSplit[0]?.length === 2;
      const yearCheck = coverageStartSplit[1]?.length === 4;
      if (!monthCheck || !yearCheck) {
        showYearMsg = true;
      } else if (
        parseInt(coverageStartSplit[1]) > parseInt(currentDateSplit[0])
      ) {
        showValidation = true;
      } else if (
        parseInt(coverageStartSplit[1]) === parseInt(currentDateSplit[0])
      ) {
        if (parseInt(coverageStartSplit[0]) > parseInt(currentDateSplit[1])) {
          showValidation = true;
        }
      }
      if (showValidation || showYearMsg) {
        let messages: any = fieldSchema.messages;
        let errorMessage = showYearMsg
          ? messages?.yearFormatItem
          : messages?.futureExpirationDate;
        isError = true;
        if (errors && errorMessage) {
          notifyBugsnag('Custom Form validation Error - futureExpirationDate', {
            errorMessage,
            inputData: data,
          });
          errors.currentCoverageStart.addError(errorMessage);
        }
      }
    }
    return { isError, warningMsgs };
  },
  confirm: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    console.log(data);
    let isError = false;
    let lookupKey = rule.targetField;
    let warningMsgs: any[] = [];
    if (data[fieldName]?.toLowerCase() !== data[lookupKey]?.toLowerCase()) {
      let messages: any = fieldSchema.messages;
      let errorMessage = messages?.confirm
        ? messages?.confirm
        : 'The value for ' +
          fieldSchema.title +
          ' needs to match the value for ' +
          schema?.properties?.[lookupKey]?.title;
      isError = true;
      if (errors && errorMessage && data[fieldName]) {
        notifyBugsnag('Custom Form validation Error - confirm', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  age: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let age = getAge(data[fieldName]);
    if (isNaN(age)) {
      let errorMessage = `Please enter valid year in YYYY format`;
      isError = true;
      if (errors && errorMessage && data[fieldName]) {
        notifyBugsnag('Custom Form validation Error - age', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    let minAge: number = Math.abs(fieldSchema?.maxYear || 1);
    // let maxAge: number = Math.abs(fieldSchema?.minYear || 100);
    if (age < minAge) {
      let messages: any = fieldSchema.messages;
      let errorMessage = messages?.minAge
        ? messages.minAge
        : `Driver must be at least ${minAge} years or older`;
      isError = true;
      if (errors && errorMessage && data[fieldName]) {
        notifyBugsnag('Custom Form validation Error - age', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    // else if (age > maxAge) {
    //   let messages: any = fieldSchema.messages;
    //   let errorMessage = messages?.maxAge
    //     ? messages.maxAge
    //     : `Driver must be at most ${maxAge} years old`;
    //   isError = true;
    //   if (errors && errorMessage && data[fieldName]) {
    //     notifyBugsnag('Custom Form validation Error - age', {
    //       errorMessage,
    //       inputData: data,
    //     });
    //     errors[fieldName].addError(errorMessage);
    //   }
    // }
    return { isError, warningMsgs };
  },
  validateAgeOnAgeLicense: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let lookupKey = rule.targetField;
    let age = getAge(data[lookupKey]);
    if (age < parseInt(data[fieldName])) {
      let messages: any = fieldSchema.messages;
      let errorMessage = messages?.validateAgeOnAgeLicense
        ? messages?.validateAgeOnAgeLicense
        : 'Driver is too young to have been licensed at ' +
          parseInt(data[fieldName]);
      isError = true;
      if (errors && errorMessage && data[fieldName]) {
        notifyBugsnag(
          'Custom Form validation Error - validateAgeOnAgeLicense',
          { errorMessage, inputData: data }
        );
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  pastExpirationDate: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const expirationDate = data[fieldName];
    if (
      expirationDate !== undefined &&
      expirationDate.match(additionalCustomFormats.card_expiration_date)
    ) {
      if (!isValidExpirationDate(expirationDate)) {
        isError = true;
        let messages: any = fieldSchema.messages;
        let errorMessage = messages?.pastExpirationDate
          ? messages?.pastExpirationDate
          : 'ExpirationDate must be less than today date.';
        if (errors && errorMessage && data[fieldName]) {
          notifyBugsnag('Custom Form validation Error - pastExpirationDate', {
            errorMessage,
            inputData: data,
          });
          errors[fieldName].addError(errorMessage);
        }
      }
    }
    return { isError, warningMsgs };
  },
  electronicSign: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const signature = data[fieldName];
    const primaryDriver = getPrimaryDriver();
    const primaryDriverFullName =
      primaryDriver.name.firstName + ' ' + primaryDriver.name.lastName;
    if (
      signature?.toLowerCase()?.trim() !==
      primaryDriverFullName?.toLowerCase()?.trim()
    ) {
      isError = true;
      let messages: any = fieldSchema.messages;
      let errorMessage = messages?.electronicSign
        ? messages?.electronicSign
        : 'Invalid Signature';
      if (errors && errorMessage && data[fieldName]) {
        notifyBugsnag('Custom Form validation Error - electronicSign', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  validAccident: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const fullDate = getFullDateFromMMYYYY(data[fieldName]);
    const date = getFormattedDate(fullDate);
    if (!isAccidentDate(date)) {
      isError = true;
      let messages: any = fieldSchema.messages;
      let errorMessage = messages?.validAccident
        ? messages?.validAccident
        : 'Please enter a valid Accident date';
      if (errors && errorMessage && data[fieldName]) {
        notifyBugsnag('Custom Form validation Error - validAccident', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },

  minYear: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const coverageStartSplit = data?.currentCoverageStart?.split('/');
    const currentDate = getToday();
    const min = rule?.minimum ?? -10;
    const currentDateSplit = currentDate?.split('-');
    if (coverageStartSplit?.length > 1 && currentDateSplit?.length > 1) {
      let showValidation = false;
      let showYearMsg = false;
      const monthCheck = coverageStartSplit[0]?.length === 2;
      const yearCheck = coverageStartSplit[1]?.length === 4;
      if (!monthCheck || !yearCheck) {
        showYearMsg = true;
      } else if (
        parseInt(coverageStartSplit[1]) <
        parseInt(currentDateSplit[0]) + min
      ) {
        showValidation = true;
      } else if (
        parseInt(coverageStartSplit[1]) ===
        parseInt(currentDateSplit[0]) + min
      ) {
        if (parseInt(coverageStartSplit[0]) < parseInt(currentDateSplit[1])) {
          showValidation = true;
        }
      }
      if (showValidation || showYearMsg) {
        let messages: any = fieldSchema.messages;
        let errorMessage = showYearMsg
          ? messages?.yearFormatItem
          : messages?.minYear;
        isError = true;
        if (errors && errorMessage) {
          notifyBugsnag('Custom Form validation Error - minYear', {
            errorMessage,
            inputData: data,
          });
          errors.currentCoverageStart.addError(errorMessage);
        }
      }
    }
    return { isError, warningMsgs };
  },
  minArrayItems: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let lookupKey = rule.targetField;
    const minCount = rule.minCount;
    // eslint-disable-next-line no-eval
    const arrayValue = eval('data.' + lookupKey);
    const conditionalField = rule.conditionalField;
    // eslint-disable-next-line no-eval
    const conditionalFieldValue = eval('data.' + conditionalField);
    const conditionalFieldValueToMatch = rule.conditionalFieldValue;
    if (
      conditionalFieldValue === conditionalFieldValueToMatch &&
      minCount > 0 &&
      (!arrayValue || arrayValue?.length < minCount)
    ) {
      let messages: any = fieldSchema.messages;
      let errorMessage = messages?.minArrayItems
        ? messages.minArrayItems
        : 'Minimum ' + minCount + ' item required';
      isError = true;
      if (errors && errorMessage) {
        notifyBugsnag('Custom Form validation Error - minArrayItems', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    return { isError, warningMsgs };
  },
  monthYearArrayItem: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let lookupKey = rule.targetField;
    let warningMsgs: any[] = [];
    const arrayField = rule?.arrayField;
    // eslint-disable-next-line no-eval
    const arrayValue = eval('data.' + arrayField);
    const maximum = rule?.maximum;
    const minimum = rule?.minimum;
    let showYearMsg = false;
    if (arrayValue) {
      const arrayItemWithInvalidData = arrayValue.find(
        (item: { [x: string]: any }) => {
          const targetValue = item[lookupKey];
          if (targetValue) {
            const targetValueSplit = targetValue?.split('/');
            const monthCheck = targetValueSplit[0]?.length === 2;
            const yearCheck = targetValueSplit[1]?.length === 4;
            if (!monthCheck || !yearCheck) {
              showYearMsg = true;
              return true;
            }
          }
          if (
            !targetValue ||
            !isMonthYearInRange(targetValue, minimum, maximum)
          ) {
            // found issue with monthYear value
            return true;
          }
          return false;
        }
      );
      if (arrayItemWithInvalidData) {
        let messages: any = fieldSchema.messages;
        let errorMessage = showYearMsg
          ? messages?.yearFormatItem
          : messages?.monthYearArrayItem
          ? messages.monthYearArrayItem
          : 'Month Year value is invalid';
        isError = true;
        if (errors && errorMessage) {
          notifyBugsnag('Custom Form validation Error - monthYearArrayItem', {
            errorMessage,
            inputData: data,
          });
          errors[fieldName].addError(errorMessage);
        }
      }
    }
    return { isError, warningMsgs };
  },
  isValidFieldValues: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let agentIdSesssion = getItem('isAgent');
    let invalidEnum = fieldSchema?.invalidEnum;
    let inValidFieldValueError = false;
    if (!agentIdSesssion) {
      invalidEnum.forEach((item: any) => {
        if (data[fieldName] === item) {
          let messages: any = fieldSchema.messages;
          let errorMessage = messages?.isValidFieldValues
            ? messages.isValidFieldValues
            : 'This is an invalid value.';
          inValidFieldValueError = true;
          if (errors && errorMessage) {
            notifyBugsnag('Custom Form validation Error - isValidFieldValues', {
              errorMessage,
              inputData: data,
            });
            errors[fieldName].addError(errorMessage);
          }
        }
      });
    }
    isError = isError || inValidFieldValueError;
    return { isError, warningMsgs };
  },
  collisionDeductible: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    // WI OH TN IN  can reuse
    let errorFieldsExists = false;
    const financeCase = ['financed', 'lease'];
    data.vehiclesList &&
      data?.vehiclesList.forEach((vehicle: any, index: number) => {
        const currentVin = getCurrentVehicleByVin(vehicle?.vin);
        const compDed =
          vehicle?.comprehensiveDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.comprehensiveDeductible?.split(' DED');
        const collDed =
          vehicle?.collisionDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.collisionDeductible?.split(' DED');

        if (compDed?.length > 0 && collDed?.length > 0) {
          let showErrorMessage = false;
          let financedErrorMessage = false;
          if (
            currentVin &&
            financeCase.includes(currentVin?.ownershipStatus?.toLowerCase()) &&
            collDed[0] === 'declined'
          ) {
            showErrorMessage = true;
            financedErrorMessage = true;
          }
          if (collDed[0] === 'declined' && compDed[0] !== 'declined') {
            showErrorMessage = true;
          }
          if (showErrorMessage) {
            const messages: any = fieldSchema.messages;
            let errorMessage = financedErrorMessage
              ? messages?.collisionDecline
              : messages?.[validationType];
            errorFieldsExists = true;
            if (errors && errorMessage && data[fieldName] !== undefined) {
              notifyBugsnag(
                'Custom Form validation Error - collisionDeductible',
                { errorMessage, inputData: data }
              );
              if (errorMessage === messages?.[validationType]) {
                errors[fieldName][index].comprehensiveDeductible.addError(
                  errorMessage
                );
              } else {
                errors[fieldName][index].collisionDeductible.addError(
                  errorMessage
                );
              }
            }
          }
        }
      });
    if (errorFieldsExists) {
      isError = true;
    }
    return { isError, warningMsgs };
  },
  collisionCompDeductible: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    // WI OH TN IN  can reuse
    let errorFieldsExists = false;
    data.vehiclesList &&
      data?.vehiclesList.forEach((vehicle: any, index: number) => {
        const compDed =
          vehicle?.comprehensiveDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.comprehensiveDeductible?.split(' DED');
        const collDed =
          vehicle?.collisionDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.collisionDeductible?.split(' DED');
        if (
          compDed?.length > 0 &&
          collDed?.length > 0 &&
          collDed[0] !== 'declined' &&
          compDed[0] !== 'declined'
        ) {
          const collDedInt = parseInt(collDed[0].replace(',', ''));
          const compDedInt = parseInt(compDed[0].replace(',', ''));
          if (collDedInt < compDedInt) {
            const messages: any = fieldSchema.messages;
            let errorMessage = messages?.[validationType];
            errorFieldsExists = true;
            if (errors && errorMessage && data[fieldName] !== undefined) {
              notifyBugsnag(
                'Custom Form validation Error - collisionCompDeductible',
                { errorMessage, inputData: data }
              );
              errors[fieldName][index].collisionDeductible.addError(
                errorMessage
              );
            }
          }
        }
      });
    if (errorFieldsExists) {
      isError = true;
    }
    return { isError, warningMsgs };
  },
  acpeCoverageLimit: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let hasError = false;
    const messages: any = fieldSchema.messages;
    let errorMessage = messages?.[validationType];

    data.vehiclesList.forEach((car: any, index: any) => {
      const acpeCoverage = car.acpe;
      if (acpeCoverage > 5000) {
        hasError = true;
        errors[fieldName][index].acpe.addError(errorMessage);
      }
    });

    if (hasError) {
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag('Custom Form validation Error - acpeCoverageLimit', {
          errorMessage,
          inputData: data,
        });
      }
    }
    return { isError, warningMsgs };
  },
  propertyDamage: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    // WI OH TN IN  can reuse
    const pdLimit = data?.propertyDamage;
    const bodilyInjury = data?.bodilyInjury?.split('/');
    if (bodilyInjury.length > 0 && pdLimit) {
      let errorValidation = false;
      const biPerPerson = bodilyInjury[0];
      if (parseInt(biPerPerson) === 25) {
        if (parseInt(pdLimit) > 25) {
          errorValidation = true;
        }
      } else if (parseInt(biPerPerson) === 50) {
        if (parseInt(pdLimit) > 50 || parseInt(pdLimit) < 15) {
          errorValidation = true;
        }
      } else if (parseInt(biPerPerson) === 100) {
        if (parseInt(pdLimit) > 100 || parseInt(pdLimit) < 50) {
          errorValidation = true;
        }
      }
      if (errorValidation) {
        const messages: any = fieldSchema.messages;
        let errorMessage = messages?.[validationType];
        isError = true;
        if (errors && errorMessage && data[fieldName] !== undefined) {
          notifyBugsnag('Custom Form validation Error - propertyDamage', {
            errorMessage,
            inputData: data,
          });
          errors[fieldName].addError(errorMessage);
        }
      }
    }
    return { isError, warningMsgs };
  },
  loanPayoffLimit: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    // WI OH TN IN  can reuse
    let errorFieldsExists = false;
    data.vehiclesList &&
      data?.vehiclesList.forEach((vehicle: any, index: number) => {
        console.log(vehicle);
        const loanPayoffDed = vehicle?.loanPayoffLimit?.toLowerCase();
        const collDed =
          vehicle?.collisionDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.collisionDeductible?.split(' DED');
        const compDed =
          vehicle?.comprehensiveDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.comprehensiveDeductible?.split(' DED');

        if (
          loanPayoffDed &&
          loanPayoffDed !== 'declined' &&
          collDed?.length > 0 &&
          collDed[0] === 'declined' &&
          compDed?.length > 0 &&
          compDed[0] === 'declined'
        ) {
          const messages: any = fieldSchema.messages;
          let errorMessage = messages?.[validationType];
          errorFieldsExists = true;
          if (errors && errorMessage && data[fieldName] !== undefined) {
            notifyBugsnag('Custom Form validation Error - loanPayoffLimit', {
              errorMessage,
              inputData: data,
            });
            errors[fieldName][index].loanPayoffLimit.addError(errorMessage);
          }
        }
      });
    if (errorFieldsExists) {
      isError = true;
    }
    return { isError, warningMsgs };
  },
  carRental: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    // WI OH TN IN  can reuse
    let errorFieldsExists = false;
    data.vehiclesList &&
      data?.vehiclesList.forEach((vehicle: any, index: number) => {
        const currentVin = getCurrentVehicleByVin(vehicle?.vin);
        const carRentalDed = vehicle?.carRental?.toLowerCase();
        const collDed =
          vehicle?.collisionDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.collisionDeductible?.split(' DED');
        const compDed =
          vehicle?.comprehensiveDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.comprehensiveDeductible?.split(' DED');

        if (
          currentVin &&
          carRentalDed &&
          carRentalDed !== 'declined' &&
          ((collDed?.length > 0 && collDed[0] === 'declined') ||
            (compDed?.length > 0 && compDed[0] === 'declined'))
        ) {
          const messages: any = fieldSchema.messages;
          let errorMessage = messages?.[validationType];
          errorFieldsExists = true;
          if (errors && errorMessage && data[fieldName] !== undefined) {
            notifyBugsnag('Custom Form validation Error - carRental', {
              errorMessage,
              inputData: data,
            });
            errors[fieldName][index].carRental.addError(errorMessage);
          }
        }
      });
    if (errorFieldsExists) {
      isError = true;
    }
    return { isError, warningMsgs };
  },
};
