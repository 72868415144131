import { getJson } from 'framework/dataService';
import { applicationForms } from 'library/application/applicationConstants';

export const incidentFormat = (formData: any) => {
  const incidentList: any = [];

  formData?.accidents?.accidentList.forEach((acc: any) => {
    const accidentDescription = incidentDescription(
      'accident',
      acc.typeOfAccident
    );
    const dateSplit = acc.monthYear ? acc.monthYear.split('/') : [];
    const accident = {
      code: acc.typeOfAccident,
      category: '',
      type: 'Accident',
      didInsurancePayOver1000: acc.didInsurancePayOver1000,
      didInsurancePayDamages: acc.didInsurancePayDamages,
      description: accidentDescription,
      location: {
        address: '',
        city: '',
        state: '',
        zip: '',
      },
      'incident-on':
        dateSplit.length > 0 ? `${dateSplit[1]}-${dateSplit[0]}-01` : '',
      disclosed: true,
      disputed: false,
      source: 'self',
    };
    incidentList.push(accident);
  });

  formData?.traffic?.trafficViolationList.forEach((vio: any) => {
    const violationDescription = incidentDescription(
      'violation',
      vio.typeOfViolation
    );
    const dateSplit = vio.monthYear ? vio.monthYear.split('/') : [];
    const violation = {
      code: vio.typeOfViolation,
      category: '',
      type: 'Violation',
      description: violationDescription,
      location: {
        address: '',
        city: '',
        state: '',
        zip: '',
      },
      'incident-on':
        dateSplit.length > 0 ? `${dateSplit[1]}-${dateSplit[0]}-01` : '',
      disclosed: true,
      disputed: false,
      source: 'self',
    };
    incidentList.push(violation);
  });
  return incidentList;
};

export const incidentDescription = (type: string, code?: string) => {
  const existingForms = getJson(applicationForms);
  const drivingHistoryForm =
    existingForms &&
    existingForms.filter((form: any) => form.formName === 'DrivingHistory');
  const accidentList = drivingHistoryForm
    ? drivingHistoryForm[0]?.schema?.definitions?.accidents?.allOf[0]?.then
        ?.properties?.accidentList?.items?.properties?.typeOfAccident
    : {};
  const violationList = drivingHistoryForm
    ? drivingHistoryForm[0]?.schema?.definitions?.traffic?.allOf[0]?.then
        ?.properties?.trafficViolationList?.items?.properties?.typeOfViolation
    : {};
  let description = '';
  if (type === 'accident') {
    const accidentTypeIndex = accidentList?.enum?.indexOf(code);
    description = accidentList?.enumNames[accidentTypeIndex];
  }
  if (type === 'violation') {
    const violationTypeIndex = violationList.enum.indexOf(code);
    description = violationList.enumNames[violationTypeIndex];
  }
  return description;
};

export const getIncidentCode = (incident: any) => {
  const dwiList: any = ['BOT', 'DWI', 'REF'];
  const spdList: any = ['SPD'];
  const indList: any = ['CMU', 'DEQ'];
  const minList: any = [
    'DEV',
    'FRA',
    'FTC',
    'FTY',
    'IP',
    'IT',
    'LIC',
    'MMV',
    'SCH',
    'WSR',
    'CML',
    'FFR',
    'IBK',
    'SAF',
  ];
  const majList: any = [
    'CRD',
    'DR',
    'FDL',
    'FEL',
    'FLE',
    'HOM',
    'LDL',
    'LTS',
    'SLV',
    'SUS',
    'MAJ',
    'UDR',
    'RKD',
  ];
  let incidentData = incident;
  if (dwiList.includes(incidentData.code)) {
    incidentData.category = 'dwi';
  } else if (spdList.includes(incidentData.code)) {
    incidentData.category = 'spd';
  } else if (minList.includes(incidentData.code)) {
    incidentData.category = 'min';
  } else if (indList.includes(incidentData.code)) {
    incidentData.category = 'ind';
  } else if (majList.includes(incidentData.code)) {
    incidentData.category = 'maj';
  } else if (incidentData.code === 'AAF') {
    incidentData.category = 'aaf';
  } else if (incidentData.code === 'NAF') {
    incidentData.category = 'naf';
  }
  return incidentData;
};
