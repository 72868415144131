import InitialInfoSchema from 'formsDefinitions/InitialInfo/schema.json';
import InitialInfoUiSchema from 'formsDefinitions/InitialInfo/uiSchema.json';
import InitialInfoUiSchemaExistingQuote from 'formsDefinitions/InitialInfo/uiSchemaExistingQuote.json';
import RetrieveQuoteSchema from 'formsDefinitions/RetrieveQuote/schema.json';
import RetrieveQuoteUiSchema from 'formsDefinitions/RetrieveQuote/uiSchema.json';
import UbiSchema from 'formsDefinitions/UBI/schema.json';
import UbiUiSchema from 'formsDefinitions/UBI/uiSchema.json';
import TestingSchema from 'formsDefinitions/Testing/schema.json';
import TestingUiSchema from 'formsDefinitions/Testing/uiSchema.json';
import { getJson } from 'framework/dataService/index';
import { applicationForms } from 'library/application/applicationConstants';

const getFormDefinitions = (screenName: string, formData?: any) => {
  let schema: any, uiSchema: any, others: any;

  switch (screenName) {
    case 'InitialInfo':
      schema = InitialInfoSchema;
      uiSchema = InitialInfoUiSchema;
      break;
    case 'RetrieveQuote':
      schema = RetrieveQuoteSchema;
      uiSchema = RetrieveQuoteUiSchema;
      break;
    case 'UBI':
      schema = UbiSchema;
      uiSchema = UbiUiSchema;
      break;
    case 'Testing':
      schema = TestingSchema;
      uiSchema = TestingUiSchema;
      break;
    default:
      try {
        const forms = getJson(applicationForms);
        const form: any = forms.find(
          (screen: any) => screenName === screen.formName
        );
        schema = form.schema;
        uiSchema = form.uiSchema;
      } catch (e) {
        console.error(e);
      }
  }
  return {
    schema,
    uiSchema,
    others,
  };
};

export default getFormDefinitions;
