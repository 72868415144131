import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './index.scss';

import App from './components/App';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG || '',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_ENV,
});

// GTM config
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER!,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));
