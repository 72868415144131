import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function Cube2(props: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='36'
      viewBox='0 0 40 36'
      fill='none'
      {...{ ...defaultProps, ...props }}
    >
      <path
        d='M31.2827 34.9648L12.1435 36.0008L0 17.614L19.0594 12.8398L31.2827 34.9648Z'
        fill='black'
      />
      <path
        d='M18.9215 12.6149L0.869253 17.1369L12.6889 6.00135L29.3101 0.518962L18.9215 12.6149Z'
        fill='white'
        stroke='black'
        strokeWidth='0.5'
      />
      <path
        d='M31.2745 34.4327L19.3614 12.8693L30.0225 0.455965L38.9772 19.5228L31.2745 34.4327Z'
        fill='#6CF96A'
        stroke='black'
        strokeWidth='0.5'
      />
    </svg>
  );
}
