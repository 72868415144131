import React from 'react';
import type { WidgetProps } from '@rjsf/core';
import ToggleButton from 'framework/components/ToggleButton';

const ToggleButtonWidget = (props: WidgetProps) => {
  const { rawErrors } = props;
  return (
    <ToggleButton
      {...{
        ...props,
        isError: rawErrors && rawErrors.length > 0,
      }}
    />
  );
};

export default ToggleButtonWidget;
