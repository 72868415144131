import React from 'react';
import { IIconProps } from './AvatarOne';

export default function PaperClipIcon({
  fillColor = 'black',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      width='20'
      height='24'
      viewBox='0 0 20 24'
      fill={fillColor}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.67188 3.02354C10.5065 -0.0313084 14.3114 -0.918598 17.1703 1.04173C20.0293 3.00205 20.8597 7.06766 19.025 10.1226L19.0234 10.1214L12.5738 20.8608C12.3256 21.2741 11.8105 21.394 11.4233 21.1286L11.2325 20.9977C10.8454 20.7323 10.7328 20.1819 10.981 19.7686L17.4307 9.02896L17.4324 9.0301C18.7023 6.91523 18.1274 4.10075 16.1482 2.74367C14.169 1.38652 11.5348 2.0008 10.2647 4.1157L8.67188 3.02354Z'
        fill='black'
      />
      <path
        d='M10.264 4.11743L2.3421 17.3084C1.49664 18.718 1.87997 20.5928 3.19901 21.497C4.51864 22.4016 6.27497 21.9921 7.12184 20.5823L9.16625 17.1786L9.16496 17.1776L14.2756 8.66797L14.2768 8.66889C14.7 7.9639 14.5085 7.02574 13.8487 6.57337C13.1889 6.12099 12.3109 6.32576 11.8875 7.03072L11.8862 7.02986L7.82818 13.9059C7.57992 14.3192 7.06482 14.4392 6.67762 14.1737L6.48686 14.0429C6.09971 13.7774 5.98712 13.2271 6.23537 12.8137L10.2935 5.93763L10.2948 5.93848C11.2827 4.29363 13.3314 3.8159 14.8709 4.87144C16.4102 5.927 16.8574 8.11617 15.8695 9.76107L15.8683 9.76024L13.8245 13.1632L13.8262 13.1643L8.71514 21.6747C7.30359 24.0242 4.37602 24.7064 2.17671 23.1988C-0.0226773 21.6912 -0.66136 18.5642 0.750166 16.2145L8.67121 3.02528L10.264 4.11743Z'
        fill='black'
      />
    </svg>
  );
}
