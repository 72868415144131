import { Grid, Typography } from '@mui/material';
import React from 'react';
import { getFormattedDateWithTimezone } from 'utils/dateUtils';
import MicrocopyComponent from '../framework/components/MicrocopyComponent';
import {
  policyInfo,
  coveragePlans,
  ACP,
} from 'library/application/applicationConstants';
import { getJson } from 'framework/dataService';
import { getMetaData } from 'framework/dataService/insuranceApplication';
import { currencyFormatter } from 'utils/util';
import CommonHeader from 'components/commonHeader';

export default function CheckEmailDetail(props: any) {
  const policyDetails = getJson(policyInfo);
  const metaData = getMetaData();
  const selectedCoveragePlan = getJson(coveragePlans).find(
    (plan: any) => plan.planName === metaData.selectedPlanName
  );
  const handleAppStore = () => {
    window.open(
      'https://apps.apple.com/us/app/novo-insurance/id1560121519',
      '_black'
    );
  };
  const handlePlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.novo.insurance.client',
      '_black'
    );
  };
  const handleCall = () => {
    window.open('tel:1-385-352-9852', '_black');
  };
  const handleEmail = () => {
    window.open('mailto:support@novo.us', '_black');
  };
  const helpHeading =
    'Novo agents are available to connect over phone, chat, and email. Monday-Friday 9am-5pm MST.';
  return (
    <Grid
      container
      columnSpacing={1}
      style={{ marginLeft: props.marginLeft, marginTop: props.marginTop }}
    >
      <Grid
        container
        item
        xs={6}
        columnSpacing={1}
        flexWrap='wrap'
        justifyContent='space-between'
      >
        <Grid xs={12}>
          <CommonHeader
            heading1='ACCESS YOUR POLICY CARDS'
            showLine={true}
          ></CommonHeader>
          <Typography
            sx={{
              color: '#55566D',
              fontWeight: 500,
              fontSize: '0.813rem',
              letterSpacing: '0.125em',
              width: 'max-content',
              maxWidth: '100%',
            }}
          >
            <p>1.Confirm your email.</p>
            <p>2.Download the app.</p>
            <p>3.Login to your account.</p>
            <p>4.Enjoy your new coverage!</p>
          </Typography>
        </Grid>
        <Grid xs={12}>
          <CommonHeader
            heading1='GET the novo app'
            showLine={true}
          ></CommonHeader>
          <Typography
            sx={{
              color: '#55566D',
              fontWeight: 500,
              fontSize: '0.813rem',
              letterSpacing: '0.125em',
              width: 'max-content',
              maxWidth: '100%',
            }}
          >
            <p>
              Access you insurance cards, coverage summary and policy documents.
            </p>
          </Typography>

          <Grid
            item
            xs={12}
            container
            flexWrap='wrap'
            justifyContent='space-between'
          >
            <img
              style={{
                objectFit: 'contain',
                marginTop: '2rem',
                cursor: 'pointer',
              }}
              src='/appstore.svg'
              alt={'appstore'}
              onClick={handleAppStore}
            />
            <img
              style={{
                objectFit: 'contain',
                marginTop: '2rem',
                cursor: 'pointer',
              }}
              src='/playstore.svg'
              alt={'playstore'}
              onClick={handlePlayStore}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} container>
        <CommonHeader
          heading1='NEED ANY HELP?'
          heading2={helpHeading}
          showLine={true}
        ></CommonHeader>
        <Grid item>
          <img
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
            onClick={handleCall}
            src='/call-phone.svg'
            alt={'phone support'}
          />
        </Grid>
        <Grid item>
          <img
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
            onClick={handleEmail}
            src='/email-support.svg'
            alt={'email support'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
