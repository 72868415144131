import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function HelpIcon({
  fillColor = 'currentColor',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      {...{ ...defaultProps, ...svgProps }}
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 15C2 22.1797 7.8203 28 15 28C22.1797 28 28 22.1797 28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15ZM15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0Z'
        fill={fillColor}
      />
      <path
        d='M15.1508 9.92C14.3028 9.92 13.6948 9.704 13.3268 9.272C12.9748 8.84 12.7988 8.304 12.7988 7.664C12.7988 7.024 12.9908 6.496 13.3748 6.08C13.7748 5.664 14.3748 5.456 15.1748 5.456C15.9748 5.456 16.5828 5.648 16.9988 6.032C17.4148 6.4 17.6228 6.936 17.6228 7.64C17.6228 8.328 17.4228 8.88 17.0228 9.296C16.6228 9.712 15.9988 9.92 15.1508 9.92ZM11.5268 11.576H17.4068V22.568H18.7508V23H11.6228V22.568H12.9428V12.008H11.5268V11.576Z'
        fill={fillColor}
      />
    </svg>
  );
}
