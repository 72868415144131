import React from 'react';
import { ReactComponent as Attachment } from '../../images/attachment.svg';

const Link = (props: any) => {
  const { url, linkText, linkIcon } = props;
  return (
    <div className='download-link'>
      <a href={url} target='_blank' rel='noopener noreferrer'>
        {linkIcon === 'iconAttachment' && (
          <span className='icon'>
            <Attachment />
          </span>
        )}
        {!!linkText && linkText}
      </a>
    </div>
  );
};

export default Link;
