import React /* useContext */ from 'react';
import { ObjectFieldTemplateProps /* , utils */ } from '@rjsf/core';
import { Grid } from '@mui/material';

// const { canExpand } = utils;

const CustomObjectFieldTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  disabled,
  readonly,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
}: ObjectFieldTemplateProps) => {
  const layout = uiSchema['ui:layout'];
  const { $id } = idSchema;
  if ($id === 'root_computed') {
    return null;
  }
  return (
    <>
      {(uiSchema['ui:title'] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      <Grid container={true} spacing={2}>
        {properties.map((element, index) => {
          const elementLayout = layout ? layout[element.name] : {};
          const hideIfFormEmpty =
            element.content.props.uiSchema['ui:options']?.hideIfFormEmpty;
          const hideEmptyForm =
            hideIfFormEmpty && element.content.props.formData.length === 0;
          return (
            // Remove the <Grid> if the inner element is hidden as the <Grid>
            // itself would otherwise still take up space.
            element.hidden || hideEmptyForm ? (
              element.content
            ) : (
              <Grid
                item={true}
                lg={
                  elementLayout?.lg ||
                  elementLayout?.md ||
                  elementLayout?.sm ||
                  elementLayout?.xs ||
                  12
                }
                md={
                  elementLayout?.md ||
                  elementLayout?.sm ||
                  elementLayout?.xs ||
                  12
                }
                sm={elementLayout?.sm || elementLayout?.xs || 12}
                xs={elementLayout?.xs || 12}
                key={index}
                className={elementLayout?.className || 'inner-form-grid'}
                style={{ marginBottom: '0px', paddingTop: '24px' }}
              >
                {element.content}
              </Grid>
            )
          );
        })}
        {/* Comment out code for add button temporarily : Original Code in ObjectField Template rjsf/material-ui */}
        {/* {canExpand(schema, uiSchema, formData) && (
                <Grid container justifyContent="flex-end">
                    <Grid item={true}>
                        <AddButton
                            className="object-property-expand"
                            onClick={onAddClick(schema)}
                            disabled={disabled || readonly}
                        />
                    </Grid>
                </Grid>
            )} */}
      </Grid>
    </>
  );
};

export default CustomObjectFieldTemplate;
