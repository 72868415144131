import * as React from 'react';
import './NovoFileUpload.scss';
import { Box, IconButton } from '@mui/material';
import TrashIcon from 'components/icons/TrashIcon';
import HelpIcon from 'components/icons/HelpIcon';
import { useEffect, useState } from 'react';
import PaperClipIcon from 'components/icons/PaperClip';
import { getJson, setJson } from 'framework/dataService';

export interface NovoFileUploadProps {
  text?: string;
  id: string;
  disabled?: boolean;
  className?: string;
  isError?: boolean;
  name?: string;
  description?: string;
  moreInfo?: string;
  sessionKey?: string;
  onChange?: (value: any) => void;
}

const NovoFileUpload: React.FC<NovoFileUploadProps> = ({
  id,
  className,
  disabled = false,
  isError,
  name,
  onChange,
  sessionKey = 'reconFileUpload',
  description = 'Upload PDF document to show proof',
  moreInfo = 'Examples of documents are a police report, accident report, or letter of experience from your prior Insurance carrier',
}) => {
  let randomString = Math.random().toString(36);
  const [fileName, setFileName] = useState(description);
  const [hasFile, setHasFile] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [inputKey, setInputKey] = useState(randomString);

  const resetFileInput = (event: any) => {
    event.stopPropagation();
    randomString = Math.random().toString(36);
    setInputKey(randomString);
    setHasFile(false);
    setFileName(description);
    if (onChange) {
      onChange('');
    }
  };

  const toggleMoreInfo = (event: any) => {
    event.stopPropagation();
    setShowMoreInfo(!showMoreInfo);
  };

  useEffect(() => {
    if (sessionKey) {
      const exsistingFiles = getJson(sessionKey);
      if (
        exsistingFiles &&
        Object.keys(exsistingFiles).length > 0 &&
        Object.keys(exsistingFiles)[0].includes(
          'accidentPreventionCourseDocument'
        )
      ) {
        const fileDataSplit =
          exsistingFiles.root_accidentPreventionCourseDocument.split('-Name:');
        const uploadedFileName =
          fileDataSplit.length > 1 ? fileDataSplit[1] : description;
        setFileName(uploadedFileName);
      }
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0].name : description;
    if (event.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener('load', () => {
        if (reader.result) {
          const exsistingFiles = getJson(sessionKey);
          const uploadedFileData: any = reader.result;
          const uploadedFileId = event.target.id;
          if (exsistingFiles) {
            const finalData = {
              ...exsistingFiles,
              [uploadedFileId]: `${uploadedFileData}-Name:${file}`,
            };
            setJson(sessionKey, finalData);
          } else {
            setJson(sessionKey, {
              [uploadedFileId]: `${uploadedFileData}-Name:${file}`,
            });
          }
          if (onChange) {
            onChange(file);
          }
        }
      });
    }
    setFileName(file);
    setHasFile(true);
  };

  return (
    <>
      <div className='input-file-container'>
        <div className='border-decor' />
        <input
          className='input-file'
          id={id}
          type='file'
          accept='.pdf'
          key={inputKey}
          onChange={handleChange}
        />
        <label className='input-file-trigger'>
          <PaperClipIcon />
          <span className='file-name'>
            <div className='green-underline'></div>
            {fileName}
          </span>
        </label>
        <div className='file-input-action'>
          {!hasFile && (
            <IconButton onClick={toggleMoreInfo}>
              <HelpIcon />
            </IconButton>
          )}
          {hasFile && (
            <button type='submit' onClick={resetFileInput}>
              <TrashIcon />
            </button>
          )}
        </div>
      </div>
      {showMoreInfo && <div className='more-info'>{moreInfo}</div>}
    </>
  );
};

export default NovoFileUpload;
