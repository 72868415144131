import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Icon, Stack, Typography } from '@mui/material';
import Page from 'components/Page';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import React from 'react';

const useStyles = makeStyles({
  root: {
    width: 200,
    height: 150,
  },
});

export default function Acknowledgement() {
  const novoRouteKey = 'Acknowledgement';

  const classes = useStyles();

  const appStoreIcon = (
    <Link
      href='https://apps.apple.com/us/app/novo-insurance/id1560121519'
      target='_blank'
    >
      <Icon className={classes.root}>
        <img alt='Download on the App Store' src='./appstore.png' />
      </Icon>
    </Link>
  );

  const googlePlayIcon = (
    <Link
      href='https://play.google.com/store/apps/details?id=com.novo.insurance.client&hl=en_US&gl=US'
      target='_blank'
    >
      <Icon className={classes.root}>
        <img alt='Get it on Google Play' src='./googleplay.png' />
      </Icon>
    </Link>
  );

  return (
    <Grid container className='quote-wrapper' ml={2} mr={2}>
      <Page routeKey={novoRouteKey}>
        <Grid item xs={12}>
          <Typography variant='h5' align='left'>
            <Box sx={{ fontWeight: 'bold' }}>
              {' '}
              <MicrocopyComponent labelKey={`q2b.download.novo.app.text`} />
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='subtitle2'
            align='left'
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          >
            <MicrocopyComponent labelKey={`q2b.download.novo.app.subtitle2`} />
          </Typography>
        </Grid>
        <Stack direction='row'>
          <Grid sx={{ ml: '-18px' }}>{appStoreIcon}</Grid>
          <Grid>{googlePlayIcon}</Grid>
        </Stack>
      </Page>
    </Grid>
  );
}
