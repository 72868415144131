import Ajv from 'ajv';
import connectWithErrorsLibrary from 'ajv-errors';
import additionalCustomFormats from 'framework/forms/lib/CustomFormats';
import { CustomSchemaValidation } from 'framework/forms/lib/customValidation/index';
import { CustomJsonSchemaProps } from 'framework/forms/interfaces/CustomJsonSchemaProps';

const ajv = new Ajv({
  // 1. The error message is custom property, we have to disable strict mode firstly
  strict: false,
  // 2. This property enables custom error messages
  allErrors: true,
  formats: {
    ...additionalCustomFormats,
  },
});
// 3. We have to connect an additional library for this
connectWithErrorsLibrary(ajv);

export default function Validate(
  formData: any,
  schema: any,
  ignoreCustomValidation?: boolean
) {
  // 6. Set up validation container
  const ajvValidate = ajv.compile(schema);

  // 7. Perform validation.
  // ... It's not straightforward, but the result will be inside the "error" property
  ajvValidate(formData);
  let isError = !!ajvValidate.errors;
  // console.debug('field error:', ajvValidate.errors);
  if (!isError && !ignoreCustomValidation) {
    isError = CustomSchemaValidation(
      schema as CustomJsonSchemaProps,
      formData
    ).isError;
  }
  return isError;
}
