import { getDriverById, setDriver } from 'framework/dataService/drivers';

export const defaultDriverFields = {
  //included: true,
};

export const getCurrentDriver = (driverId: string) => {
  let currentDriver = { ...getDriverById(driverId) };
  if (!currentDriver) {
    currentDriver = {
      primary: false,
      ...defaultDriverFields,
    };
  } else {
    currentDriver = {
      ...defaultDriverFields,
      ...currentDriver,
    };
  }
  return currentDriver;
};

export const setCurrentDriver = (driverId: string, value: any) => {
  let currentDriver = { ...value };
  if (driverId != null) {
    return setDriver(currentDriver);
  } else {
    return {};
  }
};

export const updateCurrentDriver = (driverId: string, value: any) => {
  if (value && Object.keys(value).length > 0) {
    let currentDriver = getCurrentDriver(driverId);
    let updatedDriver = { ...currentDriver, ...value };
    return setDriver(updatedDriver);
  }
};
