import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function MobileIcon({
  fillColor = 'black',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      width='21'
      height='26'
      viewBox='0 0 21 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ ...defaultProps, ...svgProps }}
    >
      <path
        d='M11.5221 8.56914C11.799 8.84606 11.9836 9.12299 12.1683 9.49222C13.1836 8.66145 14.1067 7.73837 14.9375 6.72299C14.5683 6.53837 14.2913 6.35376 14.0144 6.07683C12.8144 4.87683 13.276 3.39991 14.476 2.19991C15.676 0.99991 17.1529 0.630679 18.3529 1.73837C19.5529 2.84606 19.7375 5.06145 18.8144 6.44606C17.1529 9.03068 14.5683 11.523 11.9836 13.2768C10.599 14.1999 8.47596 14.0153 7.27596 12.8153C6.07596 11.6153 6.53749 10.2307 7.73749 9.03068C8.93749 7.83068 10.3221 7.46145 11.5221 8.56914Z'
        stroke='black'
        strokeWidth='1.6'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M13.9231 16.6923V23.1538C13.9231 24.1692 13.0923 25 12.0769 25H2.84615C1.83077 25 1 24.1692 1 23.1538V2.84615C1 1.83077 1.83077 1 2.84615 1H11.1538'
        stroke='black'
        strokeWidth='1.6'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M1 19.4609H13.9231'
        stroke='black'
        strokeWidth='1.6'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.53906 22.2305H8.38522'
        stroke='black'
        strokeWidth='1.6'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
    </svg>
  );
}
