import { Button } from '@mui/material';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import ButtonArrow from 'components/icons/ButtonArrow';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import MuiLoader from 'framework/components/MuiLoader';
import { getItem } from 'framework/dataService';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import Validate from 'framework/forms/validations/Validate';
import getFormDefinitions from 'framework/schemaServer';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { incidentRiskValidation } from 'utils/driver';
import { incidentFormat } from 'utils/driver/IncidentFormat';
import { notifyBugsnag } from 'utils/util';
import { DriverContext } from './DriverFormWrapper';

export interface DrivingHistoryProps {
  disableNext?: boolean;
  updateStatus?: any;
  index?: number;
  initialFormData?: any;
  setDirty?: boolean;
  setInitialFormData?: any;
  driverId?: any;
  mode?: string;
  readonly?: boolean;
}

export default function DrivingHistory({
  disableNext,
  driverId = -1,
  updateStatus,
  index,
  mode,
  readonly,
}: DrivingHistoryProps) {
  const hasComponentMounted = useRef(false);
  const [isDirty, setIsDirty] = useState(true as boolean);
  const [initialFormData, setInitialFormData] = useState({} as any);
  const [showLoader, setShowLoader] = useState(false);
  const [showCsrDialog, setShowCsrDialog] = useState(false);

  const routeKey = 'DrivingHistory';
  const navigate = useNavigate();
  const { schema } = getFormDefinitions(routeKey);
  const driverContextData = useContext(DriverContext);
  const [prevDriverContext, setPrevDriverContext] = useState(driverContextData);

  useEffect(() => {
    function loadFormData() {
      const param = {
        driverId: driverId,
      };
      const dataForFormData = getData('EditDrivingHistory', param);
      let formData;
      if (dataForFormData?.driver) {
        formData = { ...dataForFormData.driver };
        setInitialFormData(formData);
        // validate formData
        const errors = Validate(formData, schema);
        if (updateStatus && !errors && formData.isDataReviewed) {
          // done: when all required fields have data
          setIsDirty(false);
          updateStatus(index, 'done', 'onLoad');
        }
      }
    }

    // this works as componentDidMount, works only once
    if (!hasComponentMounted.current) {
      loadFormData();
      hasComponentMounted.current = true;
    }

    // this works as componentDidUpdate, works when context is updated
    if (
      JSON.stringify(driverContextData) !== JSON.stringify(prevDriverContext)
    ) {
      loadFormData();
      setPrevDriverContext(driverContextData);
    }
  }, [
    driverId,
    index,
    schema,
    updateStatus,
    driverContextData,
    prevDriverContext,
  ]);

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    formData.isDataReviewed = true;
    setShowLoader(true);
    const lastFormIndex = formData?.traffic?.trafficViolationList?.length;
    if (lastFormIndex > 0) {
      delete formData.traffic.trafficViolationList[lastFormIndex - 1].inAddMode;
    }
    const driverData = getData('EditDriver', { driverId });
    const formatedData = incidentFormat(formData);
    let isIncidentRisk = false;
    const agentIdSesssion = getItem('isAgent');
    if (!agentIdSesssion) {
      isIncidentRisk = incidentRiskValidation(formatedData);
    }

    if (!isIncidentRisk) {
      driverData.driver.drivingHistory = formData;
      driverData.driver.reconciliation.claimHistory = [
        {
          'claim-list': [],
          'incident-list': formatedData,
        },
      ];
      const dataForPersistance = {
        driver: {
          ...driverData.driver,
        },
      };

      if (persistanceService) persistanceService(dataForPersistance);

      setIsDirty(false);

      let path;
      if (updateStatus) {
        path = await updateStatus(index, 'done', 'onSubmit');
      }
      setShowLoader(false);
      // navigate("/drivers");
      navigate(path);
    } else {
      notifyBugsnag('CSR pop up validation - Incidents', formData);
      setShowLoader(false);
      setShowCsrDialog(true);
    }
  };

  const handleDialogClose = () => {
    setShowCsrDialog(false);
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (
      initialFormData?.accidents?.accidents &&
      !formData?.accidents?.accidents
    ) {
      //remove array list
      formData.accidents.accidentList = [];
    }
    if (
      initialFormData?.traffic?.trafficViolations &&
      !formData?.traffic?.trafficViolations
    ) {
      //remove array list
      formData.traffic.trafficViolationList = [];
    }
    // let dataSubmitted = {
    //   ...initialFormData,
    //   ...formData,
    // };
    if (!isDirty && !equals(initialFormData, formData)) {
      // formData just got dirty
      setIsDirty(true);
      if (updateStatus) updateStatus(index, 'open', 'onChange');
    }

    setInitialFormData(formData);
    // if (updateStatus && submissionDone) updateStatus(index, 'open', 'onChange');
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    return errors;
  };

  return (
    <>
      {showLoader && <MuiLoader />}
      {
        <CsrDialog
          showDialog={showCsrDialog}
          handleDialogClose={handleDialogClose}
        />
      }
      <NovoForm
        disabled={readonly}
        schemaName={routeKey}
        transitionTo={false}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        validate={handleValidation}
        button={
          <Button
            id='driverUpdate'
            className='btn-arrow'
            type='submit'
            color='secondary'
            variant='contained'
            disabled={disableNext}
            endIcon={<ButtonArrow />}
          >
            <MicrocopyComponent labelKey={`q2b.global.button.text.next`} />
          </Button>
        }
        formData={initialFormData}
        // hideSubmitButton={!isDirty}
      />
    </>
  );
}
