import type { WidgetProps } from '@rjsf/core';
import React from 'react';
import CustomNumberWidget from './CustomNumberWidget';
import './ACPEWidget.scss';

const ACPEWidget = (props: WidgetProps) => {
  return (
    <>
      <hr />
      <h4>Custom Parts and Equipment</h4>
      <p>
        These Accessories or enhancements are permanently installed in or on
        your vehicle by anyone other than the original manufacturer. These could
        be items that alter the appearance or performance of a vehicle. Anything
        installed by the dealer at the point of original sale wouldn't be
        considered custom but would be covered by Comprehensive and Collision
        coverages.
      </p>
      <CustomNumberWidget {...props} />
      <p className='provided-cov'>
        Comprehensive and Collision each provide up to $1,000 of coverage for
        custom parts or equipment. Any additional coverage would need to be
        added to your policy?
      </p>
    </>
  );
};

export default ACPEWidget;
