import { Box, Button, /* Dialog, */ Grid, Typography } from '@mui/material';
import ButtonArrow from 'components/icons/ButtonArrow';
import Page from 'components/Page';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import SimpleDialog from 'framework/components/Dialogs/SimpleDialog';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { getItem, setItem } from 'framework/dataService';
//import MuiButton from 'framework/components/MuiButton';
//import MuiLoader from 'framework/components/MuiLoader';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import { getData } from 'framework/dataService/dataRehydration';
import { novoNavigation, novoRoutes } from 'framework/routes';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionToProps from 'types/Transition';
import {
  calculateDestination,
  findFirstIncompleteDriverForm,
  findFirstRestrictedDriver,
  driverValidation,
} from 'utils/driver';
import { notifyBugsnag, removeMarkUp } from 'utils/util';
import Driver from './Driver';
import DriverRowCards from './DriverRowCards';

export default function Drivers({
  to,
  view,
  updateFormDirtyFlag,
}: TransitionToProps) {
  const routeKey = 'Drivers';
  const navigate = useNavigate();
  const [drivers, setDrivers] = useState([] as any[]);
  const [readonly, setReadonly] = useState(false);
  const [driverLimitExceed, setDriverLimitExceed] = useState(false);
  const [disableAddDriver, setDisableAddDriver] = useState(false);
  const [showCustomDialog, setShowCustomDialog] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [showCsrDialog, setShowCsrDialog] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const ratedDriver = ['PERMIT', 'INCLUDED', 'ELIGIBLE TO BE RATED'];
  useEffect(() => {
    const dataForFormData = getData(routeKey);
    if (dataForFormData?.metaData) {
      setReadonly(true);
    }
    if (dataForFormData?.drivers?.length > 0) {
      dataForFormData.drivers.forEach((driver: any) => {
        const { formCompletionPercentage } =
          findFirstIncompleteDriverForm(driver);
        driver.progressValue = formCompletionPercentage;
      });
      setDrivers([...dataForFormData.drivers]);
      const maxDriverLimit = removeMarkUp(
        getMicroCopyResourceItem(`q2b.drivers.list.max.drivers.count`)
      );
      if (dataForFormData?.drivers.length >= maxDriverLimit) {
        setDriverLimitExceed(true);
        setDisableAddDriver(true);
      } else {
        setDriverLimitExceed(false);
        setDisableAddDriver(false);
      }
    }
  }, [deleteCount]);

  const handleDialogClose = () => {
    setShowCsrDialog(false);
    setShowCustomDialog(false);
  };

  const handleNextButton = async () => {
    // calculate destination
    setItem('ReconciliationViewed', 'false');
    const agentIdSesssion = getItem('isAgent');
    let stopQuote = false;
    let validationMsg = '';
    let fieldType = '';
    if (!agentIdSesssion) {
      const response = driverValidation();
      stopQuote = response.shouldStopQuote;
      validationMsg = response.validationMessage;
      fieldType = response.fieldType;
      setValidationMessage(response.validationMessage);
    }

    if (!stopQuote) {
      const path = calculateDestination(
        routeKey,
        to,
        novoNavigation.Drivers.nextPage
      );
      if (path === novoNavigation.Drivers.nextPage) {
        // before navigating to The Cars
        // check CSR
        const { firstRestrictedDriverIndex /* firstRestrictedFormIndex */ } =
          findFirstRestrictedDriver();
        if (firstRestrictedDriverIndex < 0) {
          navigate(path);
        } else {
          setShowCsrDialog(true);
        }
      } else {
        navigate(path);
      }
    } else {
      notifyBugsnag(`CSR pop up validation - ${fieldType}`, drivers);
      if (validationMsg) {
        setShowCustomDialog(true);
      } else {
        setShowCsrDialog(true);
      }
    }
  };

  const listOfDrivers = drivers
    .filter((driver: any) => driver.isDeleted !== true)
    .map((driver: any, index: number) => {
      const excluded = ['Excluded'].includes(driver.driverType);
      return (
        <Driver
          disabled={readonly}
          driverId={driver.driverId}
          firstName={driver?.name?.firstName}
          lastName={driver?.name?.lastName}
          gender={driver?.gender}
          primary={driver.primary}
          isInsured={driver.included}
          isExcluded={excluded}
          isRated={ratedDriver.includes(driver?.driverType?.toUpperCase())}
          progressValue={driver.progressValue}
          mode='edit'
          to={to}
          key={index}
          index={index}
          readonly={readonly}
          updateList={() => setDeleteCount(deleteCount + 1)}
        />
      );
    });

  const listOfDriversRow = drivers
    .filter((driver: any) => driver.isDeleted !== true)
    .map((driver: any, index: number) => (
      <React.Fragment key={driver.driverId}>
        <DriverRowCards
          driverId={driver.driverId}
          firstName={driver?.name?.firstName}
          lastName={driver?.name?.lastName}
          gender={driver?.gender}
          primary={driver.primary}
          included={driver.included}
          mode='edit'
          to={to}
          readonly={readonly}
          index={index}
          updateFormDirtyFlag={updateFormDirtyFlag}
          driverType={driver.driverType}
        />
      </React.Fragment>
    ));

  return (
    <Grid container className='quote-wrapper'>
      <SimpleDialog
        showDialog={showCustomDialog}
        handleDialogClose={handleDialogClose}
        content={{
          title: 'q2b.modal.uneven.unmarried.drivers.title',
          messages: [validationMessage],
        }}
      />
      <CsrDialog
        showDialog={showCsrDialog}
        handleDialogClose={handleDialogClose}
      />
      {view === 'row' ? (
        <>
          <Typography
            variant='h5'
            className={'page-header-h5 heading-title'}
            sx={{ marginBottom: '1.5rem' }}
          >
            <MicrocopyComponent
              labelKey={`q2b.plan.options.plan.coverages.drivers.title`}
            />
          </Typography>
          {listOfDriversRow}
        </>
      ) : (
        <>
          <Page routeKey={routeKey} disabled={readonly}>
            <>
              <Typography variant='h5' className='page-header-h5'>
                <MicrocopyComponent labelKey={`q2b.drivers.list.title`} />
              </Typography>
              <Box className='container_tile'>
                {listOfDrivers}
                {!readonly && !disableAddDriver && (
                  <Driver readonly={driverLimitExceed} />
                )}
              </Box>
              <Typography
                className='page-header-h2'
                sx={{
                  color: '#55566D',
                  marginBottom: '2.25rem',
                }}
              >
                {disableAddDriver && (
                  <MicrocopyComponent labelKey={'q2b.drivers.count.reached'} />
                )}
              </Typography>
              <Box m={1} />
              <Button
                id='continue'
                className='btn-arrow'
                type='submit'
                color='secondary'
                variant='contained'
                onClick={handleNextButton}
                endIcon={<ButtonArrow />}
              >
                {/* Next */}
                <MicrocopyComponent labelKey={`q2b.global.button.text.next`} />
              </Button>
            </>
          </Page>
        </>
      )}
    </Grid>
  );
}
