import type { WidgetProps } from '@rjsf/core';
import MuiPassword from 'framework/components/MuiPassword';
import React from 'react';

const MuiPasswordWidget = (props: WidgetProps) => {
  const { rawErrors, id } = props;
  return (
    <MuiPassword
      password={''}
      showPassword={false}
      {...{
        ...props,
        isError: rawErrors && rawErrors.length > 0,
      }}
      id={id}
    />
  );
};

export default MuiPasswordWidget;
