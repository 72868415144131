import { Box, Dialog, Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CoverageIcon from 'components/icons/CoverageIcon';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import { getOnlyNumbers, removeMarkUp } from 'utils/util';
import MicrocopyComponent from '../MicrocopyComponent';
import MuiButton from '../MuiButton';
import React from 'react';

interface CsrDialogProps {
  showDialog: boolean;
  handleDialogClose?: any;
}

const CsrDialog: React.FC<CsrDialogProps> = ({
  showDialog,
  handleDialogClose,
}) => {
  const defaultDialog = {
    closeOutside: false,
    display: true,
    body: {
      heading1: 'q2b.csr.modal.heading1',
      heading2: 'q2b.csr.modal.heading2',
      labelWithIcon: [
        {
          label: `Call ${removeMarkUp(
            getMicroCopyResourceItem(`q2b.csr.telephoneNumber`)
          )}`,
          clickUrl: `tel:${getOnlyNumbers(
            removeMarkUp(getMicroCopyResourceItem(`q2b.csr.telephoneNumber`))
          )}`,
          underline: true,
          imgsrc: 'mobileIcon',
        },
        {
          label: `${removeMarkUp(getMicroCopyResourceItem(`q2b.csr.email`))}`,
          clickUrl: `mailto:${removeMarkUp(
            getMicroCopyResourceItem(`q2b.csr.email`)
          )}`,
          underline: true,
          imgsrc: 'mailIcon',
        },
      ],
    },
    footer: '',
  };

  return (
    <Dialog onClose={() => handleDialogClose(false)} open={showDialog}>
      <Grid container className={'dialog'} sx={{ border: '10px solid #000' }}>
        <>
          {defaultDialog.body.heading1 && (
            <Typography
              variant='h4'
              component='div'
              className={'muiDialog-title'}
            >
              <MicrocopyComponent labelKey={defaultDialog.body.heading1} />
            </Typography>
          )}
          <Divider
            sx={{ borderBottomWidth: 4 }}
            className={'muiDialog-title-divider'}
            style={{ width: '40px', borderColor: '#000' }}
          />
          {defaultDialog.body.heading2 && (
            <Typography
              variant='subtitle1'
              component='div'
              className={'muiDialog-subtitle'}
            >
              <MicrocopyComponent labelKey={defaultDialog.body.heading2} />
            </Typography>
          )}
          {defaultDialog.body.labelWithIcon
            ? defaultDialog.body.labelWithIcon.map((item: any, index: any) => {
                return (
                  <Box
                    className={'label-with-icon-field'}
                    key={index}
                    sx={{
                      borderRadius: '0px',
                      width: '100%',
                      border: 'solid 2px #E8E9EE',
                      position: 'relative',
                      cursor: 'pointer',
                      mb: 4,
                    }}
                    onClick={() => window.open(item.clickUrl, '_blank')}
                  >
                    {item && (
                      <Grid
                        container
                        sx={{ padding: '10px 15px', alignItems: 'center' }}
                      >
                        {item.imgsrc && (
                          <Grid item sx={{ mr: '12px' }}>
                            <CoverageIcon icon={item.imgsrc} />
                          </Grid>
                        )}
                        <Grid
                          item
                          sx={{
                            flex: '1 1 0',
                            minWidth: '6rem',
                            textDecoration: item.underline
                              ? 'underline'
                              : 'none',
                            wordBreak: 'break-all',
                          }}
                        >
                          {item.label}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                );
              })
            : ''}

          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <MuiButton
                id='continue'
                className='btn-gray btn-w-unset'
                type='submit'
                color='secondary'
                variant='contained'
                text={
                  <MicrocopyComponent
                    labelKey={`q2b.global.button.text.close`}
                  />
                }
                onClick={() => handleDialogClose(false)}
              />
            </Grid>
          </Grid>
        </>
      </Grid>
    </Dialog>
  );
};

export default CsrDialog;
