import './Tile.scss';

import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import AddLogo from 'components/icons/AddAvatar';
import React, { HTMLProps } from 'react';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import EditAndRemoveComponent from 'components/EditAndRemoveComponent';

interface TileProps extends HTMLProps<HTMLDivElement> {
  disabled: boolean;
  isRemoveDisabled: boolean;
  subject: string;
  key?: string | number;
  heading1: string | JSX.Element;
  heading2: string | JSX.Element;
  progressValue: number;
  mode: 'edit' | 'add';
  avatar: JSX.Element;
  context: string;
  onEditClick: () => void;
  updateList?: () => void;
  itemId: string;
}
export default function Tile({
  disabled,
  isRemoveDisabled,
  heading1,
  heading2,
  progressValue,
  avatar,
  mode,
  key,
  context,
  subject,
  onEditClick,
  updateList,
  itemId,
  ...divProps
}: TileProps) {
  return (
    <Grid
      container
      component='div'
      className='quote-wrappers'
      sx={{ flexBasis: '255px', flexGrow: 1 }}
      key={key}
    >
      <div
        className='quote-sub-wrapper'
        {...(mode === 'add' ? { ...divProps } : {})}
      >
        <Paper
          className={`tile_wrapper tile_variant1 ${
            mode === 'add' ? ' add-mode cursor-pointer' : ' edit-mode '
          } `}
          variant='outlined'
          elevation={0}
        >
          {mode === 'edit' && (
            <section className={'tile_info-wrapper'}>
              {avatar}
              <Box className='tile_info'>
                <section style={{ marginBottom: '1.375rem' }}>
                  <Typography variant='body2' sx={{ fontWeight: 700 }}>
                    {heading1}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{
                      color: '#55566D',
                      fontSize: {
                        xs: '1rem !important',
                        md: '1.25rem !important',
                        fontWeight: {
                          xs: '500 !important',
                          md: '400 !important',
                        },
                      },
                    }}
                  >
                    {heading2}
                  </Typography>
                </section>

                <Grid component='section' item xs={12}>
                  <Box sx={{ width: '100%', marginBottom: '1.25rem' }}>
                    <LinearProgress
                      color='secondary'
                      variant='determinate'
                      value={progressValue}
                    />
                  </Box>

                  <Typography className='tile_progress-label'>
                    <MicrocopyComponent
                      labelKey='q2b.car.list.tiles.progress.label'
                      placeHolderKey='$ProgressValue$'
                      actualValue={progressValue}
                    />
                  </Typography>
                </Grid>
              </Box>
            </section>
          )}

          {mode === 'add' && (
            <section className='tile_info-wrapper mode_add'>
              <Box sx={{ marginBottom: { xs: '24px', md: '36px' } }}>
                <AddLogo />
              </Box>
              <section className='tile_info'>
                {context === 'driver' && (
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: 900,
                      fontSize: {
                        xs: '1rem !important',
                        md: '1.25rem !important',
                      },
                      letterSpacing: '0.188em',
                    }}
                    variant='h6'
                  >
                    <MicrocopyComponent
                      labelKey={`q2b.driver.list.tiles.add.driver.label`}
                    />
                  </Typography>
                )}
                {context === 'vehicle' && (
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: 900,
                      letterSpacing: '0.188em',
                    }}
                    variant='h6'
                  >
                    <MicrocopyComponent
                      labelKey={`q2b.car.list.tiles.add.car.label`}
                    />
                  </Typography>
                )}
              </section>
            </section>
          )}
        </Paper>
        {mode === 'edit' && (
          <EditAndRemoveComponent
            disabled={disabled}
            isRemoveDisabled={isRemoveDisabled}
            itemId={itemId}
            subject={subject}
            context={context}
            onEditClick={() => onEditClick()}
            updateList={updateList}
          />
        )}
      </div>
    </Grid>
  );
}
