import { Box, Grid } from '@mui/material';
import SaveProgress from 'components/SaveProgress';
import LeftNav from 'framework/components/LeftNav';
import { novoRoutes } from 'framework/routes';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from 'framework/components/Header';
import { ReactComponent as NovoLogo } from '../images/partnerImages/novo_logo_black.svg';
import PageFooter from './PageFooter';
import HorizontalNav from 'framework/components/Status';
import {
  getMetaDataItem,
  setMetaDataItem,
} from 'framework/dataService/insuranceApplication';
import './Page.scss';
import {
  completedRoute,
  progressConstant,
} from 'library/application/applicationConstants';

export interface PageProps {
  children: React.ReactNode;
  routeKey: string;
  hideHeader?: boolean;
  overWrittenHeader?: any;
  placeHolderText?: string;
  actualValue?: string;
  disabled?: boolean;
}

const Page: React.FC<PageProps> = ({
  children,
  routeKey,
  hideHeader = false,
  overWrittenHeader = {},
  placeHolderText,
  actualValue,
  disabled = false,
}) => {
  const novoRoute = novoRoutes[routeKey];
  const readonly = getMetaDataItem('readonly');
  const activeStepIndex = novoRoute.activeStep - 1;

  useEffect(() => {
    // this code updates the completedRoute with currentPageNavIndex (if greater than completedRoute)
    const progressData = getMetaDataItem(progressConstant);
    let stepReachedIndex = progressData
      ? parseInt(progressData[completedRoute], 10) - 1
      : 0;

    if (stepReachedIndex < activeStepIndex) {
      const newProgressData = {
        ...(progressData && progressData),
        [completedRoute]: activeStepIndex + 1,
      };
      setMetaDataItem(progressConstant, newProgressData);
    }
  }, [activeStepIndex]);

  return (
    <>
      <Helmet>
        <link rel='icon' href='/novo-fav.ico' />
        <title>{`${novoRoute?.pageTitle} - Novo Insurance`}</title>
        <meta
          name='description'
          content='Get a Novo  Auto Insurance quote and buy a policy'
        />
      </Helmet>
      {routeKey !== 'PaymentPortal' ? (
        <>
          <Grid
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 1,
              display: { xs: 'none', md: 'flex' },
            }}
            className={'drawer'}
          >
            <SaveProgress
              displayBtnRetrieveQuote={novoRoute.displayBtnRetrieveQuote}
              displayBtnSaveQuote={novoRoute.displayBtnSaveQuote && !disabled}
            />
          </Grid>
          <Grid container sx={{ position: 'relative' }}>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
              data-testid='activestep'
              className='xs-md_display-hidden left-nav'
            >
              <Box
                className='left-nav_fixed'
                sx={{
                  backgroundImage: {
                    xs: 'none',
                    md: `${
                      novoRoute.displayPageCubes
                        ? 'url("/white-cube.svg"), '
                        : ''
                    } url("/novo-stroke-banner.svg")`,
                  },
                  paddingLeft: { xs: 0, md: '2.25rem' },
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: `${
                    novoRoute.displayPageCubes ? '60.01px 59.78px, ' : ''
                  } contain`,
                  backgroundPosition: `${
                    novoRoute.displayPageCubes ? '2.25rem 75vh, ' : ''
                  } top center`,
                  width: 'inherit',
                  zIndex: '0',
                }}
              >
                <Box
                  className='left-nav_wrapper'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: { xs: '100%', md: 'calc(100% - 2.25rem)' },
                    height: { md: '100vh' },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      height: { xs: '5.5rem', md: 'auto' },
                      justifyContent: 'space-between',
                      borderBottom: { xs: '2px solid black', md: 'none' },
                    }}
                  >
                    <div className='logo-full'>
                      <a
                        href={`${readonly ? 'https://www.novo.us' : '/'}`}
                        rel='noopener noreferrer'
                      >
                        <NovoLogo />
                      </a>
                    </div>
                    <Box
                      sx={{
                        display: { xs: 'block', md: 'none' },
                        height: '100%',
                      }}
                    >
                      <SaveProgress
                        displayBtnRetrieveQuote={
                          novoRoute.displayBtnRetrieveQuote
                        }
                        displayBtnSaveQuote={novoRoute.displayBtnSaveQuote}
                      />
                    </Box>
                  </Box>
                  <Box
                    className='left-nav_stepperWrapper'
                    sx={{ paddingTop: { xs: '0', md: '2.25rem' } }}
                  >
                    {novoRoute?.displayLeftNav === true &&
                      novoRoute?.activeStep > 0 && (
                        <>
                          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <LeftNav activeStepIndex={activeStepIndex} />
                          </Box>
                          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <HorizontalNav activeStepIndex={activeStepIndex} />
                          </Box>
                        </>
                      )}
                  </Box>
                  <Box
                    className='left-nav_footerWrapper'
                    sx={{ paddingBottom: 5 }}
                  >
                    <PageFooter />
                    <div className='footer-version'>
                      {process.env.REACT_APP_RELEASE_VERSION}
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              lg={9}
              md={9}
              sm={12}
              xs={12}
              className={`page-background ${
                novoRoute.displayPageCubes ? 'page-background_cubes' : ''
              }`}
              sx={{ backgroundColor: '#fff' }}
            >
              <Box className='page-wrapper'>
                {!hideHeader && (
                  <>
                    <Header
                      routeKey={routeKey}
                      overWrittenHeader={overWrittenHeader}
                      placeHolderText={placeHolderText}
                      actualValue={actualValue}
                      className={
                        novoRoute.displayPageCubes
                          ? 'header-underline_drum'
                          : ''
                      }
                    />

                    <div className='header-underline'></div>
                  </>
                )}
                <div style={{ marginTop: '2rem' }}>{children}</div>
              </Box>
            </Grid>
            <Grid
              className={'page_footer'}
              sx={{
                padding: '2.25rem 3rem',
                marginTop: '2.25rem',
                display: { sx: 'block', md: 'none' },
              }}
            >
              <PageFooter />
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ marginTop: '2rem' }}>{children}</div>
      )}
    </>
  );
};

export default Page;
