import Tile from 'components/Tile';
import DriverAvatar from 'framework/components/DriverAvatar';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import MuiDialog from 'framework/components/Dialogs/MuiDialog';
import { novoRoutes } from 'framework/routes';
import React, { useState /* useState */ } from 'react';
import { useNavigate } from 'react-router-dom';
import { setItem } from 'framework/dataService';

export default function Driver({
  disabled = false,
  driverId = '1',
  firstName = 'Jacob',
  lastName = 'Smith',
  gender = 'Male',
  primary = true,
  included = true,
  progressValue = '0',
  mode = 'add',
  to = '',
  isInsured = true,
  isExcluded = false,
  isRated = false,
  readonly = false,
  index = 0,
  updateList,
}: {
  disabled?: boolean;
  driverId?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  primary?: boolean;
  included?: boolean;
  isExcluded?: boolean;
  progressValue?: string;
  mode?: 'add' | 'edit';
  to?: string;
  isInsured?: boolean;
  isRated?: boolean;
  readonly?: boolean;
  index?: number;
  updateList?: () => void;
}) {
  const navigate = useNavigate();
  const [openAddDriverHelpDialog, setOpenAddDriverHelpDialog] = useState(false);
  const handleEditClick = () => {
    setItem('ReconciliationViewed', 'false');
    let path = novoRoutes.EditDriver.path;
    path = path.replace(':driverId', driverId);

    if (to && to.length > 0) {
      path += '?to=' + to;
    }

    navigate(path);
  };

  const handleAddClick = () => {
    setItem('ReconciliationViewed', 'false');
    let path = novoRoutes.AddDriver.path;

    if (mode === 'add') {
      if (!readonly) {
        navigate(path);
      } else {
        setOpenAddDriverHelpDialog(true);
      }
    }
  };

  const handleCloseDialog = async (value: any) => {
    setOpenAddDriverHelpDialog(value);
  };

  return (
    <>
      <Tile
        disabled={disabled}
        context='driver'
        role='button'
        aria-label={mode === 'edit' ? 'edit driver' : 'add driver'}
        onClick={() => {
          handleAddClick();
        }}
        onEditClick={() => {
          handleEditClick();
        }}
        updateList={updateList}
        itemId={driverId}
        isRemoveDisabled={primary}
        subject={`${firstName} ${lastName}`}
        heading1={`${firstName} ${lastName}`}
        heading2={
          primary ? (
            <MicrocopyComponent
              labelKey={`q2b.driver.list.tiles.primary.insured`}
            />
          ) : isExcluded ? (
            <MicrocopyComponent
              labelKey={`q2b.driver.list.tiles.additional.excluded`}
            />
          ) : !isInsured ? (
            <MicrocopyComponent
              labelKey={`q2b.driver.list.tiles.not.insured`}
            />
          ) : (
            <MicrocopyComponent
              labelKey={`q2b.driver.list.tiles.additional.insured`}
            />
          )
        }
        avatar={
          <DriverAvatar index={index} style={{ marginBottom: '0.75rem' }} />
        }
        mode={mode}
        progressValue={Number(progressValue)}
      />
      <MuiDialog
        open={openAddDriverHelpDialog}
        onClose={handleCloseDialog}
        closeOutSide={false}
        closeButtonText={
          <MicrocopyComponent labelKey={`q2b.global.button.text.ok`} />
        }
      />
    </>
  );
}
