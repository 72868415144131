import * as React from 'react';
import Radio from '@mui/material/Radio';
import './NovoRadioButton.scss';
import { Box } from '@mui/material';

export interface NovoRadioProps {
  text?: string;
  id: string;
  disabled: boolean;
  className: string;
  label: string;
  onClick: any;
  value: any;
  checked: any;
  isError?: boolean;
  name?: string;
}

const NovoRadioButton: React.FC<NovoRadioProps> = ({
  id,
  className,
  onClick,
  label,
  value,
  checked,
  disabled,
  isError,
  name,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick) {
      onClick(event);
    }
  };
  if (isError) {
    className += ' Mui-error';
  }
  return (
    <Box
      className={className}
      sx={{
        borderRadius: '6px',
        width: '100%',
        border: '1px solid',
        minHeight: '56px',
      }}
      onClick={handleClick}
    >
      <Box sx={{ padding: '1px 0 2px 7px' }}>
        <Radio
          checked={checked}
          value={value}
          name={name}
          inputProps={{ 'aria-label': label }}
          id={id}
          disabled={disabled}
        />
        <span>{label}</span>
      </Box>
    </Box>
  );
};

export default NovoRadioButton;
