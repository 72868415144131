import { ButtonProps, Divider, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';

export interface GreenShadowButtonProps extends ButtonProps {
  children?: React.ReactNode;
  text?: string | JSX.Element;
  isError?: boolean;
}

const GreenShadowButton: React.FC<GreenShadowButtonProps> = ({
  children,
  id = 'button',
  disabled = false,
  text = 'NEXT',
  className,
  onClick,
  variant = 'contained',
  color = 'primary',
  type = 'submit',
  isError = false,
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(event);
    }
  };
  if (isError) {
    className += ' Mui-error';
  }
  return (
    <Grid container>
      <Grid container item xl={3} lg={4} md={5} sm={5} xs={9}>
        <Button
          data-testid={id}
          id={id}
          disabled={disabled}
          className={`${className ? className : ''} novo-button`}
          type={type}
          color={color}
          variant={variant}
          onClick={handleClick}
        >
          {children || text}
        </Button>
      </Grid>
      <Grid
        container
        item
        xl={9}
        lg={8}
        md={7}
        sm={7}
        xs={3}
        className={'button-hr'}
      >
        <Divider
          className={'sub-hr'}
          sx={{ borderBottomWidth: 3, marginTop: 2 }}
        />
      </Grid>
    </Grid>
  );
};

export default GreenShadowButton;
