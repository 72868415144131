import type { CustomJsonSchemaProps } from '../interfaces/CustomJsonSchemaProps';

export const WarningValidation = (
  schema: CustomJsonSchemaProps,
  formData: any
) => {
  let warningMsgs: any[] = [];
  const BreakException = {};
  try {
    if (schema?.properties && Object.keys(schema?.properties).length > 0) {
      for (let [fieldName, fieldSchema] of Object.entries(schema?.properties)) {
        // handling each field in the schema.properties
        if (fieldSchema.warning) {
          fieldSchema.warning.forEach((warningOption: any) => {
            // schema.warning holds an array of answer options from user
            if (formData[fieldName] === warningOption) {
              let messages: any = fieldSchema.messages;
              let warningMsg = messages?.warning;
              if (warningMsg) {
                warningMsgs.push(warningMsg);
              }
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw BreakException;
            }
          });
        }
      }
    }
  } catch (e) {
    if (e !== BreakException) {
      throw e;
    }
  }
  return warningMsgs;
};
