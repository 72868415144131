import Acknowledgement from 'pages/Acknowledgement';
import Cars from 'pages/Cars';
import CarFormWrapperModule from 'pages/Cars/CarFormWrapperModule';
import SearchCar from 'pages/Cars/Search';
import Congratulations from 'pages/Congratulations';
import PlanOptions from 'pages/Coverages/PlanOptions';
import Disclosures from 'pages/Disclosures';
import Drivers from 'pages/Drivers';
import DriverFormWrapperModule from 'pages/Drivers/DriverFormWrapperModule';
import ReviewHistory from 'pages/Drivers/ReviewHistory';
import InitialInfo from 'pages/InitialInfo';
import Payment from 'pages/Payments/Payment';
import PaymentPortal from 'pages/Payments/PaymentPortal';
import RetrieveQuote from 'pages/RetrieveQuote';
import SaveQuote from 'pages/SaveQuote';
import Testing from 'pages/Testing/Testing';
import UBI from 'pages/UBI';
import React from 'react';

export interface NovoRoute {
  pageTitle: string;
  path: string;
  element: React.ReactNode;
  schemaName?: string;
  activeStep: number;
  displayLeftNav?: boolean;
  heading?: string;
  heading1?: string;
  heading2?: string;
  heading3?: string;
  disclosure?: string;
  ariaLabelTabList?: string;
  alt?: string;
  dataMappings?: {
    [key: string]: any;
  };
  body1?: string;
  image1?: string;
  displayBtnRetrieveQuote?: boolean;
  displayBtnSaveQuote?: boolean;
  displayPageCubes?: boolean;
}

export interface NovoRoutes {
  [key: string]: NovoRoute;
}

export interface NovoNavigation {
  [key: string]: {
    nextPage: string;
    prevPage: string;
    driverPage?: string;
  };
}

export const novoRoutes: NovoRoutes = {
  InitialInfo: {
    schemaName: 'InitialInfo',
    pageTitle: 'Initial Info',
    path: '/initial-info',
    element: <InitialInfo />,
    activeStep: 1,
    displayLeftNav: true,
    dataMappings: {
      drivers: {
        primary: true,
      },
      locations: {
        primary: true,
      },
      metaData: {
        readonly: true,
      },
    },
    heading1: 'q2b.initial.info.heading1',
    heading2: 'q2b.initial.info.heading2',
    disclosure: 'q2b.initial.info.disclosures',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  SaveQuote: {
    schemaName: 'SaveQuote',
    pageTitle: 'Save your quote',
    path: '',
    element: <SaveQuote />,
    heading1: 'q2b.save.quote.heading1',
    heading2: 'q2b.save.quote.heading2',
    activeStep: 0,
    dataMappings: {
      accountInformation: {
        all: true,
      },
      drivers: {
        primary: true,
      },
    },
  },
  RetrieveQuote: {
    pageTitle: 'Retrieve a Quote',
    path: '',
    element: <RetrieveQuote />,
    activeStep: 0,
    heading1: 'q2b.sidebar.drawer.heading1',
    heading2: 'q2b.sidebar.drawer.heading2',
    dataMappings: {
      drivers: {
        primary: true,
      },
    },
  },
  Congratulations: {
    pageTitle: 'Congratulations',
    path: '/congratulations',
    element: <Congratulations />,
    activeStep: 5,
    heading1: 'q2b.congratulations.heading1',
    // heading2: 'q2b.congratulations.heading2',
    dataMappings: {
      drivers: {
        primary: true,
      },
    },
    alt: 'Access to your policy: Access your policy information quickly and easily by creating a password.',
    displayPageCubes: true,
  },
  Drivers: {
    pageTitle: 'Drivers',
    path: '/drivers',
    element: <Drivers />,
    activeStep: 2,
    displayLeftNav: true,
    dataMappings: {
      drivers: {
        all: true,
      },
      metaData: {
        readonly: true,
      },
    },
    heading1: 'q2b.drivers.list.heading1',
    heading2: 'q2b.drivers.list.body1',
    body1: 'q2b.drivers.list.body1',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  Cars: {
    pageTitle: 'Cars',
    path: '/cars',
    element: <Cars />,
    activeStep: 3,
    displayLeftNav: true,
    dataMappings: {
      vehicles: {
        all: true,
      },
      metaData: {
        readonly: true,
      },
    },
    heading1: 'q2b.cars.list.heading1',
    heading2: 'q2b.cars.list.heading2',
    body1: 'q2b.cars.list.body1',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  UBI: {
    pageTitle: 'UBI',
    path: '/ubi',
    element: <UBI />,
    activeStep: 3,
    displayLeftNav: true,
    heading1: 'q2b.ubi.heading1',
    body1: 'q2b.ubi.body1',
    image1: 'q2b.ubi.image1',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  AddDriver: {
    pageTitle: 'Add Driver',
    path: '/drivers/add',
    element: <DriverFormWrapperModule mode={'add'} />,
    activeStep: 2,
    displayLeftNav: true,
    heading1: 'q2b.driver.add.driver.heading1',
    heading2: 'q2b.driver.add.driver.heading2',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  EditDriver: {
    pageTitle: 'Edit Driver',
    path: '/drivers/edit/:driverId',
    dataMappings: {
      drivers: {
        id: true,
        all: true,
      },
      metaData: {
        readonly: true,
      },
    },
    element: <DriverFormWrapperModule mode={'edit'} />,
    activeStep: 2,
    displayLeftNav: true,
    heading1: '',
    heading2: 'q2b.driver.edit.driver.heading2',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  EditInsuranceInfo: {
    pageTitle: 'Edit Driver',
    path: '/drivers/edit/:driverId',
    dataMappings: {
      drivers: {
        insuranceInfoByid: true,
      },
    },
    element: <DriverFormWrapperModule mode={'edit'} />,
    activeStep: 2,
  },
  EditDrivingHistory: {
    pageTitle: 'Edit Driver',
    path: '/drivers/edit/:driverId',
    dataMappings: {
      drivers: {
        drivingHistoryByid: true,
      },
    },
    element: <DriverFormWrapperModule mode={'edit'} />,
    activeStep: 2,
  },
  EditResidenceInfo: {
    pageTitle: 'Edit Driver',
    path: '/drivers/edit/:driverId',
    dataMappings: {
      drivers: {
        residenceInfoByid: true,
      },
    },
    element: <DriverFormWrapperModule mode={'edit'} />,
    activeStep: 2,
  },
  SearchCar: {
    pageTitle: 'Search Car',
    path: '/cars/search',
    element: <SearchCar />,
    activeStep: 3,
    displayLeftNav: true,
    dataMappings: {
      vehicles: {
        all: true,
      },
    },
    heading1: 'q2b.cars.add.car.heading1',
    heading2: 'q2b.cars.add.car.heading2',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  AddCar: {
    pageTitle: 'Add Car',
    path: '/cars/add/:vehicleId',
    element: <CarFormWrapperModule />,
    activeStep: 3,
    displayLeftNav: true,
    dataMappings: {
      vehicles: { id: true },
      locations: {
        id: true,
      },
      metaData: {
        readonly: true,
      },
    },
    heading1: 'q2b.cars.add.heading1',
    heading2: 'q2b.cars.edit.heading2',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  EditCar: {
    pageTitle: 'Edit Car',
    path: '/cars/edit/:vehicleId',
    element: <CarFormWrapperModule />,
    activeStep: 3,
    displayLeftNav: true,
    dataMappings: {
      vehicles: { id: true },
      metaData: {
        readonly: true,
      },
    },
    heading1: 'q2b.cars.edit.heading1',
    heading2: 'q2b.cars.edit.heading2',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  PlanTelematics: {
    pageTitle: '',
    path: '',
    element: '',
    activeStep: 4,
    dataMappings: {
      metaData: { all: true },
    },
  },
  PlanOptions: {
    pageTitle: 'Plan Options',
    path: '/plan-options/customize-coverage',
    element: (
      <PlanOptions currentTab='q2b.plan.options.plan.coverages.tab.data.customize.coverage.name' />
    ),
    activeStep: 4,
    displayLeftNav: true,
    dataMappings: {
      coverages: { all: true },
      metaData: { all: true },
    },
    heading1: 'q2b.plan.options.heading1',
    heading2: 'q2b.plan.options.heading2',
    ariaLabelTabList: 'Policy overview',
    displayBtnSaveQuote: false,
  },
  PlanOptionsCars: {
    pageTitle: 'Plan Options',
    path: '/plan-options/cars',
    element: (
      <PlanOptions currentTab='q2b.plan.options.plan.coverages.tab.data.cars.name' />
    ),
    activeStep: 4,
    displayLeftNav: true,
    dataMappings: {
      coverages: { all: true },
      metaData: { all: true },
    },
    heading1: 'q2b.plan.options.heading1',
    heading2: 'q2b.plan.options.heading2',
    displayBtnSaveQuote: false,
  },
  PlanOptionsDrivers: {
    pageTitle: 'Plan Options',
    path: '/plan-options/drivers',
    element: (
      <PlanOptions currentTab='q2b.plan.options.plan.coverages.tab.data.drivers.name' />
    ),
    activeStep: 4,
    displayLeftNav: true,
    dataMappings: {
      coverages: { all: true },
    },
    heading1: 'q2b.plan.options.heading1',
    heading2: 'q2b.plan.options.heading2',
    displayBtnSaveQuote: false,
  },
  ReviewHistory: {
    pageTitle: 'Review History',
    path: '/review-history',
    element: <ReviewHistory />,
    activeStep: 4,
    displayLeftNav: true,
    dataMappings: {
      drivers: {
        all: true,
      },
      metaData: {
        readonly: true,
      },
    },
    heading1: 'q2b.review.history.heading1',
    heading2: 'q2b.review.history.heading2',
    displayBtnSaveQuote: false,
  },
  Disclosures: {
    pageTitle: 'Disclosures',
    path: '/disclosures',
    element: <Disclosures />,
    activeStep: 5,
    displayLeftNav: true,
    dataMappings: {
      coverages: { all: true },
      metaData: { all: true },
      drivers: {
        all: true,
      },
      vehicles: {
        all: true,
      },
    },
    heading1: 'q2b.disclosures.heading1',
    heading2: 'q2b.disclosures.heading2',
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  Payment: {
    pageTitle: 'Payment',
    path: '/payments',
    element: <Payment />,
    activeStep: 5,
    displayLeftNav: true,
    heading1: 'q2b.payments.heading1',
    heading2: 'q2b.payments.heading2',
    dataMappings: {
      drivers: {
        primary: true,
      },
      locations: {
        primary: true,
      },
      metaData: {
        all: true,
      },
    },
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  PaymentPortal: {
    pageTitle: 'PaymentPortal',
    path: '/payment-submit',
    element: <PaymentPortal />,
    activeStep: 5,
    displayLeftNav: false,
    heading1: 'q2b.payments.heading1',
    heading2: 'q2b.payments.heading2',
    dataMappings: {
      drivers: {
        primary: true,
      },
      locations: {
        primary: true,
      },
      metaData: {
        all: true,
      },
    },
    displayBtnRetrieveQuote: false,
    displayBtnSaveQuote: false,
  },
  Acknowledgement: {
    pageTitle: '',
    path: '/acknowledgement',
    element: <Acknowledgement />,
    activeStep: 5,
    displayLeftNav: false,
    heading: 'q2b.acknowledgement.heading1',
    heading2: 'q2b.acknowledgement.heading2',
  },
  Testing: {
    schemaName: 'Testing',
    pageTitle: 'Testing Playground',
    path: '/testing',
    element: <Testing />,
    activeStep: 1,
    displayLeftNav: false,
    heading1: 'Testing Playground',
    heading2: 'Hello World header from Testing',
    displayBtnSaveQuote: false,
  },
};

export const pathNameWithNoRedirection = [novoRoutes.InitialInfo.path];

export const novoNavigation: NovoNavigation = {
  Testing: {
    nextPage: novoRoutes.InitialInfo.path,
    prevPage: novoRoutes.InitialInfo.path,
  },
  InitialInfo: {
    nextPage: novoRoutes.Drivers.path,
    prevPage: '',
  },
  Drivers: {
    nextPage: novoRoutes.Cars.path,
    prevPage: novoRoutes.InitialInfo.path,
  },
  EditDriver: {
    nextPage: novoRoutes.Drivers.path,
    prevPage: novoRoutes.Drivers.path,
  },
  Cars: {
    nextPage: novoRoutes.UBI.path,
    prevPage: novoRoutes.Drivers.path,
  },
  UBI: {
    nextPage: novoRoutes.PlanOptions.path,
    prevPage: novoRoutes.Cars.path,
  },
  AddCar: {
    nextPage: novoRoutes.Cars.path,
    prevPage: novoRoutes.Cars.path,
  },
  SearchCar: {
    nextPage: novoRoutes.AddCar.path,
    prevPage: novoRoutes.Cars.path,
  },
  PlanOptions: {
    nextPage: novoRoutes.ReviewHistory.path,
    prevPage: novoRoutes.Cars.path,
    driverPage: novoRoutes.InitialInfo.path,
  },
  ReviewHistory: {
    nextPage: novoRoutes.Disclosures.path,
    prevPage: novoRoutes.PlanOptions.path,
  },
  Disclosures: {
    nextPage: novoRoutes.Payment.path,
    prevPage: novoRoutes.PlanOptions.path,
  },
  Payment: {
    nextPage: novoRoutes.Congratulations.path,
    prevPage: novoRoutes.Disclosures.path,
  },
  Congratulations: {
    nextPage: novoRoutes.Acknowledgement.path,
    prevPage: novoRoutes.Payment.path,
  },
  Acknowledgement: {
    nextPage: '',
    prevPage: novoRoutes.Congratulations.path,
  },
};
