import React, { useEffect, useState } from 'react';

// Utilities
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, useLocation } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import RoutesContainer from 'components/RoutesContainer';

// Theme and UI
import { ThemeProvider } from '@mui/system';
import { Grid } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import theme from '../theme';
import { getPrimaryColor, getBrandColor } from '../utils/style';
import './App.scss';
import { updateMicrocopySessionStorage } from 'library/services/utils';
import MuiLoader from 'framework/components/MuiLoader';
import trackingService from 'utils/trackingService';
import { producerUrl } from 'library/services/constants';

const queryClient = new QueryClient();

const App = () => {
  const [microcopyStatus, setMicrocopyStatus] = useState(false);

  useEffect(() => {
    async function callMicrocopyUpdater() {
      await updateMicrocopySessionStorage();
      setMicrocopyStatus(true);
    }

    callMicrocopyUpdater();
  }, []);

  const usePageViews = () => {
    const { pathname } = useLocation();
    if (pathname === '/agent' || pathname === '/agent-dash') {
      window.location.href = `${producerUrl}`;
    }
    useEffect(() => {
      trackingService.trackEvent('formProgress', { page: pathname });
    }, [pathname]);
  };
  const HistoryListener = () => {
    usePageViews();
    return <></>;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <HistoryListener />
        <ThemeProvider theme={theme(getPrimaryColor(), getBrandColor())}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={0} className='app-wrapper overflow-hidden'>
              {microcopyStatus ? (
                <RoutesContainer />
              ) : (
                <MuiLoader
                  message={'Please wait while the little elves draw your map'}
                />
              )}
            </Grid>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
