import { createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {
  interface Palette {
    brand: Palette['primary'];
  }
  interface PaletteOptions {
    brand: PaletteOptions['primary'];
  }
}

const Lato = [
  'Lato',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
].join(',');

const Rubik = [
  'Rubik',
  'Lato',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
].join(',');

const pageHeaderH5 = {
  fontFamily: Lato,
  fontWeight: 900,
  color: '#000',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.188em',
  '@media screen and (min-width: 1024px)': {
    lineHeight: '2.5rem',
    fontSize: '1.25rem',
    letterSpacing: '0.25em',
  },
};

const backdropColor = 'rgba(27, 28, 34, 0.88)';

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = (primaryColor: string, brandColor: string) =>
  createTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: { main: '#FFFFFF', contrastText: primaryColor },
      brand: { main: brandColor },
      error: { main: '#EB0000' },
      success: { 500: 'rgba(50, 215, 157, 1)' },
    },
    typography: {
      fontFamily: Rubik,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 900,
            fontFamily: Lato,
            fontSize: '1rem',
            lineHeight: '1.625rem',
            letterSpacing: '0.25em',
            width: '100%',
            boxShadow: 'none',
            textTransform: 'uppercase',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: '60px',
            borderRadius: '0',
            '&.MuiButton-label': {
              textTransform: 'none',
            },
            '@media screen and (min-width: 900px)': {
              fontSize: '1.25rem',
            },
          },
          containedPrimary: {
            border: 0,
          },
          containedSecondary: {
            backgroundColor: brandColor,
            '&.MuiButtonBase-root': {
              backgroundColor: brandColor,
              width: '100%',
              paddingLeft: '20px',
              paddingRight: '20px',
              '&.w-full': {
                width: '100%',
              },
              '&.btn-w-unset': {
                width: 'unset',
                minWidth: '9.125rem',
              },
              '&.btn-gray, &.btn-grey': {
                backgroundColor: '#E8E9EE',
                '&:hover:not(:disabled)': {
                  backgroundColor: '#edeef2',
                  boxShadow: 'none',
                },
                '&:active': {
                  backgroundColor: '#dfe0e7',
                },
              },
              '&.btn-transparent': {
                backgroundColor: 'transparent',
                '&:hover:not(:disabled)': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  border: ' 1px solid #E8E9EE',
                },
                '&:active': {
                  backgroundColor: 'transparent',
                  border: '1px solid #E8E9EE',
                },
              },
              '&.no-transition': {
                '-webkit-transition': 'none',
                transition: 'none',
              },
            },
            '@media (min-width: 1024px)': {
              '&': {
                width: 'unset',
                paddingLeft: '34px',
                paddingRight: '34px',
                '&.MuiButtonBase-root': {
                  width: 'unset',
                },
              },
              '.MuiButton-endIcon': {
                marginLeft: '12px',
              },
            },
            '&:hover:not(:disabled)': {
              backgroundColor: '#10F20D',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#34C759',
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            paddingLeft: '20px',
            paddingRight: '20px',
            borderRadius: 0,
            '&.MuiButton-containedPrimary': {
              backgroundColor: primaryColor,
              color: 'white',
              '&.novo-button': {
                boxShadow: `-5px 5px 0 -2px ${brandColor}`,
              },
              '&.novo-button:hover': {
                backgroundColor: '#292c3d',
                boxShadow: `-5px 5px 0 -2px ${brandColor}`,
              },
              '&:hover': {
                backgroundColor: '#292c3d',
                boxShadow: 'none',
              },
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              marginLeft: 0,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.page-header-h': {
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '2.125rem',
              fontSize: '1.875rem',
              letterSpacing: '0.063rem',
              marginBottom: '1rem',
              '@media (min-width: 900px)': {
                marginBottom: '2.25rem',
              },
              '@media (min-width: 1024px)': {
                fontSize: '2.25rem',
                lineHeight: '2.5rem',
              },
              '@media (min-width: 1336px)': {
                fontSize: '3rem',
                lineHeight: '3.25rem',
              },
            },
            '&.common-header-h': {
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '2.125rem',
              fontSize: '1.875rem',
              letterSpacing: '0.063rem',
              '@media (min-width: 1024px)': {
                fontSize: '2.25rem',
                lineHeight: '2.5rem',
              },
              '@media (min-width: 1336px)': {
                fontSize: '2rem',
                lineHeight: '3.25rem',
              },
            },
            '&.page-header-h1': {
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '2rem',
              fontSize: '1.625rem',
              marginBottom: '1rem',
              '@media (min-width: 900px)': {
                marginBottom: '2.25rem',
              },
            },
            '&.page-header-h2': {
              fontFamily: Lato,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '1.5rem',
              fontSize: '1rem',
              color: '#55566D',
              letterSpacing: '0.031em',
              marginBottom: '2rem',
              '@media (min-width: 900px)': {
                fontSize: '1.25rem',
                lineHeight: '1.625rem',
                letterSpacing: '0.063em',
                marginBottom: '2.25rem',
              },
            },
            '&.page-header-h2-font': {
              fontSize: '0.8rem !important',
              lineHeight: '1.4rem !important',
            },
            '&.page-header-h3': {
              fontFamily: Lato,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.2rem',
              fontSize: '0.9rem',
              color: '#292C3D',
              marginBottom: '2.25rem',
            },
            '&.page-header-h5': pageHeaderH5,
            '&.title-1': {
              fontFamily: Rubik,
              fontSize: '3rem',
              fontWeight: 500,
            },
            '&.title-2': {
              fontFamily: Rubik,
              fontSize: '1.75rem',
              lineHeight: '2.063rem',
              fontWeight: 500,
            },
            '&.title-3': {
              fontFamily: Lato,
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '1rem',
              color: '#55566D',
              lineHeight: '1.5rem',
              letterSpacing: '0.031em',
              '@media (min-width: 900px)': {
                fontSize: '1.25rem',
                lineHeight: '1.625rem',
                letterSpacing: '0.063em',
              },
            },
          },
          h5: {
            fontFamily: Lato,
            // color: '#55566D',
            fontSize: '1.25rem',
            lineHeight: '1.625rem',
            letterSpacing: '0.063em',
            fontWeight: 500,
          },
          h6: {
            fontFamily: Lato,
            fontWeight: 900,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: ' 0.1875em',
            '@media (min-width: 900px)': {
              fontSize: '1.25rem',
              letterSpacing: ' 0.25em',
              lineHeight: '2.5rem',
            },
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            marginTop: '1rem',
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            '&.MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel': {
              alignItems: 'flex-start',
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: 'transparent',
            height: '4px',
            borderRadius: 0,
            boxSizing: 'border-box',
            '& .MuiLinearProgress-bar': {
              backgroundColor: primaryColor,
              borderRadius: 0,
            },
          },
          colorSecondary: {
            backgroundColor: '#000',
            height: '8px',
            borderRadius: '8px',
            border: '2px solid #000',
            boxSizing: 'border-box',
            '& .MuiLinearProgress-bar': {
              backgroundColor: brandColor,
              borderRadius: '4px',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: Lato,
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: '1.625rem',
            letterSpacing: '0.063em',
            '& .MuiAutocomplete-popupIndicator': {
              color: 'rgba(0, 0, 0, 0.87)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000000',
              boxShadow: `0 1.5px ${brandColor}`,
              backgroundColor: 'transparent',
            },
            '& .MuiSvgIcon-root': {
              color: '#000000',
            },
          },
          notchedOutline: {
            borderRadius: 0,
            borderWidth: '2px',
            borderColor: '#E8E9EE',
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '& .MuiButtonBase-root': {
              width: '60px',
              height: '64px',
              borderRadius: 0,
              backgroundColor: '#E8E9EE',
              position: 'relative',
              left: '1px',
              color: '#000000',
              '&.MuiPassword': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#737391',
            fontFamily: Lato,
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: '1.625rem',
            letterSpacing: '0.063em',
            '&[data-shrink="true"]': {
              transform: 'translate(14px, -9px) scale(0.65)',
              '-webkit-transform': 'translate(14px, -9px) scale(0.65)',
            },
          },
          shrink: {
            textTransform: 'uppercase',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            top: 0,
            right: 0,
            color: '#000000',
          },
          iconOpen: {
            '& rect': {
              fill: '#000000',
            },
            '& path': {
              fill: brandColor,
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '& .MuiBackdrop-root': {
              backgroundColor: backdropColor,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            p: {
              margin: 0,
            },
            '& .MuiBackdrop-root': {
              backgroundColor: backdropColor,
            },
            '& .page-wrapper': {
              padding: '0 !important',
              marginBottom: '0 !important',
            },
            '& .hr-double': {
              width: 'calc(100% + 95px)',
            },
            '& .muiDialog-title2': {
              fontFamily: Lato,
              fontWeight: 900,
              fontSize: '1.25rem',
              lineHeight: '2.5rem',
              letterSpacing: '.25em',
              textTransform: 'uppercase',
            },
            '& .muiDialog-title': {
              fontFamily: Rubik,
              fontWeight: 500,
              fontSize: '1.875rem',
              lineHeight: '2.125rem',
              letterSpacing: '0.063em',
              marginBottom: '2rem',
              '@media screen and (min-width: 1024px)': {
                fontSize: '3rem',
                lineHeight: '3.25rem',
              },
            },
            '& .muiDialog-title-divider': {
              marginBottom: '2rem',
            },
            '& .muiDialog-subtitle': {
              color: '#55566D',
              fontFamily: Lato,
              fontWeight: 500,
              fontSize: '1rem',
              lineHeight: '1.5rem',
              letterSpacing: '0.5px',
              marginBottom: '2rem',
              '@media screen and (min-width: 1024px)': {
                fontSize: '1.25rem',
                lineHeight: '1.625rem',
                letterSpacing: '0.063em',
              },
            },
            '& .label-with-icon-field': {
              fontFamily: Lato,
              fontWeight: 700,
              fontSize: '1.25rem',
              lineHeight: '1.625rem',
              letterSpacing: '1px',
              marginBottom: '2rem',
            },
            '& .muiDialog-body': {
              fontFamily: Lato,
              fontWeight: 700,
              fontSize: '0.875rem',
              lineHeight: '1.375rem',
              letterSpacing: '0.0625em',
            },
            '&.insurance-not-available > div > div': {
              width: '100%',
              maxWidth: '45rem',
              paddingTop: '2.5rem',
            },
            '&.found-existing-application > div > div': {
              maxWidth: '45rem',
            },
            '&.remove-reason-dialog > div > div': {
              maxWidth: '45rem',
            },
          },
          paper: {
            overflowX: 'hidden',
            margin: 0,
            '@media screen and (min-width: 900px)': {
              maxWidth: '732px',
              margin: 'auto auto',
            },
            '& .dialog': {
              padding: '56px 48px',
              '@media screen and (min-width: 900px)': {
                padding: '56px 96px',
              },
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.checkbox-style1': {
              '& .MuiFormControlLabel-label': {
                fontFamily: Lato,
              },
            },
            '&.page-header-h5 .MuiTypography-root.MuiTypography-h5':
              pageHeaderH5,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 0,
            backgroundColor: '#000',
            fontFamily: Lato,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: '0.063em',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            ' .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              right: '0px',
              top: '0px',
            },
          },
        },
      },
    },
  });

export default theme;
