import React from 'react';
import { capitalizeFirstLetter } from 'utils/util';
import { Alert, AlertTitle, AlertColor } from '@mui/material';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';

interface AlertProps {
  type?: AlertColor;
  message?: string;
}

const ErrorMapping: Record<string, string> = {
  'Network Error': 'q2b.network.error.message',
};

export default function AlertComponent({
  type = 'error',
  message,
}: AlertProps) {
  const getLabelKey = () => {
    let labelKey = message
      ? message
      : type === 'error'
      ? 'q2b.default.error.message'
      : 'q2b.default.success.message';
    if (ErrorMapping[labelKey]) return ErrorMapping[labelKey];
    return labelKey;
  };

  return (
    <Alert severity={type} className={'alert-component mb-1 w-full'}>
      <AlertTitle>{capitalizeFirstLetter(type)}</AlertTitle>
      <MicrocopyComponent labelKey={getLabelKey()} />
    </Alert>
  );
}
