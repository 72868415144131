import { AjvError } from '@rjsf/core';
import type { CustomJsonSchemaProps } from '../interfaces/CustomJsonSchemaProps';
import { isEmpty, notifyBugsnag } from 'utils/util';
import { getApplicationId } from 'framework/dataService/insuranceApplication';
export const transform = (
  schema: CustomJsonSchemaProps,
  errors: AjvError[],
  formData?: any
) => {
  if (errors.length > 0) {
    const bugName = getApplicationId()
      ? `Form validation Error (applicationId :${getApplicationId()})`
      : `Form validation Error`;
    notifyBugsnag(
      bugName,
      `[errors]: ${JSON.stringify(errors)} ,[formData]:${JSON.stringify(
        formData
      )}`
    );
  }

  const formattedErrors = errors
    // filter out all the custom msg value as "OFF"
    /* .filter((error) => {
      // Get the field name
      let fieldName = error.property.substring(1);
      let messages: any = {};

      // Get message properties from the json schema form
      if (schema.properties && schema.properties[fieldName]) {
        messages = schema.properties[fieldName].messages;
      }

      if (isEmpty(messages) && error.name === 'required') {
        let requiredMessage = getRequiredMessage(schema, fieldName);
        messages = {
          required: requiredMessage,
        };
      } else if (isEmpty(messages) && error.name === 'enum') {
        messages = { enum: 'The option selected is not correct.' };
      } else if (isEmpty(messages) && error.name === 'type') {
        messages = { type: 'The selected type is not correct.' };
      } else if (isEmpty(messages) && error.name === 'maxLength') {
        messages = {};
        messages[error.name] =
          error.message.charAt(0).toUpperCase() + error.message.slice(1);
      } else if (isEmpty(messages) && error.name === 'if') {
        messages = {};
        messages[error.name] = 'Please see errors above.';
      }
      return (
        !messages || !messages[error.name] || messages[error.name] !== 'OFF'
      );
    }) */
    .map((error) => {
      // Get the field name
      let fieldName = error.property.substring(1);
      const errorName = error.name;
      let messages: any = {};
      // Get message properties from the json schema form
      if (schema.properties && schema.properties[fieldName]) {
        const tempMessages = schema.properties[fieldName].messages;
        if (tempMessages) {
          const foundKey = Object.keys(tempMessages).find(
            (item) => item === errorName
          );
          if (foundKey) {
            messages = schema.properties[fieldName].messages;
          }
        }
      }

      if (isEmpty(messages) && error.name === 'required') {
        let requiredMessage = getRequiredMessage(schema, fieldName);
        messages = {
          required: requiredMessage,
        };
      } else if (isEmpty(messages) && error.name === 'format') {
        let requiredMessage = getFormatMessage(schema, fieldName);
        messages = {
          format: requiredMessage,
        };
      } else if (isEmpty(messages) && error.name === 'enum') {
        messages = { enum: 'The option selected is not correct.' };
      } else if (isEmpty(messages) && error.name === 'type') {
        messages = { type: 'The selected type is not correct.' };
      } else if (isEmpty(messages) && error.name === 'maxLength') {
        messages = {};
        messages[error.name] =
          error.message.charAt(0).toUpperCase() + error.message.slice(1);
      } else if (isEmpty(messages) && error.name === 'minLength') {
        messages = {};
        messages[error.name] =
          error.message.charAt(0).toUpperCase() + error.message.slice(1);
      } else if (isEmpty(messages) && error.name === 'if') {
        messages = {};
        // messages[error.name] = 'Please see errors above.';
        messages[error.name] = ' '; // keep the space, as we do not want to display the default message
      } else if (isEmpty(messages) && error.name === 'minItems') {
        messages = {};
        const minItemsCount = error.params?.limit || 1;
        messages[
          error.name
        ] = `Please add detail for minimum ${minItemsCount} items.`;
      }

      // Show custom error message
      if (messages && messages[error?.name]) {
        return { ...error, message: messages[error.name] };
      }
      return error;
    });
  return formattedErrors;
};

const getRequiredMessage = (
  schema: CustomJsonSchemaProps,
  fieldName: string
) => {
  let message = 'This field is required';
  if (fieldName) {
    let fieldTokens: string[] = fieldName.split('.');
    let obj = schema;
    fieldTokens.forEach((fieldToken: string) => {
      obj = findByKey(obj, fieldToken);
    });
    message = getMessageFromObject(obj);
  }
  return message;
};

const getFormatMessage = (schema: CustomJsonSchemaProps, fieldName: string) => {
  let message = 'This is not valid format';
  if (fieldName) {
    let fieldTokens: string[] = fieldName.split('.');
    let obj = schema;
    fieldTokens.forEach((fieldToken: string) => {
      obj = findByKey(obj, fieldToken);
    });
    message = getFormatMessageFromObject(obj);
  }
  return message;
};

const getFormatMessageFromObject = (obj: any) => {
  let message = 'This is not valid format';
  let title;
  if (obj?.messages?.format) {
    message = obj.messages.format;
  } else if (obj && obj.title) {
    title = obj.title.toLowerCase();
    if (title && title.length > 0) {
      if (obj.enum) {
        message = 'Please select your ' + title + '.';
      } else {
        message = 'Please enter your ' + title + '.';
      }
    }
  }
  return message;
};

const getMessageFromObject = (obj: any) => {
  let message = 'This field is required';
  let title;
  if (obj?.messages?.required) {
    message = obj.messages.required;
  } else if (obj && obj.title) {
    title = obj.title.toLowerCase();
    if (title && title.length > 0) {
      if (obj.enum) {
        message = 'Please select your ' + title + '.';
      } else {
        message = 'Please enter your ' + title + '.';
      }
    }
  }
  return message;
};

const findByKey = (obj: any, kee: string): any => {
  if (obj && kee in obj) return obj[kee];
  if (obj) {
    for (const num of Object.values(obj)
      .filter(Boolean)
      .filter((v) => typeof v === 'object')) {
      let found = findByKey(num, kee);
      if (found) return found;
    }
  }
};
