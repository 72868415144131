import { Box, Button, Grid, Typography } from '@mui/material';
import { IChangeEvent } from '@rjsf/core';
import ButtonArrow from 'components/icons/ButtonArrow';
import Page from 'components/Page';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import NovoForm from 'framework/forms/NovoForm';
import { novoNavigation, novoRoutes } from 'framework/routes';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionToProps from 'types/Transition';
import { calculateDestination } from 'utils/driver';
import {
  getApplicationObjectItem,
  setUbiInfo,
  deleteUbiInfo,
  getUbiInfo,
  getApplicationObject,
} from 'framework/dataService/insuranceApplication';
import {
  // isEmailExistInOtherPolicy,
  saveApplicationObject,
} from 'library/services/utils';
import { AjvError, FormValidation } from '@rjsf/core';
import {
  setAddons,
  getAddons,
  getLocations,
} from 'framework/dataService/drivers';
import SimpleDialog from 'framework/components/Dialogs/SimpleDialog';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';

export default function UBI({
  to,
  view,
  updateFormDirtyFlag,
}: TransitionToProps) {
  const routeKey = 'UBI';
  const ubiRatedRule: { [props: string]: string[] } = {
    WI: ['INCLUDED', 'PERMIT', 'RATED'],
    OH: ['INCLUDED', 'PERMIT', 'RATED'],
  };
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState<any>({
    optInUbi: null,
    showErrorTips: false,
  });
  // const [ratedProp,setRatedProp]=useState({})
  const ratedProp = useRef({});
  const [ratedName, setRatedName] = useState<string[]>([]);
  const [validateMessage, setValidateMessage] = useState<string>('');
  const [showCustomDialog, setShowCustomDialog] = useState<boolean>(false);

  const [dynamicUiSchema, setDynamicUiSchema] = useState<any>({
    'ui:layout': {
      optInUbi: {
        lg: 12,
      },
      termsAndConditions: {
        lg: 12,
      },
      telematicsPolicy: {
        lg: 12,
      },
      additionalInformationRequired: {
        lg: 12,
      },
    },
    optInUbi: {
      'ui:widget': 'toggleRadioButtonWidget',
    },
    showTelematicsPolicy: {
      'ui:widget': 'hidden',
    },
    showTermsAndConditions: {
      'ui:widget': 'hidden',
    },
    showErrorTips: {
      'ui:widget': 'hidden',
    },
    termsAndConditions: {
      'ui:widget': 'novoCheckboxWidget',
    },
    telematicsPolicy: {
      'ui:widget': 'novoCheckboxWidget',
    },
  });
  useEffect(() => {
    initInfo();
  }, []);

  const initInfo = async () => {
    const addOns = await getAddons();
    const ubiInfo = await getUbiInfo();
    let formData;
    // getRatedDriver
    if (addOns.length > 0) {
      formData = {
        ...formInfo,
        optInUbi: addOns?.[0]?.properties?.optIn ? 'true' : 'false',
        showTermsAndConditions: addOns?.[0]?.properties?.optIn,
        showTelematicsPolicy: addOns?.[0]?.properties?.optIn,
        showErrorTips: false,
        termsAndConditions: ubiInfo?.condition ?? formInfo?.termsAndConditions,
        telematicsPolicy:
          ubiInfo?.telematicsPolicy ?? formInfo?.telematicsPolicy,
      };
      setFormInfo(formData);
    } else {
      formData = {
        ...formInfo,
        optInUbi:
          addOns?.[0]?.properties?.optIn ?? formInfo?.optInUbi ?? 'undefined',
        showTermsAndConditions: addOns?.[0]?.properties?.optIn || false,
        showTelematicsPolicy: addOns?.[0]?.properties?.optIn || false,
      };
      setFormInfo(formData);
    }

    // eslint-disable-next-line max-len
    if (
      formData?.optInUbi === 'true' &&
      formData?.termsAndConditions &&
      formData?.telematicsPolicy
    ) {
      handleRender(formData);
    }
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    console.log('handleValidation', errors, formData);
    const set = new Set();
    for (let prop of Object.keys(ratedProp.current)) {
      if (!formData?.[prop]) {
        errors?.[prop]?.addError(`${prop} is required`);
      } else {
        if (prop.includes('email')) {
          //  check email rules
          let reg =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          let result = reg.test(formData?.[prop]);
          if (!result) {
            errors?.[prop]?.addError(`Not a valid email address`);
          }
        }

        if (set.has(formData?.[prop])) {
          prop.includes('email')
            ? errors?.[prop]?.addError(`Email address should be unique`)
            : errors?.[prop]?.addError(`Phone number should be unique`);
        } else {
          set.add(formData?.[prop]);
        }
      }
    }

    if (formData?.optInUbi === 'undefined') {
      errors?.optInUbi?.addError('Opt in UBI is required to select');
    }

    return errors;
  };
  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };
  const handleRender = (formData?: any) => {
    console.log(ratedProp.current);
    // check rated driver info
    if (Object.keys(ratedProp.current).length === 0) {
      checkRatedDriver(formData);
    } else {
      console.log('update rated info');
      // step4 : update rated info
      updateRatedInfo(ratedProp.current, formData);
    }
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    console.log('handleChange', formData); // {optInUbi: false, termsAndConditions: null}
    if (formData?.optInUbi === 'true') {
      const checkedOptIn = [
        {
          name: 'ubi',
          properties: {
            optIn: formData?.optInUbi === 'true' ? true : false,
          },
        },
      ];
      setAddons(checkedOptIn);
      setFormInfo({
        ...formData,
        optInUbi: 'true',
        showTermsAndConditions: true,
        showTelematicsPolicy: true,
      });
      setUbiInfo({
        ...formData,
        optInUbi: 'true',
        showTermsAndConditions: true,
        showTelematicsPolicy: true,
      });
      // only if terms and condition checked
      if (formData?.termsAndConditions && formData?.telematicsPolicy) {
        handleRender(formData);
      }
      // backup session storage add addOn
    } else if (formData.optInUbi === 'false') {
      const checkedOptIn = [
        {
          name: 'ubi',
          properties: {
            optIn: formData?.optInUbi === 'true' ? true : false,
          },
        },
      ];
      setAddons(checkedOptIn);
      deleteUbiInfo();
      setFormInfo({
        optInUbi: 'false',
        showErrorTips: false,
        showTermsAndConditions: false,
        showTelematicsPolicy: false,
      });
      ratedProp.current = {};
    }
  };
  const handleDialogClose = () => {
    setShowCustomDialog(false);
  };

  const checkUniqueProps = (driverList: any, mainItem: any, key: string) => {
    const isRepeat = driverList.some((item: { [x: string]: any }) => {
      return item[key] === mainItem[key];
    });
    return isRepeat;
  };

  const getRatedDriver = async (originalDrivers: any) => {
    let ratedInfo: { [x: string]: string } = {};
    let ratedNames: string[] = [];
    const pniFlag = 'self';
    let appLocations = await getLocations();
    const state: string = appLocations.filter((item: { primary: boolean }) => {
      return item.primary === true;
    })?.[0]?.address?.state;
    const ratedRule = ubiRatedRule[state];
    const ratedDrivers = originalDrivers.filter(
      // eslint-disable-next-line max-len
      (item: { driverType: string; relationToPrimaryDriver: string }) =>
        ratedRule.includes(item?.driverType?.toUpperCase()) ||
        item?.relationToPrimaryDriver?.toUpperCase() === pniFlag.toUpperCase()
    );
    if (ratedDrivers.length > 1) {
      for (let key in ratedDrivers) {
        let driver = ratedDrivers[key];
        let emailKey = `${driver?.name?.firstName}'s email`;
        let numberKey = `${driver?.name?.firstName}'s phone number`;
        if (ratedInfo[emailKey]) {
          //handle when the key already exists

          ratedInfo[emailKey + key] = driver?.email || '';
          ratedInfo[numberKey + key] = driver?.phoneNumber || '';
        } else {
          ratedInfo[emailKey] = driver?.email || '';
          ratedInfo[numberKey] = driver?.phoneNumber || '';
        }

        ratedNames.push(driver?.name?.firstName);
      }
    }

    if (ratedNames.length > 0) {
      setRatedName(ratedNames);
    }
    return ratedInfo;
  };

  const checkIsUnique = async (originalDrivers: any) => {
    console.log('checkIsUnique');
    let ratedDriver = {};
    const pniFlag = 'self';
    const pniDriver = originalDrivers.filter(
      (driver: { relationToPrimaryDriver: string }) => {
        return driver?.relationToPrimaryDriver === pniFlag;
      }
    );
    // get other drivers
    const otherDrivers = originalDrivers.filter(
      (driver: { relationToPrimaryDriver: string }) => {
        return driver?.relationToPrimaryDriver !== pniFlag;
      }
    );
    // check unique props  email  / phoneNumber
    const isEmailUnique = checkUniqueProps(
      otherDrivers,
      pniDriver?.[0],
      'email'
    );
    const isPhoneUnique = checkUniqueProps(
      otherDrivers,
      pniDriver?.[0],
      'phoneNumber'
    );

    if (isEmailUnique || isPhoneUnique) {
      console.log('into isPhoneUnique or isEmailUnique');
      // get rated driver
      ratedDriver = await getRatedDriver(originalDrivers);
      console.log('ratedDriver', ratedDriver);
    }

    return ratedDriver;
  };

  const checkRequiredProps = (originalDrivers: any, keys: string[]) => {
    for (let driver of originalDrivers) {
      // filter every driver's empty prop
      let result = keys.some((key) => {
        return !driver?.[key];
      });

      if (result) {
        return false;
      }
    }
    return true;
  };

  const updateRatedInfo = (
    ratedDriver: React.SetStateAction<{}>,
    formData?: any
  ) => {
    console.log('ratedDriver', ratedDriver);
    // setRatedProp(ratedDriver);
    ratedProp.current = ratedDriver;
    let UiSchema = dynamicUiSchema;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let formInfo;
    for (let key of Object.keys(ratedDriver)) {
      let uiLaylout = UiSchema['ui:layout'];
      uiLaylout[key] = { md: 6 };
    }

    formInfo = {
      optInUbi: 'true',
      ...ratedDriver,
      ...formData,
      termsAndConditions: formData?.termsAndConditions ?? undefined,
      telematicsPolicy: formData?.telematicsPolicy ?? undefined,
    };
    if (Object.keys(ratedDriver).length > 0) {
      formInfo = {
        optInUbi: 'true',
        ...ratedDriver,
        ...formData,
        termsAndConditions: formData?.termsAndConditions ?? undefined,
        telematicsPolicy: formData?.telematicsPolicy ?? undefined,
        showTermsAndConditions: true,
        showTelematicsPolicy: true,
        showErrorTips: true,
      };
    }
    setDynamicUiSchema(UiSchema);
    setFormInfo(formInfo);
    setUbiInfo({
      optInUbi: 'true',
      condition: formData?.termsAndConditions ?? undefined,
      telematicsPolicy: formData?.telematicsPolicy ?? undefined,
      showTermsAndConditions: true,
      showTelematicsPolicy: true,
      ratedProps: ratedDriver,
    });
  };

  const checkRatedDriver = async (formData?: any) => {
    console.log('check rated driver');
    let ratedDriver = {};
    const requiredProps = ['email', 'phoneNumber'];
    // step1: get application driver list :
    let originalDrivers = await getApplicationObjectItem('drivers');
    // step2: check required props not be empty
    let requiredResult = checkRequiredProps(originalDrivers, requiredProps);
    if (!requiredResult) {
      ratedDriver = await getRatedDriver(originalDrivers);
    } else {
      // step3: it should be checked if not empty
      ratedDriver = await checkIsUnique(originalDrivers);
    }
    console.log('ratedDriver', ratedDriver);
    // step4 : update rated info
    updateRatedInfo(ratedDriver, formData);
  };

  const getUpdatedDrivers = (originalDrivers: any[], formData: any) => {
    let updatedDrivers = originalDrivers;
    updatedDrivers.map((driver) => {
      if (ratedName.includes(driver?.name?.firstName)) {
        let emailKey = `${driver?.name?.firstName}'s email`;
        let numberKey = `${driver?.name?.firstName}'s phone number`;
        driver.email = formData[emailKey];
        driver.phoneNumber = formData[numberKey];
      }
    });
    return updatedDrivers;
  };

  const handleSubmit = async (
    event: IChangeEvent<unknown>,
    formData: any,
    persistanceService: any
  ) => {
    console.log('handleSubmit', event, formData);
    //  step1: update application
    let originalDrivers = await getApplicationObjectItem('drivers');
    let updatedDrivers = getUpdatedDrivers(originalDrivers, formData);
    const pniEmail = updatedDrivers.filter(
      (el) => el?.primary || el.relationToPrimaryDriver.toLowerCase() === 'self'
    )?.[0]?.email;
    // const isEmailExist = await isEmailExistInOtherPolicy(pniEmail);
    // if (isEmailExist) {
    //   setShowCustomDialog(true);
    //   setValidateMessage(
    //     getMicroCopyResourceItem('q2b.primary.driver.email.validation')
    //   );
    //   return;
    // }
    const applicationObject = await getApplicationObject();
    const dataForPersistance = {
      ...applicationObject,
      drivers: updatedDrivers,
    };
    await saveApplicationObject(dataForPersistance);
    //  step2:  calculate destination  update application before quote
    const path = calculateDestination(
      routeKey,
      to,
      novoNavigation.UBI.nextPage
    );
    if (path === novoNavigation.UBI.nextPage) {
      navigate(path);
    } else {
      navigate(path);
    }
  };

  return (
    <Grid container className='quote-wrapper'>
      <>
        <Page routeKey={routeKey}>
          <SimpleDialog
            showDialog={showCustomDialog}
            handleDialogClose={handleDialogClose}
            content={{
              title: 'q2b.modal.uneven.unmarried.drivers.title',
              messages: [validateMessage],
            }}
          />
          <>
            {novoRoutes?.UBI?.image1 && (
              <div className='overlap-image-ubi'>
                <MicrocopyComponent labelKey={novoRoutes?.UBI?.image1} />
              </div>
            )}
            <Box mt={4} />
            <Typography
              className='page-header-h2'
              sx={{
                color: '#55566D',
                marginBottom: '2.25rem',
              }}
            >
              {novoRoutes?.UBI?.body1 && (
                <MicrocopyComponent labelKey={novoRoutes?.UBI?.body1} />
              )}
            </Typography>
            <Box mt={7} />
            <NovoForm
              // disabled={readonly}
              // alert={error}
              formData={formInfo}
              schemaName={routeKey}
              dynamicUiSchema={dynamicUiSchema}
              onSubmit={handleSubmit}
              onChange={handleChange}
              onError={handleError}
              validate={handleValidation}
              transitionTo={false}
              button={
                <Button
                  id='continue'
                  className='btn-arrow'
                  type='submit'
                  color='secondary'
                  variant='contained'
                  endIcon={<ButtonArrow />}
                >
                  {/* Next */}
                  <MicrocopyComponent
                    labelKey={`q2b.global.button.text.next`}
                  />
                </Button>
              }
            />
          </>
        </Page>
      </>
    </Grid>
  );
}
