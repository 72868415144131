import React from 'react';
import { IIconExtendedProps, defaultProps } from './AvatarOne';

export default function AddAvatar({
  fillColor = 'black',
  bgFillColor = '#E8E9EE',
  ...svgProps
}: IIconExtendedProps) {
  return (
    <svg
      {...{ ...defaultProps, ...svgProps }}
      width='72'
      height='72'
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='72' height='72' fill={bgFillColor} />
      <path
        d='M35.8865 21C34.8509 21 34.0115 21.8395 34.0115 22.875L34.0115 49.125C34.0115 50.1605 34.8509 51 35.8865 51C36.922 51 37.7615 50.1605 37.7615 49.125V22.875C37.7615 21.8395 36.922 21 35.8865 21Z'
        fill={fillColor}
      />
      <path
        d='M21 35.8865C21 34.8509 21.8395 34.0115 22.875 34.0115L49.125 34.0115C50.1605 34.0115 51 34.8509 51 35.8865C51 36.922 50.1605 37.7615 49.125 37.7615H22.875C21.8395 37.7615 21 36.922 21 35.8865Z'
        fill={fillColor}
      />
    </svg>
  );
}
