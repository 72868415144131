import { Box, BoxProps, Grid } from '@mui/material';
import { WidgetProps } from '@rjsf/core';
import NovoRadioButton from 'framework/components/NovoRadioButton';
import { getItem } from 'framework/dataService';
import { CustomJsonSchemaProps } from 'framework/forms/interfaces/CustomJsonSchemaProps';
import React from 'react';
import { notifyBugsnag } from 'utils/util';
import CsrDialog from './Dialogs/CsrDialog';
import Label from './Label';

interface EnumOption {
  label: string;
  value: string;
}

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        minWidth: 140,
        ...sx,
      }}
      {...other}
    />
  );
}

const ToggleRadioButton = (props: WidgetProps) => {
  const { id, disabled, options, onChange, value, label, required, isError } =
    props;
  const agentIdSesssion = getItem('isAgent');

  const schema: CustomJsonSchemaProps = props.schema as CustomJsonSchemaProps;
  const [openDialog, setOpenDialog] = React.useState(false);
  const enumOptions: EnumOption[] = options.displayOptions
    ? JSON.parse(JSON.stringify(options.displayOptions))
    : JSON.parse(JSON.stringify(options.enumOptions));

  const handleClick = (data: any) => {
    if (!disabled) {
      const { csrRestriction } = schema;
      if (!agentIdSesssion && csrRestriction?.includes(data)) {
        notifyBugsnag(`CSR pop up validation - Toogle Option`, props);
        setOpenDialog(true);
      }
      onChange(data);
    }
  };

  const handleClose = (closeValue: any) => {
    setOpenDialog(closeValue);
  };

  return (
    <Box
      role={'radiogroup'}
      aria-labelledby={id}
      sx={{
        display: 'flex',
        p: 0,
        bgcolor: 'background.paper',
        borderRadius: 1,
        position: 'relative',
      }}
    >
      <CsrDialog showDialog={openDialog} handleDialogClose={handleClose} />
      <Grid
        container
        className='toggle-radio-button'
        sx={{ rowGap: '0.5rem', position: 'relative' }}
      >
        <Grid
          item
          container
          xs={12}
          lg={7}
          className='toggle-button-label'
          alignItems='center'
        >
          <Item sx={{ flexGrow: 1 }}>
            <Label labelId={id} {...{ disabled, label, required, isError }} />
          </Item>
        </Grid>
        <Grid item xs={12} lg={5} className='toggle-button-content'>
          <Grid
            container
            className='toggle-button-alignment'
            sx={{
              columnGap: { xs: '1rem', lg: '1.875rem' },
            }}
          >
            <Grid item>
              {enumOptions[0] && (
                <Item className='toggle-button-size'>
                  <NovoRadioButton
                    id={`${id}_${enumOptions[0].label}`}
                    name={`${id}_${enumOptions[0].label}`}
                    label={enumOptions[0].label}
                    onClick={() => handleClick(enumOptions[0].value)}
                    disabled={disabled || false}
                    value={enumOptions[0].value}
                    checked={enumOptions[0].value === value}
                    className={`novo-radio-button radio-button-yes ${
                      enumOptions[0].value === value
                        ? 'radio-button-checked'
                        : ''
                    }`}
                    isError={isError}
                  />
                </Item>
              )}
            </Grid>
            <Grid item>
              {enumOptions[1] && (
                <Item className='toggle-button-size'>
                  <NovoRadioButton
                    id={`${id}_${enumOptions[1].label}`}
                    label={enumOptions[1].label}
                    name={`${id}_${enumOptions[1].label}`}
                    onClick={() => handleClick(enumOptions[1].value)}
                    disabled={disabled || false}
                    value={enumOptions[1].value}
                    checked={enumOptions[1].value === value}
                    className={`novo-radio-button radio-button-yes ${
                      enumOptions[1].value === value
                        ? 'radio-button-checked'
                        : ''
                    }`}
                    isError={isError}
                  />
                </Item>
              )}
            </Grid>
          </Grid>
        </Grid>
        <span className='toggle-radio-button-span'></span>
      </Grid>
    </Box>
  );
};

export default ToggleRadioButton;
