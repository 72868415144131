import React from 'react';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';
import parse from 'html-react-parser';
import { isEmpty } from 'utils/util';

interface MicrocopyComponentProps {
  labelKey: string;
  placeHolderKey?: string | string[];
  actualValue?: string | string[] | number;
  customStyle?: boolean;
}

export default function MicrocopyComponent({
  labelKey,
  placeHolderKey,
  actualValue,
  customStyle,
}: MicrocopyComponentProps) {
  let sessionStorageValue = getMicroCopyResourceItem(labelKey);
  if (placeHolderKey && actualValue && sessionStorageValue) {
    if (
      Array.isArray(actualValue) &&
      Array.isArray(placeHolderKey) &&
      actualValue.length === placeHolderKey.length
    ) {
      placeHolderKey.forEach((currentPlaceHolder, index) => {
        sessionStorageValue = sessionStorageValue.replaceAll(
          currentPlaceHolder,
          actualValue[index]
        );
      });
    } else {
      sessionStorageValue = sessionStorageValue.replaceAll(
        placeHolderKey,
        actualValue
      );
    }
  }

  return (
    <div
      className={'microcopy-label'}
      style={customStyle ? { fontSize: '1rem' } : {}}
    >
      {isEmpty(sessionStorageValue) ? labelKey : parse(sessionStorageValue)}
    </div>
  );
}
