import { Box, Grid, Typography } from '@mui/material';
import { novoRoutes } from 'framework/routes';
import React from 'react';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';

export interface HeaderProps {
  routeKey: string;
  overWrittenHeader?: any;
  placeHolderText?: string;
  actualValue?: string;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({
  routeKey,
  overWrittenHeader,
  className,
  placeHolderText,
  actualValue,
}) => {
  const novoRoute = novoRoutes[routeKey];
  let heading = overWrittenHeader?.heading
    ? overWrittenHeader?.heading
    : novoRoute.heading;
  let heading1 = overWrittenHeader?.heading1
    ? overWrittenHeader?.heading1
    : novoRoute.heading1;
  let heading2 = overWrittenHeader?.heading2
    ? overWrittenHeader?.heading2
    : novoRoute.heading2;
  let heading3 = overWrittenHeader?.heading3
    ? overWrittenHeader?.heading3
    : novoRoute.heading3;

  //const novoNav = novoNavigation[routeKey];
  return (
    <>
      <Grid container className={`header-wrapper ${className || ''}`}>
        {heading && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              variant='h1'
              component='h1'
              className={'page-header-h text-line-bottom'}
            >
              {/*  {heading} */}
              <MicrocopyComponent
                labelKey={heading}
                placeHolderKey={placeHolderText}
                actualValue={actualValue}
              />
            </Typography>
          </Grid>
        )}
        {heading1 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1' component='h1' className={'page-header-h'}>
              {/* {heading1} */}
              <MicrocopyComponent
                labelKey={heading1}
                placeHolderKey={placeHolderText}
                actualValue={actualValue}
              />
            </Typography>
          </Grid>
        )}
        {heading1 && heading2 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mb={4} className='text-line-bottom no-padding'></Box>
          </Grid>
        )}
        {heading2 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              variant='subtitle2'
              component='h2'
              className={'page-header-h2'}
            >
              {/* {heading2} */}
              <MicrocopyComponent
                labelKey={heading2}
                placeHolderKey={placeHolderText}
                actualValue={actualValue}
              />
            </Typography>
          </Grid>
        )}
        {heading3 && (
          <>
            <Box m={1} />
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant='subtitle2'
                component='h2'
                className={'page-header-h3'}
              >
                {/* {heading3} */}
                <MicrocopyComponent
                  labelKey={heading3}
                  placeHolderKey={placeHolderText}
                  actualValue={actualValue}
                />
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Header;
