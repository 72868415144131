import { Dialog, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import MicrocopyComponent from '../MicrocopyComponent';
import MuiButton from '../MuiButton';

interface UdrDialogProps {
  showDialog: boolean;
  handleDialogEdit?: any;
  handleDialogNext?: any;
  handleDialogClose?: any;
  content: any;
}

const UdrDialog: React.FC<UdrDialogProps> = ({
  showDialog,
  handleDialogEdit,
  handleDialogNext,
  handleDialogClose,
  content,
}) => {
  const getContent = (msg: string) => {
    return <div className='msgItem'>{msg}</div>;
  };
  const getContentsSection = () => {
    const contentsList: any[] = [];
    let addressContent: any[] = [];
    if (Object.keys(content).length > 0) {
      addressContent = [content.address1, content.address2, content.zipCode];
    }
    addressContent.length > 0 &&
      addressContent.forEach((msg: string) => {
        contentsList.push(getContent(msg));
      });
    return contentsList;
  };

  const contentCheck = (field: string) => {
    if (Object.keys(content).length > 0) {
      return content[field] ? content[field] : '';
    }
    return '';
  };

  return (
    <Dialog onClose={() => handleDialogClose()} open={showDialog}>
      <Grid container sx={{ padding: '40px 55px', border: '10px solid #000' }}>
        <>
          <Typography
            variant='h1'
            component='h1'
            className={'page-header-h text-line-bottom'}
          >
            <MicrocopyComponent labelKey={`q2b.modal.ubi.title`} />
          </Typography>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='subtitle2' className={'page-header-h2'}>
              <MicrocopyComponent labelKey={`q2b.modal.ubi.header`} />
            </Typography>
            <Typography
              variant='subtitle2'
              className={'title-3'}
              style={{ fontWeight: 'bold' }}
            >
              {contentCheck('fullName')}
            </Typography>
            <Typography variant='subtitle2' className={'page-header-h2'}>
              {getContentsSection().map((item) => item)}
            </Typography>
            <Typography variant='subtitle2' className={'title-3'}>
              <MicrocopyComponent labelKey={`q2b.modal.ubi.dl.number`} />
            </Typography>
            <Typography variant='subtitle2' className={'page-header-h2'}>
              {contentCheck('dlNumber')}
            </Typography>
            <Typography variant='subtitle2' className={'title-3'}>
              <MicrocopyComponent labelKey={`q2b.modal.ubi.dob`} />
            </Typography>
            <Typography variant='subtitle2' className={'page-header-h2'}>
              {contentCheck('dob')}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <MuiButton
                id='continue'
                className='btn-gray btn-w-unset'
                type='submit'
                color='secondary'
                variant='contained'
                text={<MicrocopyComponent labelKey={`q2b.modal.ubi.edit`} />}
                onClick={() => handleDialogEdit()}
              />
              <MuiButton
                id='continue'
                className='btn-gray btn-w-unset'
                style={{ marginLeft: '2rem' }}
                type='submit'
                color='secondary'
                variant='contained'
                text={<MicrocopyComponent labelKey={`q2b.modal.ubi.next`} />}
                onClick={() => handleDialogNext()}
              />
            </Grid>
          </Grid>
        </>
      </Grid>
    </Dialog>
  );
};

export default UdrDialog;
