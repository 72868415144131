import { getItem } from 'framework/dataService';
import { notifyBugsnag } from 'utils/util';
import type { CustomJsonSchemaProps } from '../interfaces/CustomJsonSchemaProps';
import { checkAdditionalCSRValidation } from './customValidation/index';

export const CsrRestrictionValidation = (
  schema: CustomJsonSchemaProps,
  formData: any
) => {
  let flag = false;
  const BreakException = {};
  const agentIdSesssion = getItem('isAgent');
  try {
    if (schema?.properties && Object.keys(schema?.properties).length > 0) {
      for (let [fieldName, fieldSchema] of Object.entries(schema?.properties)) {
        // handling each field in the schema.properties
        const additionalCheck = checkAdditionalCSRValidation(
          fieldName,
          fieldSchema,
          schema,
          formData
        );
        fieldName = additionalCheck.fieldName;
        fieldSchema = additionalCheck.fieldSchema;
        if (fieldSchema.csrRestriction && !agentIdSesssion) {
          // if field schema has: csrRestriction
          fieldSchema.csrRestriction.forEach((restrictedOption: any) => {
            // schema.csrRestriction holds an array of restricted answer options from user
            if (formData[fieldName] === restrictedOption) {
              notifyBugsnag(`CSR pop up validation - ${fieldName}`, formData);
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw BreakException;
            }
          });
        }
      }
    }
  } catch (e) {
    if (e !== BreakException) {
      throw e;
    } else {
      flag = true;
    }
  }
  return flag;
};
