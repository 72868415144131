import { getCurrentVehicleByVin } from 'framework/dataService/vehicle';
import { notifyBugsnag } from 'utils/util';
import type { CustomJsonSchemaProps } from '../../../interfaces/CustomJsonSchemaProps';

export const ohRuleMap = {
  uimCompare: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    const bodilyInjury = data?.bodilyInjury?.split('/');
    const uimBodilyInjury =
      data?.uim?.toLowerCase() !== 'declined' ? data?.uim?.split('/') : [];
    if (
      bodilyInjury?.length > 0 &&
      uimBodilyInjury.length > 0 &&
      parseInt(bodilyInjury[0], 10) < parseInt(uimBodilyInjury[0], 10)
    ) {
      const messages: any = fieldSchema.messages;
      let errorMessage = messages?.[validationType];
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag('Custom Form validation Error - uimCompare', {
          errorMessage,
          inputData: data,
        });
        errors[fieldName].addError(errorMessage);
      }
    }
    return {
      isError,
      warningMsgs,
    };
  },
  umpdCoverage: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let errorFieldsExists = false;
    data.vehiclesList &&
      data?.vehiclesList.forEach((vehicle: any, index: number) => {
        const umpd = vehicle?.umpd?.toLowerCase();
        const collDed =
          vehicle?.collisionDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.collisionDeductible?.split(' DED');
        if (
          collDed?.length > 0 &&
          collDed[0] !== 'declined' &&
          umpd &&
          umpd !== 'declined'
        ) {
          const messages: any = fieldSchema.messages;
          let errorMessage = messages?.[validationType];
          errorFieldsExists = true;
          if (errors && errorMessage && data[fieldName] !== undefined) {
            notifyBugsnag('Custom Form validation Error - umpdCoverage', {
              errorMessage,
              inputData: data,
            });
            errors[fieldName][index].umpd.addError(errorMessage);
          }
        }
      });
    if (errorFieldsExists) {
      isError = true;
    }
    return {
      isError,
      warningMsgs,
    };
  },
  comprehensiveDeductible: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let errorFieldsExists = false;
    const financeCase = ['financed', 'lease'];
    data.vehiclesList &&
      data?.vehiclesList.forEach((vehicle: any, index: number) => {
        const currentVin = getCurrentVehicleByVin(vehicle?.vin);
        const compDed =
          vehicle?.comprehensiveDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.comprehensiveDeductible?.split(' DED');
        const collDed =
          vehicle?.collisionDeductible?.toLowerCase() === 'declined'
            ? ['declined']
            : vehicle?.collisionDeductible?.split(' DED');

        if (compDed?.length > 0 && collDed?.length > 0) {
          let showErrorMessage = false;
          let financedErrorMessage = false;
          if (
            currentVin &&
            financeCase.includes(currentVin?.ownershipStatus?.toLowerCase()) &&
            compDed[0] === 'declined'
          ) {
            showErrorMessage = true;
            financedErrorMessage = true;
          }
          if (collDed[0] !== 'declined' && compDed[0] === 'declined') {
            showErrorMessage = true;
          }
          if (showErrorMessage) {
            const messages: any = fieldSchema.messages;
            let errorMessage = financedErrorMessage
              ? messages?.comprehensiveDecline
              : messages?.[validationType];
            errorFieldsExists = true;
            if (errors && errorMessage && data[fieldName] !== undefined) {
              notifyBugsnag(
                'Custom Form validation Error - comprehensiveDeductible',
                { errorMessage, inputData: data }
              );
              errorFieldsExists = true;
              if (errorMessage === messages?.[validationType]) {
                errors[fieldName][index].collisionDeductible.addError(
                  errorMessage
                );
              } else {
                errors[fieldName][index].comprehensiveDeductible.addError(
                  errorMessage
                );
              }
            }
          }
        }
      });
    if (errorFieldsExists) {
      isError = true;
    }
    return {
      isError,
      warningMsgs,
    };
  },
  acpeCoverageLimit: (
    schema: CustomJsonSchemaProps,
    data: any,
    fieldSchema: any,
    validationType: any,
    fieldName: any,
    rule: any,
    errors?: any
  ) => {
    let isError = false;
    let warningMsgs: any[] = [];
    let hasError = false;
    const messages: any = fieldSchema.messages;
    let errorMessage = messages?.[validationType];

    data.vehiclesList.forEach((car: any, index: any) => {
      const acpeCoverage = car.acpe;
      const collDed =
        car?.collisionDeductible?.toLowerCase() === 'declined'
          ? ['declined']
          : car?.collisionDeductible?.split(' DED');
      const compDed =
        car?.comprehensiveDeductible?.toLowerCase() === 'declined'
          ? ['declined']
          : car?.comprehensiveDeductible?.split(' DED');

      if (acpeCoverage > 5000) {
        hasError = true;
        errors[fieldName][index].acpe.addError(errorMessage);
      }

      if (
        acpeCoverage &&
        collDed?.length > 0 &&
        collDed[0] === 'declined' &&
        compDed?.length > 0 &&
        compDed[0] === 'declined'
      ) {
        errorMessage = messages?.acpeCoverage;
        hasError = true;
        errors[fieldName][index].acpe.addError(errorMessage);
      }
    });

    if (hasError) {
      isError = true;
      if (errors && errorMessage && data[fieldName] !== undefined) {
        notifyBugsnag('Custom Form validation Error - acpeCoverageLimit', {
          errorMessage,
          inputData: data,
        });
      }
    }
    return {
      isError,
      warningMsgs,
    };
  },
};
