import { Grid } from '@mui/material';
import Page from 'components/Page';
import React from 'react';

export default function PaymentPortal() {
  const routeKey = 'PaymentPortal';
  return (
    <Grid container className='quote-wrapper'>
      <Page routeKey={routeKey}>
        <div
          style={{ marginLeft: '1rem', fontWeight: 'bold', fontSize: '25px' }}
        >
          Card has been added successfully, please click "make payment" button.
        </div>
      </Page>
    </Grid>
  );
}
