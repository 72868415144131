import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import { getData } from 'framework/dataService/dataRehydration';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import React, { useEffect, useState, useRef, useContext } from 'react';
import Validate from 'framework/forms/validations/Validate';
import getFormDefinitions from 'framework/schemaServer';
import MuiLoader from 'framework/components/MuiLoader';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { DriverContext } from './DriverFormWrapper';

export interface DriverInsuranceInfoProps {
  updateStatus?: any;
  index?: number;
  initialFormData?: any;
  setDirty?: boolean;
  setInitialFormData?: any;
  driverId?: any;
  mode?: any;
  readonly?: boolean;
  routeKey?: string;
}

export default function InsuranceInfo({
  updateStatus,
  index,
  driverId,
  mode,
  readonly,
  routeKey = 'DriverInsuranceInfo',
}: DriverInsuranceInfoProps) {
  const hasComponentMounted = useRef(false);
  const [isDirty, setIsDirty] = useState(true as boolean);
  const [initialFormData, setInitialFormData] = useState({} as any);
  const [showLoader, setShowLoader] = useState(false);
  const { schema } = getFormDefinitions(routeKey);
  const driverContextData = useContext(DriverContext);
  const [prevDriverContext, setPrevDriverContext] = useState(driverContextData);

  useEffect(() => {
    function loadFormData() {
      const param = {
        driverId: driverId,
      };
      const dataForFormData = getData('EditInsuranceInfo', param);
      let formData;
      if (dataForFormData?.driver) {
        formData = { ...dataForFormData.driver };
        setInitialFormData(formData);
        // validate formData
        const errors = Validate(formData, schema);
        if (updateStatus && !errors && formData.isDataReviewed) {
          // done: when all required fields have data
          setIsDirty(false);
          updateStatus(index, 'done', 'onLoad');
        }
      }
    }

    // this works as componentDidMount, works only once
    if (!hasComponentMounted.current) {
      loadFormData();
      hasComponentMounted.current = true;
    }

    // this works as componentDidUpdate, works when context is updated
    if (
      JSON.stringify(driverContextData) !== JSON.stringify(prevDriverContext)
    ) {
      loadFormData();
      setPrevDriverContext(driverContextData);
    }
  }, [
    driverId,
    index,
    schema,
    updateStatus,
    driverContextData,
    prevDriverContext,
  ]);

  const handleSubmit = (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    formData.isDataReviewed = true;
    setShowLoader(true);
    const driverData = getData('EditDriver', { driverId });
    driverData.driver.insuranceDetails = formData;
    const dataForPersistance = {
      driver: {
        ...driverData.driver,
      },
    };
    if (persistanceService) persistanceService(dataForPersistance);

    setIsDirty(false);
    if (updateStatus) updateStatus(index, 'done', 'onSubmit');
    setShowLoader(false);
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (!isDirty && !equals(initialFormData, formData)) {
      // formData just got dirty
      setIsDirty(true);
      if (updateStatus) updateStatus(index, 'open', 'onChange');
    }
    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    return errors;
  };

  return (
    <>
      {showLoader && <MuiLoader />}
      <NovoForm
        disabled={readonly}
        schemaName={routeKey}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        validate={handleValidation}
        //buttonName='looks good'
        buttonName={
          <MicrocopyComponent labelKey={`q2b.global.button.text.looks.good`} />
        }
        formData={initialFormData}
        transitionTo={false}
        shadowButton={true}
        hideSubmitButton={!isDirty}
      />
    </>
  );
}
