import React from 'react';
import { Grid } from '@mui/material';
import MuiButton from 'framework/components/MuiButton';
import { CustomArrayFieldTemplateProps } from './CustomArrayFieldTemplate';
import { removeMarkUp } from 'utils/util';
import { getMicroCopyResourceItem } from 'framework/dataService/contentfulObject';

const DriversArrayFieldTemplate = (props: CustomArrayFieldTemplateProps) => {
  const { items, className, canAdd, onAddClick } = props;

  return (
    <>
      <Grid className={className}>
        {items &&
          items.map((element) => (
            <Grid key={element.key} className={element.className}>
              <Grid>{element.children}</Grid>
              {element.hasMoveDown && (
                <button
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1
                  )}
                >
                  {/* Down */}
                  {removeMarkUp(
                    getMicroCopyResourceItem(`q2b.global.button.text.down`)
                  )}
                </button>
              )}
              {element.hasMoveUp && (
                <button
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1
                  )}
                >
                  {/* Up */}
                  {removeMarkUp(
                    getMicroCopyResourceItem(`q2b.global.button.text.up`)
                  )}
                </button>
              )}
              {element.hasRemove && (
                <button onClick={element.onDropIndexClick(element.index)}>
                  {/* Delete */}
                  {removeMarkUp(
                    getMicroCopyResourceItem(`q2b.global.button.text.delete`)
                  )}
                </button>
              )}
              <hr />
            </Grid>
          ))}

        {canAdd && (
          <Grid className='row test'>
            <p className='col-xs-3 col-xs-offset-9 array-item-add text-right'>
              <MuiButton onClick={onAddClick} type='button'>
                {/* Add */}
                {removeMarkUp(getMicroCopyResourceItem(`q2b.global.text.add`))}
              </MuiButton>
            </p>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DriversArrayFieldTemplate;
