import { novoRoutes } from 'framework/routes';

import { getAllCoverages } from './coverage';
import { getDrivers, getDriverById, getPrimaryDriver } from './drivers';
import {
  getApplicationObjectItem,
  getMetaData,
  getMetaDataItem,
} from './insuranceApplication';
import {
  getAllLocations,
  getLocationById,
  getPrimaryLocation,
} from './locations';
import { getVehicles, getVehicleById } from './vehicles';

export const getData = (routeKey: string, params?: any) => {
  const dataForFormData: any = {
    driver: { driverId: undefined, locationId: undefined },
    drivers: [],
    location: { locationId: undefined },
    locations: [],
    vehicle: { vehicleId: undefined },
    vehicles: [],
    coverages: {},
    testing: null,
  };
  const dataMappings = novoRoutes[routeKey].dataMappings;

  if (dataMappings) {
    Object.entries(dataMappings).forEach(([key, criterias]) => {
      Object.entries(criterias).forEach(([criteria, value]) => {
        switch (key) {
          case 'auth':
            // retrieve quote
            // save progress
            break;
          case 'reviewHistory':
            switch (criteria) {
              case 'all':
                if (value) {
                  const item = getMetaDataItem('reviewHistory');
                  if (item) {
                    dataForFormData.reviewHistory = item;
                  }
                }
                break;
              default:
                console.debug(
                  "reviewHistory's Mapping Criteria is not supported yet for: ",
                  criteria
                );
            }
            break;
          case 'accountInformation':
            switch (criteria) {
              case 'all':
                if (value) {
                  const item = getApplicationObjectItem('accountInformation');
                  if (item) {
                    dataForFormData.accountInformation = item;
                  }
                }
                break;
              default:
                console.debug(
                  "accountInformation's Mapping Criteria is not supported yet for: ",
                  criteria
                );
            }
            break;
          case 'drivers':
            switch (criteria) {
              case 'primary':
                if (value) {
                  const savedDriver = getPrimaryDriver();
                  if (savedDriver) {
                    dataForFormData.driver = savedDriver;
                  }
                }
                break;
              case 'id':
                if (params?.driverId) {
                  const savedDriver = getDriverById(params.driverId);
                  if (savedDriver) {
                    dataForFormData.driver = savedDriver;
                  }
                }
                break;
              case 'insuranceInfoByid':
                if (params?.driverId) {
                  const savedDriver = getDriverById(params.driverId);
                  if (savedDriver) {
                    dataForFormData.driver = {
                      ...savedDriver.insuranceDetails,
                    };
                  }
                }
                break;
              case 'drivingHistoryByid':
                if (params?.driverId) {
                  const savedDriver = getDriverById(params.driverId);
                  if (savedDriver) {
                    dataForFormData.driver = {
                      ...savedDriver.drivingHistory,
                    };
                  }
                }
                break;
              case 'residenceInfoByid':
                if (params?.driverId) {
                  const savedDriver = getDriverById(params.driverId);
                  if (savedDriver) {
                    dataForFormData.driver = {
                      ...savedDriver.residenceInfo,
                    };
                    if (savedDriver.residenceInfo.locationId) {
                      const savedLocation = getLocationById(
                        savedDriver.residenceInfo.locationId
                      );
                      if (savedLocation) {
                        dataForFormData.location = savedLocation;
                      }
                    }
                  }
                }
                break;
              case 'all':
                if (value) {
                  const savedDrivers = getDrivers();
                  if (savedDrivers) {
                    dataForFormData.drivers = savedDrivers;
                  }
                }
                break;
              default:
                console.debug(
                  "Drivers' Mapping Criteria is not supported yet for: ",
                  criteria
                );
            }
            break;
          case 'locations':
            switch (criteria) {
              case 'primary':
                if (value) {
                  const savedLocation = getPrimaryLocation();
                  if (savedLocation) {
                    dataForFormData.location = savedLocation;
                  }
                }
                break;
              case 'id':
                if (params?.locationId) {
                  const savedLocation = getLocationById(params.locationId);
                  if (savedLocation) {
                    dataForFormData.location = savedLocation;
                  }
                }
                break;
              case 'all':
                if (value) {
                  const savedLocations = getAllLocations();
                  if (savedLocations) {
                    dataForFormData.locations = savedLocations;
                  }
                }
                break;
              default:
                console.debug(
                  "Locations' Mapping Criteria is not supported yet for: ",
                  criteria
                );
            }
            break;
          case 'vehicles':
            switch (criteria) {
              case 'id':
                if (params?.vehicleId) {
                  const savedVehicle = getVehicleById(params.vehicleId);
                  if (savedVehicle) {
                    if (savedVehicle.garagingLocationId) {
                      const savedLocation = getLocationById(
                        savedVehicle.garagingLocationId
                      );
                      if (savedLocation) {
                        savedVehicle.location = savedLocation;
                      }
                    }
                    dataForFormData.vehicle = savedVehicle;
                  }
                }
                break;
              case 'all':
                if (value) {
                  const savedVehicles = getVehicles();
                  if (savedVehicles) {
                    dataForFormData.vehicles = savedVehicles;
                  }
                }
                break;
              default:
                console.debug(
                  "Vehicles' Mapping Criteria is not supported yet for: ",
                  criteria
                );
            }
            break;
          case 'coverages':
            switch (criteria) {
              case 'all':
                if (value) {
                  const savedCoverages = getAllCoverages();
                  if (savedCoverages) {
                    dataForFormData.coverage = savedCoverages;
                  }
                }
                break;
              default:
                console.debug(
                  'Coverages Criteria is not supported yet for: ',
                  criteria
                );
            }
            break;
          case 'metaData':
            switch (criteria) {
              case 'all':
                if (value) {
                  const metaData = getMetaData();
                  if (metaData) {
                    dataForFormData.metaData = metaData;
                  }
                }
                break;
              default:
                if (value) {
                  const metaDataItem = getMetaDataItem(criteria);
                  if (metaDataItem) {
                    dataForFormData.metaData = metaDataItem;
                  }
                }
                break;
            }
            break;
          default:
            console.debug('Data Mapping Key is not supported yet for: ', key);
        }
      });
    });
  } else {
    dataForFormData.testing = getApplicationObjectItem('Testing');
  }

  return dataForFormData;

  // return { ...dataForFormData, ...formData };
};
