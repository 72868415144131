import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import ButtonArrow from 'components/icons/ButtonArrow';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { getJson, setItem } from 'framework/dataService';
import { getData } from 'framework/dataService/dataRehydration';
import { getApplicationObjectItem } from 'framework/dataService/insuranceApplication';
import { getVehicles } from 'framework/dataService/vehicles';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import { novoRoutes } from 'framework/routes';
import { lienHolder } from 'library/application/applicationConstants';
import { saveApplicationCurrentSessionState } from 'library/services/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { calculateDestination, setOtherAddressFields } from 'utils/car';
import { getLocationFromFormData } from 'utils/location';

export default function CarForm({ carId, data }: any) {
  const routeKey = 'EditCar';
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const navigateTo = searchParams.get('to');
  const [readonly, setReadonly] = useState(false);

  const [initialFormData, setInitialFormData] = useState(data);
  const [emptyAddress, setEmptyAddress] = useState(true);
  const previousValue = useRef(null);

  useEffect(() => {
    const dataForFormData = getData(routeKey);
    if (dataForFormData?.metaData) {
      setReadonly(true);
    }
  }, []);

  useEffect(() => {
    // This useEffect is for getting the previous formData used for LienHolder info
    previousValue.current = initialFormData;
  }, [initialFormData]);

  useEffect(() => {
    setInitialFormData({ ...initialFormData, ...data });
  }, [data]);

  const getVehicleCoverageFromFormData = (formDataWithoutLocation: any) => {
    let existingCoverage = getApplicationObjectItem('coverage');
    let vehicleCoverage = existingCoverage?.vehicleCoverage
      ? [...existingCoverage?.vehicleCoverage]
      : [];

    if (formDataWithoutLocation?.ownershipStatus?.toUpperCase() === 'OWN') {
      // handle the case own
      return vehicleCoverage?.map((coverage: any) => {
        if (coverage?.vehicleId === formDataWithoutLocation?.vehicleId) {
          coverage.loanPayoffLimit = 'DECLINED';
        }
        return coverage;
      });
    } else {
      return vehicleCoverage;
    }
  };

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    setItem('ReconciliationViewed', 'false');
    let updatedFormData = {
      ...initialFormData,
      ...formData,
    };
    const { location, formDataWithoutLocation } = getLocationFromFormData(
      updatedFormData,
      true
    );

    // eslint-disable-next-line max-len
    const vehicleCoverage = getVehicleCoverageFromFormData(
      formDataWithoutLocation
    );
    let dataForPersistance = {
      vehicleLocation: {
        vehicle: {
          ...formDataWithoutLocation,
        },
        location: undefined,
      },
      vehicleCoverage: vehicleCoverage,
    };
    if (location && !location.primary) {
      dataForPersistance.vehicleLocation.location = {
        ...location,
        primary: false,
        type: 'garaging',
        vehicleId: formData.vehicleId,
      };
    }

    if (persistanceService) persistanceService(dataForPersistance);

    // Update Application Object
    const resp = await saveApplicationCurrentSessionState();
    if (!resp.success) {
      console.error('Car: Update: Update Application Object is FAILED.');
    } else {
      console.debug('Car: Update: Update Application Object is DONE.');
    }

    navigate(calculateDestination(routeKey, navigateTo, novoRoutes.Cars.path));
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    const savedVehicles = getVehicles();
    const filteredVehicle = savedVehicles.filter(
      (v: any) => v.vehicleId === formData.vehicleId
    );
    let shouldLocationChange = false;

    if (filteredVehicle && filteredVehicle.length > 0) {
      const locationStatus =
        filteredVehicle[0]?.garagingAddressSameAsPrimaryAddress;
      const formLocationStatus = formData?.garagingAddressSameAsPrimaryAddress;
      if (locationStatus !== formLocationStatus) {
        shouldLocationChange = true;
      }
    }

    if (
      !formData.garagingAddressSameAsPrimaryAddress &&
      equals(formData?.location, initialFormData?.location) &&
      emptyAddress &&
      shouldLocationChange
    ) {
      formData.location = {
        city: '',
        fullAddress: '',
        state: '',
        streetName: '',
        streetNumber: '',
        zipCode: '',
      };
      formData.city = '';
      formData.state = '';
      formData.zipCode = '';
      formData.garagingLocationId = '';
      setEmptyAddress(false);
    }

    if (formData.ownershipStatus?.toUpperCase() === 'OWN') {
      formData.lienHolderName = '';
      formData.lienHolderAddress1 = '';
      formData.lienHolderAddress2 = '';
      formData.lienHolderCity = '';
      formData.lienHolderState = '';
      formData.lienHolderZipCode = '';
    } else {
      if (formData.lienHolderName) {
        const lienHolderInfo = getJson(lienHolder);
        const lienHolderFilter = lienHolderInfo.filter(
          (info: any) => info.value === formData.lienHolderName
        );
        // To get the previous formData
        const previousFormData: any = previousValue.current;
        // To empty the Lien holder address form fields when other is choosen
        if (
          formData.lienHolderName === 'Others' &&
          previousFormData &&
          previousFormData.lienHolderName &&
          previousFormData.lienHolderName !== 'Others'
        ) {
          formData.lienHolderAddress1 = '';
          formData.lienHolderAddress2 = '';
          formData.lienHolderCity = '';
          formData.lienHolderState = '';
          formData.lienHolderZipCode = '';
        }
        if (lienHolderFilter.length > 0) {
          formData.lienHolderAddress1 = lienHolderFilter[0].address || '';
          formData.lienHolderAddress2 = lienHolderFilter[0].address2 || '';
          formData.lienHolderCity = lienHolderFilter[0].city || '';
          formData.lienHolderState = lienHolderFilter[0].state || '';
          formData.lienHolderZipCode = lienHolderFilter[0].zip || '';
        } else if (!formData.lienHolderAddress1) {
          formData.lienHolderAddress1 = '';
          formData.lienHolderAddress2 = '';
          formData.lienHolderCity = '';
          formData.lienHolderState = '';
          formData.lienHolderZipCode = '';
        }
      }
    }

    if (
      formData?.location?.fullAddress?.length > 0 &&
      !equals(formData?.location, initialFormData?.location)
    ) {
      setOtherAddressFields(formData.location, formData, initialFormData);
    }
    if (
      formData.garagingAddressSameAsPrimaryAddress &&
      formData.state !== data.state
    ) {
      // replace address/location fields to primary
      setOtherAddressFields({}, formData, data);
    }
    setInitialFormData(formData); // needed for custom fields
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };
  const ownershipValidation = (
    range: number,
    formData: any,
    errors: FormValidation
  ) => {
    const ownerLength = [
      'Less than 1 year',
      '1-2 years',
      '3-4 years',
      '5 or more years',
    ];
    // eslint-disable-next-line max-len
    if (
      (range === 0 && formData?.ownershipLength !== ownerLength[0]) ||
      (range <= 2 &&
        !ownerLength.slice(0, 2).includes(formData?.ownershipLength)) ||
      (range <= 4 &&
        !ownerLength.slice(0, 3).includes(formData?.ownershipLength))
    ) {
      if (errors.ownershipLength.__errors.length === 0) {
        errors.ownershipLength.addError('Please confirm your ownership length');
      }
    }
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    if (formData.garagingAddressSameAsPrimaryAddress === false) {
      const { location } = formData;
      if (!location || (location && !location.fullAddress)) {
        errors.location.addError('Please enter your address.');
      }
    }
    const fullYear = new Date().getFullYear();
    const ownerRange = fullYear - Number(formData.year);
    ownershipValidation(ownerRange, formData, errors);
    return errors;
  };

  return (
    <>
      <Grid className='car-form'>
        <NovoForm
          disabled={readonly}
          schemaName={routeKey}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onError={handleError}
          validate={handleValidation}
          transitionTo={false}
          formData={initialFormData}
          getLienHolder={true}
          button={
            <Button
              className='btn-arrow'
              type='submit'
              color='secondary'
              variant='contained'
              disabled={false}
              endIcon={<ButtonArrow />}
            >
              <MicrocopyComponent labelKey={`q2b.global.button.text.next`} />
            </Button>
          }
        />
      </Grid>
    </>
  );
}
