import { getAge } from 'utils/dateUtils';
import { incidentDescription } from './IncidentFormat';

export const claimsFormat = (d: any) => {
  const claimAnalysis: any[] = [];
  d.reconciliation &&
    d.reconciliation.claimHistory &&
    d.reconciliation.claimHistory[0] &&
    d.reconciliation.claimHistory[0]['claim-list'] &&
    d.reconciliation.claimHistory[0]['claim-list'].forEach((c: any) => {
      if (c?.claim['incident-on']) {
        claimAnalysis.push(c);
      }
    });

  return claimAnalysis;
};

export const incidentFormatNew = (d: any) => {
  const incidentAnalysis: any[] = [];
  const accidentList: any[] = [];
  const violationList: any[] = [];
  const incidentList: any[] = [];
  d.reconciliation &&
    d.reconciliation.claimHistory &&
    d.reconciliation.claimHistory[0] &&
    d.reconciliation.claimHistory[0]['incident-list'] &&
    d.reconciliation.claimHistory[0]['incident-list'].forEach((c: any) => {
      if (c.category && c.source !== 'self') {
        const categoryType = c.category.toLowerCase();
        let groupInfo = '';
        if (
          categoryType === 'aaf' ||
          categoryType === 'ind' ||
          categoryType === 'naf'
        ) {
          groupInfo = 'accident';
        } else {
          groupInfo = 'violation';
        }
        if (groupInfo === 'accident') {
          const incident = {
            date: c['incident-on'] ? c['incident-on'] : '',
            type: groupInfo,
            category: c.category,
            code: c.code,
            name: d.name,
            description: c.description,
          };
          if (c['incident-on']) {
            const getDiffYear = getAge(c['incident-on']);
            if (getDiffYear < 4) {
              accidentList.push(incident);
            }
          }
        } else if (groupInfo === 'violation') {
          const incident = {
            date: c['incident-on'] ? c['incident-on'] : '',
            type: groupInfo,
            category: c.category,
            code: c.code,
            name: d.name,
            description: c.description,
          };
          c['incident-on'] && violationList.push(incident);
        }
      }
    });
  incidentAnalysis.push({
    accidentList,
    violationList,
  });
  return incidentAnalysis;
};

export const dlFormat = (a: any) => {
  const dlAnalysis: any[] = [];
  if (
    a.reconciliation &&
    a.reconciliation.claimHistory &&
    a.reconciliation.claimHistory[0] &&
    a.reconciliation.claimHistory[0].license &&
    Object.keys(a.reconciliation.claimHistory[0].license).length > 0
  ) {
    const dlNumber = a.reconciliation.claimHistory[0].license?.id || '';
    const dlState = a.reconciliation.claimHistory[0].license?.state || '';
    const dlStatus = a.reconciliation.claimHistory[0].license?.status || '';
    const dlValidStatus =
      a.driverLicenseStatus &&
      a.driverLicenseStatus.toLowerCase().includes('valid')
        ? 'valid'
        : '';
    const dlReported = a.dlNumber;
    let dlStatusCheck = false;
    dlStatusCheck = dlStatus && dlValidStatus !== dlStatus.toLowerCase();
    if (dlReported !== dlNumber || dlStatusCheck) {
      const licenseData = {
        dlNumber,
        dlState,
        dlStatus,
        dlNumberReported: dlReported,
        dlStateReported: a.licenseState,
        dlStatusReported: dlValidStatus,
      };
      dlAnalysis.push(licenseData);
    }
  }
  return dlAnalysis;
};

export const coverageFormat = (d: any) => {
  const coverageAnalysis: any[] = [];
  if (
    d.reconciliation &&
    d.reconciliation.coverageHistory &&
    d.reconciliation.coverageHistory[0] &&
    d.reconciliation.coverageHistory[0]['policy-list'] &&
    d.reconciliation.coverageHistory[0]['policy-list'].length > 0
  ) {
    let providerConditionCheck = false,
      coverageConditionCheck = false,
      dateConditionCheck = false;
    const coverageLimitformat = d.reconciliation.coverageHistory[0][
      'policy-list'
    ][0]
      ? `${
          parseInt(
            d.reconciliation.coverageHistory[0]['policy-list'][0][
              'per-injury-limit'
            ]
          ) / 1000
        }k/${
          parseInt(
            d.reconciliation.coverageHistory[0]['policy-list'][0][
              'per-accident-limit'
            ]
          ) / 1000
        }k`
      : '';
    const reportedDateSplit = d.insuranceDetails.currentCoverageStart
      ? d.insuranceDetails.currentCoverageStart.split('/')
      : '';
    const reportedDateFormat = reportedDateSplit
      ? `${reportedDateSplit[1]}-${reportedDateSplit[0]}-01`
      : '';
    const reportedBodilyInjuryLimits =
      d.insuranceDetails.currentBodilyInjuryLimits;
    const reportedInsuranceProvider = d.insuranceDetails
      .currentInsuranceProvider
      ? d.insuranceDetails.currentInsuranceProvider.toLowerCase()
      : '';
    const foundCoverageStart = d.reconciliation.coverageHistory[0][
      'policy-list'
    ][0]['expire-on']
      ? d.reconciliation.coverageHistory[0]['policy-list'][0][
          'expire-on'
        ].split('-')
      : '';
    const foundInsuranceProvider = d.reconciliation.coverageHistory[0][
      'policy-list'
    ][0].carrier
      ? d.reconciliation.coverageHistory[0][
          'policy-list'
        ][0].carrier.toLowerCase()
      : '';

    providerConditionCheck =
      reportedInsuranceProvider && foundInsuranceProvider
        ? reportedInsuranceProvider != foundInsuranceProvider
        : false;
    dateConditionCheck =
      reportedDateSplit && foundCoverageStart
        ? reportedDateSplit[0] != foundCoverageStart[1] &&
          reportedDateSplit[1] != foundCoverageStart[0]
        : false;
    coverageConditionCheck =
      reportedBodilyInjuryLimits && coverageLimitformat
        ? reportedBodilyInjuryLimits != coverageLimitformat
        : false;

    if (
      providerConditionCheck ||
      dateConditionCheck ||
      coverageConditionCheck
    ) {
      const coverageData = {
        reportedBodilyInjuryLimits:
          d.insuranceDetails.currentBodilyInjuryLimits,
        reportedCoverageStart: reportedDateFormat,
        reportedInsuranceProvider: d.insuranceDetails.currentInsuranceProvider,
        foundBodilyInjuryLimits: coverageLimitformat,
        foundCoverageStart:
          d.reconciliation.coverageHistory[0]['policy-list'][0]['expire-on'],
        foundInsuranceProvider:
          d.reconciliation.coverageHistory[0]['policy-list'][0].carrier,
      };
      coverageAnalysis.push(coverageData);
    }
  }
  return coverageAnalysis;
};

export const incidentFilter = (incidentList: any, drivingReported: any) => {
  let incidentFormatedData: any[] = [];
  let accidentCompareList: any[] = [];
  let accidentUnmatchedList: any[] = [];
  let accidentMatchedList: any[] = [];
  let violationCompareList: any[] = [];
  let violationUnmatchedList: any[] = [];
  let violationMatchedList: any[] = [];
  let enetredLoopAcc = false;
  incidentList &&
    incidentList[0].accidentList.length > 0 &&
    incidentList[0].accidentList.forEach((a: any) => {
      let breakLoop = false;
      drivingReported.accidents.accidentList.forEach((d: any) => {
        let foundYear = '',
          foundMonth = '',
          reportedYear = '',
          reportedMonth = '';
        if (a.date && a.date.split('-').length > 1) {
          foundYear = a.date.split('-')[0];
          foundMonth = a.date.split('-')[1].toString().padStart(2, '0');
        }
        if (d.monthYear && d.monthYear.split('/').length > 1) {
          reportedYear = d.monthYear.split('/')[1];
          reportedMonth = d.monthYear.split('/')[0].toString().padStart(2, '0');
        }
        let monthCheckBefore = reportedMonth ? parseInt(reportedMonth) + 1 : 0;
        let monthCheckAfter = reportedMonth ? parseInt(reportedMonth) - 1 : 0;
        const foundMonthInt = parseInt(foundMonth);
        if (monthCheckAfter == 13) {
          monthCheckAfter = 1;
        }
        if (
          foundMonth &&
          reportedMonth &&
          foundYear &&
          reportedYear &&
          foundMonth == reportedMonth &&
          foundYear == reportedYear &&
          d.typeOfAccident == a.code &&
          !breakLoop
        ) {
          const incident = {
            foundDate: a.date,
            foundCode: a.code,
            type: a.type,
            foundDescription: a.description,
            status: 'matched',
            source: 'mvr',
            removeReportedDate: d.monthYear,
          };
          breakLoop = true;
          enetredLoopAcc = true;
          accidentMatchedList.push(incident);
        } else if (
          foundYear &&
          reportedYear &&
          (foundMonthInt == parseInt(reportedMonth) ||
            foundMonthInt == monthCheckBefore ||
            foundMonthInt == monthCheckAfter) &&
          foundYear == reportedYear &&
          d.typeOfAccident == a.code &&
          !breakLoop
        ) {
          const description = incidentDescription('accident', d.typeOfAccident);
          const incident = {
            foundDate: a.date,
            foundCode: a.code,
            type: a.type,
            foundDescription: a.description,
            reportedDate: `${reportedYear}-${reportedMonth}-01`,
            reportedCode: d.typeOfAccident,
            reportedDescription: description,
            status: 'compare',
            removeReportedDate: d.monthYear,
          };
          breakLoop = true;
          enetredLoopAcc = true;
          accidentCompareList.push(incident);
        }
      });
      if (!enetredLoopAcc) {
        const incident = {
          foundDate: a.date,
          foundCode: a.code,
          type: a.type,
          foundDescription: a.description,
          status: 'unmatched',
        };
        breakLoop = true;
        enetredLoopAcc = false;
        accidentUnmatchedList.push(incident);
      }
    });

  incidentList &&
    incidentList[0].violationList.length > 0 &&
    incidentList[0].violationList.forEach((v: any) => {
      let breakLoop = false;
      let enetredLoopVio = false;
      drivingReported.traffic.trafficViolationList.length > 0 &&
        drivingReported.traffic.trafficViolationList.forEach((d: any) => {
          let foundYear = '',
            foundMonth = '',
            reportedYear = '',
            reportedMonth = '';
          if (v.date && v.date.split('-').length > 1) {
            foundYear = v.date.split('-')[0];
            foundMonth = v.date.split('-')[1].toString().padStart(2, '0');
          }
          if (d.monthYear && d.monthYear.split('/').length > 1) {
            reportedYear = d.monthYear.split('/')[1];
            reportedMonth = d.monthYear
              .split('/')[0]
              .toString()
              .padStart(2, '0');
          }
          let monthCheckBefore = reportedMonth
            ? parseInt(reportedMonth) + 1
            : 0;
          let monthCheckAfter = reportedMonth ? parseInt(reportedMonth) - 1 : 0;
          const foundMonthInt = parseInt(foundMonth);
          if (monthCheckAfter == 13) {
            monthCheckAfter = 1;
          }
          if (
            foundMonth &&
            reportedMonth &&
            foundYear &&
            reportedYear &&
            foundMonth == reportedMonth &&
            foundYear == reportedYear &&
            d.typeOfViolation == v.code &&
            !breakLoop
          ) {
            const incident = {
              foundDate: v.date,
              foundCode: v.code,
              type: v.type,
              foundDescription: v.description,
              status: 'matched',
              source: 'mvr',
              removeReportedDate: d.monthYear,
            };
            breakLoop = true;
            enetredLoopVio = true;
            violationMatchedList.push(incident);
          } else if (
            foundYear &&
            reportedYear &&
            (foundMonthInt == parseInt(reportedMonth) ||
              foundMonthInt == monthCheckBefore ||
              foundMonthInt == monthCheckAfter) &&
            foundYear == reportedYear &&
            d.typeOfViolation == v.code &&
            !breakLoop
          ) {
            const description = incidentDescription(
              'violation',
              d.typeOfViolation
            );
            const incident = {
              foundDate: v.date,
              foundCode: v.code,
              type: v.type,
              foundDescription: v.description,
              reportedDate: `${reportedYear}-${reportedMonth}-01`,
              reportedCode: d.typeOfViolation,
              reportedDescription: description,
              status: 'compare',
              removeReportedDate: d.monthYear,
            };
            breakLoop = true;
            enetredLoopVio = true;
            violationCompareList.push(incident);
          }
        });
      if (!enetredLoopVio) {
        const incident = {
          foundDate: v.date,
          foundCode: v.code,
          type: v.type,
          foundDescription: v.description,
          status: 'unmatched',
        };
        breakLoop = true;
        enetredLoopVio = false;
        violationUnmatchedList.push(incident);
      }
    });

  incidentFormatedData.push({
    accidentCompareList: accidentCompareList,
    accidentUnmatchedList: accidentUnmatchedList,
    accidentMatchedList: accidentMatchedList,
    violationCompareList: violationCompareList,
    violationUnmatchedList: violationUnmatchedList,
    violationMatchedList: violationMatchedList,
  });

  return incidentFormatedData;
};
