import React from 'react';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl, TextField } from '@mui/material';
import CalendarIcon from 'components/icons/CalendarIcon';
import type { WidgetProps } from '@rjsf/core';
import { CustomJsonSchemaProps } from 'framework/forms/interfaces/CustomJsonSchemaProps';
import { getDateAtMidnight, getDateYearsFromToday } from 'utils/dateUtils';
import { EnumOption } from 'types/GenericTypes';
import { DatePickerView } from '@mui/lab/DatePicker/shared';

const MuiDatePickerWidget = (props: WidgetProps) => {
  const {
    label,
    schema,
    // uiSchema,
    required,
    value,
    disabled,
    onChange,
    className,
    rawErrors,
    options,
    id,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<Date | null>(null);
  const enumOptions: EnumOption = JSON.parse(JSON.stringify(options)) || {};
  const openTo = enumOptions?.openTo || 'date';

  React.useEffect(() => {
    if (value) {
      setSelectedValue(getDateAtMidnight(value));
    }
  }, [value]);

  const updatedSchema = schema as CustomJsonSchemaProps;
  const maxDate =
    updatedSchema?.maxYear !== undefined
      ? getDateYearsFromToday(updatedSchema.maxYear)
      : undefined;
  const minDate =
    updatedSchema?.minYear !== undefined
      ? getDateYearsFromToday(updatedSchema.minYear)
      : undefined;

  const handleChange = (date: Date | null) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = date || selectedValue;
    }
    if (onChange) {
      if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
        const dateArray = new Intl.DateTimeFormat('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })
          ?.format(newDate)
          ?.split('/');
        const formattedDate = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
        setSelectedValue(getDateAtMidnight(formattedDate));
        onChange(formattedDate);
      } else {
        onChange(undefined);
        setSelectedValue(null);
      }
    }
  };

  const requiredLabel = required ? label + ' *' : label;

  return (
    <FormControl
      className={`${open ? 'date-picker_open' : ''} ${
        className ? className : ''
      }`}
      sx={{ width: '100%' }}
    >
      <DatePicker
        allowSameDateSelection={true}
        disableFuture={enumOptions?.disableFuture}
        disabled={disabled}
        label={requiredLabel}
        openTo={openTo as DatePickerView}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        maxDate={maxDate}
        minDate={minDate}
        views={['year', 'month', 'day']}
        value={selectedValue || null}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} id={id} error={Boolean(rawErrors?.length)} />
        )}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
      />
    </FormControl>
  );
};

export default MuiDatePickerWidget;
