import ButtonUnstyled from '@mui/base/ButtonUnstyled/ButtonUnstyled';
import { styled } from '@mui/system';
import EditIcon from 'components/icons/EditIcon';
import React from 'react';

const Button = styled(ButtonUnstyled)`
  font-family: Lato, sans-serif;
  color: #000;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 0.875;
  font-weight: 600;
  background-color: #fff;
  flex: 1 1 0;
  padding: 12px;
  border: none;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  letter-spacing: 0.063em;
  width: 100%;
  height: 100%;
  position: relative;
  &:hover {
    background-color: #f1f2f5;
  }
  @media screen and (min-width: 1024px) {
    border-top: 2px solid #e9ebf0;
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
  .tab_icon {
    display: none;
  }
  &:focus {
    border-radius: 0px;
  }
  &:not([aria-selected='true']):not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    background-color: black;
    height: 100%;
    width: 2px;
    right: 0;
    top: 0;
    @media screen and (min-width: 1024px) {
      height: 20px;
      background-color: #e9ebf0;
    }
  }
  &[aria-selected='true'] {
    background-color: #000;
    color: white;
    border-color: #000;
    &:hover {
      background-color: #292c3d;
    }
    .tab_icon {
      @media screen and (min-width: 1024px) {
        display: block;
        margin-right: 12px;
      }
    }
    &:after {
      content: '';
      display: block;
      width: calc(100% - 9px);
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: -2px;
      right: 0;
    }
    &:before {
      content: '';
      display: block;
      background-image: url('/stripe-line.svg');
      background-repeat: no-repeat;
      background-position: top left;
      width: 100%;
      height: 4px;
      top: -4px;
      left: 0;
      position: absolute;
    }
  }
`;
interface NovoTabProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string | JSX.Element;
}
const NovoTab: React.FC<NovoTabProps> = ({ text, ...props }) => {
  return (
    <Button {...props}>
      <EditIcon className={'tab_icon'} />
      {text}
    </Button>
  );
};

export default NovoTab;
