import * as zipcodeToTimezone from 'zipcode-to-timezone';

export function compareWithMaxDate(date: string, compareToDate: string) {
  const dateObject = getDateFromString(date);
  const compareToDateObject = getDateFromString(compareToDate);

  if (!(isValidDate(dateObject) && isValidDate(compareToDateObject))) {
    return true;
  }
  return dateObject.getTime() > compareToDateObject.getTime();
}

export function compareWithMinDate(date: string, compareToDate: string) {
  const dateObject = getDateFromString(date);
  const compareToDateObject = getDateFromString(compareToDate);

  if (!(isValidDate(dateObject) && isValidDate(compareToDateObject))) {
    return true;
  }
  return dateObject.getTime() < compareToDateObject.getTime();
}

export function formatDate(date: string) {
  const newDate = new Date(date + 'T00:00:00');
  return newDate;
}

export function dateToString(date: Date) {
  var month = '' + (date.getMonth() + 1);
  var day = '' + date.getDate();
  var year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-') + 'T00:00:00';
}

export function getDateOfMidnight(date: Date) {
  const today = dateToString(date);
  return today;
}

export function getToday() {
  const today = dateToString(getDateAtMidnight());
  return today;
}

export function getUpComingDate(date: string, day: number) {
  const newDate = new Date().setDate(new Date(date).getDate() + day - 1);
  return dateToString(new Date(newDate));
}

export function getPastDate(date: string, day: number) {
  const newDate = new Date().setDate(new Date(date).getDate() - day + 1);
  return dateToString(new Date(newDate));
}

export function getDateFromString(date: string) {
  const newDate = new Date(date);
  return newDate;
}

export function getDateAtMidnight(date?: string) {
  const dateObject = date ? new Date(`${date}T00:00:00`) : new Date();
  // To get rid of the hours, minutes, seconds and milliseconds
  dateObject.setHours(0, 0, 0, 0);
  return dateObject;
}

export function getDateAfterDaysFromTodayMidnight(days: number) {
  const dateObject = getDateAtMidnight();
  dateObject.setDate(dateObject.getDate() + days);
  dateObject.setHours(0, 0, 0, 0);
  return dateObject;
}

export function getDateAfterDaysFromToday(days: number) {
  const dateObject = getDateAtMidnight();
  dateObject.setDate(dateObject.getDate() + days);
  return dateObject.toString();
}

export function getDateYearsFromToday(years: number) {
  const dateObject = new Date();
  dateObject.setFullYear(dateObject.getFullYear() + years);
  return dateObject;
}

export function getDateYearsBeforeToday(years: number) {
  const dateObject = new Date();
  dateObject.setFullYear(dateObject.getFullYear() - years);
  return dateObject;
}

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function compareDates(date: string, compareToDate?: string) {
  const dateObject = getDateAtMidnight(date);
  const compareToDateObject = getDateAtMidnight(compareToDate);
  return dateObject.getTime() >= compareToDateObject.getTime();
}

export function getFormattedDate(date: Date) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}

export function getFormattedDateFromString(dateStr: string) {
  const date = formatDate(dateStr);
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}
export function getDateAfterDays(date: string, day: number) {
  const newDate = new Date().setDate(new Date(date).getDate() + day);
  return dateToString(new Date(newDate));
}

export function isValidDate(date: any) {
  return (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date)
  );
}

export function getCalendarFormattedDate(date?: Date) {
  const dateArray = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
    ?.format(date)
    ?.split('/');

  return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
}

export function getAge(date: string) {
  const today = getDateAtMidnight();
  const birthDate = getDateAtMidnight(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1;
  }
  return age;
}
export function getValidateDate(date: string) {
  const today = getDateAfterDaysFromTodayMidnight(0);
  const birthDate = getDateAtMidnight(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
    age = age - 1;
  }
  return age;
}

export function futureDateValidation(date: any) {
  const inputDate = new Date(date);
  const currentDate = new Date();
  if (inputDate > currentDate) {
    return true;
  } else {
    return false;
  }
}

export function isValidExpirationDate(date: any) {
  const selectedDate = date.split('/');
  const today = getFormattedDate(new Date());
  const todayDate = today.split('/');
  let currentYear = todayDate[2];
  const selectedYear = selectedDate[1];
  const currentMonth = todayDate[0];
  const selectedMonth = selectedDate[0];
  if (selectedYear.length === 2) {
    currentYear = currentYear.slice(-2);
  }
  if (currentYear === selectedYear) {
    if (selectedMonth < currentMonth) {
      return false;
    }
  } else if (currentYear > selectedYear) {
    return false;
  }
  return true;
}

export function getFullDateFromMMYYYY(date: any) {
  const newDate = date.split('/');
  const year = newDate[1];
  const month = newDate[0];
  const fullDate = year + '-' + month;
  return new Date(fullDate);
}

export function isAccidentDate(date: any) {
  const minYear = getFormattedDate(getDateYearsBeforeToday(5));
  const today = getFormattedDate(new Date());
  const selectedDate = date.split('/');
  const todayDate = today.split('/');
  const Year = minYear.split('/');
  let currentYear = todayDate[2];
  let selectedYear = selectedDate[2];
  const currentMonth = parseInt(todayDate[0]);
  let selectedMonth = parseInt(selectedDate[0]);
  let pastYear = Year[2];
  const pastMonth = parseInt(Year[0]);

  if (selectedYear.length === 2) {
    currentYear = currentYear.slice(-2);
    pastYear = pastYear.slice(-2);
  }

  //month comes in as the previous month number, must add 1 for correct month and add 1 to year if month selected was Jan
  // if (selectedMonth === 12) {
  //   selectedMonth = 1;
  //   let selectedYearFull = parseInt(selectedYear) + 1;
  //   selectedYear = selectedYearFull.toString();
  // } else {
  //   selectedMonth = selectedMonth + 1;
  // }

  if (currentYear < selectedYear) {
    return false;
  } else if (selectedYear < pastYear) {
    return false;
  } else if (currentYear === selectedYear) {
    if (selectedMonth > currentMonth) {
      return false;
    }
  } else if (selectedYear === pastYear) {
    if (selectedMonth < pastMonth) {
      return false;
    }
  }
  return true;
}
export function isMonthYearInRange(
  selectedMonthYear: any,
  minimum: number,
  maximum: number
) {
  const selectedMYArray = selectedMonthYear.split('/');

  const today = getFormattedDate(new Date());
  const todayDateArray = today.split('/');

  const currentYear = (todayDateArray[2] as any) * 1;
  const minimumYear = currentYear + minimum;
  const maximumYear = currentYear + maximum;
  const selectedYear = selectedMYArray[1] * 1;

  const currentMonth = (todayDateArray[0] as any) * 1;
  const selectedMonth = selectedMYArray[0] * 1;

  if (selectedYear < minimumYear) {
    return false;
  } else if (selectedYear === minimumYear) {
    if (selectedMonth < currentMonth) {
      return false;
    }
  } else if (selectedYear > maximumYear) {
    return false;
  } else if (selectedYear === maximumYear) {
    if (selectedMonth > currentMonth) {
      return false;
    }
  }
  return true;
}

export function getFormattedDateWithTimezone(
  dateString: string,
  zipCode: string
) {
  const date = new Date(dateString);
  date.setHours(0, 1);
  const dateStringSplit = dateString.split('-');
  let dateUsFormat;
  if (dateStringSplit?.length === 1) {
    dateUsFormat = `${dateStringSplit?.[0]}`;
  } else {
    dateUsFormat = `${dateStringSplit?.[1]}/${dateStringSplit?.[2]}/${dateStringSplit?.[0]}`;
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneZip = zipcodeToTimezone.lookup(zipCode) || timezone;
  const formattedDateTimeZone = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: timeZoneZip,
    timeZoneName: 'short',
    hour: 'numeric',
    minute: 'numeric',
  })?.format(date);
  const formattedDate = `${dateUsFormat} ${
    formattedDateTimeZone.toString().split(',')[1]
  }`;
  return formattedDate;
}
