import { getApplicationObjectItem } from 'framework/dataService/insuranceApplication';
import { v4 as uuidv4 } from 'uuid';

export const getLocationFromFormData = (
  formData: any,
  isGaragingLocation: boolean = false
) => {
  const exsistingLocation = getApplicationObjectItem('locations');
  let location = { ...formData.location };

  if (location?.fullAddress) {
    if (formData.defaultAddress) {
      location.defaultAddress = formData.defaultAddress;
    }
    if (formData.fullAddress) {
      location.fullAddress = formData.fullAddress;
    }
    if (formData.apt) {
      location.apt = formData.apt;
    }
    if (formData.city) {
      location.city = formData.city;
    }
    if (formData.state) {
      location.state = formData.state;
    }
    if (formData.zipCode) {
      location.zipCode = formData.zipCode;
    }

    location.address = {
      address:
        location.defaultAddress ||
        `${location.streetNumber} ${location.streetName}`,
      apt: location.apt,
      city: location.city,
      state: location.state,
      zip: location.zipCode,
    };

    delete formData.location;
    delete formData.apt;
    delete formData.city;
    delete formData.state;
    delete formData.zipCode;

    // locationId
    if (isGaragingLocation) {
      const changedAddress = location.address;
      let matched = 0;
      exsistingLocation.forEach((loc: any) => {
        const exsistingAddress = loc.address;
        if (exsistingAddress !== {}) {
          if (
            exsistingAddress?.address == changedAddress?.address &&
            exsistingAddress?.city == changedAddress?.city &&
            exsistingAddress?.apt == changedAddress?.apt &&
            exsistingAddress?.state == changedAddress?.state &&
            exsistingAddress?.zip == changedAddress?.zip
          ) {
            matched = matched + 1;
          }
        }
      });
      if (matched == 0) {
        location.locationId = undefined;
        location.garagingLocationId = undefined;
        location.primary = false;
      }
    }
    let locationId = undefined;
    if (location.locationId) {
      locationId = location.locationId;
    } else if (location.garagingLocationId) {
      locationId = location.garagingLocationId;
    } else if (location.fullAddress) {
      locationId = uuidv4();
    }
    location.locationId = locationId;
    if (isGaragingLocation) {
      formData.garagingLocationId = locationId;
    } else {
      formData.locationId = locationId;
    }
  }

  return {
    formDataWithoutLocation: formData,
    location,
  };
};
