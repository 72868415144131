import { Button, Grid } from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import './Driver.scss';
import { novoNavigation } from 'framework/routes';
import MuiLoader from 'framework/components/MuiLoader';
import { useNavigate } from 'react-router-dom';
import {
  AccordionItem,
  MuiAccordionProps,
} from 'framework/components/MuiAccordion';
import { DriverReviewHistoryFormDefinitions } from 'utils/driver/ReviewHistoryAccordionDrivers';
import { getReconciliationServiceCall } from 'library/services/utils';
import { getData } from 'framework/dataService/dataRehydration';
import { setDrivers } from 'framework/dataService/drivers';
import ButtonArrow from 'components/icons/ButtonArrow';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import {
  claimsFormat,
  coverageFormat,
  dlFormat,
  incidentFilter,
  incidentFormatNew,
} from 'utils/driver/ReconciliationCompare';
import { getItem, setItem } from 'framework/dataService';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import UdrDialog from 'framework/components/Dialogs/UdrDialog';
import { getApplicationObjectItem } from 'framework/dataService/insuranceApplication';

const AccordionReviewHistory = () => {
  const routeKey = 'ReviewHistory';
  const navigate = useNavigate();
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [csrDialog, setCsrDialog] = useState(false);
  const [expandedStatus, setExpandedStatus] = useState(false);
  const [isFormDirty, setIsDirty] = useState(false as boolean);
  const [showUdrDialog, setShowUdrDialog] = useState(false);
  const [udrDialogCotent, setUdrDialogCotent] = useState({});

  const updateStatus = (index: number, status: string, caller: string) => {
    return updateStatusImpl(index, status, caller);
  };

  const isReviewHistoryDirty = (value: any) => {
    setIsDirty(value);
  };

  const [forms, setForms] = useState(
    DriverReviewHistoryFormDefinitions(
      updateStatus,
      isReviewHistoryDirty,
      isFormDirty,
      false
    ) as MuiAccordionProps[]
  );

  const [activeAccordionExpanded, setActiveAccordionExpanded] =
    React.useState(null);

  const handleChange = (index: any) => {
    setActiveAccordionExpanded(index);
  };

  /* const checkIfAnyFormOpen = () => {
    let result = forms.find((form: any) => form.status === 'open');
    if (result) {
      return true;
    } else {
      return false;
    }
  }; */

  useEffect(() => {
    async function fetchData() {
      const response = await getReconciliationServiceCall();
      if (response && JSON.stringify(response) === '{}') {
        setItem('disclosureApiFailure', 'true');
        navigate(novoNavigation[routeKey].prevPage);
      } else {
        setItem('disclosureApiFailure', 'false');
        setForms(
          DriverReviewHistoryFormDefinitions(
            updateStatus,
            isReviewHistoryDirty,
            isFormDirty,
            true
          ) as MuiAccordionProps[]
        );
        const dataForFormData = getData(routeKey);
        if (dataForFormData?.drivers) {
          setDrivers(dataForFormData.drivers);
          const redirect = pageRedirect(dataForFormData.drivers);
          const udrCheckResult = udrCheck(dataForFormData.drivers);
          setShowLoader(false);
          if (redirect) {
            // transition to next page
            const disclosureViewed = getItem('DisclosureViewed');
            if (disclosureViewed && disclosureViewed === 'true') {
              navigate(novoNavigation[routeKey].prevPage);
            } else {
              if (udrCheckResult.shouldPageRedirectQuote) {
                setItem('isUdrPresent', 'true');
                navigate(novoNavigation[routeKey].prevPage);
              } else {
                navigate(novoNavigation[routeKey].nextPage);
              }
            }
          } else {
            if (
              udrCheckResult.shouldShowUdr &&
              getItem('hasCloseUdrDialog') !== 'true'
            ) {
              setShowUdrDialog(true);
              const dataForFormDataDriver = getApplicationObjectItem('drivers');
              const dataForFormDataLocation =
                getApplicationObjectItem('locations');
              const pniLocation = dataForFormDataLocation?.filter(
                (d: any) => d.primary
              );
              const driverId = udrCheckResult.udrDriverId || 0;
              if (dataForFormDataDriver.length > 0 && pniLocation.length > 0) {
                const data = {
                  fullName: `${dataForFormDataDriver[driverId].name.firstName} ${dataForFormDataDriver[driverId].name.lastName}`,
                  address1: `${pniLocation[0].address.address},`,
                  address2: `${pniLocation[0].address.city} ${pniLocation[0].address.state},`,
                  zipCode: pniLocation[0].address.zip,
                  dlNumber: dataForFormDataDriver[driverId].dlNumber,
                  dob: dataForFormDataDriver[driverId].dob,
                };
                setUdrDialogCotent(data);
              }
            }
          }
        }
      }
    }
    fetchData();
  }, [navigate]);

  const pageRedirect = (driversList: any) => {
    // formData with array of objects with driverId and reconciliationList
    let shouldPageRedirect: any = true;
    driversList &&
      driversList.forEach((d: any) => {
        const claimData = claimsFormat(d);
        const incidentData = incidentFormatNew(d);
        const dlData = dlFormat(d);
        const coverageData = coverageFormat(d);
        const filterIncident = incidentFilter(incidentData, d.drivingHistory);
        if (
          claimData.length > 0 ||
          dlData.length > 0 ||
          coverageData.length > 0
        ) {
          shouldPageRedirect = false;
        }
        filterIncident.length > 0 &&
          filterIncident.forEach((i: any) => {
            if (
              i.accidentCompareList.length > 0 ||
              i.accidentUnmatchedList.length > 0 ||
              i.violationCompareList.length > 0 ||
              i.violationUnmatchedList.length > 0
            ) {
              shouldPageRedirect = false;
            }
          });
      });
    return shouldPageRedirect;
  };

  const handleDialogEdit = () => {
    if (novoNavigation.PlanOptions.driverPage) {
      navigate(novoNavigation.PlanOptions.driverPage);
    }
    setShowUdrDialog(false);
  };

  const handleDialogNext = () => {
    setItem('hasCloseUdrDialog', 'true');
    setShowUdrDialog(false);
  };

  const handleDialogClose = () => {
    setShowUdrDialog(false);
  };

  const udrCheck = (driversList: any) => {
    let shouldPageRedirectQuote: any = false;
    let shouldShowUdr: any = false;
    let driverIdUpdate = false;
    let udrDriverId = 0;
    let udrCount = 0;
    let mvrCount = 0;
    driversList &&
      driversList.forEach((d: any, index: any) => {
        let udrExists = false;
        let mvrExists = false;
        d?.reconciliation?.claimHistory[0] &&
          d?.reconciliation?.claimHistory[0]['incident-list'] &&
          d?.reconciliation?.claimHistory[0]['incident-list'].forEach(
            (data: any) => {
              if (data?.code && data?.code.toLowerCase() === 'udr') {
                udrExists = true;
              } else if (data?.code && data?.code.toLowerCase() !== 'udr') {
                mvrExists = true;
              }
            }
          );
        if (udrExists) {
          udrDriverId = driverIdUpdate ? udrDriverId : index;
          driverIdUpdate = true;
          udrCount = udrCount + 1;
        }
        if (mvrExists) {
          mvrCount = mvrCount + 1;
        }
      });
    if (udrCount > 0 && mvrCount === 0) {
      shouldPageRedirectQuote = true;
    }
    if (udrCount > 0) {
      shouldShowUdr = true;
    }
    return {
      shouldPageRedirectQuote,
      shouldShowUdr,
      udrDriverId,
    };
  };

  useEffect(() => {
    //true when one of the form is dirty
    if (forms && forms.length > 0) {
      const checkIfDisableNext = () => {
        const BreakException = {};
        let flag = false;
        const lastFormIndex = forms.length - 1;
        try {
          forms.forEach((form: any, index: number) => {
            if (index < lastFormIndex) {
              // checking for all forms except last one
              if (form.status !== 'done') {
                flag = true;
                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw BreakException;
              }
            }
          });
        } catch (e) {
          if (e !== BreakException) throw e;
        }
        return flag;
      };
      setDisableNextBtn(checkIfDisableNext());
    } else {
      // navigate(novoNavigation.ReviewHistory.nextPage);
    }
  }, [forms, navigate]);

  const checkIfLastFormDisabled = (index: number) => {
    return index + 1 === forms.length && disableNextBtn;
  };

  const updateStatusImpl = (index: number, status: string, caller: string) => {
    let path = novoNavigation.ReviewHistory.nextPage;
    let updatedForm = forms && forms.length > 0 ? [...forms] : [];
    const reconViewed = getItem('ReconciliationViewed');
    if (forms && forms.length === 0 && reconViewed === 'true') {
      updatedForm = DriverReviewHistoryFormDefinitions(
        updateStatus,
        isReviewHistoryDirty,
        isFormDirty,
        true
      ) as MuiAccordionProps[];
    }

    if (updatedForm && updatedForm.length > 0) {
      switch (caller) {
        case 'onLoad':
          if (index + 1 === updatedForm.length) {
            setShowLoader(false);
          }
          updatedForm[index].status = status;
          updatedForm[index].disableNext = false;
          if (index + 1 < updatedForm.length) {
            if (updatedForm[index + 1].status === 'disabled') {
              updatedForm[index + 1].status = 'open';
              updatedForm[index].disableNext = false;
            }
          }
          break;
        case 'onSubmit':
          updatedForm[index].status = status;
          updatedForm[index].disableNext = false;
          if (index + 1 < updatedForm.length) {
            if (updatedForm[index + 1].status === 'disabled') {
              updatedForm[index + 1].status = 'open';
              updatedForm[index].disableNext = false;
            }
          } else {
            path = novoNavigation.ReviewHistory.nextPage;
          }
          break;
        case 'onChange':
          // if (status === 'open' && updatedForm[index].status === 'done') {
          updatedForm[index].status = status;
          updatedForm[index].disableNext = true;
          break;
        default:
          console.debug('updateStatusImpl: caller not handled yet');
      }
      setForms(updatedForm);
    }
    return path;
  };

  const handleNextButton = () => {
    navigate(novoNavigation.ReviewHistory.nextPage);
  };

  const handleRequoteButton = () => {
    setItem('ReconciliationViewed', 'true');
    navigate(novoNavigation.ReviewHistory.prevPage);
  };

  const checkExpanded = (value: any) => {
    setExpandedStatus(value);
  };
  return (
    <>
      <CsrDialog
        showDialog={csrDialog}
        handleDialogClose={() => setCsrDialog(false)}
      />
      <Grid container className='quote-wrapper'>
        <Page routeKey={routeKey}>
          <UdrDialog
            showDialog={showUdrDialog}
            handleDialogEdit={handleDialogEdit}
            handleDialogNext={handleDialogNext}
            handleDialogClose={handleDialogClose}
            content={udrDialogCotent}
          />
          <>
            {showLoader && <MuiLoader />}
            <Grid className={`review-history-container`}>
              {forms &&
                forms.length > 0 &&
                forms.map((item: any, index: any) => (
                  <AccordionItem
                    // onClick={(e) => handleAccordionClick(index)}
                    key={index}
                    data={item}
                    index={index}
                    disabled={
                      item.status === 'disabled' ||
                      checkIfLastFormDisabled(index)
                    }
                    isLastItem={index === forms.length - 1}
                    type={`form`}
                    isExpanded={checkExpanded}
                    page={'review-history'}
                    onChange={handleChange}
                    expanded={activeAccordionExpanded === index}
                    showOnlyOne={true}
                  />
                ))}
              {!disableNextBtn &&
                forms &&
                forms.length > 0 &&
                forms[forms.length - 1].status === 'done' &&
                !expandedStatus && (
                  <Button
                    id='reviewHistory'
                    className='btn-arrow review-history-btn'
                    type='submit'
                    color='secondary'
                    variant='contained'
                    disabled={false}
                    onClick={handleRequoteButton}
                    endIcon={<ButtonArrow />}
                  >
                    {
                      <MicrocopyComponent
                        labelKey={`q2b.global.button.text.update.and.requote`}
                      />
                    }
                  </Button>
                )}
            </Grid>
          </>
        </Page>
      </Grid>
    </>
  );
};

export default AccordionReviewHistory;
