import { Grid, Typography } from '@mui/material';
import React from 'react';
import { getFormattedDateWithTimezone } from 'utils/dateUtils';
import MicrocopyComponent from '../framework/components/MicrocopyComponent';
import {
  policyInfo,
  coveragePlans,
  ACP,
} from 'library/application/applicationConstants';
import { getJson } from 'framework/dataService';
import {
  getApplicationObject,
  getMetaData,
} from 'framework/dataService/insuranceApplication';
import { currencyFormatter } from 'utils/util';
import CommonHeader from 'components/commonHeader';

export default function CongratDetail(props: any) {
  const policyDetails = getJson(policyInfo);
  const metaData = getMetaData();
  const application = getApplicationObject();
  const pniAddress = application.locations.filter((l: any) => l.primary);
  const zipCode = pniAddress[0].address.zip;
  const selectedCoveragePlan = getJson(coveragePlans).find(
    (plan: any) => plan.planName === metaData.selectedPlanName
  );
  const paidAmount = `$${currencyFormatter(
    metaData?.paymentMethodType?.toUpperCase() === ACP
      ? selectedCoveragePlan.initialPremium
      : selectedCoveragePlan.pifPremium
  )}`;
  const packagePlan =
    metaData.selectedPlanName?.slice(0, 1).toUpperCase() +
    metaData.selectedPlanName?.slice(1).toLowerCase();
  const handleCall = () => {
    window.open('tel:1-385-352-9852', '_black');
  };
  const handleEmail = () => {
    window.open('mailto:support@novo.us', '_black');
  };
  const helpHeading =
    'Novo agents are available to connect over phone, chat, and email. Monday-Friday 9am-5pm MST.';
  return (
    <Grid
      container
      style={{ marginLeft: props.marginLeft, marginTop: props.marginTop }}
    >
      <Grid
        container
        item
        xs={6}
        columnSpacing={1}
        flexWrap='wrap'
        justifyContent='space-between'
      >
        <Grid xs={12}>
          <CommonHeader
            heading1='CHECK YOUR NEW POLICY'
            showLine={true}
          ></CommonHeader>
          <Typography
            sx={{
              paddingTop: '15px',
              color: '#55566D',
              fontFamily: 'Lato',
              fontWeight: 900,
              fontSize: '0.813rem',
              letterSpacing: '0.125em',
              width: 'max-content',
              maxWidth: '100%',
            }}
          >
            <MicrocopyComponent labelKey={`q2b.global.policy.number.text`} />
            <span
              style={{
                letterSpacing: '4px',
                color: '#000',
                marginTop: '1rem',
                display: 'inline-block',
                lineHeight: '40x',
              }}
            >
              {policyDetails.policyId}
            </span>
          </Typography>

          <Typography
            sx={{
              paddingTop: '15px',
              color: '#55566D',
              fontFamily: 'Lato',
              fontWeight: 900,
              fontSize: '0.813rem',
              letterSpacing: '0.125em',
              width: 'max-content',
              maxWidth: '100%',
            }}
          >
            <MicrocopyComponent labelKey={`q2b.global.effective.date.text`} />
            <span
              style={{
                letterSpacing: '4px',
                color: '#000',
                marginTop: '1rem',
                display: 'inline-block',
              }}
            >
              {getFormattedDateWithTimezone(
                policyDetails.policyEffectiveDate,
                zipCode
              )}
            </span>
          </Typography>
        </Grid>
        <Grid
          container
          className={`payment-and-next-steps`}
          sx={{ paddingTop: '25px', justifyContent: 'space-between' }}
        >
          <Grid item xs={12}>
            <MicrocopyComponent
              labelKey={`q2b.congrats.confirmation`}
              placeHolderKey={['$PaymentAmount$', '$PackagePlan$']}
              actualValue={[paidAmount, packagePlan]}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} container>
        <CommonHeader
          heading1='NEED ANY HELP?'
          heading2={helpHeading}
          showLine={true}
        ></CommonHeader>
        <Grid
          item
          sx={{
            mr: { xs: 'unset', xl: '-27px' },
            ml: { xs: '-27px', xl: 'unset' },
          }}
        >
          <img
            style={{
              cursor: 'pointer',
              width: '100%',
              objectFit: 'contain',
            }}
            onClick={handleCall}
            src='/call-phone.svg'
            alt={'phone support'}
          />
        </Grid>
        <Grid
          item
          sx={{
            mr: { xs: 'unset', xl: '-27px' },
            ml: { xs: '-27px', xl: 'unset' },
          }}
        >
          <img
            style={{
              cursor: 'pointer',
              width: '100%',
              objectFit: 'contain',
              marginTop: '2rem',
            }}
            onClick={handleEmail}
            src='/email-support.svg'
            alt={'email support'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
