import CustomNumberWidget from './CustomNumberWidget';
import LabelWithIconWidget from './LabelWithIconWidget';
import MuiMonthYearPickerWidget from './MuiMonthYearPickerWidget';
import MuiPasswordWidget from './MuiPasswordWidget';
import MuiSelectWidget from './MuiSelectWidget';
import ToggleButtonWidget from './ToggleButtonWidget';
import ToggleRadioButtonWidget from './ToggleRadioButtonWidget';
import ToggleLargeRadioButtonWidget from './ToggleLargeRadioButtonWidget';
import MuiDatePickerWidget from './MuiDatePickerWidget';
import SocialSecurityWidget from './SocialSecurityWidget';
import MuiCheckboxWidget from './MuiCheckBoxWidget';
import MuiAutoCompleteWidget from './MuiAutoCompeleteWidget';
import NovoCheckboxWidget from './NovoCheckboxWidget';
import UploadDocumentWidget from './UploadDocumentWidget';
import UploadCertificateWidget from './UploadCertificateWidget';
import ACPEWidget from './ACPEWidget';

const additionalWidgets = {
  customNumberWidget: CustomNumberWidget,
  labelWithIconWidget: LabelWithIconWidget,
  muiCheckboxWidget: MuiCheckboxWidget,
  muiDatePickerWidget: MuiDatePickerWidget,
  muiMonthYearPickerWidget: MuiMonthYearPickerWidget,
  muiPasswordWidget: MuiPasswordWidget,
  muiSelectWidget: MuiSelectWidget,
  novoCheckboxWidget: NovoCheckboxWidget,
  socialSecurityWidget: SocialSecurityWidget,
  toggleButtonWidget: ToggleButtonWidget,
  toggleRadioButtonWidget: ToggleRadioButtonWidget,
  toggleLargeRadioButtonWidget: ToggleLargeRadioButtonWidget,
  autoCompleteWidget: MuiAutoCompleteWidget,
  uploadDocumentWidget: UploadDocumentWidget,
  uploadCertificateWidget: UploadCertificateWidget,
  acpeWidget: ACPEWidget,
};

export default additionalWidgets;
