import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { WidgetProps } from '@rjsf/core';
import React, { useState } from 'react';
import EyeIcon from 'components/icons/EyeIcon';
import EyeHideIcon from 'components/icons/EyeHideIcon';

export interface MuiPasswordProps extends WidgetProps {
  isError?: boolean;
  password: string;
  showPassword: boolean;
}

const handleMouseDownPassword = (
  event: React.MouseEvent<HTMLButtonElement>
) => {
  event.preventDefault();
};

const MuiPassword: React.FC<MuiPasswordProps> = (props) => {
  const {
    /* children, */
    id = 'MuiPassword',
    disabled = false,
    className,
    variant = 'outlined',
    isError,
    label,
    required,
    onChange,
  } = props;

  let muiClassName = className;
  if (isError) {
    muiClassName += ' Mui-error';
  }
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const fieldLabel = required ? `${label} *` : label;

  const handleChange =
    (prop: keyof MuiPasswordProps) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      if (onChange) {
        onChange(event.target.value);
      }
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  return (
    <>
      <FormControl
        className={muiClassName}
        sx={{ width: '100%' }}
        variant={variant}
      >
        <InputLabel htmlFor='outlined-adornment-password'>
          {fieldLabel}
        </InputLabel>
        <OutlinedInput
          id={id}
          disabled={disabled}
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                className='MuiPassword'
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {values.showPassword ? <EyeHideIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          }
          label={fieldLabel}
        />
      </FormControl>
    </>
  );
};

export default MuiPassword;
