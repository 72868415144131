import {
  getApplicationObjectItem,
  setApplicationObjectItem,
} from './insuranceApplication';
import { locations } from 'library/application/applicationConstants';

export const getAllLocations = () => {
  const quoteEnvelopeItem = getApplicationObjectItem(locations);
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    quoteEnvelopeItem.forEach((item: any) => prepareLocationObject(item));
  }
  return quoteEnvelopeItem;
};

export const getPrimaryLocation = () => {
  const quoteEnvelopeItem = getApplicationObjectItem(locations);
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    const location = quoteEnvelopeItem.find(
      (item: { primary: boolean }) => item.primary === true
    );
    return prepareLocationObject(location);
  }
  return {};
};

export const getGaragingAddressCheck = (
  vehicleState: string,
  vehicleId: string,
  garagingLocationId: string
) => {
  let quoteLocationItem = getApplicationObjectItem(locations);
  let quoteVehicleList = getApplicationObjectItem('vehicles');
  quoteLocationItem = quoteLocationItem.filter(
    (l: any) => l.locationId !== garagingLocationId
  );
  if (quoteVehicleList && quoteVehicleList.length > 1) {
    let conditionCheck: any = [];
    quoteVehicleList.forEach((v: any) => {
      const locationDetails = quoteLocationItem.filter(
        (l: any) => l.locationId === v.garagingLocationId
      )[0];
      if (vehicleState !== locationDetails?.address.state) {
        conditionCheck.push(true);
      }
    });
    return conditionCheck.includes(true);
  }
  return false;
};

// export const getGaragingAddressCheck = (vehicleState: string) => {
//   const quoteLocationItem = getApplicationObjectItem(locations);
//   const quoteVehicleList = getApplicationObjectItem('vehicles');
//   if(quoteVehicleList && quoteVehicleList.length > 1){
//     console.log('enetred', quoteVehicleList)
//     quoteVehicleList.forEach((v: any) => {
//       const locationDetails = quoteLocationItem.filter((l:any) => l.locationId === v.garagingLocationId)[0]
//       if(vehicleState !== locationDetails?.address.state){
//         return true;
//       }
//     });
//   }
//   return false;
// };

export const getLocationById = (locationId: number) => {
  const quoteEnvelopeItem = getApplicationObjectItem(locations);
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    const location = quoteEnvelopeItem.find(
      (item: { locationId: number }) => item.locationId === locationId
    );
    return prepareLocationObject(location);
  }
  return undefined;
};

export const prepareLocationObject = (location: any) => {
  if (location && location.address?.address && !location.fullAddress) {
    location.fullAddress = `${location.address.address}, ${location.address.city}, ${location.address.state}, USA`;
    location.defaultAddress = location.address.address;
    location.apt = location.address.apt;
    location.city = location.address.city;
    location.state = location.address.state;
    location.zipCode = location.address.zip;
    location.city = location.address.city;
  }
  return location;
};

export const setLocation = (value: any) => {
  let quoteEnvelopeItem = getApplicationObjectItem(locations);
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    const existingIndex = quoteEnvelopeItem.findIndex(
      (item: { locationId: any }) => item.locationId === value.locationId
    );
    if (existingIndex !== -1) {
      quoteEnvelopeItem[existingIndex] = value;
    } else {
      quoteEnvelopeItem.push(value);
    }
  } else {
    quoteEnvelopeItem = [value];
  }
  return setApplicationObjectItem(locations, quoteEnvelopeItem);
};

export const deleteLocation = (locationId: string) => {
  let quoteEnvelopeItem = getApplicationObjectItem(locations);
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    const existingIndex = quoteEnvelopeItem.findIndex(
      (item: { locationId: any }) => item.locationId === locationId
    );
    quoteEnvelopeItem.splice(existingIndex, 1);
  }
  return setApplicationObjectItem(locations, quoteEnvelopeItem);
};
