import { useNavigate } from 'react-router-dom';
import { AjvError, IChangeEvent, ISubmitEvent } from '@rjsf/core';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import React, { useEffect, useState, useRef } from 'react';
import { getData } from 'framework/dataService/dataRehydration';
import {
  getReconciliationServiceCall,
  saveApplicationCurrentSessionState,
} from 'library/services/utils';
import { Button } from '@mui/material';
import ButtonArrow from 'components/icons/ButtonArrow';
import { novoNavigation } from 'framework/routes';
import { ReviewHistoryFormParamType } from 'types/Driver';
import { setDriver } from 'framework/dataService/drivers';
import Validate from 'framework/forms/validations/Validate';
import getFormDefinitions from 'framework/schemaServer';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import ReconciliationTile from 'components/ReconciliationTile';
import CsrDialog from 'framework/components/Dialogs/CsrDialog';
import { getItem, getJson, setItem } from 'framework/dataService';
import { incidentDescription } from 'utils/driver/IncidentFormat';
import MuiLoader from 'framework/components/MuiLoader';
import { uploadDocument } from 'library/services';
import { getMetaDataItem } from 'framework/dataService/insuranceApplication';
import { multiDriverIncidentRiskValidation } from 'utils/driver';
import Bugsnag from '@bugsnag/js';
import { notifyBugsnag } from 'utils/util';

export default function ReviewHistoryForm({
  driversReconciliationList,
  index,
  formLength,
  updateStatus,
  isReviewHistoryDirty,
  isAnyFormDirty,
}: ReviewHistoryFormParamType) {
  const hasComponentMounted = useRef(false);
  const navigate = useNavigate();
  const routeKey = 'ReviewHistory';
  const [driversData, setDriversData] = useState([] as any);
  const [nextButton, setNextButton] = useState(false);
  const [reQuoteButton, setReQuoteButton] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [initialFormData, setInitialFormData] = useState({
    driversReconciliationList: [driversReconciliationList],
  });
  const newFormIndex = index!;
  const newFormLength = formLength!;
  const { schema } = getFormDefinitions(routeKey);
  const [csrDialog, setCsrDialog] = React.useState(false);
  const [isLicenseValid, setLicenseValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const agentIdSesssion = getItem('isAgent');

  useEffect(() => {
    async function fetchData() {
      const dataForFormData = getData(routeKey);
      if (dataForFormData?.metaData) {
        setReadonly(true);
      }
      if (dataForFormData?.drivers) {
        setDriversData(dataForFormData?.drivers);
        const errors = Validate(
          initialFormData.driversReconciliationList[0],
          schema
        );
        const dispute =
          initialFormData.driversReconciliationList[0]?.accidentList[0]
            ?.dispute;
        if (updateStatus && !errors && dispute) {
          // done: when all required fields have data
          setIsDirty(false);
          updateStatus(index, 'done', 'onLoad');
        }
      }
    }
    if (!hasComponentMounted.current) {
      fetchData();
      hasComponentMounted.current = true;
    }
  }, [index, initialFormData.driversReconciliationList, schema, updateStatus]);

  useEffect(() => {
    //Check if license is valid to show recon forms
    if (initialFormData.driversReconciliationList[0]?.dlList.length > 0) {
      const foundStatus =
        initialFormData.driversReconciliationList[0]?.dlList[0]?.dlStatus;
      if (foundStatus.toLowerCase() === 'valid' || foundStatus === '') {
        setLicenseValid(true);
      } else {
        if (!agentIdSesssion) {
          notifyBugsnag(
            'CSR pop up validation - Reconcilation driver license',
            initialFormData
          );
          setCsrDialog(true);
          setLicenseValid(false);
        } else {
          setLicenseValid(true);
        }
      }
    } else {
      setLicenseValid(true);
    }
  }, []);

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    setIsSubmitting(true);
    const tempDrivers = [...driversData];
    formData.driversReconciliationList.forEach(async (data: any) => {
      const driverIndex = tempDrivers.findIndex(
        (d: { driverId: any }) => d.driverId === data.driverId
      );
      if (driverIndex >= 0) {
        let reconciliationData = tempDrivers[driverIndex].reconciliation;
        tempDrivers[driverIndex].reconciliation = {};
        //Claim Data format
        let claimList =
          reconciliationData !== {} ? reconciliationData.claimHistory : '';
        if (data.claimList && data.claimList.length > 0) {
          data.claimList.forEach((d: any) => {
            claimList[0]['claim-list'].map((c: any, i: any) => {
              if (c.id == d.id) {
                claimList[0]['claim-list'][i].disputed = !d.dispute;
              }
            });
          });
        }
        // Prior insurance format
        if (data.coverageList && data.coverageList.length > 0) {
          if (!data.coverageList[0].dispute) {
            const dateSplit = data.coverageList[0].foundCoverageStart
              ? data.coverageList[0].foundCoverageStart.split('-')
              : [];
            const carrierInfo = data.coverageList[0].foundInsuranceProvider
              ? data.coverageList[0].foundInsuranceProvider.split(' ')
              : [];
            let carrier = '';
            if (carrierInfo.length > 0) {
              if (carrierInfo.length > 1) {
                carrier = `${carrierInfo[0]}  ${carrierInfo[1]}`;
              } else {
                carrier = `${carrierInfo[0]}`;
              }
            }
            tempDrivers[driverIndex].insuranceDetails.carrier = carrier;
            tempDrivers[driverIndex].insuranceDetails.currentInsuranceProvider =
              data.coverageList[0].foundInsuranceProvider;
            tempDrivers[
              driverIndex
            ].insuranceDetails.currentBodilyInjuryLimits =
              data.coverageList[0].foundBodilyInjuryLimits;
            tempDrivers[driverIndex].insuranceDetails.currentCoverageStart =
              dateSplit.length > 0 ? `${dateSplit[1]}/${dateSplit[0]}` : '';
            tempDrivers[driverIndex].insuranceDetails.monthContinuouslyInsured =
              dateSplit.length > 0 ? dateSplit[1] : '';
            tempDrivers[driverIndex].insuranceDetails.yearContinuouslyInsured =
              dateSplit.length > 0 ? dateSplit[0] : '';
          }
        }
        //DL Data format
        if (data.dlList && data.dlList.length > 0) {
          tempDrivers[driverIndex].dlNumber = data.dlList[0].dlNumber;
          tempDrivers[driverIndex].licenseState = data.dlList[0].dlState;
          if (data.dlList[0].dlStatus) {
            tempDrivers[driverIndex].driverLicenseStatus =
              data.dlList[0].dlStatus.toLowerCase() == 'valid'
                ? 'Active / Valid'
                : data.dlList[0].dlStatus;
          }
        }

        //Incident Data format
        let incidentListData: any[] = [];
        const incidentData = (inc: any, type: any, source: any) => {
          const incident = {
            code: inc.foundCode,
            category: '',
            type: type,
            didInsurancePayOver1000: false,
            description: inc.foundDescription,
            location: {
              address: '',
              city: '',
              state: '',
              zip: '',
            },
            'incident-on': inc.foundDate,
            disclosed: true,
            vendorSVCCode: inc.vendorSVCCode,
            source: source,
            disputed: !inc.dispute,
            disputeReason: inc?.disputeReason ? inc.disputeReason : '',
          };
          return incident;
        };

        if (data.violationCompareList && data.violationCompareList.length > 0) {
          data.violationCompareList.forEach((v: any) => {
            if (v.dispute) {
              const reportedMonthYearSplit = v.reportedDate.split('-');
              const reportedMonthYear =
                reportedMonthYearSplit.length > 0
                  ? `${reportedMonthYearSplit[1]}/${reportedMonthYearSplit[0]}`
                  : '';
              if (
                tempDrivers[driverIndex]?.drivingHistory?.traffic
                  ?.trafficViolationList.length > 0
              ) {
                const indexOfObject = tempDrivers[
                  driverIndex
                ].drivingHistory.traffic.trafficViolationList.findIndex(
                  (t: any) => {
                    return reportedMonthYear == v.removeReportedDate;
                  }
                );
                tempDrivers[
                  driverIndex
                ].drivingHistory.traffic.trafficViolationList.splice(
                  indexOfObject,
                  1
                );
              }
              const incidentDataFormat = incidentData(v, 'Violation', 'mvr');
              incidentListData.push(incidentDataFormat);
            } else {
              const incidentDataFormat = incidentData(v, 'Violation', 'mvr');
              incidentListData.push(incidentDataFormat);
            }
          });
        }

        if (
          data.violationUnmatchedList &&
          data.violationUnmatchedList.length > 0
        ) {
          data.violationUnmatchedList.forEach((v: any) => {
            const incidentDataFormat = incidentData(v, 'Violation', 'mvr');
            incidentListData.push(incidentDataFormat);
          });
        }

        if (data.violationMatchedList && data.violationMatchedList.length > 0) {
          data.violationMatchedList.forEach((v: any) => {
            const incidentDataFormat = incidentData(v, 'Violation', 'self');
            incidentListData.push(incidentDataFormat);
            if (
              tempDrivers[driverIndex]?.drivingHistory?.traffic
                ?.trafficViolationList.length > 0
            ) {
              const indexOfObject = tempDrivers[
                driverIndex
              ].drivingHistory.traffic.trafficViolationList.findIndex(
                (t: any) => {
                  return t.monthYear == v.removeReportedDate;
                }
              );
              tempDrivers[
                driverIndex
              ].drivingHistory.traffic.trafficViolationList.splice(
                indexOfObject,
                1
              );
            }
          });
        }

        if (data.accidentCompareList && data.accidentCompareList.length > 0) {
          data.accidentCompareList.forEach((v: any) => {
            if (v.dispute) {
              const reportedMonthYearSplit = v.reportedDate.split('-');
              const reportedMonthYear =
                reportedMonthYearSplit.length > 0
                  ? `${reportedMonthYearSplit[1]}/${reportedMonthYearSplit[0]}`
                  : '';
              if (
                tempDrivers[driverIndex]?.drivingHistory?.accidents
                  ?.accidentList.length > 0
              ) {
                const indexOfObject = tempDrivers[
                  driverIndex
                ].drivingHistory.accidents.accidentList.findIndex((t: any) => {
                  return reportedMonthYear == v.removeReportedDate;
                });
                tempDrivers[
                  driverIndex
                ].drivingHistory.accidents.accidentList.splice(
                  indexOfObject,
                  1
                );
              }
              const incidentDataFormat = incidentData(v, 'Accident', 'mvr');
              incidentListData.push(incidentDataFormat);
            } else {
              const incidentDataFormat = incidentData(v, 'Accident', 'mvr');
              incidentListData.push(incidentDataFormat);
            }
          });
        }

        if (
          data.accidentUnmatchedList &&
          data.accidentUnmatchedList.length > 0
        ) {
          data.accidentUnmatchedList.forEach((v: any) => {
            const incidentDataFormat = incidentData(v, 'Accident', 'mvr');
            incidentListData.push(incidentDataFormat);
          });
        }

        if (data.accidentMatchedList && data.accidentMatchedList.length > 0) {
          data.accidentMatchedList.forEach((v: any) => {
            const incidentDataFormat = incidentData(v, 'Accident', 'self');
            incidentListData.push(incidentDataFormat);
            if (
              tempDrivers[driverIndex]?.drivingHistory?.accidents?.accidentList
                .length > 0
            ) {
              const indexOfObject = tempDrivers[
                driverIndex
              ].drivingHistory.accidents.accidentList.findIndex((t: any) => {
                return t.monthYear == v.removeReportedDate;
              });
              tempDrivers[
                driverIndex
              ].drivingHistory.accidents.accidentList.splice(indexOfObject, 1);
            }
          });
        }

        if (
          tempDrivers[driverIndex].drivingHistory.traffic.trafficViolationList
            .length > 0
        ) {
          tempDrivers[
            driverIndex
          ].drivingHistory.traffic.trafficViolationList.forEach((t: any) => {
            const description = incidentDescription(
              'violation',
              t.typeOfViolation
            );
            const dateSplit = t?.monthYear.split('/');
            const incidentObject = {
              code: t.typeOfViolation,
              category: '',
              type: 'Violation',
              didInsurancePayOver1000: false,
              description: description,
              location: {
                address: '',
                city: '',
                state: '',
                zip: '',
              },
              'incident-on':
                dateSplit.length > 0
                  ? `${dateSplit[1]}-${dateSplit[0]}-01`
                  : '',
              disclosed: true,
              source: 'self',
              disputed: false,
            };
            incidentListData.push(incidentObject);
          });
        }

        if (
          tempDrivers[driverIndex].drivingHistory.accidents.accidentList
            .length > 0
        ) {
          tempDrivers[
            driverIndex
          ].drivingHistory.accidents.accidentList.forEach((t: any) => {
            const description = incidentDescription(
              'violation',
              t.typeOfViolation || t.typeOfAccident
            );
            const dateSplit = t?.monthYear.split('/');
            const incidentObject = {
              code: t.typeOfViolation || t.typeOfAccident,
              category: '',
              type: 'Accident',
              didInsurancePayOver1000: t.didInsurancePayOver1000,
              description: description,
              location: {
                address: '',
                city: '',
                state: '',
                zip: '',
              },
              'incident-on':
                dateSplit.length > 0
                  ? `${dateSplit[1]}-${dateSplit[0]}-01`
                  : '',
              disclosed: true,
              source: 'self',
              disputed: false,
            };
            incidentListData.push(incidentObject);
          });
        }

        claimList[0]['incident-list'] = incidentListData;
        reconciliationData.claimHistory = claimList;
        tempDrivers[driverIndex].reconciliation = reconciliationData;

        setDriver(tempDrivers[driverIndex]);
        setIsDirty(false);
      }
    });

    const incidentValidation = multiDriverIncidentRiskValidation();
    if (!incidentValidation) {
      const exsistingFiles = getJson('reconFileUpload');
      if (exsistingFiles) {
        let policyValues = Object.values(exsistingFiles);
        const applicationId = getMetaDataItem('applicationId');
        policyValues.length > 0 &&
          policyValues.forEach(async (data: any) => {
            fetch(data)
              .then((res) => res.blob())
              .then(async (blob) => {
                const file = new File([blob], 'application/pdf', {
                  type: 'application/pdf',
                });
                try {
                  const resp = await uploadDocument(applicationId, file);
                } catch {
                  notifyBugsnag('Create account API', resp);
                }
              });
          });
      }
      const resp = await saveApplicationCurrentSessionState();
      if (!resp.success) {
        setIsSubmitting(false);
        console.error(
          'Driver : Included : Update Application Object is FAILED: Backend may still have the Searched Vehicle with bad data.'
        );
      } else {
        setItem('ReconciliationViewed', 'true');
        setIsSubmitting(false);
        if (reQuoteButton) {
          navigate(novoNavigation.ReviewHistory.prevPage);
        }

        console.debug('Driver : Included : Update Application Object is done.');
      }
      updateStatus(index, 'done', 'onSubmit');
    } else {
      notifyBugsnag('CSR pop up validation - Incidents', formData);
      setIsSubmitting(false);
      setCsrDialog(true);
    }
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (
      !isDirty &&
      !equals(
        initialFormData.driversReconciliationList,
        formData.driversReconciliationList
      )
    ) {
      // formData just got dirty
      setIsDirty(true);
      isReviewHistoryDirty(true);
      if (updateStatus) updateStatus(index, 'open', 'onChange');
    }
    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleNextButton = () => {
    setNextButton(true);
  };

  const handleReQuoteButton = () => {
    setReQuoteButton(true);
  };

  const buildDLTile = () => {
    if (initialFormData.driversReconciliationList[0].dlList.length > 0) {
      const {
        dlNumber,
        dlState,
        dlStatus,
        dlNumberReported,
        dlStateReported,
        dlStatusReported,
      } = initialFormData.driversReconciliationList[0].dlList[0];
      const found = {
        heading1: dlNumber,
        heading2: `State: ${dlState}`,
        heading3: `Status: ${dlStatus}`,
      };
      const reported = {
        heading1: dlNumberReported,
        heading2: `State: ${dlStateReported}`,
        heading3: `Status: ${dlStatusReported}`,
      };
      return (
        <>
          <h5 className='driver-license-title'>DRIVER LICENSE</h5>
          <ReconciliationTile
            found={found}
            reported={reported}
            color={'#000'}
          />
        </>
      );
    }
  };

  return (
    <>
      <CsrDialog
        showDialog={csrDialog}
        handleDialogClose={() => setCsrDialog(false)}
      />
      {isSubmitting && <MuiLoader />}
      {buildDLTile()}
      {isLicenseValid && (
        <NovoForm
          disabled={readonly}
          schemaName={routeKey}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onError={handleError}
          formData={initialFormData}
          transitionTo={false}
          shadowButton={newFormIndex + 1 === newFormLength ? false : true}
          buttonName={
            <MicrocopyComponent
              labelKey={`q2b.global.button.text.ok.extended`}
            />
          }
          //buttonName={'OK....'}
          hideSubmitButton={
            newFormIndex + 1 !== newFormLength && !isDirty ? true : false
          }
          button={
            newFormIndex + 1 === newFormLength ? (
              <Button
                id='reviewHistory'
                className='btn-arrow review-history-btn'
                type='submit'
                color='secondary'
                variant='contained'
                disabled={false}
                onClick={
                  isAnyFormDirty || isDirty
                    ? handleReQuoteButton
                    : handleNextButton
                }
                endIcon={<ButtonArrow />}
              >
                {isAnyFormDirty || isDirty ? (
                  <MicrocopyComponent
                    labelKey={`q2b.global.button.text.update.and.requote`}
                  />
                ) : (
                  <MicrocopyComponent
                    labelKey={`q2b.global.button.text.continue`}
                  />
                )}
              </Button>
            ) : (
              ''
            )
          }
        />
      )}
    </>
  );
}
