import { getMetaDataItem } from 'framework/dataService/insuranceApplication';
import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
// Novo Routes
import {
  NovoRoute,
  novoRoutes,
  pathNameWithNoRedirection,
} from 'framework/routes';
import { progressConstant } from 'library/application/applicationConstants';

export function getCompletedStep() {
  const progress: any = getMetaDataItem(progressConstant);
  const completedSteps = Number(progress?.completedRoute || 0) + 1;
  return completedSteps;
}

function comparePathNames(completedRoute: string, pathName: string) {
  const paths = pathName.split('/');
  const completedRoutes = completedRoute.split('/');

  if (paths.length !== completedRoutes.length) {
    return false;
  } else {
    for (let i = 0; i < paths.length; i++) {
      if (
        completedRoutes[i].indexOf(':') === -1 &&
        paths[i] !== completedRoutes[i]
      ) {
        return false;
      }
    }
    return true;
  }
}

function findCurrentRouteActiveStep(pathname: string) {
  const currentRoute: NovoRoute | undefined = Object.values(novoRoutes).find(
    (item) => {
      return comparePathNames(item.path, pathname);
    }
  );
  const currentRouteActiveStep = currentRoute?.activeStep || 100;
  return currentRouteActiveStep;
}

export default function RoutesContainer() {
  const [renderChild, setRenderChild] = React.useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const defaultPath: NovoRoute = novoRoutes[Object.keys(novoRoutes)[0]];

  React.useEffect(() => {
    setRenderChild(false);

    const isNavigationAllowed =
      pathNameWithNoRedirection.find((item) => item === pathname) ||
      getCompletedStep() >= findCurrentRouteActiveStep(pathname);
    if (!isNavigationAllowed) {
      navigate(defaultPath.path);
    } else {
      setRenderChild(true);
    }
    window.scrollTo(0, 0);
  }, [pathname, defaultPath, navigate]);

  return (
    <>
      {renderChild && (
        <Routes>
          <Route
            path='/'
            element={<Navigate replace to={defaultPath.path} />}
          />
          {Object.entries(novoRoutes).map(([routeKey, route]) => {
            return (
              <Route path={route.path} element={route.element} key={routeKey} />
            );
          })}
        </Routes>
      )}
    </>
  );
}
