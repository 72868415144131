import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function DottedTailArrowRight({
  fillColor = 'currentColor',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='37'
      height='24'
      viewBox='0 0 37 24'
      fill='none'
      {...{ ...defaultProps, ...svgProps }}
    >
      <circle cx='1.5' cy='12' r='1.5' fill={fillColor} />
      <circle cx='7.5' cy='12' r='1.5' fill={fillColor} />
      <path
        d='M13.1382 12.8462L35.1684 12.8463C35.5043 12.8463 35.8081 12.6398 35.9367 12.3242C36.0653 12.0077 35.9943 11.6439 35.7565 11.4019L25.7813 1.24813C25.4564 0.917288 24.9309 0.917288 24.606 1.24813C24.2811 1.57897 24.2811 2.11374 24.606 2.44458L33.1614 11.154L13.139 11.154C12.6787 11.154 12.3073 11.533 12.3073 12.0001C12.3073 12.4672 12.6787 12.8462 13.1382 12.8462Z'
        fill={fillColor}
      />
      <path
        d='M25.1924 23C25.4047 23 25.618 22.9171 25.7804 22.7521L35.6736 12.7404C35.9985 12.4096 35.9985 11.8748 35.6736 11.544C35.3487 11.2131 34.8232 11.2131 34.4983 11.544L24.6051 21.5556C24.2802 21.8865 24.2802 22.4212 24.6051 22.7521C24.7667 22.9171 24.98 23 25.1924 23Z'
        fill={fillColor}
      />
      <path
        d='M13.1382 12.8462L35.1684 12.8463C35.5043 12.8463 35.8081 12.6398 35.9367 12.3242C36.0653 12.0077 35.9943 11.6439 35.7565 11.4019L25.7813 1.24813C25.4564 0.917288 24.9309 0.917288 24.606 1.24813C24.2811 1.57897 24.2811 2.11374 24.606 2.44458L33.1614 11.154L13.139 11.154C12.6787 11.154 12.3073 11.533 12.3073 12.0001C12.3073 12.4672 12.6787 12.8462 13.1382 12.8462Z'
        stroke={fillColor}
      />
      <path
        d='M25.1924 23C25.4047 23 25.618 22.9171 25.7804 22.7521L35.6736 12.7404C35.9985 12.4096 35.9985 11.8748 35.6736 11.544C35.3487 11.2131 34.8232 11.2131 34.4983 11.544L24.6051 21.5556C24.2802 21.8865 24.2802 22.4212 24.6051 22.7521C24.7667 22.9171 24.98 23 25.1924 23Z'
        stroke={fillColor}
      />
    </svg>
  );
}
