import { Grid, Typography } from '@mui/material';
import { AjvError, IChangeEvent, ISubmitEvent } from '@rjsf/core';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import { getJson } from 'framework/dataService';
import { getPolicyCoverage } from 'framework/dataService/coverage';
import { getApplicationObjectItem } from 'framework/dataService/insuranceApplication';
import NovoForm from 'framework/forms/NovoForm';
import { coveragePlans } from 'library/application/applicationConstants';
import React, { useEffect, useState } from 'react';

interface PolicyCoveragesProps {
  selectedCoverages: any;
  coverageIndex: number;
  updateCoverage: any;
  errorHandling: any;
  updateFormDirtyFlag: any;
  submitBtnRef: any;
  readonly: boolean;
  onValidateTabs?: any;
  currentTab: any;
}
const PolicyCoverages: React.FC<PolicyCoveragesProps> = ({
  selectedCoverages, // user selected coverages
  coverageIndex = 0,
  updateCoverage,
  errorHandling,
  updateFormDirtyFlag,
  submitBtnRef,
  onValidateTabs,
  currentTab,
  readonly,
}) => {
  const coverageSchemaRouteKey = 'Coverages';
  const [initialFormData, setInitialFormData] = useState({} as any);

  useEffect(() => {
    let coverageToShow;
    const quoteEnvelopeItemMeta = getApplicationObjectItem('metaData');
    let selectedIndex = -1;
    if (quoteEnvelopeItemMeta.selectedPlanName) {
      let selectCoverageIndex: any = {
        economy: 0,
        comfort: 1,
        turbo: 2,
      };
      selectedIndex =
        selectCoverageIndex[quoteEnvelopeItemMeta.selectedPlanName];
    }

    if (coverageIndex === selectedIndex) {
      coverageToShow = getPolicyCoverage();
    }
    if (!coverageToShow || coverageIndex !== selectedIndex) {
      // default coverages from backend
      const defaultCoverages = getJson(coveragePlans);
      coverageToShow =
        defaultCoverages[coverageIndex]?.coverages?.policyCoverage;
    }
    setInitialFormData(coverageToShow);
  }, [coverageIndex]);

  const handleSubmit = (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    const dataForPersistance = {
      policyCoverage: {
        ...initialFormData,
        ...formData,
      },
    };
    if (persistanceService) persistanceService(dataForPersistance);
    //updateCoverage(dataForPersistance);
  };

  const handleChange = (event: IChangeEvent<unknown>) => {
    const formData: any = event.formData;
    setInitialFormData(formData);
    updateFormDirtyFlag(true);
  };

  const handleError = (errors: AjvError[]) => {
    errorHandling(errors);
    if (onValidateTabs) onValidateTabs(currentTab);
  };

  return (
    <Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ marginBottom: '1.875rem' }}
      >
        <Typography
          variant='h5'
          component='h1'
          className={'page-header-h5 heading-title'}
        >
          <MicrocopyComponent
            labelKey={`q2b.plan.options.plan.coverages.customize.coverage.title`}
          />
        </Typography>
      </Grid>
      <NovoForm
        disabled={readonly}
        formData={initialFormData}
        schemaName={coverageSchemaRouteKey}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onError={handleError}
        transitionTo={false}
        button={
          <button
            ref={submitBtnRef}
            id='button'
            type='submit'
            style={{ display: 'none' }}
          />
        }
      />
    </Grid>
  );
};
export default PolicyCoverages;
