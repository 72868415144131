import { Grid, Button, Typography } from '@mui/material';
import {
  AjvError,
  FormValidation,
  IChangeEvent,
  ISubmitEvent,
} from '@rjsf/core';
import Page from 'components/Page';
import { useNavigate } from 'react-router-dom';
import { getData } from 'framework/dataService/dataRehydration';
import { AddressProps } from 'framework/forms/interfaces/AddressProps';
import NovoForm, { equals } from 'framework/forms/NovoForm';
import React, { useEffect, useState } from 'react';
import {
  agentMakePayment,
  tokenizeCardAndMakePayment,
} from 'library/services/utils';
import AlertComponent from 'components/AlertComponent';
import MuiLoader from 'framework/components/MuiLoader';
import { novoNavigation, novoRoutes } from 'framework/routes';
import ButtonArrow from 'components/icons/ButtonArrow';
import MicrocopyComponent from 'framework/components/MicrocopyComponent';
import {
  getApplicationObject,
  getMetaDataItem,
} from 'framework/dataService/insuranceApplication';
import { getJson } from 'framework/dataService';
import { getDateAtMidnight, getFormattedDate } from 'utils/dateUtils';

export interface PaymentOptionType {
  id: string;
  heading?: string;
  text?: string;
  selectedOptionId?: string;
  handleOptionChange?: (value: string) => void;
}

export default function Payment() {
  const routeKey = 'Payment';
  const novoRoute = novoRoutes[routeKey];
  const navigate = useNavigate();

  const [initialFormData, setInitialFormData] = useState({} as any);
  /*  const [ignoreReadonly, setIgnoreReadOnly] = useState(true); */
  const [loader, showLoader] = useState(false);
  const [error, setError] = useState('');
  const [readonly, setReadonly] = useState(false);
  const [applicationId, setApplicationId] = useState('');
  const [isAgent, setIsAgent] = useState(false);
  const [primaryDriver, setPrimaryDriver]: any = useState({});
  const [policyId, setPolicyId] = useState('');
  const paymentPortal =
    process.env.REACT_APP_ENV === 'production'
      ? 'novoinsurance'
      : 'novoinsuranceuat';

  useEffect(() => {
    const dataForFormData = getData(routeKey);
    const application = getApplicationObject();
    const appId = getMetaDataItem('applicationId');
    const policyIdSesssion = getJson('policyId');
    const isAgentSession = getJson('isAgent');
    if (isAgentSession && isAgentSession === 'true') {
      setIsAgent(true);
    }
    let formData = {} as any;
    if (dataForFormData?.driver) {
      formData.name = dataForFormData.driver.name;
      if (dataForFormData?.metaData?.billing) {
        formData = {
          ...formData,
          ...dataForFormData.metaData.billing,
        };
      }
      /* if (dataForFormData?.metaData?.readonly){
        setIgnoreReadOnly(false);
      } */
    }
    setApplicationId(appId);
    setPolicyId(policyIdSesssion);
    if (application) {
      const pniDriver = application.drivers.filter((d: any) => d.primary);
      const pniAddress = application.locations.filter((l: any) => l.primary);
      const driverInfo = {
        firstName: pniDriver[0].name.firstName,
        lastName: pniDriver[0].name.lastName,
        email: pniDriver[0].email,
        address: pniAddress[0].address.address,
        city: pniAddress[0].address.city,
        zip: pniAddress[0].address.zip,
        state: pniAddress[0].address.state,
      };
      setPrimaryDriver(driverInfo);
    }
    setInitialFormData(formData);
    if (dataForFormData?.metaData?.readonly) {
      setReadonly(true);
    }
  }, []);

  const handleSubmit = async (
    event: ISubmitEvent<any>,
    formData: any,
    persistanceService: any
  ) => {
    showLoader(true);
    let { isError, errorMessage } = await tokenizeCardAndMakePayment(formData);
    showLoader(false);
    if (isError) {
      setError(errorMessage);
    } else {
      delete formData.cardNumber;
      delete formData.expirationDate;
      delete formData.cvv;
      delete formData.paymentInformation;
      const dataForPersistance = {
        metaDataItem: {
          billing: {
            ...formData,
          },
          readonly: true,
        },
      };
      if (persistanceService) persistanceService(dataForPersistance);
      navigate(novoNavigation.Payment.nextPage);
    }
  };

  const agentHandleSubmit = async () => {
    showLoader(true);
    let { isError, errorMessage } = await agentMakePayment();
    showLoader(false);
    if (isError) {
      setError(errorMessage);
    } else {
      navigate(novoNavigation.Payment.nextPage);
    }
  };

  const setOtherAddressFields = (location: AddressProps, formData: any) => {
    const { city, state, zipCode } = location;
    formData.city = city;
    formData.state = state;
    formData.zipCode = zipCode;
  };

  const handleChange = (event: IChangeEvent<unknown>, formData: any) => {
    if (!initialFormData.homeAddress && formData.homeAddress) {
      const dataForFormData = getData(routeKey);
      formData.location = dataForFormData.location;
    } else if (initialFormData.homeAddress && !formData.homeAddress) {
      formData.location = {};
    }

    if (!equals(formData?.location, initialFormData?.location)) {
      setOtherAddressFields(formData.location, formData);
    }
    setInitialFormData(formData);
  };

  const handleError = (errors: AjvError[]) => {
    console.error(errors);
  };

  const handleValidation = (formData: any, errors: FormValidation) => {
    if (readonly) {
      navigate(novoNavigation.Payment.nextPage);
    }
    const { location } = formData;
    if (!location || (location && !location.fullAddress)) {
      errors.location.addError('Please enter your address.');
    }
    return errors;
  };

  return (
    <Grid container className='quote-wrapper'>
      <Page routeKey={routeKey} disabled={readonly}>
        {loader && <MuiLoader />}
        {error && <AlertComponent type='error' message={error} />}
        {isAgent ? (
          <>
            <iframe
              src={`https://${paymentPortal}.securepayments.cardpointe.com/pay?cf_hidden_policyId=${policyId}&cf_hidden_applicationId=${applicationId}&total=0.01&billFName=${primaryDriver.firstName}&billLName=${primaryDriver.lastName}&billAddress1=${primaryDriver.address}&billCity=${primaryDriver.city}&billState=${primaryDriver.state}&billZip=${primaryDriver.zip}&email=${primaryDriver.email}`}
              frameBorder='0'
              width='100%'
              height='700'
            />
            <div id='paymentButton'>
              <Button
                type='submit'
                onClick={agentHandleSubmit}
                color='secondary'
                variant='contained'
              >
                Make payment
              </Button>
            </div>
          </>
        ) : (
          <NovoForm
            disabled={readonly}
            schemaName={routeKey}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onError={handleError}
            formData={initialFormData}
            validate={handleValidation}
            information={
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: '1.5rem' }}>
                <Typography
                  variant='subtitle2'
                  component='h2'
                  id='payment_disclosure'
                  className={'page-header-h2 page-header-h2-font'}
                >
                  {`Today is ${getFormattedDate(
                    getDateAtMidnight()
                  )}. You agree to allow Novo to store your payment method and authorize Novo to auto debit future payments.`}
                </Typography>
              </Grid>
            }
            button={
              <Button
                id='paymentButton'
                type='submit'
                color='secondary'
                variant='contained'
                disabled={false}
                endIcon={<ButtonArrow />}
              >
                {readonly ? (
                  <MicrocopyComponent
                    labelKey={`q2b.global.button.text.next`}
                  />
                ) : (
                  <MicrocopyComponent
                    labelKey={`q2b.global.button.text.complete.payment`}
                  />
                )}
              </Button>
            }
          ></NovoForm>
        )}
      </Page>
    </Grid>
  );
}
