import { Box, Grid } from '@mui/material';
import NovoTabList from 'framework/components/NovoTabList';
import React, { useEffect, useState } from 'react';
import PlanCoverageCars from './PlanCoverageCars';
import PlanCoverageDrivers from './PlanCoverageDrivers';
import PolicyCoverages from './PolicyCoverages';

interface NovoTabListProps {
  selectedTabData?: any;
  selectedCoverages?: any;
  quote?: any;
  quoteIndex: number;
  updateFormDirtyFlag?: any;
  updateCoverage?: any;
  errorHandling?: any;
  submitBtnRef?: any;
  submitBtnRef3?: any;
  onValidateTabs?: any;
  handleSelectedTabData?: any;
  readonly?: boolean;
}

export interface TabDataProps {
  name?: string;
  url: string;
  content?: any;
  quote?: any;
}

const PlanCoverages: React.FC<NovoTabListProps> = ({
  selectedTabData,
  selectedCoverages, // user's selections using our screen
  quote, // defaults on page load (coveragePlans)
  quoteIndex,
  updateFormDirtyFlag,
  updateCoverage,
  errorHandling,
  submitBtnRef,
  submitBtnRef3,
  onValidateTabs,
  handleSelectedTabData,
  readonly,
}) => {
  const handleCoverageChange = (data: any) => {
    updateCoverage(data);
    console.debug('updateCoverage ' + data);
    setTabs(tabData);
  };

  const tabData = [
    {
      name: 'q2b.plan.options.plan.coverages.tab.data.customize.coverage.name',
      url: '/customize-coverage',
      content: (
        <>
          <PolicyCoverages
            selectedCoverages={selectedCoverages.policyCoverage}
            coverageIndex={quoteIndex}
            updateFormDirtyFlag={updateFormDirtyFlag}
            updateCoverage={handleCoverageChange}
            errorHandling={errorHandling}
            submitBtnRef={submitBtnRef}
            readonly={readonly!}
            onValidateTabs={onValidateTabs}
            currentTab={{
              name: 'q2b.plan.options.plan.coverages.tab.data.customize.coverage.name',
              url: '/customize-coverage',
            }}
          />
        </>
      ),
    },
    {
      name: 'q2b.plan.options.plan.coverages.tab.data.drivers.name',
      url: '/drivers',
      content: (
        <PlanCoverageDrivers updateFormDirtyFlag={updateFormDirtyFlag} />
      ),
    },
    {
      name: 'q2b.plan.options.plan.coverages.tab.data.cars.name',
      url: '/cars',
      content: (
        <>
          <PlanCoverageCars
            defaultVehicleCoverage={
              quote[quoteIndex]?.coverages.vehicleCoverage[0]
            }
            coverageIndex={quoteIndex}
            coverages={selectedCoverages.vehicleCoverage}
            updateFormDirtyFlag={updateFormDirtyFlag}
            updateCoverage={handleCoverageChange}
            errorHandling={errorHandling}
            submitBtnRef={submitBtnRef3}
            onValidateTabs={onValidateTabs}
            currentTab={{
              name: 'q2b.plan.options.plan.coverages.tab.data.cars.name',
              url: '/cars',
            }}
            readonly={readonly!}
          />
        </>
      ),
    },
  ];

  const [tabs, setTabs] = useState(tabData);

  useEffect(() => {
    setTabs(tabData);
  }, [quoteIndex]);

  return (
    <Grid container className='quote-wrapper' mt={2}>
      <Grid item xs={12}>
        <NovoTabList
          tabData={tabs}
          selectedTabData={selectedTabData}
          handleSelectedTabData={handleSelectedTabData}
        />
        <Box m={1} />
      </Grid>
    </Grid>
  );
};

export default PlanCoverages;
