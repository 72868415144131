import React from 'react';
import { IIconProps } from './AvatarOne';

export default function UnionIcon({
  fillColor = 'black',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.6471 1.20741C12.6653 0.493362 11.3351 0.493362 10.3533 1.20741L1.35331 7.75287C0.62883 8.27977 0.200195 9.12151 0.200195 10.0173V21.9989C0.200195 23.5453 1.4538 24.7989 3.0002 24.7989H21.0002C22.5466 24.7989 23.8002 23.5453 23.8002 21.9989V10.0173C23.8002 9.12151 23.3716 8.27977 22.6471 7.75287L13.6471 1.20741ZM11.2944 2.50139C11.7152 2.19537 12.2852 2.19537 12.706 2.50139L21.1936 8.67415L14.2337 15.634L13.6471 15.2074C12.6653 14.4934 11.3351 14.4934 10.3533 15.2074L9.18777 16.0551L2.22989 9.0972C2.25073 9.07976 2.27224 9.06296 2.29439 9.04685L11.2944 2.50139ZM15.5437 16.5868L22.2002 21.4279V10.0173C22.2002 9.98915 22.1992 9.96109 22.1972 9.9332L15.5437 16.5868ZM1.8002 10.9303L7.87776 17.0078L1.8002 21.4279V10.9303ZM2.3491 23.0071C2.53664 23.1284 2.76019 23.1989 3.0002 23.1989H21.0002C21.2402 23.1989 21.4638 23.1284 21.6513 23.0071L12.706 16.5014C12.2852 16.1954 11.7152 16.1954 11.2944 16.5014L2.3491 23.0071Z'
        fill='black'
      />
    </svg>
  );
}
