import { Grid } from '@mui/material';
import { novoRoutes } from 'framework/routes';
import React from 'react';

import Cars from '../Cars';

interface PlanCoverageCarsProps {
  defaultVehicleCoverage?: any;
  coverageIndex?: number;
  coverages?: any;
  updateFormDirtyFlag?: any;
  updateCoverage?: any;
  errorHandling?: any;
  submitBtnRef?: any;
  onValidateTabs?: any;
  currentTab?: any;
  readonly?: boolean;
}
const PlanCoverageCars: React.FC<PlanCoverageCarsProps> = ({
  defaultVehicleCoverage,
  coverageIndex = 0,
  coverages,
  updateFormDirtyFlag,
  updateCoverage,
  errorHandling,
  submitBtnRef,
  onValidateTabs,
  currentTab,
  readonly,
}) => {
  return (
    <Grid container className=''>
      <Cars
        to={novoRoutes.PlanOptionsCars.path}
        view={'row'}
        coverageIndex={coverageIndex}
        showCoverages={true}
        defaultVehicleCoverage={defaultVehicleCoverage}
        coverages={coverages}
        errorHandling={errorHandling}
        updateFormDirtyFlag={updateFormDirtyFlag}
        updateCoverage={updateCoverage}
        submitBtnRef={submitBtnRef}
        onValidateTabs={onValidateTabs}
        currentTab={currentTab}
        readonly={readonly}
      />
    </Grid>
  );
};

export default PlanCoverageCars;
