import {
  getApplicationObjectItem,
  setApplicationObjectItem,
} from './insuranceApplication';
import { vehiclesConstant } from 'library/application/applicationConstants';
import { deleteLocation, getAllLocations, setLocation } from './locations';

export const getVehicles = () => {
  const vehicles = getApplicationObjectItem(vehiclesConstant);
  const locations = getAllLocations();
  const updatedVehicles = vehicles
    .filter((vehicle: any) => vehicle.isDeleted !== true)
    .map((vehicle: any) => {
      if (vehicle.garagingAddressSameAsPrimaryAddress === false) {
        const location = locations.find(
          (loc: any) => loc.locationId === vehicle.garagingLocationId
        );
        return {
          ...vehicle,
          location: location.address,
          ...location.address,
          zipCode: location.address?.zip,
          vehicleId: vehicle.vehicleId,
        };
      } else {
        return vehicle;
      }
    });
  return updatedVehicles;
};

export const getVehicleById = (vehicleId: string) => {
  const vehicles = getVehicles();
  if (vehicles && vehicles.length > 0) {
    let result = vehicles.find(
      (vehicle: { vehicleId: string }) => vehicle.vehicleId === vehicleId
    );
    return result;
  }
  return undefined;
};

export const getVehicleIndex = (vehicleId: string) => {
  const vehicles = getVehicles();
  if (vehicles && vehicles.length > 0) {
    let result = vehicles.findIndex(
      (vehicle: { vehicleId: string }) => vehicle.vehicleId === vehicleId
    );
    return result;
  }
  return undefined;
};

export const checkMinOneCarIncluded = () => {
  const vehicles = getVehicles();
  if (vehicles && vehicles.length > 0) {
    const includedCar = vehicles?.find(
      (vehicle: any) => vehicle.included || vehicle.included === undefined
    );
    return !!includedCar;
  }
  return false;
};

export const setVehicle = (value: any) => {
  let quoteEnvelopeItem = getVehicles();
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    const existingIndex = quoteEnvelopeItem.findIndex(
      (item: { vehicleId: any }) => item.vehicleId === value.vehicleId
    );
    if (existingIndex !== -1) {
      quoteEnvelopeItem[existingIndex] = value;
    } else {
      quoteEnvelopeItem.push(value);
    }
    if (quoteEnvelopeItem[existingIndex].isDeleted) {
      quoteEnvelopeItem.splice(existingIndex, 1);
    }
  } else {
    quoteEnvelopeItem = [value];
  }
  return setApplicationObjectItem(vehiclesConstant, quoteEnvelopeItem);
};

export const setVehicleLocation = async (value: any) => {
  let vehicle = value.vehicle;
  let location = value.location;
  let quoteEnvelopeItem = getVehicles();
  let existingIndex = -1;
  if (quoteEnvelopeItem && quoteEnvelopeItem.length > 0) {
    existingIndex = quoteEnvelopeItem.findIndex(
      (item: { vehicleId: any }) => item.vehicleId === vehicle.vehicleId
    );
  }
  if (existingIndex > -1) {
    let existingVehicle = quoteEnvelopeItem[existingIndex];
    if (
      existingVehicle.garagingAddressSameAsPrimaryAddress === false &&
      vehicle.garagingAddressSameAsPrimaryAddress === true &&
      Object.keys(location).length > 0
    ) {
      let locationIdToBeDeleted = existingVehicle.locationId;
      deleteLocation(locationIdToBeDeleted);
      delete vehicle.locationId;
    } else if (
      vehicle.garagingAddressSameAsPrimaryAddress === false &&
      location != undefined &&
      Object.keys(location).length > 0
    ) {
      setLocation(location);
    }
  }
  setVehicle(vehicle);
};
