import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'components/icons/CloseIcon';
import { Drawer, DrawerProps, Grid } from '@mui/material';
import React from 'react';
import './SideDrawer.scss';
export interface SideDrawerProps extends DrawerProps {
  id?: string;
  children: React.ReactNode;
}

const SideDrawer: React.FC<SideDrawerProps> = ({
  id = 'drawer',
  children,
  onClose,
  variant,
  anchor = 'right',
  open = false,
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Drawer
      id={id}
      anchor={anchor}
      open={open}
      onClose={onClose}
      variant={variant}
      className={'novo-slide-drawer app-wrapper'}
    >
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flex: '1 !important',
          marginTop: '5rem',
          marginBottom: '5rem',
          marginRight: '2rem',
        }}
      >
        <div className={'novo-icon-button'}>
          <IconButton onClick={handleClick}>
            <CloseIcon />
          </IconButton>
        </div>
      </Grid>
      <Grid
        item
        className={'side-drawer-container page-wrapper'}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ marginTop: '0rem' }}
      >
        {children}
      </Grid>
    </Drawer>
  );
};

export default SideDrawer;
