import React from 'react';
import { IIconProps, defaultProps } from './AvatarOne';

export default function TrashIcon({
  fillColor = 'currentColor',
  ...svgProps
}: IIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='28'
      viewBox='0 0 30 28'
      fill='none'
      {...{ ...defaultProps, ...svgProps }}
    >
      <path
        d='M24 6H6V26H24V6Z'
        stroke={fillColor}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 6H28'
        stroke={fillColor}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 2H18'
        stroke={fillColor}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 12V20'
        stroke={fillColor}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 12V20'
        stroke={fillColor}
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
